import React from "react";
import Page from "../../../common/layout/Page";
import Table from "../../../common/Table/Table";
import ClientContractListFilters from "./ClientContractListFilters";
import { Link } from "react-router-dom";
import Pagination from "../../../common/Pagination";
import useApiDatas from "../../../common/Hooks/useApiDatas";

const ClientContractList = (props) => {
  const clientId = props.match.params.clientid;

  const {
    state,
    constants,
    errors,
    setErrors,
    isLoading,
    setIsLoading,
    searchDatas,
    setSearchDatas,
    nbPages,
    setNbPages,
    getSearchDatas,
    deleteData,
    isInit,
    search,
    setSearch,
    otherDatas,
    setOtherDatas,
    nbResults,
  } = useApiDatas({
    resourcePath: `clients/${clientId}/clientcontracts`,
    page: "ClientContractList",
    searchParams: {
      clientContract: { searchString: "" },
      page: 1,
    },
    dependenciesPath: [
      `clients/${clientId}`,
      `slotcodes`,
      `clients`,
      `productionsites`,
      `sites`,
    ],
  });

  const client = otherDatas[`clients/${clientId}`];
  const slotCodes = otherDatas.slotcodes;
  const productionSites = otherDatas.productionsites;
  const sites = otherDatas.sites;
  console.log(client);

  return (
    <Page
      title={`Liste des contrats du client ${client?.name || ""}`}
      back={`/clients`}
      errors={errors}
      isLoading={isLoading}
      action={{
        to: `/contracts/${clientId}/create`,
        text: "Créer un contrat",
      }}
      isInit={isInit}
    >
      <div className="row">
        <ClientContractListFilters
          search={search}
          setSearch={setSearch}
          errors={errors}
        />
      </div>
      <div className="row ">
        <div className="col-12">
          <Table
            isLoading={isLoading}
            nbResults={nbResults}
            exportOptions={{
              excludedFields: ["Actions"],
            }}
            fields={[
              {
                name: "Cuisine",
                path: "productionSite",
                render: (el) => productionSites.find((p) => p.id == el).name,
              },
              {
                name: "Réf. plan alim.",
                path: "slotCodes",
                render: (el) =>
                  el.map((e, ek) => {
                    var sc = slotCodes.find((s) => s.id == e);

                    return (
                      <span
                        key={`${e}${ek}`}
                        className="badge text-white mr-1"
                        style={{
                          background: sc.color,
                        }}
                      >
                        {sc.name}
                      </span>
                    );
                  }),
              },
              {
                name: "Prix",
                path: "price",
                render: (el) => el + " €",
              },
              {
                name: "Sites",
                path: "sites",
                render: (el) =>
                  el.map((e, ek) => {
                    var sc = sites.find((s) => s.id == e);

                    return (
                      <span key={`${e}${ek}`} className="badge badge-dark mr-1">
                        {sc.name}
                      </span>
                    );
                  }),
              },
              {
                name: "Actions",
                path: "id",
                render: (el) => (
                  <>
                    <Link
                      to={`/contracts/${clientId}/${el}`}
                      className="btn btn-default d-center d-inline-flex"
                    >
                      <i className="fa fa-edit mr-2" />
                      Modifier
                    </Link>
                    <button
                      className="btn btn-danger"
                      onClick={() => deleteData(el)}
                    >
                      <i className="fa fa-times" />
                    </button>
                  </>
                ),
              },
            ]}
            datas={searchDatas}
          />
          <Pagination
            nbPages={nbPages}
            page={search.page}
            changePage={(p) => setSearch({ ...search, page: p })}
          />
        </div>
      </div>
    </Page>
  );
};

export default ClientContractList;
