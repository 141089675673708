import React from "react";
import tools from "../../../../helpers/tools";
import useSites from "../../../../hooks/useSites";
import Control from "../../../common/Controls/Control";
import SaveBtn from "../../../common/SaveBtn";
import useClosingPeriods from "./useClosingPeriods";

const AddClosingPeriodForm = () => {
  const { sites, orderedSitesAlpha, patchSite, errors, isSavingSite } = useSites(false);
  const { newClosingPeriod, onChangeNewClosingPeriod } = useClosingPeriods();

  const onChangeSite = (e) => {
    const { value } = e.target;
    onChangeNewClosingPeriod({
      target: {
        name: "site",
        value: tools.findIn(sites, value, "id"),
      },
    });
  };

  const saveSite = () => {
    if (!newClosingPeriod.site.id) return;
    patchSite({
      ...newClosingPeriod.site,
      closingPeriods: [
        ...newClosingPeriod.site.closingPeriods,
        {
          start: newClosingPeriod.beginAt,
          end: newClosingPeriod.endAt,
        },
      ],
    });
  };

  return (
    <div className="bg-white shadow-sm p-3 mb-3">
      <div className="row mx-0">
        <div className="col-12">
          <div>
            <strong>Ajouter une période de fermeture</strong>
          </div>
        </div>
        <div className="col-12 col-md-4">
          <Control
            label="Site"
            type="autocomplete"
            value={newClosingPeriod.site.id}
            name="ingredient"
            autoCompleteDisplayIndex="name"
            change={onChangeSite}
            datas={orderedSitesAlpha}
            dataIndex="id" //default
            dataLabel="name" //default (sera inclut dans la recherche)
            required={true}
            btnInline={false}
            hideAutocompleteOnFocusOut={false} //buggé
            noResults={false} //si aucun résultat api
            autoCompleteHasApiDatas={false} // true si afficher tous les résultats api
          />
        </div>
        <div className="col-12 col-md-4">
          <Control
            label="Entre le"
            type="date"
            name="beginAt"
            value={newClosingPeriod.beginAt}
            change={onChangeNewClosingPeriod}
            error={errors}
          />
        </div>
        <div className="col-12 col-md-4">
          <Control
            label="Et le"
            type="date"
            name="endAt"
            value={newClosingPeriod.endAt}
            change={onChangeNewClosingPeriod}
            error={errors}
          />
        </div>
        <div className="col-12 justify-content-end d-flex">
          <SaveBtn
            save={saveSite}
            isSaving={isSavingSite}
            text="Enregistrer la période de fermeture"
          />
        </div>
      </div>
    </div>
  );
};

export default AddClosingPeriodForm;
