import React, { useEffect } from "react";
import Page from "../../../../common/layout/Page";
import useStoreState from "../Context/Store/useStoreState";
import ClientSpecificities from "../GlobalMenuPage/SpecificitiesFilter/ClientSpecificities";
import MenuClient from "./MenuClient/MenuClient";
import Panels from "./Panels/Panels";

const ClientMenuPage = ({ showClientMenu }) => {
  const { state, items, items_actions, client_actions } = useStoreState();
  const { menuRef, utils, productionSites } = { ...items };

  useEffect(() => {
    if (showClientMenu) {
      client_actions.fetchClientMenu();
    }
  }, [showClientMenu]);

  let productionSiteName =
    productionSites?.find((ps) => ps.id == menuRef.productionSite)?.name || "";

  return items.menu?.slots?.length ? (
    <Page
      title={`Menu site - ${productionSiteName}`}
      style={{ maxWidth: "95%" }}
    >
      {state.isInit ? (
        <>
          <div className="row d-flex justify-content-between shadow-sm p-3 bg-white overflow-hidden position-relative rounded mb-2">
            <MenuClient />
          </div>
          {/* <div
            className="row d-flex align-items-center justify-content-start p-2 border mb-2"
            style={{ gap: "2rem" }}
          >
            <ClientSpecificities
            />
          </div> */}
          <Panels />
        </>
      ) : null}
    </Page>
  ) : null;
};

export default ClientMenuPage;
