import React from "react";
import Control from "../../../common/Controls/Control";

const SiteListFilters = ({ search, setSearch, errors }) => {
  return (
    <>
      <div className="col-12 col-md-4">
        <Control
          label="Recherche"
          type="text"
          name="searchString"
          value={search.site.searchString}
          change={(e) =>
            setSearch({
              ...search,
              page: 1,
              site: { searchString: e.target.value },
            })
          }
          error={errors}
        />
      </div>
      <div className="col-12 col-md-4"></div>
      <div className="col-12 col-md-4"></div>
    </>
  );
};

export default SiteListFilters;
