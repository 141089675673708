import dataService from "../../../../../helpers/dataService";

export const itemsActions = (state, dispatch) => {
  const initItems = async (loadUrls) => {
    dispatch({ type: "IS_LOADING", payload: true });
    var allPromises = [];
    loadUrls.forEach((url) => {
      allPromises.push(
        new Promise((resolve, reject) => {
          dataService.get(url.path, resolve, reject);
        })
      );
    });

    Promise.all(allPromises).then((values) => {
      var items = {};
      values.forEach((value, key) => {
        items[loadUrls[key].name] = value;
      });

      dispatch({
        type: "INIT_ITEMS",
        payload: items,
      });
    });
  };

  const updateItem = (e) => {
    const { name, value, k } = e.target;
    var newProductionSites = state.items.productionSites.map((p, pk) =>
      pk == k ? { ...p, [name]: value } : p
    );
    dispatch({
      type: "UPDATE_ITEM",
      payload: {
        item: "productionSites",
        value: newProductionSites,
      },
    });
  };

  return { initItems, updateItem };
};
