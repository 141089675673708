import { DateTime } from "luxon";
import React from "react";
import useStore from "../../../../context/useStore";
import tools from "../../../../helpers/tools";

const DeliveryBlockBody = ({ data }) => {
  const [state, dispatch] = useStore();
  const { CONSUME_TIMES, UNITS } = state.constants.items;

  const similarSheets = (moment) => {
    var ts = [];
    moment.consumers.forEach((consumer) => {
      consumer.technicalSheets.forEach((sheet) => {
        var tRef = ts.find(
          (t) => t.texture == consumer.texture && t.name == sheet.name && t.unit == sheet.unit
        );
        if (tRef) {
          ts = ts.map((t) => {
            if (t.texture == consumer.texture && t.name == sheet.name && t.unit == sheet.unit) {
              const hasSameNetWeight = t.otherNets.includes(sheet.net);
              let otherNets = [...t.otherNets];
              let otherQtys = [...t.otherQtys];
              if (hasSameNetWeight) {
                var indexOfNet = otherNets.indexOf(sheet.net);
                otherQtys[indexOfNet] = parseInt(otherQtys[indexOfNet]) + parseInt(sheet.qty);
              } else {
                otherNets = [...otherNets, sheet.net];
                otherQtys = [...otherQtys, sheet.qty];
              }

              return {
                ...t,
                net: t.net,
                qty: t.qty,
                otherNets,
                otherQtys,
              };
            }

            return t;
          });
        } else {
          ts.push({
            ...sheet,
            texture: consumer.texture,
            otherNets: [sheet.net],
            otherQtys: [sheet.qty],
          });
        }
      });
    });

    return ts;
  };

  return (
    <div>
      <div>
        <div className="row">
          <div
            className="col-12 text-center font-weight-bold text-primary"
            style={{ fontSize: 18 }}
          >
            {data.client.name}
          </div>
        </div>
      </div>
      {data.consumeDays.map((day, dayk) => (
        <div key={`csday${day.day}${dayk}`}>
          <div className="row">
            <div className="col-12 text-center">
              Jour de consommation :{" "}
              <span className="font-weight-bold" style={{ fontSize: 16 }}>
                {DateTime.fromISO(day.day).setLocale("FR").toFormat("EEEE dd MMMM yyyy")}
              </span>
            </div>
          </div>
          {day.moments
            .sort((a, b) => (a.moment > b.moment ? 1 : -1))
            .map((moment, momentk) => {
              var mom = CONSUME_TIMES.find((cs) => cs.id == moment.moment);
              return (
                <div key={`moment${day.day}${moment.id}`}>
                  <div className="row mx-0">
                    <div
                      className="col-12 font-weight-bold mb-2 mt-3  text-dark border-bottom"
                      style={{ fontSize: 23 }}
                    >
                      {mom ? mom.name : ""}
                    </div>
                    <div className="col-3 font-weight-bold" style={{ fontSize: 15 }}>
                      {moment.consumers.map((consumer) => {
                        var tSheets = consumer.technicalSheets.sort((a, b) =>
                          a.order > b.order ? 1 : -1
                        );
                        return (
                          <div key={`consumer${consumer.id}`}>
                            <span style={{ color: "black" }}>
                              {consumer.technicalSheets[0].qty} repas {consumer.name}
                            </span>{" "}
                            <small className="d-block">
                              <i>texture {consumer.texture}</i>
                            </small>
                            {tSheets.map((technicalSheet) => (
                              <div
                                style={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                  fontSize: 11,
                                  fontStyle: "italic",
                                }}
                              >
                                {technicalSheet.name}
                              </div>
                            ))}
                          </div>
                        );
                      })}
                    </div>
                    <div className="col-9">
                      <div className="row border-bottom text-dark">
                        {/* <div className="col-2 font-weight-bold" style={{ fontSize: 12 }}>
                          Consommateurs
                        </div> */}

                        <div className="col-5 font-weight-bold" style={{ fontSize: 12 }}>
                          Plats
                        </div>
                        <div className="col-3 font-weight-bold" style={{ fontSize: 12 }}>
                          Poids unité
                        </div>
                        <div className="col-2 font-weight-bold" style={{ fontSize: 12 }}>
                          Poids total
                        </div>
                      </div>
                      {(() => {
                        // var tSheets = consumer.technicalSheets.sort((a, b) =>
                        //   a.order > b.order ? 1 : -1
                        // );
                        var sheets = similarSheets(moment);
                        console.log("sheets", sheets);
                        return (
                          <div
                            className="row  border-bottom text-dark"
                            //key={`detcons${consumer.id}`}
                            style={{ fontSize: 16 }}
                          >
                            {/* <div className="col-2">
                              {consumer.name}{" "}
                              <small className="d-block">
                                <i>texture {consumer.texture}</i>
                              </small>
                            </div> */}

                            <div className="col-5">
                              {sheets.map((technicalSheet) => (
                                <div
                                  style={{
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {technicalSheet.name}{" "}
                                  <small>
                                    <i>{technicalSheet.texture}</i>
                                  </small>
                                </div>
                              ))}
                            </div>
                            <div className="col-3">
                              {sheets.map((technicalSheet) => (
                                <div>
                                  {/* {tools.transformQtyKg(
                                    tools.round(technicalSheet.net),
                                    technicalSheet.unit
                                  )} */}
                                  {technicalSheet.otherNets.length > 0 &&
                                    technicalSheet.otherNets
                                      .map(
                                        (onet) =>
                                          `${tools.transformQtyKg(
                                            tools.round(onet),
                                            technicalSheet.unit
                                          )}`
                                      )
                                      .join(" et ")}
                                </div>
                              ))}
                            </div>
                            <div className="col-2">
                              {sheets.map((technicalSheet) => {
                                var otherWeight = 0;
                                if (technicalSheet.otherNets.length > 0) {
                                  technicalSheet.otherNets.forEach((onet, netidx) => {
                                    otherWeight += onet * technicalSheet.otherQtys[netidx];
                                  });
                                }
                                let { qty, unit } = tools.transformQtyKgToObject(
                                  otherWeight,
                                  technicalSheet.unit
                                );
                                return (
                                  <div>
                                    {qty} {unit}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })()}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      ))}
    </div>
  );
};

export default DeliveryBlockBody;
