import React, { useState } from "react";
import Control from "../../common/Control";
import Axios from "axios";
import { API_URL, PLATFORM } from "../../../config";
import Loader from "../../common/Loader";
import SaveBtn from "../../common/SaveBtn";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

const ForgotPassword = ({ handleClickForgotPassword, captchaToken }) => {
  const [email, setEmail] = useState("");
  const [emailOk, setEmailOk] = useState(false);
  const [errors, setErrors] = useState({});
  const [checkingEmail, setCheckingEmail] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const resetPasswordHandle = (e) => {
    e.preventDefault();
    setCheckingEmail(true);
  };
  const verifyCallback = (recaptchaToken) => {
    // Here you will get the final recaptchaToken!!!
    console.log("recaptchaToken", recaptchaToken);

    if (recaptchaToken) {
      checkEmail();
    }
  };

  function checkEmail() {
    Axios.post(API_URL + "auth/renew-password/" + PLATFORM, {
      login: email,
      captchaToken,
    })
      .then((res) => {
        setCheckingEmail(false);
        setEmailSent(true);
      })
      .catch((err) => {
        console.log(err);
        setCheckingEmail(false);

        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      });
  }

  return (
    <>
      {" "}
      <form
        onSubmit={(e) => e.preventDefault()}
        className="d-flex align-items-center justify-content-start flex-column mx-auto animated fadeInRight faster login-form mt-5 custom-card"
      >
        <div className=" text-center">
          <h1 className="mt-5 pb-4 text-secondary main-title">
            J'ai oublié mon mot de passe
            <hr className="w-50 mt-4" />
          </h1>
        </div>
        {emailSent ? (
          <div>
            <p className="text-secondary mb-0 w-75 m-auto text-center">
              Un email vous a été envoyé pour réinitialiser votre mot de passe.
              Consultez votre boite email et cilquez sur le lien qu'il contient.
            </p>
            <button
              onClick={() => handleClickForgotPassword(false)}
              className="btn btn-link w-100 mt-3"
            >
              <i className="fa fa-chevron-left mr-2"></i>Revenir à la connexion
            </button>
          </div>
        ) : (
          <>
            <p className="mb-4 w-75 text-left">
              Une fois ce formulaire rempli, vous recevrez un email contenant un
              lien qui vous permettra de définir un nouveau mot de passe pour
              votre compte.
            </p>
            <Control
              label="Entrez votre email"
              className="w-75"
              value={email}
              change={(e) => setEmail(e.target.value)}
              name="email"
              error={errors}
            />
            {checkingEmail ? (
              <Loader className="my-3" withContainer={true} />
            ) : (
              <>
                <div className="w-75 mx-auto d-flex justify-content-center mt-3">
                  {
                    <SaveBtn
                      className="animated zoomIn"
                      isSaving={checkingEmail}
                      save={resetPasswordHandle}
                      text="Recevoir le lien"
                      type="primary"
                      typeBtn="button"
                    />
                  }
                </div>
                <div className="w-75 mx-auto d-flex justify-content-center mt-3">
                  <button
                    onClick={() => handleClickForgotPassword(false)}
                    style={{ fontSize: 12 }}
                    className="btn btn-link text-secondary p-0"
                    type="button"
                  >
                    <i className="fa fa-chevron-left mr-2" />
                    Revenir à la connexion
                  </button>
                </div>
              </>
            )}
          </>
        )}
      </form>
      {checkingEmail && <GoogleReCaptcha onVerify={verifyCallback} />}
    </>
  );
};

export default ForgotPassword;
