import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";

const DateSelector = ({
  beginAt,
  disabled,
  change,
  name = "beginAt",
  text = "Date du début de la semaine",
}) => {
  const [displayDateSelector, setDisplayDateSelector] = useState(false);

  useEffect(() => {
    setDisplayDateSelector(false);
  }, [beginAt]);

  return (
    <div className="">
      <label className=" d-flex justify-content-start flex-column">
        <span className="label-text">{text}</span>
        <div className="d-flex">
          {beginAt && (
            <button
              className="btn btn-primary d-center flex-column"
              type="button"
              disabled={disabled}
              onClick={() =>
                change({
                  target: {
                    name: name,
                    value: DateTime.fromISO(beginAt).minus({ week: 1 }).toISO(),
                  },
                })
              }
              style={{
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              }}
            >
              <i className="fa fa-chevron-left fa-2x" />
              {/* Semaine précédente */}
            </button>
          )}
          <button
            className="btn btn-primary"
            type="button"
            onClick={() => setDisplayDateSelector(!displayDateSelector)}
            disabled={disabled}
            style={{
              borderRadius: 0,
            }}
          >
            {beginAt
              ? DateTime.fromISO(beginAt).toFormat("dd/MM/yyyy")
              : "Cliquez pour choisir une date"}
          </button>
          {beginAt && (
            <button
              className="btn btn-primary d-center flex-column"
              type="button"
              disabled={disabled}
              onClick={() =>
                change({
                  target: {
                    name: name,
                    value: DateTime.fromISO(beginAt).plus({ week: 1 }).toISO(),
                  },
                })
              }
              style={{
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
              }}
            >
              <i className="fa fa-chevron-right fa-2x" />
              {/* Semaine suivante */}
            </button>
          )}
        </div>
      </label>
      {displayDateSelector && (
        <Calendar
          next2Label={null}
          prev2Label={null}
          onChange={(val) => {
            change({
              target: {
                name: name,
                value: DateTime.fromJSDate(val).startOf("week").startOf("day").toISO(),
              },
            });
          }}
          value={beginAt ? DateTime.fromISO(beginAt).toJSDate() : DateTime.local().toJSDate()}
        />
      )}
    </div>
  );
};

export default DateSelector;
