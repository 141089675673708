import React, { useEffect, useState } from "react";
import useStore from "../../../../context/useStore";
import tools from "../../../../helpers/tools";
import Page from "../../../common/layout/Page";
import dataService from "../../../../helpers/dataService";
import Filters from "./Filters";
import NowTime from "../NowTime";
import { DateTime } from "luxon";
import TableNav from "../BillingGlobal/TableNav";
import DayData from "./DayData";

function debounce(fn, ms) {
  let timer;
  return (_) => {
    clearTimeout(timer);
    timer = setTimeout((_) => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

const ExpensesSummary = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [state, dispatch] = useStore();
  const constants = state.constants.items;
  const { PREPARATION_CATEGORIES, INGREDIENT_UNITS } = constants;
  var searchState = tools.getState("ExpensesSummary", null);
  const [searchDone, setSearchDone] = useState(true);
  const [daysArray, setDaysArray] = useState([]);

  const [search, setSearch] = useState(
    searchState
      ? JSON.parse(searchState.search)
      : {
          month: "",
          year: "",
          productionSite: "",
        }
  );

  useEffect(() => {
    if (search.month.length && search.year.length) {
      var days = [];
      const firstDayOfMonth = DateTime.local(
        parseInt(search.year),
        parseInt(search.month),
        1
      ).startOf("day");
      var diff = firstDayOfMonth.diff(firstDayOfMonth.endOf("month"), "days").toObject();

      for (var i = 0; i <= Math.abs(Math.ceil(diff.days)); i++) {
        var date = firstDayOfMonth.plus({ days: i }).toISO();
        days.push(date);
      }
      setDaysArray(days);
    }
  }, [search.month, search.year]);

  const [datas, setDatas] = useState([]);

  const getDatas = () => {
    setSearchDone(false);

    tools.saveState("ExpensesSummary", "search", JSON.stringify(search));
    setIsLoading(true);
    dataService.post(
      `expenses/search`,
      search,
      (datas) => {
        // const testSet = datas.map((d, dk) => {
        //   if (dk == 0 || dk == 1) {
        //     return {
        //       ...d,
        //       days: d.days.map((da, dak) => {
        //         if (dak == 0) {
        //           return {
        //             ...da,
        //             expenses: [{ deliveryAmount: 20 }],
        //             purchaseAmount: 20,
        //           };
        //         }
        //         return da;
        //       }),
        //     };
        //   }
        //   return d;
        // });
        // console.log("testSet", testSet);
        setDatas(datas);
      },
      (err) => {},
      () => {
        setIsLoading(false);
        setSearchDone(true);
      }
    );
  };

  const calculateDayTotals = (day) => {
    //return { expectedTotal: 0, realTotal: 0 };

    let realTotal = 0;
    day.expenses.forEach((delivery) => {
      // expectedTotal += parseFloat(expense.purchaseAmount);

      realTotal += parseFloat(delivery.deliveryAmount);
    });

    return {
      expectedTotal: tools.round(day.purchaseAmount),
      realTotal: tools.round(realTotal),
    };
  };
  const calculateAllDaysTotals = (day) => {
    //return { expectedTotal: 0, realTotal: 0 };
    var results = { expectedTotal: 0, realTotal: 0 };
    datas.forEach((provider) => {
      var dayData = provider.days.find((d) => {
        let diff = DateTime.fromISO(d.date).diff(DateTime.fromISO(day), "days").toObject();
        if (Math.abs(diff.days) < 1) {
          var result = calculateDayTotals(d);
          results.expectedTotal += result.expectedTotal;
          results.realTotal += result.realTotal;
        }
        return false;
      });
    });
    return results;
  };
  const calculateProviderTotals = (providerId) => {
    //return { expectedTotal: 0, realTotal: 0 };

    let expectedTotal = 0;
    let realTotal = 0;
    var prodDatas = datas.find((d) => d.providerId == providerId);
    prodDatas.days.forEach((day) => {
      expectedTotal += parseFloat(day.purchaseAmount);
      day.expenses.forEach((delivery) => {
        realTotal += parseFloat(delivery.deliveryAmount);
      });
    });

    return {
      expectedTotal: tools.round(expectedTotal),
      realTotal: tools.round(realTotal),
    };
  };
  const calculateGlobalTotal = () => {
    // return { expectedTotal: 0, realTotal: 0 };

    let expectedTotal = 0;
    let realTotal = 0;
    datas.forEach((data) => {
      data.days.forEach((day) => {
        expectedTotal += parseFloat(day.purchaseAmount);
        day.expenses.forEach((delivery) => {
          realTotal += parseFloat(delivery.deliveryAmount);
        });
      });
    });

    return {
      expectedTotal: tools.round(expectedTotal),
      realTotal: tools.round(realTotal),
    };
  };

  useEffect(() => {
    var scrollDiv = document.querySelector("#table-scroll-anchor1");
    const debouncedHandleScroll = debounce(function handleScroll() {
      var scrollDiv = document.querySelector("#table-scroll-anchor1");
      var stickyDiv = document.querySelector("#sticky-column");
      if (scrollDiv && stickyDiv) {
        stickyDiv.style.transform = "translateX(" + scrollDiv.scrollLeft + "px)";
      }
    }, 10);
    if (scrollDiv) {
      scrollDiv.addEventListener("scroll", debouncedHandleScroll);
    }
    return () => {
      var scrollDiv = document.querySelector("#table-scroll-anchor1");
      if (scrollDiv) {
        scrollDiv.removeEventListener("scroll", debouncedHandleScroll);
      }
    };
  });

  useEffect(() => {
    datas.forEach((d, dk) => {
      let tcol = document.querySelector("#tcol-" + dk);
      let ccol = document.querySelector("#ccol-" + dk);
      if (tcol && ccol) {
        ccol.style.height = tcol.offsetHeight + "px";
      }
    });
  }, [datas]);

  return (
    <Page
      title="Récapitulatif des dépenses"
      titleMargin="mb-3"
      isLoading={isLoading}
      printOrientation="landscape"
      container="container-fluid"
      style={{ maxWidth: "100%" }}
    >
      <NowTime />
      <Filters setSearch={setSearch} search={search} getDatas={getDatas} isLoading={isLoading} />
      {!datas.length ? (
        searchDone ? (
          <p>Aucune donnée trouvée</p>
        ) : null
      ) : (
        <>
          <TableNav scrollTable={(dir, end) => tools.scrollTable(dir, end, 1)} />
          <div
            id={`table-scroll-anchor1`}
            className="position-relative"
            style={{
              overflowX: "auto",
              scrollBehavior: "smooth",
              width: "100%",
            }}
          >
            <table className="table table-bordered table-sm table-small-padding w-100 bg-white shadow-sm  ">
              <thead>
                <tr>
                  <th style={{ minWidth: 200, maxWidth: 200 }}>Fournisseur</th>
                  {daysArray.map((day, dk) => (
                    <th className="text-center" style={{ minWidth: 330 }} key={`fday${dk}`}>
                      {DateTime.fromISO(day).setLocale("FR").toFormat("dd LLLL ")}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {datas.map((provider, pk) => {
                  const { expectedTotal, realTotal } = calculateProviderTotals(provider.providerId);
                  return (
                    <React.Fragment key={`provider${pk}`}>
                      <tr key={`prov${pk}`}>
                        <td id={`tcol-${pk}`}>
                          <strong style={{ fontSize: 14 }}>{provider.providerName}</strong>
                          <div className="d-flex flex-row mt-2">
                            <div className="d-center flex-fill flex-column">
                              <span style={{ fontSize: 11 }}>Prévu </span>
                              <span
                                className="badge badge-primary "
                                style={{
                                  fontSize: 14,
                                }}
                              >
                                {tools.round(expectedTotal)}€
                              </span>
                            </div>

                            <div className="d-center flex-fill flex-column">
                              <span style={{ fontSize: 11 }}>Réel </span>
                              <span
                                className="badge badge-secondary"
                                style={{
                                  fontSize: 14,
                                }}
                              >
                                {realTotal}€
                              </span>
                            </div>
                          </div>
                        </td>

                        {daysArray.map((day, dk) => {
                          let dayIndex = null;
                          var dayData = provider.days.find((d, di) => {
                            let diff = DateTime.fromISO(d.date)
                              .diff(DateTime.fromISO(day), "days")
                              .toObject();
                            if (Math.abs(diff.days) < 1) {
                              dayIndex = di;
                              return true;
                            }
                            return false;
                          });
                          return (
                            <td key={`prov${pk}d${dk}`} className="text-center">
                              {dayData && (
                                <DayData
                                  dayData={dayData}
                                  datas={datas}
                                  setDatas={setDatas}
                                  providerIndex={pk}
                                  providerName={provider.providerName}
                                  dayIndex={dayIndex}
                                  productionSite={search.productionSite}
                                />
                              )}
                            </td>
                          );
                        })}
                      </tr>
                      <tr className="bg-default">
                        <td>TOTAL JOUR</td>

                        {daysArray.map((day, dk) => {
                          var dayData = provider.days.find((d) => {
                            let diff = DateTime.fromISO(d.date)
                              .diff(DateTime.fromISO(day), "days")
                              .toObject();
                            if (Math.abs(diff.days) < 1) {
                              return true;
                            }
                            return false;
                          });
                          return (
                            <td key={`dday${dk}`}>
                              <div className="d-flex flex-row justify-content-between">
                                <div className="text-left">
                                  {dayData && (
                                    <span className="" style={{ fontSize: 14 }}>
                                      Prévu :{" "}
                                      {tools.round(calculateDayTotals(dayData).expectedTotal)}€
                                    </span>
                                  )}
                                </div>
                                <div className="d-flex flex-row">
                                  <div className="text-right">
                                    {dayData && (
                                      <span
                                        className="badge badge-default"
                                        style={{ fontSize: 14 }}
                                      >
                                        Réel : {calculateDayTotals(dayData).realTotal}€
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </td>
                          );
                        })}
                      </tr>
                    </React.Fragment>
                  );
                })}
                <tr>
                  <td className="bg-dark text-white">TOTAL FOURN/JOUR</td>
                  {daysArray.map((day, dk) => {
                    var res = calculateAllDaysTotals(day);
                    return (
                      <td className="bg-dark text-white">
                        <div className="d-flex flex-row justify-content-between">
                          <div className="text-left">
                            {res && (
                              <span className="" style={{ fontSize: 14 }}>
                                Prévu : {tools.round(res.expectedTotal)}€
                              </span>
                            )}
                          </div>
                          <div className="d-flex flex-row">
                            <div className="text-right">
                              {res && (
                                <span className="badge badge-default" style={{ fontSize: 14 }}>
                                  Réel : {res.realTotal}€
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </td>
                    );
                  })}
                </tr>
              </tbody>
            </table>

            <table
              className="table table-bordered table-sm table-small-padding bg-white  "
              id="sticky-column"
              style={{
                position: "absolute",
                width: 200,
                top: 0,
                left: 0,
                boxShadow: "7px 0px 18px -11px black",
                transition: "all 200ms linear 0s",
              }}
            >
              <thead>
                <tr>
                  <th style={{ minWidth: 200, maxWidth: 200 }}>Fournisseur</th>
                </tr>
              </thead>
              <tbody>
                {datas.map((provider, pk) => {
                  const { expectedTotal, realTotal } = calculateProviderTotals(provider.providerId);
                  return (
                    <React.Fragment key={`provider${pk}`}>
                      <tr key={`prov${pk}`}>
                        <td id={`ccol-${pk}`}>
                          <strong style={{ fontSize: 14 }}>{provider.providerName}</strong>
                          <div className="d-flex flex-row mt-2">
                            <div className="d-center flex-fill flex-column">
                              <span style={{ fontSize: 11 }}>Prévu </span>
                              <span
                                className="badge badge-primary "
                                style={{
                                  fontSize: 14,
                                }}
                              >
                                {tools.round(expectedTotal)}€
                              </span>
                            </div>

                            <div className="d-center flex-fill flex-column">
                              <span style={{ fontSize: 11 }}>Réel </span>
                              <span
                                className="badge badge-secondary"
                                style={{
                                  fontSize: 14,
                                }}
                              >
                                {realTotal}€
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr className="bg-default">
                        <td style={{ height: 32 }}>TOTAL JOUR</td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
          <TableNav scrollTable={(dir, end) => tools.scrollTable(dir, end, 1)} />
          <div
            className="bg-white custom-card"
            style={{
              position: "fixed",
              bottom: 0,
              left: 16,
              width: 199,
              maxWidth: 199,
            }}
          >
            <div className="text-center mb-2 font-weight-bold">TOTAL PÉRIODE</div>
            <div className="d-flex align-items-center justify-content-around flex-row">
              <div className="d-center flex-column">
                <strong style={{ fontSize: 11 }} className="mr-1">
                  Prévu
                </strong>
                <div className="badge badge-primary" style={{ fontSize: 16 }}>
                  {tools.round(calculateGlobalTotal().expectedTotal)} €
                </div>
              </div>
              <div className="d-center flex-column">
                <strong style={{ fontSize: 11 }} className="mr-1">
                  Réel
                </strong>
                <div className="badge badge-secondary" style={{ fontSize: 16 }}>
                  {tools.round(calculateGlobalTotal().realTotal)} €
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Page>
  );
};

export default ExpensesSummary;
