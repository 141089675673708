import React, { useState } from "react";
import useStore from "../../../context/useStore";
import { sidePanelActions } from "../../../context/actions/sidePanelActions";
import RecipesList from "./RecipesList";
import dataService from "../../../helpers/dataService";
import Loader from "../../common/Loader";

const NbRecipesBtn = ({ nbRecipes, preparationId, backUrl = false }) => {
  const [state, dispatch] = useStore();
  const sp_actions = sidePanelActions(state, dispatch);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const openRecipes = async () => {
    var recipes = await getRecipes().catch((err) => ({
      error: err,
    }));
    if (!recipes.error) {
      sp_actions.updateSidePanel({
        isOpen: true,
        content: <RecipesList recipes={recipes} />,
        backUrl,
      });
    } else {
      setErrors(recipes.error);
    }
  };

  const getRecipes = () => {
    setIsLoading(true);
    return new Promise((resolve, reject) => {
      dataService.get(`preparations/${preparationId}/recipes`, resolve, reject, () =>
        setIsLoading(false)
      );
    });
  };

  return (
    <button className="btn btn-danger btn-sm" onClick={openRecipes}>
      {isLoading ? <Loader color="white" /> : nbRecipes}
    </button>
  );
};

export default NbRecipesBtn;
