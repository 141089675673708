import React from "react";
import "./Table.scss";
import ExportCSV from "../ExportCsv/ExportCsv";
import Loader from "../Loader";
import TableNav from "../../pages/Summaries/BillingGlobal/TableNav";
import tools from "../../../helpers/tools";

const Table = ({
  datas,
  fields,
  exportOptions,
  isLoading,
  noMargin = false,
  nbResults = null,
  lineBackgroundFromAttribute = false,
  lineColor = false,
  cardBg = "bg-white",
  scrollable = false,
  scrollableId = "",
}) => {
  return (
    <div className={noMargin ? "" : "mt-4"}>
      {exportOptions && (
        <div
          className={`d-flex justify-content-end animated faster mb-2 ${
            isLoading ? "fadeOut" : "fadeIn"
          }`}
        >
          <ExportCSV
            fields={fields}
            datas={exportOptions.datas || datas}
            exportOptions={exportOptions}
          />
        </div>
      )}
      {nbResults !== null && (
        <div className="d-flex align-items-center justify-content-end">
          <div className="badge text-secondary" style={{ fontSize: 12 }}>
            {isLoading ? (
              <Loader color="secondary" size={12} />
            ) : (
              <>
                {nbResults} résultat{nbResults > 0 ? "s" : ""}
              </>
            )}
          </div>
        </div>
      )}
      {scrollable && (
        <TableNav scrollTable={(dir, end) => tools.scrollTable(dir, end, scrollableId)} />
      )}
      <div
        className={`pt-0   mt-2 shadow-sm rounded ${cardBg}`}
        style={
          scrollable
            ? {
                overflowX: "auto",
                scrollBehavior: "smooth",
                width: "100%",
              }
            : {}
        }
        id={`table-scroll-anchor${scrollableId}`}
      >
        <table className="table table-striped table-borderless shadow-sm custom-table">
          <thead>
            <tr>
              {fields.map((h, hk) => (
                <th
                  className={hk == fields.length - 1 ? "d-flex  justify-content-end" : ""}
                  key={"th" + hk + h.name}
                >
                  {h.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {datas &&
              datas.map((d, dk) => (
                <tr
                  key={"dk" + dk}
                  className="animated fadeInUp faster"
                  style={{
                    animationDelay: dk * 30 + "ms",
                  }}
                >
                  {fields.map((f, fk) => (
                    <td
                      className={fk == fields.length - 1 ? "text-right" : ""}
                      style={{
                        verticalAlign: "middle",
                        background: lineBackgroundFromAttribute
                          ? d[lineBackgroundFromAttribute]
                          : "",
                        color: lineColor || "",
                      }}
                      key={"df" + dk + "" + fk}
                    >
                      {f.render ? f.render(f.path == "*" ? d : d[f.path], dk) : d[f.path]}
                    </td>
                  ))}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {scrollable && (
        <TableNav scrollTable={(dir, end) => tools.scrollTable(dir, end, scrollableId)} />
      )}
    </div>
  );
};

export default Table;
