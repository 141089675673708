import React from "react";
import tools from "../../../../helpers/tools";
import TwoPartColumn from "../Purchase/TwoPartColumn";
import purchaseTools from "./purchaseTools";
import useIngredientContext from "./useIngredientContext";

const TotalForIngredientTable = ({ productionSites, ingredientData }) => {
  const { calcTotalBySite, calcTotalQtyBySite } = purchaseTools;
  const { selectedProviders } = useIngredientContext();

  let ingredientSlots = purchaseTools.getAllIngredientSlots(ingredientData, selectedProviders);

  return (
    <table className="table table-bordered table-sm table-small-padding  bg-white shadow-sm hover-table ">
      <thead>
        <tr>
          <th style={{ minWidth: 170 }}>Date</th>
          {productionSites.map((ps) => (
            <th className="text-center" key={`thps${ps.id}`} style={{ minWidth: 170 }}>
              {ps.name}
            </th>
          ))}
          <th className="text-center bg-default" style={{ minWidth: 170 }}>
            TOTAL
          </th>
        </tr>
      </thead>
      <tbody>
        <tr className="bg-default">
          <td className="font-weight-bold">TOTAL</td>
          {productionSites.map((ps, psk) => {
            const totalForSite = calcTotalBySite(ingredientSlots, ps.id);
            const totalQtyForSite = calcTotalQtyBySite(ingredientSlots, ps.id);

            return (
              <td>
                <TwoPartColumn
                  spaceBetween
                  col1Text={<>{tools.round(totalForSite.previousTotal)}€ (N-1)</>}
                  col1ClassName="font-italic text-black-50"
                  col2Text={
                    <>
                      {tools.round(totalQtyForSite.previousTotal)}
                      Kg (N-1)
                    </>
                  }
                  col2ClassName="font-italic text-black-50"
                  key={`thcoltot${ps.id}`}
                />
                <TwoPartColumn
                  spaceBetween
                  col1Text={
                    <>
                      {tools.round(totalForSite.total)}€
                      {totalForSite.evolPct != "" && (
                        <sup className={totalForSite.evolPct > 0 ? "text-danger" : "text-success"}>
                          {totalForSite.evolPct > 0 ? "+" : ""}
                          {tools.round(totalForSite.evolPct)}%
                        </sup>
                      )}
                    </>
                  }
                  col1ClassName="font-weight-bold"
                  col2Text={
                    <>
                      {tools.round(totalQtyForSite.total)}Kg
                      {totalQtyForSite.evolPct != "" && (
                        <sup
                          className={totalQtyForSite.evolPct > 0 ? "text-danger" : "text-success"}
                        >
                          {totalQtyForSite.evolPct > 0 ? "+" : ""}
                          {tools.round(totalQtyForSite.evolPct)}%
                        </sup>
                      )}
                    </>
                  }
                  col2ClassName="font-weight-bold"
                  key={`thcoltot${ps.id}`}
                />
              </td>
            );
          })}
          <td>
            {(() => {
              let totalForSite = calcTotalBySite(ingredientSlots);
              return (
                <TwoPartColumn
                  spaceBetween
                  col1Text={<>{tools.round(totalForSite.previousTotal)}€ (N-1)</>}
                  col1ClassName="font-italic text-black-50"
                  col2Text={<>{tools.round(totalForSite.previousTotal)}Kg (N-1)</>}
                  col2ClassName="font-italic text-black-50"
                />
              );
            })()}
            {(() => {
              let totalForSite = calcTotalQtyBySite(ingredientSlots);
              return (
                <TwoPartColumn
                  spaceBetween
                  col1Text={
                    <>
                      {tools.round(totalForSite.total)}€
                      {totalForSite.evolPct != "" && (
                        <sup className={totalForSite.evolPct > 0 ? "text-danger" : "text-success"}>
                          {totalForSite.evolPct > 0 ? "+" : ""}
                          {tools.round(totalForSite.evolPct)}%
                        </sup>
                      )}
                    </>
                  }
                  col1ClassName="font-weight-bold"
                  col2Text={
                    <>
                      {tools.round(totalForSite.total)}Kg
                      {totalForSite.evolPct != "" && (
                        <sup className={totalForSite.evolPct > 0 ? "text-danger" : "text-success"}>
                          {totalForSite.evolPct > 0 ? "+" : ""}
                          {tools.round(totalForSite.evolPct)}%
                        </sup>
                      )}
                    </>
                  }
                  col2ClassName="font-weight-bold"
                />
              );
            })()}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default TotalForIngredientTable;
