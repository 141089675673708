import React from "react";
import dataService from "../../../../helpers/dataService";

const ChangeOrder = ({ dishTypes, dishType, callback }) => {
  const updateOrder = (dir) => {
    var newPos = dishType.order + (dir ? 1 : -1);
    var otherDishtype = dishTypes.find((d) => d.order == newPos);
    dataService.patch(
      "dishtypes/" + dishType.id,
      { ...dishType, order: newPos },
      () => {
        dataService.patch(
          "dishtypes/" + otherDishtype.id,
          { ...otherDishtype, order: dishType.order },
          () => {
            callback();
          }
        );
      }
    );
  };

  return (
    <>
      <button
        data-priv="create_modify_dish_types"
        className="btn btn-default btn-sm"
        onClick={() => updateOrder(false)}
      >
        <i className="fa fa-arrow-up" />
      </button>
      <span className="mx-2">{dishType.order}</span>
      <button
        data-priv="create_modify_dish_types"
        className="btn btn-default btn-sm"
        onClick={() => updateOrder(true)}
      >
        <i className="fa fa-arrow-down" />
      </button>
    </>
  );
};

export default ChangeOrder;
