import React from "react";

const DishSlotItem = ({
  ds,
  DAYS,
  CONSUME_TIMES,
  dishSlotsSelected,
  setDishSlotsSelected,
  slotCodes,
}) => {
  const slotDsref = slotCodes.find((s) => s.id == ds.slotCode);
  const slotName = slotDsref?.name || "Réf. non trouvée";

  return (
    <div className="d-between w-100 border">
      <div
        className="p-2 d-center text-white"
        style={{
          width: 62,
          background: ds.color,
          textShadow: "0 0 6px black",
          overflow: "hidden",
          fontSize: 12,
        }}
      >
        {slotName}
      </div>
      <div>
        {DAYS.find((d) => d.id == ds.day).name},{" "}
        {CONSUME_TIMES.find((c) => c.id == ds.moment).name}
      </div>
      {dishSlotsSelected.find((d) => d.id == ds.id) ? (
        <button
          className="btn btn-default"
          onClick={() =>
            setDishSlotsSelected((d) => [...d.filter((di) => di.id != ds.id)])
          }
        >
          <i className="fa fa-minus" />
        </button>
      ) : (
        <button
          className="btn btn-default"
          onClick={() => setDishSlotsSelected((d) => [...d, ds])}
        >
          <i className="fa fa-plus" />
        </button>
      )}
    </div>
  );
};

export default DishSlotItem;
