import React, { useRef } from "react";
import LabelContainer from "../LabelContainer";
import ControlTools from "../ControlTools";
import EmptyButton from "./EmptyButton";

const FloatInput = ({
  k = -1,
  autoFocus,
  autocomplete = "on",
  inputStyle = {},
  inputClassName = "",
  type,
  disabled = false,
  id = null,
  value,
  change = () => {},
  placeholder = "",

  ...props
}) => {
  const inputRef = useRef(null);
  const valueChange = (e) => {
    var filteredValue = ControlTools.floatWithSpaces(e.target.value).split(".");
    var leftSide = parseInt(filteredValue[0].split(" ").join(""), 10);
    filteredValue =
      leftSide +
      (filteredValue[1] || e.target.value[e.target.value.length - 1] == "."
        ? "." + filteredValue[1].split(" ").join("")
        : "");

    let changeEvent = {
      target: {
        name: props.name,
        value: !isNaN(filteredValue) ? filteredValue : "",
        k: k,
      },
    };

    change(changeEvent);
  };

  const formatValue = (value) => {
    return ControlTools.floatWithSpaces(value || "");
  };

  return (
    <LabelContainer {...props} value={value}>
      <input
        ref={inputRef}
        autoFocus={autoFocus}
        onBlur={props.onBlur}
        autoComplete={autocomplete}
        style={inputStyle}
        className={"form-control " + inputClassName}
        pattern="[0-9]*"
        type="text"
        disabled={disabled ? "disabled" : false}
        id={k != -1 ? props.name + "-" + k : id ? id : props.name}
        name={props.name}
        value={formatValue(value)}
        onChange={valueChange}
        placeholder={placeholder}
      />
      <EmptyButton change={valueChange} name={props.name} targetInput={inputRef} value={value} />
    </LabelContainer>
  );
};

export default FloatInput;
