import React from "react";
import useStoreState from "../useStoreState";

const ContractsContainer = ({ site }) => {
  const { state, items, items_actions } = useStoreState();
  const { client, sites, contracts } = { ...items };

  return (
    <>
      {contracts
        .filter((c) => c.sites.includes(site.id))
        .map((c, ck) => {
          return (
            <div className="d-flex flex-row">
              <button
                key={`contract${site.id}${c.id}`}
                className="btn btn-primary btn-sm my-1 d-flex flex-fill"
                onClick={(e) =>
                  items_actions.setEntitySelected("contract", c.id)
                }
              >
                {c.name}
              </button>
              <button
                data-priv="create_modify_contract"
                className="btn btn-sm"
                onClick={() => items_actions.removeContract(c.id)}
              >
                <i className="fa fa-times text-danger" />
              </button>
            </div>
          );
        })}
    </>
  );
};

export default ContractsContainer;
