import tools from "../../../../helpers/tools";

const purchaseTools = {
  calcTotal: (data) => {
    var result = { total: 0, previousTotal: 0 };

    var entry = "price";
    result.total = tools.round(
      data.productionSites.reduce(
        (a, b) => {
          return { [entry]: a[entry] + b[entry] };
        },
        { [entry]: 0 }
      )[entry]
    );
    entry = "previousPrice";
    result.previousTotal = tools.round(
      data.productionSites.reduce(
        (a, b) => {
          return { [entry]: a[entry] + b[entry] };
        },
        { [entry]: 0 }
      )[entry]
    );
    return {
      ...result,
      evolPct: tools.getPct(result.total - result.previousTotal, result.total),
    };
  },
  calcTotalBySite: (datas, productionSiteId) => {
    let result = {
      total: 0,
      previousTotal: 0,
    };
    for (var key in datas) {
      var entry = datas[key];
      for (var key2 in entry.productionSites) {
        var ps = entry.productionSites[key2];
        if (!productionSiteId || ps.productionSite == productionSiteId) {
          result.total += ps.price;
          result.previousTotal += ps.previousPrice;
        }
      }
    }

    return {
      ...result,
      evolPct: tools.getPct(result.total - result.previousTotal, result.total),
    };
  },
};

export default purchaseTools;
