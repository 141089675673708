import { useState } from "react";
import useSites from "../../../../hooks/useSites";

const useClosingPeriods = () => {
  const [newClosingPeriod, setNewClosingPeriod] = useState({
    site: {},
    beginAt: "",
    endAt: "",
  });

  const onChangeNewClosingPeriod = (e) => {
    const { name, value } = e.target;

    setNewClosingPeriod({
      ...newClosingPeriod,
      [name]: value,
    });
  };

  return {
    newClosingPeriod,
    onChangeNewClosingPeriod,
  };
};

export default useClosingPeriods;
