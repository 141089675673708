import React, { useEffect, useState } from "react";
import dataService from "../../../../../../helpers/dataService";
import tools from "../../../../../../helpers/tools";
import Control from "../../../../../common/Controls/Control";
import DynamicList from "../../../../../common/DynamicList";
import SimpleDynamicList from "../../../../../common/SimpleDynamicList";
import useStoreState from "../../Context/Store/useStoreState";

const Specificities = () => {
  const { items, items_actions } = useStoreState();
  const { specificities, utils, menuRef } = { ...items };
  const specificitiesFilter = [...items.utils.specificitiesFilter];
  const [selSpec, setSelSpec] = useState("");

  // useEffect(() => {
  //   items_actions.updateSpecificitiesFilter(specificities.map((sp) => sp.id));
  // }, []);

  const addSpec = (specId) => {
    items_actions.updateSpecificitiesFilter([...specificitiesFilter, specId]);
    setSelSpec("");
  };
  const removeSpec = (specId) => {
    items_actions.updateSpecificitiesFilter([...specificitiesFilter.filter((s) => s !== specId)]);
  };
  const [isOpenedToOrders, setIsOpenedToOrders] = useState(menuRef.isAvailable);

  useEffect(() => {
    setIsOpenedToOrders(menuRef.isAvailable);
  }, [menuRef.isAvailable]);

  const toggleIsAvailable = (isChecked) => {
    //menuref/:id/isavailable
    dataService.patch(
      `menurefs/${menuRef.id}/isAvailable`,
      {
        isAvailable: isChecked,
      },
      (data) => {
        setIsOpenedToOrders(isChecked);
      }
    );
  };

  const getNewLetter = (specificity, specificityIndex) => {
    const prevSpecificity = specificities[specificityIndex - 1];
    let newLetter = "";
    if (prevSpecificity) {
      newLetter = prevSpecificity.name[0] != specificity.name[0] ? specificity.name[0] : "";
    } else {
      newLetter = specificity.name[0];
    }
    return newLetter ? <div>{newLetter}</div> : null;
  };

  return (
    <>
      <div className="d-flex align-items-end justify-content-between w-100">
        <Control
          label="Afficher tous les plats contenant les spécificités sélectionnées : "
          type="select"
          name="specificity"
          value={selSpec}
          datas={specificities.filter((sp) => !specificitiesFilter.includes(sp.id))}
          change={(e) => addSpec(e.target.value)}
          dataIndex="id"
          dataLabel="name"
          disabled={utils.editSlotId}
        />
        <Control
          label={`Menu ${isOpenedToOrders ? "ouvert" : "fermé"} aux commandes client`}
          type="checkbox"
          name="isOpenedToOrders"
          checked={isOpenedToOrders}
          change={(e) => toggleIsAvailable(e.target.checked)}
        />
      </div>
      <div
        className="flex-wrap d-flex align-items-center justify-content-start"
        style={{ gap: "0.2rem" }}
      >
        {specificitiesFilter
          .sort((a, b) => (a.name > b.name ? -1 : 1))
          .map((sp, spk) => {
            const newLetter = getNewLetter(tools.findIn(specificities, sp, "id"), spk);
            return (
              <>
                {newLetter ? <div className="font-weight-bold">{newLetter}</div> : null}

                <button
                  className="btn btn-primary btn-xsm"
                  disabled={utils.editSlotId}
                  onClick={() => removeSpec(sp)}
                >
                  {specificities.find((s) => s.id == sp).name}
                  <i className="fa fa-times cursor-pointer ml-2" />
                </button>
              </>
            );
          })}
      </div>
    </>
  );
};

export default Specificities;
