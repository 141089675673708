import React, { useEffect } from "react";
import Control from "../../../common/Controls/Control";
import useStoreState from "../useStoreState";

const ProductionSitesList = () => {
  const {
    state,
    items,
    dispatch,
    gState,
    gDispatch,
    items_actions,
  } = useStoreState();
  const { productionSites } = { ...items };

  useEffect(() => {
    console.log("updated production site");
  }, [productionSites]);

  return (
    <>
      <ul>
        {productionSites.map((p, pk) => (
          <li key={`item${pk}`}>
            <Control
              k={pk}
              label="Nom"
              type="text"
              name="name"
              value={p.name}
              change={items_actions.updateItem}
            />
          </li>
        ))}
      </ul>
    </>
  );
};

export default ProductionSitesList;
