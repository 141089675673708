import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import dataService from "../../../../helpers/dataService";
import tools from "../../../../helpers/tools";
import Page from "../../../common/layout/Page";
import TableNav from "../BillingGlobal/TableNav";
import NowTime from "../NowTime";
import TwoPartColumn from "../Purchase/TwoPartColumn";
import Filters from "./Filters";
import useProductionSites from "../../../../hooks/useProductionSites";
import purchaseTools from "./purchaseTools";
import useIngredients from "../../../../hooks/useIngredients";
import Control from "../../../common/Controls/Control";
import { IngredientProvider } from "./useIngredientContext";
import SelectProviders from "./SelectProviders";
import TotalForIngredientTable from "./TotalForIngredientTable";

const PurchaseIngredientSummary = () => {
  const [isLoading, setIsLoading] = useState(false);
  var searchState = tools.getState("SummaryIngredient", null);
  const [searchDone, setSearchDone] = useState(true);
  const { productionSites, setProductionSites } = useProductionSites();
  const { calcTotal, calcTotalBySite, calcTotalQty, calcTotalQtyBySite } = purchaseTools;
  const { ingredients } = useIngredients();
  const [showDetails, setShowDetails] = useState([]);

  const toggleDetails = (ingredientId) => {
    if (showDetails.includes(ingredientId)) {
      setShowDetails(showDetails.filter((ing) => ing !== ingredientId));
    } else {
      setShowDetails([...showDetails, ingredientId]);
    }
  };

  const [datas, setDatas] = useState([]);
  const [search, setSearch] = useState(
    searchState
      ? JSON.parse(searchState.search)
      : {
          ingredients: [],
          beginAt: DateTime.local().toISO(),
          endAt: DateTime.local().toISO(),
          timeScale: "DAY",
        }
  );

  const getDatas = () => {
    if (checkPeriod()) {
      setSearchDone(false);

      tools.saveState("SummaryIngredient", "search", JSON.stringify(search));
      setIsLoading(true);
      dataService.post(
        `summaries/purchases/ingredients`,
        search,
        setDatas,
        (err) => {},
        () => {
          setIsLoading(false);
          setSearchDone(true);
        }
      );
    }
  };

  useEffect(() => {
    setDatas([]);
  }, [search]);

  const scrollTable = (backward, toEnd, elemId) => {
    var container = document.querySelector(`#table-scroll-anchor${elemId}`);
    if (backward) {
      container.scrollLeft -= toEnd ? 100000 : 500;
    } else {
      container.scrollLeft += toEnd ? 100000 : 500;
    }
  };

  const getPeriodDateFormat = () => {
    //exemple d'appel : DateTime.fromISO(data.date).setLocale("FR").toFormat(getPeriodDateFormat())
    switch (search.timeScale) {
      case "DAY":
        return "EEEE dd LLLL yy";
      case "WEEK":
        return "dd LLLL yyyy";
      case "MONTH":
        return "LLLL yyyy";
      case "YEAR":
        return "yyyy";
      default:
        return "dd MM yyyy";
    }
  };

  const checkPeriod = () => {
    var period = "";
    switch (search.timeScale) {
      case "DAY":
        period = DateTime.fromISO(search.beginAt)
          .diff(DateTime.fromISO(search.endAt), "days")
          .toObject();
        break;
      case "WEEK":
        period = DateTime.fromISO(search.beginAt)
          .diff(DateTime.fromISO(search.endAt), "weeks")
          .toObject();
        break;
      case "MONTH":
        period = DateTime.fromISO(search.beginAt)
          .diff(DateTime.fromISO(search.endAt), "months")
          .toObject();
        break;
      case "YEAR":
        period = DateTime.fromISO(search.beginAt)
          .diff(DateTime.fromISO(search.endAt), "years")
          .toObject();
        break;
    }
    var diffValue = Object.values(period)[0];
    if (diffValue > 0) {
      toast.warning("La date de fin ne peut être antérieure à la date de début");
      return false;
    }
    if (diffValue > 0) {
      toast.warning("La période n'a pas une durée suffisante pour l'échelon sélectionné");
      return false;
    }
    return true;
  };
  console.log("showDetails", showDetails);

  return (
    <Page
      title="Récapitulatif des achats par ingrédient"
      titleMargin="mb-3"
      isLoading={isLoading}
      printOrientation="landscape"
      container="container-fluid"
      style={{ maxWidth: "100%" }}
    >
      <NowTime />
      <Filters search={search} setSearch={setSearch} getDatas={getDatas} isLoading={isLoading} />
      {!datas.length
        ? null
        : datas.map((ingredient, ingk) => {
            const ingredientRef = tools.findIn(ingredients, ingredient.ingredient, "id");

            let ingredientProviders = purchaseTools.getAllIngredientProviders(ingredient.data);
            let detailsOpen = showDetails.includes(ingredientRef.id);

            return (
              <div key={`ingk${ingk}`}>
                {/* TOTAL FOR ALL PROVIDERS  */}
                {/* TOTAL FOR ALL PROVIDERS  */}
                {/* TOTAL FOR ALL PROVIDERS  */}
                <IngredientProvider providers={ingredientProviders}>
                  <div className="py-3  mt-4 mb-2">
                    <h4 className="py-0 mb-3">
                      <span className="text-primary">
                        {"<"}
                        {ingredientRef.name}
                        {">"}
                      </span>{" "}
                      Total achats tous fournisseurs
                    </h4>
                    {/* <div className="px-3">
                      <SelectProviders />
                    </div> */}
                    <div
                      id={`table-scroll-anchortotalprov${ingredient.ingredient}`}
                      style={{
                        overflowX: "auto",
                        scrollBehavior: "smooth",
                        width: "100%",
                      }}
                    >
                      <TotalForIngredientTable
                        productionSites={productionSites}
                        ingredientData={ingredient.data}
                      />
                    </div>
                    <TableNav
                      scrollTable={(dir, end) =>
                        scrollTable(dir, end, `totalprov${ingredient.ingredient}`)
                      }
                    />
                  </div>
                </IngredientProvider>
                <div className="text-center">
                  <button
                    className="btn btn-link text-secondary text-uppercase"
                    onClick={() => toggleDetails(ingredientRef.id)}
                  >
                    {detailsOpen ? "Masquer" : "Afficher"} le détail{" "}
                    <span className="text-primary">
                      {"<"}
                      {ingredientRef.name}
                      {">"}
                    </span>{" "}
                    par fournisseur
                  </button>
                </div>
                {detailsOpen && (
                  <div>
                    {ingredient.data.map((provider, pk) => {
                      let scrollId = `${ingredient.ingredient}${provider.provider.id}`;
                      return (
                        <>
                          <div>
                            <strong>
                              {provider.provider.name} <small>({ingredientRef.name})</small>
                            </strong>
                          </div>
                          <div
                            id={`table-scroll-anchor${scrollId}`}
                            style={{
                              overflowX: "auto",
                              scrollBehavior: "smooth",
                              width: "100%",
                            }}
                          >
                            <table className="table table-bordered table-sm table-small-padding  bg-white shadow-sm hover-table ">
                              <thead>
                                <tr>
                                  <th style={{ minWidth: 170 }}>Date</th>
                                  {productionSites.map((ps) => (
                                    <th
                                      className="text-center"
                                      key={`thps${ps.id}`}
                                      style={{ minWidth: 170 }}
                                    >
                                      {ps.name}
                                    </th>
                                  ))}
                                  <th className="text-center bg-default" style={{ minWidth: 170 }}>
                                    TOTAL
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="text-right">
                                    <i>PRIX/POIDS</i>
                                  </td>
                                  {productionSites.map((ps, psk) => (
                                    <td>
                                      <TwoPartColumn
                                        spaceBetween
                                        col1Text="Prix"
                                        col2Text="Poids"
                                        key={`thcol${ps.id}`}
                                      />
                                    </td>
                                  ))}
                                  <td className="bg-default">
                                    <TwoPartColumn spaceBetween col1Text="Prix" col2Text="Poids" />
                                  </td>
                                </tr>
                                {provider.slots.map((slot, slotk) => {
                                  var totals = calcTotal(slot);
                                  var totalsQty = calcTotalQty(slot);
                                  return (
                                    <tr key={`prov${pk}${slotk}`}>
                                      <td>
                                        {DateTime.fromISO(slot.date)
                                          .setLocale("FR")
                                          .toFormat(getPeriodDateFormat())}
                                      </td>
                                      {productionSites.map((ps, psk) => {
                                        let entryPs = slot.productionSites.find(
                                          (p) => p.productionSite == ps.id
                                        );
                                        var prevPrice = entryPs ? entryPs.previousPrice : "x";
                                        var price = entryPs ? entryPs.price : "x";
                                        var evolPct = entryPs
                                          ? tools.getPct(price - prevPrice, price)
                                          : null;
                                        var prevQty = entryPs ? entryPs.previousQty : "x";
                                        var qty = entryPs ? entryPs.qty : "x";
                                        var evolQtyPct = entryPs
                                          ? tools.getPct(qty - prevPrice, qty)
                                          : null;

                                        return (
                                          <td>
                                            <TwoPartColumn
                                              spaceBetween
                                              col1Text={
                                                <>
                                                  {tools.round(prevPrice)}
                                                  {prevPrice != "x" && "€ (N-1)"}
                                                </>
                                              }
                                              col2Text={
                                                <>
                                                  {tools.round(prevQty)}
                                                  {prevPrice != "x" && "Kg (N-1)"}
                                                </>
                                              }
                                              col1ClassName="font-italic text-black-50"
                                              col2ClassName="font-italic text-black-50"
                                            />
                                            <TwoPartColumn
                                              spaceBetween
                                              col1Text={
                                                <>
                                                  <span>{tools.round(price)}</span>
                                                  {price != "x" && "€"}
                                                  {evolPct && evolPct != "" && (
                                                    <sup
                                                      className={`${
                                                        evolPct > 0 ? "text-danger" : "text-success"
                                                      }`}
                                                    >
                                                      {evolPct > 0 ? "+" : ""}
                                                      {tools.round(evolPct)}%
                                                    </sup>
                                                  )}
                                                </>
                                              }
                                              col2Text={
                                                <>
                                                  <span className="hover-underline cursor-pointer">
                                                    {tools.round(qty)}
                                                  </span>
                                                  {qty != "x" && "Kg"}
                                                  {evolPct && evolPct != "" && (
                                                    <sup
                                                      className={`${
                                                        evolQtyPct > 0
                                                          ? "text-danger"
                                                          : "text-success"
                                                      }`}
                                                    >
                                                      {evolQtyPct > 0 ? "+" : ""}
                                                      {tools.round(evolQtyPct)}%
                                                    </sup>
                                                  )}
                                                </>
                                              }
                                              col1ClassName="font-weight-bold"
                                              col2ClassName="font-weight-bold"
                                            />
                                          </td>
                                        );
                                      })}
                                      <td className="bg-default">
                                        <TwoPartColumn
                                          spaceBetween
                                          col1Text={<>{tools.round(totals.previousTotal)}€ (N-1)</>}
                                          col2Text={
                                            <>{tools.round(totalsQty.previousTotal)}Kg (N-1)</>
                                          }
                                          col1ClassName="font-italic text-black-50"
                                          col2ClassName="font-italic text-black-50"
                                        />
                                        <TwoPartColumn
                                          spaceBetween
                                          col1Text={
                                            <>
                                              {tools.round(totals.total)}€
                                              {totals.evolPct != "" && (
                                                <sup
                                                  className={
                                                    totals.evolPct > 0
                                                      ? "text-danger"
                                                      : "text-success"
                                                  }
                                                >
                                                  {totals.evolPct > 0 ? "+" : ""}
                                                  {tools.round(totals.evolPct)}%
                                                </sup>
                                              )}
                                            </>
                                          }
                                          col2Text={
                                            <>
                                              {tools.round(totalsQty.total)}Kg
                                              {totalsQty.evolPct != "" && (
                                                <sup
                                                  className={
                                                    totalsQty.evolPct > 0
                                                      ? "text-danger"
                                                      : "text-success"
                                                  }
                                                >
                                                  {totalsQty.evolPct > 0 ? "+" : ""}
                                                  {tools.round(totalsQty.evolPct)}%
                                                </sup>
                                              )}
                                            </>
                                          }
                                          col1ClassName="font-weight-bold"
                                          col2ClassName="font-weight-bold"
                                        />
                                      </td>
                                    </tr>
                                  );
                                })}
                                <tr className="bg-default">
                                  <td className="font-weight-bold">TOTAL</td>
                                  {productionSites.map((ps, psk) => {
                                    const totalForSite = calcTotalBySite(provider.slots, ps.id);
                                    const totalQtyForSite = calcTotalQtyBySite(
                                      provider.slots,
                                      ps.id
                                    );

                                    return (
                                      <td key={`ing${ingk}totps${psk}prov${pk}`}>
                                        <TwoPartColumn
                                          spaceBetween
                                          col1Text={
                                            <>{tools.round(totalForSite.previousTotal)}€ (N-1)</>
                                          }
                                          col1ClassName="font-italic text-black-50"
                                          col2Text={
                                            <>
                                              {tools.round(totalQtyForSite.previousTotal)}
                                              Kg (N-1)
                                            </>
                                          }
                                          col2ClassName="font-italic text-black-50"
                                        />
                                        <TwoPartColumn
                                          spaceBetween
                                          col1Text={
                                            <>
                                              {tools.round(totalForSite.total)}€
                                              {totalForSite.evolPct != "" && (
                                                <sup
                                                  className={
                                                    totalForSite.evolPct > 0
                                                      ? "text-danger"
                                                      : "text-success"
                                                  }
                                                >
                                                  {totalForSite.evolPct > 0 ? "+" : ""}
                                                  {tools.round(totalForSite.evolPct)}%
                                                </sup>
                                              )}
                                            </>
                                          }
                                          col1ClassName="font-weight-bold"
                                          col2Text={
                                            <>
                                              {tools.round(totalQtyForSite.total)}Kg
                                              {totalQtyForSite.evolPct != "" && (
                                                <sup
                                                  className={
                                                    totalQtyForSite.evolPct > 0
                                                      ? "text-danger"
                                                      : "text-success"
                                                  }
                                                >
                                                  {totalQtyForSite.evolPct > 0 ? "+" : ""}
                                                  {tools.round(totalQtyForSite.evolPct)}%
                                                </sup>
                                              )}
                                            </>
                                          }
                                          col2ClassName="font-weight-bold"
                                        />
                                      </td>
                                    );
                                  })}
                                  <td>
                                    {(() => {
                                      let totalForSite = calcTotalBySite(provider.slots);
                                      return (
                                        <TwoPartColumn
                                          spaceBetween
                                          col1Text={
                                            <>{tools.round(totalForSite.previousTotal)}€ (N-1)</>
                                          }
                                          col1ClassName="font-italic text-black-50"
                                          col2Text={
                                            <>{tools.round(totalForSite.previousTotal)}Kg (N-1)</>
                                          }
                                          col2ClassName="font-italic text-black-50"
                                        />
                                      );
                                    })()}
                                    {(() => {
                                      let totalForSite = calcTotalQtyBySite(provider.slots);
                                      return (
                                        <TwoPartColumn
                                          spaceBetween
                                          col1Text={
                                            <>
                                              {tools.round(totalForSite.total)}€
                                              {totalForSite.evolPct != "" && (
                                                <sup
                                                  className={
                                                    totalForSite.evolPct > 0
                                                      ? "text-danger"
                                                      : "text-success"
                                                  }
                                                >
                                                  {totalForSite.evolPct > 0 ? "+" : ""}
                                                  {tools.round(totalForSite.evolPct)}%
                                                </sup>
                                              )}
                                            </>
                                          }
                                          col1ClassName="font-weight-bold"
                                          col2Text={
                                            <>
                                              {tools.round(totalForSite.total)}Kg
                                              {totalForSite.evolPct != "" && (
                                                <sup
                                                  className={
                                                    totalForSite.evolPct > 0
                                                      ? "text-danger"
                                                      : "text-success"
                                                  }
                                                >
                                                  {totalForSite.evolPct > 0 ? "+" : ""}
                                                  {tools.round(totalForSite.evolPct)}%
                                                </sup>
                                              )}
                                            </>
                                          }
                                          col2ClassName="font-weight-bold"
                                        />
                                      );
                                    })()}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <TableNav
                            scrollTable={(dir, end) => scrollTable(dir, end, `${scrollId}`)}
                          />
                        </>
                      );
                    })}

                    <div className="text-center">
                      <button
                        className="btn btn-link text-secondary text-uppercase"
                        onClick={() => toggleDetails(ingredientRef.id)}
                      >
                        {detailsOpen ? "Masquer" : "Afficher"} le détail{" "}
                        <span className="text-primary">
                          {"<"}
                          {ingredientRef.name}
                          {">"}
                        </span>{" "}
                        par fournisseur
                      </button>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
    </Page>
  );
};

export default PurchaseIngredientSummary;
