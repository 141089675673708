import React, { useContext, useEffect } from "react";
import { itemsActions } from "./Context/actions/ItemsActions";
import useItemStore from "./Context/useItemStore";
import Page from "../../common/layout/Page";
const ItemsPage = (props) => {
  const [state, dispatch] = useItemStore();
  const item_actions = itemsActions(state, dispatch);

  useEffect(() => {
    item_actions.initItems(props.items);
  }, []);

  return (
    <Page title={props.pageTitle} isLoading={state.isLoading}>
      {state.isInit ? props.children : null}
    </Page>
  );
};

export default ItemsPage;
