import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useStore from "../../../context/useStore";
import dataService from "../../../helpers/dataService";
import Control from "../Controls/Control";
import SaveBtn from "../SaveBtn";

const ModifyPassword = ({ route, showPassword, setShowPassword }) => {
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  const [passwordsIdentical, setPasswordsIdentical] = useState(false);

  const save = () => {
    setIsLoading(true);
    dataService.patch(
      route,
      { password },
      (datas) => {
        setIsLoading(false);
        toast.success("Le mot de passe de cet utilisateur a bien été modifié");
        setShowPassword(false);
      },
      (error) => {
        setErrors(error);
      },
      () => setIsLoading(false)
    );
  };

  useEffect(() => {
    if (password == passwordConfirm) {
      setPasswordsIdentical(true);
    } else {
      setPasswordsIdentical(false);
    }
  }, [password, passwordConfirm]);

  return (
    <>
      <button
        data-priv="create_modify_managers"
        className="btn btn-tertiary"
        onClick={() => setShowPassword((p) => !p)}
      >
        {showPassword ? (
          <>
            <i className="fa fa-arrow-left mr-2" />
            Annuler
          </>
        ) : (
          "Modifier le mot de passe"
        )}
      </button>

      {showPassword && (
        <div>
          <Control
            label="Nouveau mot de passe"
            type="password"
            value={password}
            change={(e) => setPassword(e.target.value)}
            error={errors}
            setErrors={setErrors}
          />
          <Control
            label="Confirmez le mot de passe"
            type="password"
            value={passwordConfirm}
            change={(e) => setPasswordConfirm(e.target.value)}
            error={errors}
            setErrors={setErrors}
          />
          {passwordsIdentical ? (
            <div className="d-flex w-100 justify-content-end">
              <SaveBtn
                save={save}
                text="Enregistrer le mot de passe"
                isSaving={isLoading}
              />
            </div>
          ) : (
            <div className="alert alert-warning">
              Les 2 mots de passe doivent être identiques
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ModifyPassword;
