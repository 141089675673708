import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useStore from "../../../../context/useStore";
import dataService from "../../../../helpers/dataService";
import Control from "../../../common/Controls/Control";
import DisplayDates from "../../../common/DisplayDates";
import useApiDatas from "../../../common/Hooks/useApiDatas";
import Page from "../../../common/layout/Page";
import Loader from "../../../common/Loader";
import SaveBtn from "../../../common/SaveBtn";
import SimpleDynamicList from "../../../common/SimpleDynamicList";
import Tabs from "../../../common/Tabs";
import RecipeList from "./RecipeList";

var getTimeout = null;

const TechnicalSheetDetail = (props) => {
  var pathId = props.match.params.id;
  var apiProps = useApiDatas({
    itemPaths: {
      path: "/technicalsheets/", //url de redirection
      get: "technicalsheets/" + pathId, //get item
      post: "technicalsheets", //post item
      patch: "technicalsheets/" + pathId, //patch item
    },
    dependenciesPath: [
      "dishtypes",
      "consumertypes",
      "textures",
      "ingredients",
      "specificities",
    ],
    itemId: pathId, //create ou id
    itemParams: {
      name: "",
      dishType: "",
      unit: "",
      specificities: [],
    },
  });
  const {
    constants,
    errors,
    setErrors,
    isLoading,
    setIsLoading,
    isInit,
    itemId,
    isEdit,
    item,
    setItem,
    saveItem,
    otherDatas,
  } = apiProps;

  const consumerTypes = otherDatas.consumertypes;
  const textures = otherDatas.textures;
  const dishTypes = otherDatas.dishtypes;
  const ingredients = otherDatas.ingredients;
  const specificities = otherDatas.specificities;
  const [recipes, setRecipes] = useState(false);
  const [hasReferent, setHasReferent] = useState(false);
  const [multiplicator, setMultiplicator] = useState(10);

  useEffect(() => {
    clearTimeout(getTimeout);
    if (parseInt(multiplicator) > 0 && pathId !== "create") {
      getTimeout = setTimeout(() => {
        getRecipes();
      }, 1500);
    }
  }, [multiplicator]);

  const getRecipes = () => {
    setIsLoading(true);
    dataService.post(
      "technicalsheets/" + pathId + "/recipes",
      { multiplicator: multiplicator },
      setRecipes,
      setErrors,
      () => setIsLoading(false)
    );
  };

  useEffect(() => {
    if (textures && consumerTypes && recipes) {
      var consumerTypeReferent = consumerTypes.find((r) => r.isReferent).id;
      var textureReferent = textures.find((r) => r.isReferent).id;
      setHasReferent(recipes[consumerTypeReferent][textureReferent].id);
    }
  }, [textures, consumerTypes, recipes]);

  useEffect(() => {
    if (isInit && pathId !== "create" && recipes) {
      setTimeout(() => {
        document.querySelector("#recipe-list-section").scrollIntoView();
      }, 500);
    }
  }, [isInit, recipes]);

  return (
    <Page
      container="container"
      back="/technicalsheets"
      title={(isEdit ? "Modification" : "Détail") + " de la fiche technique"}
      errors={errors}
      isLoading={isLoading}
    >
      {isInit && (
        <>
          <div className="row bg-white rounded shadow-sm p-3">
            <div className="col-12 col-md-6">
              <Control
                label="Nom"
                name="name"
                type="text"
                value={item.name}
                change={(e) => setItem({ ...item, name: e.target.value })}
                error={errors}
                setErrors={setErrors}
                required={true}
              />
              <Control
                label="Type de plat"
                type="select"
                value={item.dishType}
                datas={dishTypes}
                dataIndex="id"
                dataLabel="name"
                name="dishType"
                change={(e) => setItem({ ...item, dishType: e.target.value })}
              />
              <Control
                label="Unité"
                type="select"
                value={item.unit}
                datas={constants.UNITS.filter((f) => f.id != 3)}
                dataIndex="id"
                dataLabel="name"
                name="unit"
                change={(e) => setItem({ ...item, unit: e.target.value })}
              />
            </div>
            <div className="col-12 col-md-6">
              <SimpleDynamicList
                label="Spécificités"
                name="specificities"
                change={(e) =>
                  setItem({
                    ...item,
                    specificities: e.target.value,
                  })
                }
                field={{
                  type: "select",
                  datas: specificities,
                  name: "specificities",
                  dataIndex: "id",
                  dataLabel: "name",
                }}
                value={item.specificities}
                btnLabel="Ajouter"
              />
            </div>
          </div>
          <div className="row" data-priv="create_modify_technical_sheet">
            <div className="col-12 px-0">
              <div className="d-flex justify-content-end w-100">
                <DisplayDates
                  createdAt={item.createdAt}
                  updatedAt={item.updatedAt}
                />
                <SaveBtn
                  save={saveItem}
                  isSaving={isLoading}
                  text="Enregistrer"
                  type="primary"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 p-0">
              {recipes && (
                <>
                  <Control
                    className="mt-2 mb-4"
                    label="Nombre de portions pour le calcul des informations"
                    name="multiplicator"
                    type="text"
                    value={multiplicator}
                    change={(e) => setMultiplicator(e.target.value)}
                    error={errors}
                    setErrors={setErrors}
                    required={true}
                  />
                  <h4 id="recipe-list-section">
                    Recettes par type de consommateur
                  </h4>
                  {item.id && (
                    <Tabs
                      items={consumerTypes.map((cs, csk) => ({
                        id: "ct" + csk,
                        title: cs.name,
                        content: (
                          <RecipeList
                            hasReferent={hasReferent}
                            key={"reclist" + csk}
                            apiProps={apiProps}
                            consumerType={cs}
                            textures={textures}
                            consumerTypes={consumerTypes}
                            ingredients={ingredients}
                            recipeDatas={recipes[cs.id]}
                            recipes={recipes}
                            getRecipes={getRecipes}
                            multiplicator={multiplicator}
                            specificities={specificities}
                          />
                        ),
                      }))}
                      className="mt-3 border-0"
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </>
      )}
    </Page>
  );
};

export default TechnicalSheetDetail;
