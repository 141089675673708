import { DateTime } from "luxon";
import React from "react";

const DisplayDates = ({ createdAt, updatedAt }) => {
  return (
    <div
      className="mr-3 text-right d-center flex-column"
      style={{ color: "#adadad", fontSize: 14 }}
    >
      {createdAt && (
        <div>
          Créé le {DateTime.fromISO(createdAt).toFormat("dd/MM/yyyy à HH'h'mm")}
        </div>
      )}
      {updatedAt && (
        <div>
          Modifié le{" "}
          {DateTime.fromISO(updatedAt).toFormat("dd/MM/yyyy à HH'h'mm")}
        </div>
      )}
    </div>
  );
};

export default DisplayDates;
