import React from "react";

const PanelMoment = ({ consumeTime, ...props }) => {
  return (
    <div
      className={`consume-time-block p-1 d-flex flex-column align-items-start  justify-content-between reducable forward-click`}
      style={{ minHeight: 154 }}
    >
      {/* <strong>{consumeTime.name}</strong> */}
      {props.children}
    </div>
  );
};

export default PanelMoment;
