import React from "react";
import Control from "../../../common/Controls/Control";
import DynamicList from "../../../common/DynamicList";
import SaveBtn from "../../../common/SaveBtn";
import useStoreState from "../../Clients/ClientDetail/useStoreState";
import ConsumeMomentsTable from "./ConsumeMomentsTable";
import CreateGuestsNb from "./CreateGuestsNb";

const ConsumerEdit = ({
  consumer,
  setConsumer,
  consumptionProfiles,
  saveNewConsumer,
  errors,
  isSaving,
  cancelEdit,
  addNewConsumptionProfile,
}) => {
  const { gState } = useStoreState();
  const constants = gState.constants.items;

  return (
    <div>
      <Control
        label="Nom"
        type="text"
        name="name"
        value={consumer.name}
        change={(e) => setConsumer({ ...consumer, name: e.target.value })}
        error={errors}
      />
      <Control
        label="Profil de consommation"
        type="select"
        name="consumptionProfile"
        value={consumer.consumptionProfile}
        datas={consumptionProfiles}
        dataIndex="id"
        dataLabel="name"
        change={(e) =>
          setConsumer({ ...consumer, consumptionProfile: e.target.value })
        }
        error={errors}
      />
      <button
        className="btn btn-default btn-sm"
        onClick={() => addNewConsumptionProfile(null)}
        data-priv="create_modify_consumption_profile"
      >
        <i className="fa fa-plus mr-2" />
        Créer un profil de consommation
      </button>
      {consumer.consumptionProfile && (
        <button
          data-priv="create_modify_consumption_profile"
          className="btn btn-default btn-sm ml-2"
          onClick={() => addNewConsumptionProfile(consumer.consumptionProfile)}
        >
          <i className="fa fa-edit mr-2" />
          Modifier le profil de consommation sélectionné
        </button>
      )}
      <Control
        label="Date d'entrée"
        type="date"
        name="beginAt"
        value={consumer.beginAt}
        change={(e) => setConsumer({ ...consumer, beginAt: e.target.value })}
        error={errors}
      />
      <Control
        label="Date de sortie"
        type="date"
        name="endAt"
        value={consumer.endAt}
        change={(e) => setConsumer({ ...consumer, endAt: e.target.value })}
        error={errors}
      />
      <ConsumeMomentsTable
        consumerDays={consumer.days}
        updateConsumerDays={(value) =>
          setConsumer({ ...consumer, days: value })
        }
      />

      <Control
        label="Commentaire"
        type="text"
        name="comment"
        value={consumer.comment}
        change={(e) => setConsumer({ ...consumer, comment: e.target.value })}
        error={errors}
      />
      <CreateGuestsNb
        guests={consumer.guests}
        updateConsumerGuests={(value) =>
          setConsumer({ ...consumer, guests: value })
        }
      />
      <DynamicList
        label="Convives"
        name="guests"
        change={(e) => setConsumer({ ...consumer, guests: e.target.value })}
        fields={[
          {
            label: "Nom",
            type: "text",
            name: "name",
          },
        ]}
        value={consumer.guests}
        btnLabel="Ajouter un convive"
        error={errors}
      />

      {consumer.guests.length ? (
        <SaveBtn
          text="Enregistrer le groupe de consommateur"
          save={() => saveNewConsumer()}
          isSaving={isSaving}
        />
      ) : (
        <small className="text-danger">
          Ajoutez au moins 1 convive pour pouvoir enregistrer
        </small>
      )}
      <button className="btn btn-link" onClick={cancelEdit}>
        Annuler
      </button>
    </div>
  );
};

export default ConsumerEdit;
