import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import dataService from "../../../../helpers/dataService";
import Control from "../../../common/Controls/Control";
import Table from "../../../common/Table/Table";
import useStoreState from "../../Clients/ClientDetail/useStoreState";
import AddConsumerProfile from "./AddConsumerProfile";
import ConsumerEdit from "./ConsumerEdit";
import ConsumerTypeGrammages from "./ConsumerTypeGrammages";

const ConsumerForm = () => {
  const { state, items, items_actions, gState, spActions } = useStoreState();

  var currentSite = items.utils.entitySelected.id;
  const [errors, setErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [currentConsumer, setCurrentConsumer] = useState(false);

  const consumerInitialState = {
    name: "",
    site: currentSite,
    consumptionProfile: "",
    beginAt: "",
    endAt: "",
    days: [],
    comment: "",
    guests: [],
  };
  const [consumers, setConsumers] = useState([]);
  const [consumptionProfiles, setConsumptionProfiles] = useState([]);
  const [showArchived, setShowArchived] = useState(false);

  useEffect(() => {
    getConsumptionProfiles();
    setCurrentConsumer(false);
  }, [currentSite]);

  const getConsumers = () => {
    dataService.get(`sites/${currentSite}/consumers`, setConsumers);
  };

  const filterConsumers = (consumers) => {
    return consumers.filter((d) => {
      if (!showArchived && d.endAt) {
        var dateSortie = DateTime.fromISO(d.endAt);
        if (DateTime.local() > dateSortie) {
          return false;
        }
      }
      return d;
    });
  };

  const getConsumptionProfiles = () => {
    dataService.get(`sites/${currentSite}/consumptionprofiles`, (datas) => {
      setConsumptionProfiles(datas);
      getConsumers();
    });
  };

  const addNewConsumer = () => setCurrentConsumer(consumerInitialState);
  const saveNewConsumer = () => {
    if (currentConsumer.id) {
      setIsSaving(true);
      dataService.patch(
        `consumers/${currentConsumer.id}`,
        currentConsumer,
        (datas) => {
          getConsumers();
          setCurrentConsumer(false);
        },
        setErrors,
        () => setIsSaving(false)
      );
    } else {
      setIsSaving(true);

      dataService.post(
        `consumers`,
        currentConsumer,
        (datas) => {
          getConsumers();
          setCurrentConsumer(false);
        },
        setErrors,
        () => setIsSaving(false)
      );
    }
  };

  const addNewConsumptionProfile = (consumptionProfileId = null) => {
    spActions.updateSidePanel({
      isOpen: true,
      content: (
        <AddConsumerProfile
          selectedSite={currentSite}
          consumptionProfiles={consumptionProfiles}
          contracts={items.contracts}
          slotCodes={items.slotCodes}
          consumerTypes={items.consumerTypes}
          specificities={items.specificities}
          textures={items.textures}
          savedCallback={savedConsumptionProfileCallback}
          id={consumptionProfileId}
        />
      ),
    });
  };

  const savedConsumptionProfileCallback = () => {
    spActions.updateSidePanel({
      isOpen: false,
      content: null,
    });
    getConsumptionProfiles();
  };

  const openGrammages = (consumerTypeId) => {
    spActions.updateSidePanel({
      isOpen: true,
      content: <ConsumerTypeGrammages consumerTypeId={consumerTypeId} />,
    });
  };

  return (
    <div className="mt-3">
      <h4>Consommateurs du site</h4>
      <Control
        label="Afficher les consommateurs sortis"
        type="checkbox"
        checked={showArchived}
        change={(e) => setShowArchived(e.target.checked)}
      />
      <Table
        fields={[
          {
            name: "Nom",
            path: "name",
          },
          {
            name: "Profil de conso.",
            path: "consumptionProfile",
            render: (el) =>
              consumptionProfiles.find((c) => c.id == el)
                ? consumptionProfiles.find((c) => c.id == el).name
                : "",
          },
          {
            name: "Type de conso.",
            path: "consumptionProfile",
            render: (el) => {
              let cp = consumptionProfiles.find((c) => c.id == el);
              let ct = cp
                ? items.consumerTypes.find((ct) => ct.id == cp.consumerType)
                : null;
              return (
                (
                  <>
                    {ct?.name || "-"}
                    <div>
                      <button
                        onClick={() => openGrammages(ct.id)}
                        className="btn btn-sm btn-default"
                      >
                        Grammages
                      </button>
                    </div>
                  </>
                ) || ""
              );
            },
          },
          {
            name: "Période",
            path: "*",
            render: (el) => (
              <>
                du{" "}
                {el.beginAt
                  ? DateTime.fromISO(el.beginAt).toFormat("dd/MM/yyyy")
                  : "N.D"}{" "}
                au{" "}
                {el.endAt
                  ? DateTime.fromISO(el.endAt).toFormat("dd/MM/yyyy")
                  : "N.D"}
              </>
            ),
          },
          {
            name: "Convives",
            path: "guests",
            render: (el) => el.length,
          },
          {
            name: "Modifier",
            path: "*",
            render: (el) => (
              <button
                className="btn btn-default"
                onClick={() => setCurrentConsumer(el)}
              >
                <i className="fa fa-edit" />
              </button>
            ),
          },
        ]}
        datas={filterConsumers(consumers)}
      />
      {currentConsumer && (
        <ConsumerEdit
          consumer={currentConsumer}
          setConsumer={setCurrentConsumer}
          consumptionProfiles={consumptionProfiles}
          saveNewConsumer={saveNewConsumer}
          errors={errors}
          isSaving={isSaving}
          cancelEdit={() => setCurrentConsumer(false)}
          addNewConsumptionProfile={addNewConsumptionProfile}
        />
      )}

      {!currentConsumer && (
        <button className="btn btn-default" onClick={addNewConsumer}>
          <i className="fa fa-plus mr-2" />
          Nouveau groupe de consommateur
        </button>
      )}
    </div>
  );
};

export default ConsumerForm;
