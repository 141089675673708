import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "proxy-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import "bootstrap";
import "./theme/variables.scss";
import "./animate.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Store from "./context/Store";
import rootReducer from "./context/rootReducer";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { recaptcha_token } from "./config";

ReactDOM.render(
  <Store rootReducer={rootReducer}>
    <GoogleReCaptchaProvider reCaptchaKey={recaptcha_token}>
      <App />
    </GoogleReCaptchaProvider>
  </Store>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
