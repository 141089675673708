import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useStore from "../../../../context/useStore";
import dataService from "../../../../helpers/dataService";
import tools from "../../../../helpers/tools";
import Control from "../../../common/Controls/Control";
import DisplayDates from "../../../common/DisplayDates";
import DynamicList from "../../../common/DynamicList";
import Page from "../../../common/layout/Page";
import SaveBtn from "../../../common/SaveBtn";

import ProviderPreparationList from "../../PreparationModule/ProviderPreparationList";

const ProviderDetail = (props) => {
  const [state, dispatch] = useStore();
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [provider, setProvider] = useState({
    name: "",
    code: "",
    address: "",
    postalCode: "",
    city: "",
    contacts: [],
    deliveryDays: [],
    orderDelay: "",
    ingredients: [],
  });

  const itemId = props.id || props.match.params.id;
  const isEdit = itemId == "create" ? false : true;
  const constants = state.constants.items;

  useEffect(() => {
    if (isEdit) {
      getDatas();
    }
  }, []);

  const getDatas = () => {
    dataService.get(
      "providers/" + itemId,
      (datas) => setProvider(datas),
      (error) => setErrors(error),
      () => setIsLoading(false)
    );
  };

  useEffect(() => {
    console.log(provider);
  }, [provider]);

  const save = () => {
    const onError = (error) => {
      setErrors(error);
    };
    setIsLoading(true);
    if (isEdit) {
      const onSuccess = (datas) => {
        setProvider({
          ...provider,
          createdAt: datas.createdAt,
          updatedAt: datas.updatedAt,
        });
        toast.success("Fournisseur enregistré avec succès");
      };
      dataService.patch(
        "providers/" + itemId,
        provider,
        onSuccess,
        onError,
        () => setIsLoading(false)
      );
    } else {
      const onSuccess = (datas) => {
        toast.success("Fournisseur créé avec succès");
        if (props.id) {
          props.successCallback();
        } else {
          window.location.href = "/providers/" + datas.id;
        }
      };

      dataService.post("providers", provider, onSuccess, onError, () =>
        setIsLoading(false)
      );
    }
  };

  return (
    <Page
      container="container"
      back={props.id ? false : "/providers"}
      title={(isEdit ? "Modification" : "Détail") + " du fournisseur"}
      errors={errors}
    >
      <div className="row custom-card">
        <div className="col-12 col-md-6">
          <Control
            label="Nom"
            name="name"
            type="text"
            value={provider.name}
            change={(e) => setProvider({ ...provider, name: e.target.value })}
            error={errors}
            setErrors={setErrors}
            required={true}
          />
          <Control
            label="Délais de commande (jours)"
            name="orderDelay"
            type="text"
            value={provider.orderDelay}
            change={(e) =>
              setProvider({
                ...provider,
                orderDelay: e.target.value,
              })
            }
            error={errors}
            setErrors={setErrors}
            required={true}
          />
        </div>
        <div className="col-12 col-md-6">
          <Control
            label="Code de référence"
            name="code"
            type="text"
            value={provider.code}
            change={(e) => setProvider({ ...provider, code: e.target.value })}
            error={errors}
            setErrors={setErrors}
            required={true}
          />
          <Control
            label="Jours de livraison"
            name="deliveryDays"
            type="checkBtnList"
            btnInline={true}
            value={provider.deliveryDays}
            datas={tools.getWeekdays()}
            change={(e) =>
              setProvider({
                ...provider,
                deliveryDays: e.target.value,
              })
            }
            error={errors}
            setErrors={setErrors}
            required={true}
          />
        </div>
        <div className="col-12 col-md-4 ">
          <Control
            label="Adresse"
            name="address"
            type="text"
            value={provider.address}
            change={(e) =>
              setProvider({
                ...provider,
                address: e.target.value,
              })
            }
            error={errors}
            setErrors={setErrors}
            required={true}
          />
        </div>
        <div className="col-12 col-md-4 ">
          <Control
            label="Code postal"
            name="postalCode"
            type="text"
            value={provider.postalCode}
            change={(e) =>
              setProvider({
                ...provider,
                postalCode: e.target.value,
              })
            }
            error={errors}
            setErrors={setErrors}
            required={true}
          />
        </div>
        <div className="col-12 col-md-4 ">
          <Control
            label="Ville"
            name="city"
            type="text"
            value={provider.city}
            change={(e) =>
              setProvider({
                ...provider,
                city: e.target.value,
              })
            }
            error={errors}
            setErrors={setErrors}
            required={true}
          />
        </div>

        <div className="col-12 mt-2">
          <DynamicList
            label="Contacts"
            name="contacts"
            change={(e) =>
              setProvider({
                ...provider,
                contacts: e.target.value,
              })
            }
            fields={[
              {
                label: "Nom/Prénom",
                type: "text",
                name: "name",
              },
              {
                label: "Email",
                type: "email",
                name: "email",
              },
              {
                label: "Téléphone",
                type: "tel",
                name: "phone",
              },
            ]}
            value={provider.contacts}
            btnLabel="Ajouter"
          />
        </div>

        {!props.id && (
          <div className="col-12 p-0">
            <h4>Liste des produits</h4>
            {isEdit ? (
              <ProviderPreparationList providerId={itemId} />
            ) : (
              <span>Enregistrez avant de pouvoir ajouter des produits</span>
            )}
          </div>
        )}
        <div
          className="d-flex justify-content-end w-100 mt-3"
          data-priv="create_modify_provider"
        >
          <DisplayDates
            createdAt={provider.createdAt}
            updatedAt={provider.updatedAt}
          />
          <SaveBtn
            save={save}
            isSaving={isLoading}
            text={props.id ? "Créer" : "Enregistrer"}
            type="primary"
          />
          {props.id && (
            <button className="btn btn-default" onClick={props.close}>
              Annuler
            </button>
          )}
        </div>
      </div>
    </Page>
  );
};

export default ProviderDetail;
