import { DateTime } from "luxon";
import React, { useState } from "react";
import tools from "../../../../helpers/tools";
import Control from "../../../common/Controls/Control";

const OrderReceipt = ({ orderReceipt }) => {
  const [showTheo, setShowTheo] = useState(false);
  const getTva = (amount) => {
    return tools.round(amount * 0.2);
  };
  const getTtc = (amount) => {
    return tools.round(amount + getTva(amount));
  };

  const getPreparationPrice = (preparation) => {
    var createdAt = orderReceipt.createdAt;
    var periodPrice = preparation.price;
    periodPrice =
      Math.round(
        (parseFloat(periodPrice) / parseFloat(preparation.packingWeight)) * 100
      ) / 100;
    if (preparation.priceHistorics.length) {
      for (var key in preparation.priceHistorics) {
        var prep = preparation.priceHistorics[key];
        if (prep.endAt >= createdAt && prep.beginAt <= createdAt) {
          periodPrice = prep.price;
        }
      }
    }
    return periodPrice;
  };

  // var totalHt = tools.round(
  //   orderReceipt.preparations.reduce(
  //     (a, b) => {
  //       return {
  //         preparation: {
  //           price:
  //             a.preparation.price +
  //             getPreparationPrice(b.preparation) * b.finalQty,
  //         },
  //       };
  //     },
  //     { preparation: { price: 0 } }
  //   ).preparation.price
  // );
  var totalHt = orderReceipt.totalPriceHT;

  return (
    <div>
      <Control
        className="noprint"
        label="Afficher la qté théorique"
        type="checkbox"
        name="showTheo"
        checked={showTheo}
        change={(e) => setShowTheo(!showTheo)}
      />
      <h1>BON DE COMMANDE</h1>
      <div className="d-flex justify-content-between flex-row">
        <div>
          <div>
            <strong>Qualicarry</strong>
          </div>
          <div>
            <i>22 rue Pierre Brossolette</i>
          </div>
          <div>
            <i>97420 LE PORT</i>
          </div>
          <div>
            <i>Tél : 0262 25 18 05</i>
          </div>
          <div>
            <i>Email : cuisinecentrale@qualicarry.fr</i>
          </div>
          <div>
            <i>SIRET : 501 315 055 000 35</i>
          </div>
        </div>

        <div>
          <div>
            <span>Date de commande : </span>
            <span>{DateTime.local().toFormat("dd/MM/yyyy")}</span>
          </div>
          <div>
            <span>Période d'achat : </span>
            <span>
              du {DateTime.fromISO(orderReceipt.beginAt).toFormat("dd/MM")} au{" "}
              {DateTime.fromISO(orderReceipt.endAt).toFormat("dd/MM")}
            </span>
          </div>
          <div>
            <span>Bon de commande N° : {orderReceipt.reference}</span>
            <span></span>
          </div>
          <div>
            <span>Code fournisseur : </span>
            <span>{orderReceipt.provider.code}</span>
          </div>
          <div>
            <span>Généré par : </span>
            <span>
              {tools.getFullName(orderReceipt.updatedBy)} le{" "}
              {DateTime.fromISO(orderReceipt.createdAt).toFormat(
                "dd/MM/yyyy HH'h'mm"
              )}
            </span>
          </div>
        </div>

        <div>
          <div>
            <strong>Destinataire</strong>
          </div>
          <div>
            <strong className="text-black-50">
              {orderReceipt.provider.name}
            </strong>
          </div>
          <div>
            <i>{orderReceipt.provider.address}</i>
          </div>
          <div>
            <i>
              {orderReceipt.provider.postalCode} {orderReceipt.provider.city}
            </i>
          </div>
          <div>
            <i>Tél : </i>
          </div>
          <div>
            <i>Email : </i>
          </div>
        </div>
      </div>
      <div>
        <table className="table table-bordered table-sm table-small-padding w-100 bg-white shadow-sm hover-table ">
          <thead>
            <tr>
              <th>Produit</th>
              {showTheo && <th>Qté théorique</th>}
              <th>Qté réelle</th>
              <th>Unité</th>
              <th>Prix unitaire HT</th>
              <th>% TVA</th>
              <th>Total TVA</th>
              <th>TotalTTC</th>
            </tr>
          </thead>
          <tbody>
            {orderReceipt.preparations
              .sort((a, b) =>
                a.preparation.name > b.preparation.name ? 1 : -1
              )
              .map((prep) => (
                <tr>
                  <td>{prep.preparation.name}</td>
                  {showTheo && <td>{prep.theoreticalQty}</td>}
                  <td>{prep.finalQty}</td>
                  <td>Kg</td>
                  <td>{getPreparationPrice(prep.preparation)} €</td>
                  <td>20</td>
                  <td>{tools.round(getTva(prep.finalPriceHT))} €</td>
                  <td>{tools.round(getTtc(prep.finalPriceHT))} €</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-end flex-column align-items-end">
        <strong>TOTAL HT : {totalHt} €</strong>
        <strong>TOTAL TVA : {getTva(totalHt)} €</strong>
        <strong className="text-secondary">
          TOTAL TTC : {getTtc(totalHt)} €
        </strong>
      </div>
      <div className="mt-2">Commentaires : {orderReceipt.comment}</div>
    </div>
  );
};

export default OrderReceipt;
