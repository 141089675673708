import { atom, useAtom } from "jotai";
import { useEffect, useState } from "react";
import dataService from "../helpers/dataService";

const providersAtom = atom([]);

const useProviders = (fetchOnLoad = true) => {
  const [providers, setProviders] = useAtom(providersAtom);

  useEffect(() => {
    if (fetchOnLoad) {
      getProviders();
    }
  }, []);

  const getProviders = () => {
    dataService.get(`providers`, setProviders);
  };

  return {
    providers,
    setProviders,
    getProviders,
  };
};

export default useProviders;
