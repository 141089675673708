import { DateTime } from "luxon";
import React from "react";

const DeliveryBlockHeader = ({ data, date }) => {
  return (
    <div className=" p-3">
      <div className="row">
        <div className="col-4 text-left">
          <h4 className="pt-0" style={{ textTransform: "lowercase" }}>
            {DateTime.fromISO(date).setLocale("FR").toFormat("EEEE dd MMMM yyyy")}
          </h4>
          <h6>TRANSPORTEUR : QUALICARRY</h6>
          <div className="">
            <strong>Contrôles à la livraison</strong>
            <ul className="list-unstyled text-muted" style={{ fontSize: 13 }}>
              <li>Température</li>
              <li>Aspect</li>
              <li>DLC</li>
              <li>Estampille sanitaire</li>
              <li>Température camion</li>
              <li>Heure de livraison</li>
            </ul>
          </div>
        </div>
        <div className="col-4 d-flex align-items-center justify-content-center">
          <h2 style={{ fontWeight: "800" }}>BON DE LIVRAISON</h2>
        </div>

        <div className="col-4 text-right">
          <img src="/logo.png" alt="Logo QUALICARRY" width="111" height="34" />
          <h4 className="mt-4">{data.name}</h4>
          <h6>{data.address}</h6>
          <h6>
            {data.postalCode} {data.city}
          </h6>
          <h6>{data.phone}</h6>
        </div>
      </div>
    </div>
  );
};

export default DeliveryBlockHeader;
