import React from "react";
import Page from "../../../common/layout/Page";
import Table from "../../../common/Table/Table";
import ProductionSiteListFilters from "./ProductionSiteListFilters";
import { Link } from "react-router-dom";
import Pagination from "../../../common/Pagination";
import useApiDatas from "../../../common/Hooks/useApiDatas";

const ProductionSiteList = () => {
  const {
    state,
    constants,
    errors,
    setErrors,
    isLoading,
    setIsLoading,
    searchDatas,
    setSearchDatas,
    nbPages,
    setNbPages,
    getSearchDatas,
    deleteData,
    isInit,
    search,
    setSearch,
    otherDatas,
    setOtherDatas,
    nbResults,
  } = useApiDatas({
    resourcePath: "productionsites",
    page: "ProductionSites",
    searchParams: {
      productionSite: { searchString: "" },
      page: 1,
    },
  });

  return (
    <Page
      title="Liste des cuisines"
      errors={errors}
      isLoading={isLoading}
      action={{
        dataPriv: "create_modify_production_sites",
        to: "/productionSites/create",
        text: "Créer une cuisine",
      }}
      isInit={isInit}
    >
      <div className="row">
        <ProductionSiteListFilters
          search={search}
          setSearch={setSearch}
          errors={errors}
        />
      </div>
      <div className="row ">
        <div className="col-12">
          <Table
            isLoading={isLoading}
            nbResults={nbResults}
            exportOptions={{
              excludedFields: ["Actions"],
            }}
            fields={[
              { name: "Nom", path: "name" },

              {
                name: "Actions",
                path: "id",
                render: (el) => (
                  <>
                    <Link
                      to={`/productionSites/${el}`}
                      className="btn btn-default d-center d-inline-flex mr-2"
                    >
                      <i className="fa fa-edit mr-2" />
                      Modifier
                    </Link>
                    <button
                      data-priv="create_modify_production_sites"
                      className="btn btn-danger"
                      onClick={() => deleteData(el)}
                    >
                      <i className="fa fa-times" />
                    </button>
                  </>
                ),
              },
            ]}
            datas={searchDatas}
          />
          <Pagination
            nbPages={nbPages}
            page={search.page}
            changePage={(p) => setSearch({ ...search, page: p })}
          />
        </div>
      </div>
    </Page>
  );
};

export default ProductionSiteList;
