import { DateTime } from "luxon";
import React from "react";
import DeliveryForm from "./DeliveryForm";

const DayData = ({
  dayData,
  datas,
  setDatas,
  providerIndex,
  dayIndex,
  productionSite,
  providerName,
}) => {
  const { expenses } = dayData;

  const addRogueDelivery = () => {
    let newDatas = [...datas];
    newDatas[providerIndex].days[dayIndex].expenses.push({
      deliveryNumber: "",
      deliveryAmount: "",
    });
    setDatas(newDatas);
  };

  return (
    <div className="d-flex align-items-start flex-column justify-content-between  ">
      <div className="d-flex flex-row w-100">
        <div className=" p-1" style={{ width: "100%" }}>
          <div className="d-flex align-items-start flex-column justify-content-between flex-fill w-100">
            {expenses.map((delivery, dk) => {
              return (
                <DeliveryForm
                  key={`deliv${dk}`}
                  delivery={delivery}
                  providerIndex={providerIndex}
                  dayIndex={dayIndex}
                  deliveryIndex={dk}
                  datas={datas}
                  setDatas={setDatas}
                  productionSite={productionSite}
                />
              );
            })}
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between w-100 align-items-center">
        <div className="text-black-50 text-left">
          {DateTime.fromISO(dayData.date).setLocale("FR").toFormat("dd LLLL")}
          <div style={{ fontSize: 10 }}>{providerName}</div>
        </div>
        <button
          className="btn btn-sm btn-link"
          onClick={addRogueDelivery}
          style={{ fontWeight: "700" }}
        >
          <i className="fa fa-plus mr-1" />
          Ajouter un BL
        </button>
      </div>
    </div>
  );
};

export default DayData;
