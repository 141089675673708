import React, { useEffect, useState } from "react";
import { modalActions } from "../../../../context/actions/modalActions";
import dataService from "../../../../helpers/dataService";
import tools from "../../../../helpers/tools";
import Control from "../../../common/Controls/Control";
import DynamicEditor from "../../../common/DynamicEditor/DynamicEditor";
import DynamicList from "../../../common/DynamicList";
import ProgressBar from "../../../common/FormGenerator/ProgressBar";
import useApiDatas from "../../../common/Hooks/useApiDatas";
import Loader from "../../../common/Loader";
import SaveBtn from "../../../common/SaveBtn";
import Table from "../../../common/Table/Table";
import RecipeTotals from "./RecipeTotals";

const RecipeDetail = ({
  consumerType,
  technicalSheet,
  texture,
  recipeData,
  getRecipes,
}) => {
  const recipeId = recipeData.id || "create";

  const {
    state,
    dispatch,
    constants,
    errors,
    setErrors,
    isLoading,
    setIsLoading,
    isInit,
    itemId,
    isEdit,
    otherDatas,
    item,
    setItem,
    saveItem,
  } = useApiDatas({
    itemPaths: {
      path: "/recipes/", //url de redirection
      get: "recipes/" + recipeId, //get item
      post: "recipes", //post item
      patch: "recipes/" + recipeId, //patch item
    },
    itemId: recipeId, //create ou id
    itemParams: recipeData,
  });
  const modal_actions = modalActions(state, dispatch);
  const dishTypes = technicalSheet.otherDatas.dishtypes;
  const ingredients = technicalSheet.otherDatas.ingredients;
  const [productsByIngredient, setProductsByIngredient] = useState({});
  const [costByPreparation, setCostByPreparation] = useState({});
  const [lossByPreparation, setLossByPreparation] = useState({});
  const [isLoadingPbi, setIsLoadingPbi] = useState(false);

  useEffect(() => {
    //item.components.forEach(async (i) => {
    if (!isLoadingPbi) {
      getPbi();
    }
    //});
  }, [item]);

  const getPbi = async () => {
    var pbi = {};
    var cbp = {};
    var loss = {};
    setIsLoadingPbi(true);
    for (var key in item.components) {
      let i = item.components[key];
      let ingId = i.ingredient;
      if (!pbi[ingId]) {
        if (!productsByIngredient[ingId] && ingId) {
          var datas = await getProductsFromIngredient(ingId);
          pbi[ingId] = datas.map((p) => ({
            ...p,
            category: constants.PREPARATION_CATEGORIES.find(
              (c) => c.id == p.category
            ).name,
            name: p.provider.name,
            price: p.price + "€/" + p.packingWeight + "kg",
          }));
        }
      }

      cbp[i._id] = tools.round(i.cost);
      var pbiComplete = pbi[ingId] ? pbi[ingId] : productsByIngredient[ingId];

      loss[i._id] = getLossByPreparation(i.cookingMode, pbiComplete, i);
    }
    console.log("productsByIngredient", { ...productsByIngredient, ...pbi });
    setCostByPreparation({ ...costByPreparation, ...cbp });
    setProductsByIngredient({ ...productsByIngredient, ...pbi });
    setLossByPreparation({ ...lossByPreparation, ...loss });
    setIsLoadingPbi(false);
  };

  const getLossByPreparation = (cookingMode, pbi, component) => {
    //si cookingMode = 1 aller chercher dans la préparation lossPercentBrutToNet, sinon percentNetToCooked
    return pbi && cookingMode && component
      ? cookingMode == 1
        ? pbi.find((p) => p.id == component.preparation)?.lossPercentBrutToNet
        : pbi.find((p) => p.id == component.preparation)?.percentNetToCooked
      : "";
  };

  useEffect(() => {
    if (Object.keys(productsByIngredient).length) {
      var errs = {};
      item.components.forEach((comp, compk) => {
        var product = productsByIngredient[comp.ingredient]?.find(
          (c) => c.id == comp.preparation
        );
        if (product) {
          if (comp.unit != 1) {
            if (product.isSpecificEquivalentPacking) {
              if (product.specificEquivalentPackingUnit != comp.unit) {
                errs = {
                  components: {
                    [compk]:
                      "Aucun conditionnement équivalent pour l'unitée choisie",
                  },
                };
              }
            } else {
              errs = {
                components: {
                  [compk]:
                    "Aucun conditionnement équivalent pour l'unitée choisie",
                },
              };
            }
          }
        }
      });
    }
    setErrors(errs);
  }, [item.components]);

  const getProductsFromIngredient = (ingredientId) => {
    //On récupère les préparations de l'ingrédient sélectionné

    return new Promise((resolve, reject) => {
      setIsLoadingPbi(ingredientId);
      dataService.get(
        "preparations/ingredient/" + ingredientId,
        (datas) => {
          //var pbi = JSON.parse(JSON.stringify(productsByIngredient));
          // if (!pbi[ingredientId]) {
          //   pbi[ingredientId] = datas.map((p) => ({
          //     ...p,
          //     category: constants.PREPARATION_CATEGORIES.find(
          //       (c) => c.id == p.category
          //     ).name,
          //     name: p.provider.name,
          //   }));
          // }
          //setProductsByIngredient(pbi);
          resolve(datas);
        },
        (error) => {
          setErrors(error);

          reject(false);
        },
        () => {
          //setIsLoadingPbi(false);
          setIsLoading(false);
        }
      );
    });
  };

  return isInit ? (
    <div className="row">
      <div className="col-12">
        <h4>
          {"Recette pour " +
            technicalSheet.item.name +
            " (" +
            consumerType.name +
            ") - Texture " +
            texture.name}
        </h4>
      </div>
      <div className="col-12">
        {/* <Control
          label="Type de plat"
          type="select"
          value={item.dishType}
          datas={dishTypes}
          dataIndex="id"
          dataLabel="name"
          name="dishType"
          change={(e) => setItem({ ...item, dishType: e.target.value })}
        /> */}
        <DynamicList
          label="Composants pour la préparation de 10 portions"
          name="components"
          change={(e) => {
            var val = e.target.value;
            var { emmiter } = e.target;
            if (emmiter && emmiter.target.name == "cookingMode") {
              val[emmiter.target.k]["percentNetToCooked"] =
                getLossByPreparation(
                  emmiter.target.value,
                  productsByIngredient[val[emmiter.target.k].ingredient],
                  val[emmiter.target.k]
                );
              //lossByPreparation[val[emmiter.target.k]._id];
            }
            setItem({
              ...item,
              components: val,
            });
          }}
          colSizes={[20, 25, 10, 10, 10, 5, 5]}
          fields={[
            {
              label: "Ingrédient",
              type: "autocomplete",
              name: "ingredient",
              datas: ingredients,
              dataIndex: "id",
              dataLabel: "name",
              autoCompleteDisplayIndex: "name",
              dataLabel2: "code",
            },
            {
              label: "Produit",
              type: "select",
              name: "preparation",
              datas: Object.values(productsByIngredient).length
                ? productsByIngredient
                : [],
              dataEntryId: "ingredient",
              dataIndex: "id",
              dataLabel: "category",
              dataLabel2: "name",
              dataLabel3: "price",
            },
            {
              label: "Quantité",
              type: "float",
              name: "qty",
            },
            {
              label: "Unité",
              type: "select",
              name: "unit",
              datas: constants.INGREDIENT_UNITS,
              dataIndex: "id",
              dataLabel: "name",
              defaultValue: 1,
              formatDatas: (datas, item) => {
                var ingProduct = productsByIngredient[item.ingredient];
                if (ingProduct) {
                  ingProduct = ingProduct.find(
                    (prod) => prod.id == item.preparation
                  );
                  if (!ingProduct?.isSpecificEquivalentPacking) {
                    return constants.INGREDIENT_UNITS.filter(
                      (unit) => unit.slug == "G"
                    );
                  } else {
                    return constants.INGREDIENT_UNITS.filter(
                      (unit) =>
                        unit.id == 1 ||
                        unit.id == ingProduct.specificEquivalentPackingUnit
                    );
                  }
                }
                console.log(
                  "🚀 ~ file: RecipeDetail.js ~ line 257 ~ ingProduct",
                  ingProduct
                );
                return datas;
              },
            },
            {
              label: "Mode de cuisson",
              type: "select",
              name: "cookingMode",
              datas: constants.COOKING_MODES,
              dataIndex: "id",
              dataLabel: "name",
              defaultValue: 1, //si 1 aller chercher dans la préparation lossPercentBrutToNet, sinon percentNetToCooked
            },
            {
              label: "% perte ",
              type: "text",
              name: "percentNetToCooked",
            },
            {
              label: "Coût/portion",
              type: "text",
              name: "cost",
              disabled: true,
            },
          ]}
          value={item.components.map((it) => {
            return {
              ...it,
              cost:
                costByPreparation[it._id] || costByPreparation[it._id] == "0"
                  ? costByPreparation[it._id]
                  : "Enregistrez",
              percentNetToCooked:
                it.percentNetToCooked || it.percentNetToCooked == "0"
                  ? it.percentNetToCooked
                  : lossByPreparation[it._id],
            };
          })}
          btnLabel="Ajouter"
          error={errors}
        />
        <div className="row">
          <div className="col-12 col-md-6">
            <RecipeTotals
              title="Détails pour 1 portion"
              totals={recipeData.total1Dish}
              nbPortions={1}
            />
          </div>
          <div className="col-12 col-md-6">
            <RecipeTotals
              title="Détails pour 10 portions"
              totals={recipeData.total10Dishes}
              nbPortions={10}
            />
          </div>
          <div className="col-12">
            <div>
              <strong>
                Valeur nutritionnelle:{" "}
                <span className="text-primary">
                  {tools.roundInt(recipeData.nutritionalValues?.perPortion)}{" "}
                  kcal
                </span>
                <span className="text-black-50"> || </span>
                <span className="text-primary">
                  {tools.roundInt(recipeData.nutritionalValues?.per100g)}{" "}
                  kcal/100g
                </span>
              </strong>
            </div>
          </div>
        </div>
        {/* <div>
          <h4>
            Quantités principales attendues / réelles pour 1 portion{" "}
            {consumerType.name}
          </h4>
          <Table
            noMargin
            fields={[
              { name: "Type d'ingrédient", path: "ingredientType" },
              { name: "Quantité attendue", path: "expectedQty" },
              { name: "Quantité actuelle", path: "qty" },
              {
                name: "Pertes",
                path: "*",
                render: (el) => {
                  var progress = 100 - (el.qty * 100) / el.expectedQty;
                  return (
                    <ProgressBar
                      progress={progress}
                      text={progress + "%"}
                      height={20}
                    />
                  );
                },
              },
            ]}
            datas={[
              {
                ingredientType: "exemple",
                expectedQty: 100,
                qty: 25,
              },
            ]}
          />
        </div> */}
        <h4>Méthodologie</h4>
        <DynamicEditor
          page={item.methodology}
          setPage={(content) => setItem({ ...item, methodology: content })}
        />
        <div
          className="d-flex justify-content-end w-100"
          data-priv="create_modify_technical_sheet"
        >
          <SaveBtn
            save={() =>
              saveItem(false, () => {
                modal_actions.updateModal({
                  isOpen: false,
                  content: null,
                });
                getRecipes();
              })
            }
            isSaving={isLoading}
          />
        </div>
      </div>
    </div>
  ) : (
    <Loader />
  );
};

export default RecipeDetail;
