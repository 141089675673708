import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import dataService from "../../../../helpers/dataService";
import Control from "../../../common/Controls/Control";
import Loader from "../../../common/Loader";

const Filters = ({
  isLoading,
  productionSites,
  providers,
  search,
  setSearch,
  getPurchases,
}) => {
  return (
    <div className="row mb-3 noprint">
      <div className="col-6">
        <div className="row">
          <div className="col-12">
            <Control
              label="Cuisine"
              type="select"
              datas={productionSites}
              value={search.productionSite}
              dataIndex="id"
              dataLabel="name"
              change={(e) =>
                setSearch({ ...search, productionSite: e.target.value })
              }
            />
          </div>
          <div className="col-12">
            <Control
              placeholder="Tous"
              label="Fournisseur"
              type="select"
              datas={providers}
              value={search.provider}
              dataIndex="id"
              dataLabel="name"
              change={(e) => setSearch({ ...search, provider: e.target.value })}
            />
          </div>
        </div>
      </div>
      <div className="col-6">
        <div className="row">
          <div className="col-12">
            <Control
              label="Entre le"
              type="date"
              withNav
              value={search.beginAt}
              change={(e) => setSearch({ ...search, beginAt: e.target.value })}
            />
          </div>
          <div className="col-12">
            <Control
              label="Et le"
              type="date"
              withNav
              value={search.endAt}
              change={(e) => setSearch({ ...search, endAt: e.target.value })}
            />
          </div>

          <div className="col-12 mt-3 justify-content-between align-items-end d-flex noprint">
            <button
              className="btn btn-secondary btn-sm mb-1"
              onClick={getPurchases}
            >
              {isLoading ? <Loader color="white" /> : "Afficher"}
            </button>
            <Link to="/order-receipts" className="btn btn-default btn-sm ml-2">
              <i className="fa fa-link mr-2" />
              Voir les bons de commande
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filters;
