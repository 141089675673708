import React, { useEffect, useMemo } from "react";
import useProductionSites from "../../../../hooks/useProductionSites";
import useSites from "../../../../hooks/useSites";
import useSpecificities from "../../../../hooks/useSpecificities";
import useTextures from "../../../../hooks/useTextures";
import Control from "../../../common/Controls/Control";
import Loader from "../../../common/Loader";
import SaveBtn from "../../../common/SaveBtn";
import ClientFilter from "../common/ClientFilter";
import useSpecificitiesSummary from "./useSpecificitiesSummary";
//cuisine
//date
//sites
//régimes
const Filters = () => {
  const { productionSites } = useProductionSites();
  const { filters, setFilters, onGetSummary, isLoading } = useSpecificitiesSummary();
  const { sites } = useSites();
  const { specificities } = useSpecificities(false);
  const { textures } = useTextures(false);

  useEffect(() => {
    if (sites.length > 0) {
      handleChangeFilters({
        target: {
          name: "sites",
          value: sites.map((s) => s.id),
        },
      });
    }
  }, [sites]);

  useEffect(() => {
    if (textures.length > 0) {
      handleChangeFilters({
        target: {
          name: "textures",
          value: textures.map((s) => s.id),
        },
      });
    }
  }, [textures]);

  useEffect(() => {
    if (specificities.length > 0) {
      setFilters({ ...filters, specificities: specificities.map((s) => s.id) });
    }
  }, [specificities]);

  const handleChangeFilters = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "productionSite":
        setFilters({
          ...filters,
          [name]: value,
          sites: sites.filter((site) => site.productionSite == value),
        });
        break;
      default:
        setFilters({ ...filters, [name]: value });
    }
  };

  const filteredSites = useMemo(
    () => sites.filter((site) => site.productionSite == filters.productionSite),
    [sites, filters.productionSite]
  );

  console.log("filters", filters);

  return (
    <div className="row mb-3">
      <div className="col-6">
        <Control
          label="Cuisine"
          type="select"
          name="productionSite"
          value={filters.productionSite}
          change={handleChangeFilters}
          datas={productionSites}
          dataIndex="id"
          dataLabel="name"
        />
        <Control
          label="Date de consommation"
          type="date"
          name="beginAt"
          value={filters.beginAt}
          change={handleChangeFilters}
          datas={productionSites}
        />
        <label>
          <span className="label-text">Sites</span>
        </label>
        <ClientFilter
          clients={filteredSites}
          hiddenClients={filters.sites}
          setHiddenClients={(specs) =>
            handleChangeFilters({
              target: {
                name: "sites",
                value: specs,
              },
            })
          }
        />

        <div className="w-100 justify-content-end mt-3 align-items-end d-flex noprint">
          <button className="btn btn-secondary btn-sm mb-1" onClick={onGetSummary}>
            {isLoading ? <Loader color="white" /> : "Afficher"}
          </button>
        </div>
      </div>
      <div className="col-6">
        <Control
          label="Textures"
          type="checkBtnList"
          name="textures"
          value={filters.textures}
          change={handleChangeFilters}
          datas={textures}
          dataIndex="id"
          dataLabel="name"
          btnInline
        />
        <label>
          <span className="label-text">Spécificités</span>
        </label>
        <ClientFilter
          clients={specificities}
          hiddenClients={filters.specificities}
          setHiddenClients={(specs) =>
            handleChangeFilters({
              target: {
                name: "specificities",
                value: specs,
              },
            })
          }
        />
      </div>
    </div>
  );
};

export default Filters;
