const controller = {
  addDishTypesTyConsumerType: (consumerType, setConsumerType, dishTypes) => {
    if (!consumerType.dishTypes.length && dishTypes.length) {
      setConsumerType({
        ...consumerType,
        dishTypes: dishTypes.map((dt) => {
          return {
            dishType: dt.id,
            quantities: [
              { qty: 0, unit: 1 },
              { qty: 0, unit: 2 },
            ],
          };
        }),
      });
    }
  },
};

export default controller;
