import React from "react";

const Tabs = ({ items, k, currentTab = 0, className = "" }) => {
  return (
    <>
      <ul
        className={"nav nav-tabs d-flex flex-wrap " + className}
        id={"myTab" + k}
        role="tablist"
        style={{ borderRadius: window.borderRadius }}
      >
        {items.map((it, itk) => (
          <li
            key={"li" + k + "" + itk}
            className="nav-item  mx-1 text-center animated fadeIn faster "
            role="presentation"
            style={{
              // width: 100 / items.length + "%",
              animationDelay: itk * 100 + "ms",
            }}
          >
            <a
              className={"nav-link " + (currentTab == itk ? "active" : "")}
              id={"c-" + it.id + "-tab"}
              data-toggle="tab"
              href={"#c-" + it.id}
              role="tab"
              aria-controls={"c-" + it.id}
              aria-selected="true"
              onClick={it.onClick ? it.onClick : () => {}}
              style={{
                borderRadius: window.borderRadius,
              }}
            >
              {it.title}
            </a>
          </li>
        ))}
      </ul>
      <div className="tab-content pt-4" id={"myTabContent" + k}>
        {items.map((it, itk) => (
          <div
            key={"tabitem" + itk + k}
            className={
              "tab-pane fade " + (currentTab == itk ? "show active" : "")
            }
            id={"c-" + it.id}
            role="tabpanel"
            aria-labelledby={"c-" + it.id + "-tab"}
          >
            {it.content}
          </div>
        ))}
      </div>
    </>
  );
};

export default Tabs;
