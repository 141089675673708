import React from "react";

const DeselectButton = ({ isActive, onClick, text }) => {
  return (
    <div className="p-1 border">
      <button
        className={`btn btn-default btn-sm position-relative w-100 ${
          isActive && "active"
        }`}
        onClick={onClick}
      >
        {text}
        {isActive && (
          <i
            className="fa fa-check"
            style={{
              position: "absolute",
              right: "2%",
              top: "50%",
              transform: "translateY(-50%)",
            }}
          />
        )}
      </button>
    </div>
  );
};

export default DeselectButton;
