import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { modalActions } from "../../../context/actions/modalActions";
import useStore from "../../../context/useStore";
import dataService from "../../../helpers/dataService";
import Control from "../../common/Controls/Control";
import Table from "../../common/Table/Table";
import IngredientDetail from "../Ingredients/IngredientDetail/IngredientDetail";
import ProviderDetail from "../Providers/ProviderDetail/ProviderDetail";
import ProgressBar from "../../common/FormGenerator/ProgressBar";
import Colors from "../../../theme/variables.scss";
import calculations from "../../../helpers/calculations";
import NbRecipesBtn from "./NbRecipesBtn";

const PreparationModule = ({
  providerId = null,
  ingredientId = null,
  preparationId = null,
  successCallback = () => {},
  close = () => {},
}) => {
  const [state, dispatch] = useStore();
  const constants = state.constants.items;
  const modal_actions = modalActions(state, dispatch);
  const [preparation, setPreparation] = useState({
    category: "",
    ingredient: ingredientId || "",
    provider: providerId || "",
    isDefaultProvider: false,
    price: "",
    priceHistorics: [],
    packingWeight: "",
    isSpecificEquivalentPacking: false,
    specificEquivalentPackingQty: "",
    specificEquivalentPackingUnit: "",
    lossPercentBrutToNet: 0,
    percentNetToCooked: 0,
    isBio: false,
    isLocal: false,
    SIQOTypes: [],
    localType: "",
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [providers, setProviders] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [showPriceHistoric, setShowPriceHistoric] = useState(false);
  const isEdit = preparationId ? true : false;
  const itemId = preparationId;
  const [displayAddProvider, setDisplayAddProvider] = useState(false);
  const [displayAddIngredient, setDisplayAddIngredient] = useState(false);
  const [isLoss, setIsLoss] = useState(1);

  useEffect(() => {
    getDependencies();
    if (isEdit) {
      getDatas();
    }
  }, []);

  useEffect(() => {
    console.log("PREPARATION", preparation);
  }, [preparation]);

  const getDatas = () => {
    dataService.get(
      "preparations/" + itemId,
      (datas) => {
        if (parseFloat(datas.percentNetToCooked) < 0) {
          setIsLoss(2);
        }
        setPreparation(datas);
      },
      (error) => setErrors(error),
      () => setIsLoading(false)
    );
  };

  const getDependencies = () => {
    if (providerId) {
      //Si on a un prodiderId, on charge les ingrédients
      const onSuccess = (datas) => setIngredients(datas);
      const onError = (error) => {
        setErrors(error);
      };
      dataService.get("ingredients/", onSuccess, onError, () => setIsLoading(false));
    }
    if (ingredientId) {
      //Si on a un ingredientId, on charge les fournisseurs
      const onSuccess = (datas) => setProviders(datas);
      const onError = (error) => {
        setErrors(error);
      };
      dataService.get("providers/", onSuccess, onError, () => setIsLoading(false));
    }
  };

  const save = () => {
    const onError = (error) => {
      setErrors(error);
    };
    setIsLoading(true);
    if (isEdit) {
      const onSuccess = (datas) => {
        toast.success("Produit enregistrée avec succès");
        successCallback();
        modal_actions.updateModal({
          isOpen: false,
          content: null,
        });
      };

      dataService.patch(
        "preparations/" + itemId,
        {
          ...preparation,
          percentNetToCooked:
            isLoss == 1
              ? Math.abs(parseFloat(preparation.percentNetToCooked))
              : -Math.abs(parseFloat(preparation.percentNetToCooked)),
        },
        onSuccess,
        onError,
        () => setIsLoading(false)
      );
    } else {
      const onSuccess = (datas) => {
        toast.success("Produit créée avec succès");
        successCallback();
        modal_actions.updateModal({
          isOpen: false,
          content: null,
        });
      };

      dataService.post(
        "preparations",
        {
          ...preparation,
          percentNetToCooked:
            isLoss == 1
              ? Math.abs(parseFloat(preparation.percentNetToCooked))
              : -Math.abs(parseFloat(preparation.percentNetToCooked)),
        },
        onSuccess,
        onError,
        () => setIsLoading(false)
      );
    }
  };

  const addNewPrice = () => {
    if (
      !window.confirm(
        "Êtes-vous certain(e) de vouloir archiver le prix existant et en renseigner un nouveau ?"
      )
    )
      return false;
    var prep = JSON.parse(JSON.stringify(preparation));
    var newEntry = {};
    if (prep.priceHistorics.length > 0) {
      newEntry = {
        price: prep.price,
        beginAt: DateTime.fromISO(prep.priceHistorics[prep.priceHistorics.length - 1].endAt),
        endAt: DateTime.local().toISO(),
      };
    } else {
      newEntry = {
        price: prep.price,
        beginAt: prep.createdAt,
        endAt: DateTime.local().toISO(),
      };
    }
    prep.priceHistorics.push(newEntry);
    prep.price = "";
    setPreparation(prep);
  };

  var brutToNet = calculations.calcRatioBrutNetCru100g(preparation.lossPercentBrutToNet);
  var netToCuit = calculations.calcRatioNetCruCuit100g(
    preparation.lossPercentBrutToNet,
    preparation.percentNetToCooked,
    isLoss
  );

  return (
    <div className="row">
      <div className="col-12">
        <h4 className="pt-0 d-flex flex-row align-items-center justify-content-between">
          <span>{preparationId ? "Détail du" : "Ajouter un nouveau"} produit</span>
          {preparationId && (
            <div className="d-center h6">
              <span className="mr-2">Nb de recettes liées</span>
              {parseInt(preparation.nbRecipes) > 0 ? (
                <NbRecipesBtn nbRecipes={preparation.nbRecipes} preparationId={preparationId} />
              ) : (
                <span
                  className={"p-3 d-center "}
                  style={{
                    width: 30,
                    height: 30,
                    borderRadius: "100%",
                  }}
                >
                  {preparation.nbRecipes}
                </span>
              )}
            </div>
          )}
        </h4>
        {!providerId && (
          <div className="row">
            <div className="col-12 col-md-6">
              <Control
                label="Fournisseur"
                name="provider"
                type="select"
                datas={providers}
                value={preparation.provider}
                change={(e) => setPreparation({ ...preparation, provider: e.target.value })}
                error={errors}
                setErrors={setErrors}
              />
              <div className="d-flex justify-content-end" data-priv="create_modify_provider">
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => setDisplayAddProvider(true)}
                >
                  Créer un nouveau fournisseur
                </button>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <Control
                label="Fournisseur par défault pour cette catégorie"
                type="checkbox"
                checked={preparation.isDefaultProvider}
                name="isDefaultProvider"
                change={(e) =>
                  setPreparation({
                    ...preparation,
                    isDefaultProvider: e.target.checked,
                  })
                }
                error={errors}
                setError={setErrors}
              />
            </div>
            <div className="col-12 d-flex justify-content-end flex-column">
              {displayAddProvider && (
                <ProviderDetail
                  id="create"
                  close={() => setDisplayAddProvider(false)}
                  successCallback={() => {
                    getDependencies();
                    setDisplayAddProvider(false);
                  }}
                />
              )}
            </div>
          </div>
        )}
        {!ingredientId && (
          <div className="row">
            <div className="col-12">
              <Control
                label="Ingrédient"
                type="autocomplete"
                value={preparation.ingredient}
                name="ingredient"
                autoCompleteDisplayIndex="name"
                change={(e) => setPreparation({ ...preparation, ingredient: e.target.value })}
                datas={ingredients}
                dataIndex="id" //default
                dataLabel="name" //default (sera inclut dans la recherche)
                dataLabel2="code" //index dans datas
                required={true}
                error={errors}
                setError={setErrors}
                btnInline={false}
                hideAutocompleteOnFocusOut={false} //buggé
                noResults={false} //si aucun résultat api
                autoCompleteHasApiDatas={false} // true si afficher tous les résultats api
              />
            </div>
            <div className="col-12 d-flex justify-content-end">
              <button
                data-priv="create_modify_ingredients"
                className="btn btn-primary btn-sm"
                onClick={() => setDisplayAddIngredient(true)}
              >
                Créer un nouvel ingrédient
              </button>
              {displayAddIngredient && (
                <IngredientDetail
                  id="create"
                  close={() => setDisplayAddIngredient(false)}
                  successCallback={() => {
                    getDependencies();
                    setDisplayAddIngredient(false);
                  }}
                />
              )}
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-12 col-md-6">
            <Control
              label="Catégorie de transformation"
              name="category"
              type="select"
              datas={constants.PREPARATION_CATEGORIES}
              value={preparation.category}
              change={(e) => setPreparation({ ...preparation, category: e.target.value })}
              error={errors}
              setErrors={setErrors}
            />
          </div>
          <div className="col-12 col-md-6">
            <Control
              label="Prix"
              name="price"
              type="float"
              value={preparation.price}
              change={(e) => setPreparation({ ...preparation, price: e.target.value })}
              error={errors}
              setErrors={setErrors}
            />
            <button
              data-priv="create_modify_ingredients"
              className="btn btn-default mr-1 btn-sm"
              onClick={addNewPrice}
            >
              Renseigner un nouveau prix
            </button>
            <button
              data-priv="create_modify_ingredients"
              className="btn btn-default btn-sm"
              onClick={() => setShowPriceHistoric((p) => !p)}
            >
              {showPriceHistoric ? "Masquer" : "Afficher"} l'historique des prix
            </button>
            {showPriceHistoric && (
              <Table
                exportOptions={false}
                fields={[
                  {
                    name: "Date début",
                    path: "beginAt",
                    render: (el) => DateTime.fromISO(el).toFormat("dd/MM/yyyy hh:mm"),
                  },
                  {
                    name: "Date fin",
                    path: "endAt",
                    render: (el) => DateTime.fromISO(el).toFormat("dd/MM/yyyy hh:mm"),
                  },
                  {
                    name: "Prix",
                    path: "price",
                  },
                ]}
                datas={preparation.priceHistorics}
              />
            )}
          </div>
          <div className="col-12 col-md-6">
            <Control
              label="Conditionnement (kg)"
              name="packingWeight"
              type="float"
              value={preparation.packingWeight}
              change={(e) =>
                setPreparation({
                  ...preparation,
                  packingWeight: e.target.value,
                })
              }
              error={errors}
              setErrors={setErrors}
            />
            <Control
              label="Conditionnement spécifique équivalent"
              type="checkbox"
              checked={preparation.isSpecificEquivalentPacking}
              name="isSpecificEquivalentPacking"
              change={(e) =>
                setPreparation({
                  ...preparation,
                  isSpecificEquivalentPacking: e.target.checked,
                })
              }
              error={errors}
              setError={setErrors}
            />
            <div className="row">
              <div className="col-12 col-md-6">
                <Control
                  label="Quantité"
                  name="specificEquivalentPackingQty"
                  type="float"
                  value={preparation.specificEquivalentPackingQty}
                  change={(e) =>
                    setPreparation({
                      ...preparation,
                      specificEquivalentPackingQty: e.target.value,
                    })
                  }
                  error={errors}
                  setErrors={setErrors}
                />
              </div>
              <div className="col-12 col-md-6">
                {preparation.isSpecificEquivalentPacking && (
                  <Control
                    label="Unité"
                    name="specificEquivalentPackingUnit"
                    type="select"
                    datas={constants.PREPARATION_UNITS}
                    value={preparation.specificEquivalentPackingUnit}
                    change={(e) =>
                      setPreparation({
                        ...preparation,
                        specificEquivalentPackingUnit: e.target.value,
                      })
                    }
                    error={errors}
                    setErrors={setErrors}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6">
            <Control
              label="Pourcentage de perte du brut vers le net cru"
              name="lossPercentBrutToNet"
              type="float"
              value={preparation.lossPercentBrutToNet}
              change={(e) =>
                setPreparation({
                  ...preparation,
                  lossPercentBrutToNet: e.target.value,
                })
              }
              error={errors}
              setErrors={setErrors}
              suffix={"%"}
            />
            <Control
              type="select"
              label="Gagne ou perd du poids après cuisson ?"
              value={isLoss}
              datas={[
                { id: 1, name: "Perd du poids" },
                { id: 2, name: "Gagne du poids" },
              ]}
              change={(e) => setIsLoss(e.target.value)}
            />
            <Control
              label={"Pourcentage de " + (isLoss == 1 ? "perte" : "gain") + " après cuisson"}
              type="float"
              value={Math.abs(parseFloat(preparation.percentNetToCooked))}
              change={(e) =>
                setPreparation({
                  ...preparation,
                  percentNetToCooked: e.target.value,
                })
              }
              error={errors}
              setErrors={setErrors}
              suffix={"%"}
            />
          </div>
        </div>

        <div className="row my-2">
          <div className="col-12">
            <h4>Poids après transformation (pour 100g)</h4>
          </div>
          <div className="col-12 col-md-6 mx-auto d-center">
            <div className=" p-4  w-100 ">
              <div className="d-center flex-column">
                <div>
                  Brut <i className="fa fa-arrow-right mx-2" /> Net cru
                </div>
                <ProgressBar
                  progress={brutToNet}
                  text={
                    <>
                      Reste {brutToNet} GRAMMES{" "}
                      {brutToNet > 100 ? (
                        <i className="fa fa-arrow-up text-primary" />
                      ) : (
                        <i className="fa fa-arrow-down text-danger" />
                      )}
                    </>
                  }
                  color={Colors.primary}
                  bgColor={Colors.danger}
                  height={10}
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mx-auto d-center">
            <div className=" p-4  w-100 ">
              <div className="d-center flex-column">
                <div>
                  Net cru <i className="fa fa-arrow-right mx-2" /> Cuit
                </div>
                <ProgressBar
                  progress={netToCuit}
                  text={
                    <>
                      Reste {netToCuit} GRAMMES{" "}
                      {netToCuit > brutToNet ? (
                        <i className="fa fa-arrow-up text-primary" />
                      ) : (
                        <i className="fa fa-arrow-down text-danger" />
                      )}
                    </>
                  }
                  color={Colors.primary}
                  bgColor={Colors.danger}
                  height={10}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 d-center">
            <Control
              label="Est un produit BIO ?"
              type="checkbox"
              name="isBio"
              checked={preparation.isBio}
              change={(e) => setPreparation({ ...preparation, isBio: e.target.checked })}
              error={errors}
            />
          </div>
          <div className="col-12 col-md-6">
            <Control
              label="Type de SIQO"
              type="checkBtnList"
              name="SIQOTypes"
              value={preparation.SIQOTypes || []}
              change={(e) => setPreparation({ ...preparation, SIQOTypes: e.target.value })}
              datas={constants.PREPARATION_SIQO_TYPES}
              dataIndex="id"
              dataLabel="name"
              error={errors}
              btnInline={true}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 d-center">
            <Control
              label="Est un produit Local ?"
              type="checkbox"
              name="isLocal"
              checked={preparation.isLocal}
              change={(e) => setPreparation({ ...preparation, isLocal: e.target.checked })}
              error={errors}
            />
          </div>
          <div className="col-12 col-md-6">
            {preparation.isLocal && (
              <Control
                label="Type de local"
                type="select"
                name="localType"
                value={preparation.localType}
                datas={constants.PREPARATION_LOCAL_TYPES}
                change={(e) => setPreparation({ ...preparation, localType: e.target.value })}
                error={errors}
              />
            )}
          </div>
        </div>
        {/* <DynamicList
          label="Ratio Net cru / Net transformé (kg)"
          name="cookingRatios"
          change={(e) =>
            setPreparation({
              ...preparation,
              cookingRatios: e.target.value,
            })
          }
          fields={[
            {
              label: "Mode de cuisson",
              type: "select",
              name: "cookingMode",
              datas: constants.COOKING_MODES,
            },
            {
              label: "Ratio",
              type: "float",
              name: "ratio",
            },
          ]}
          value={preparation.cookingRatios}
          btnLabel="Ajouter une cuisson"
        /> */}
        <div className="d-flex justify-content-end mt-3">
          <button data-priv="create_modify_ingredients" className="btn btn-primary" onClick={save}>
            {preparationId ? "Enregistrer" : "Créer"}
          </button>
          <button
            className="btn btn-default"
            onClick={() => {
              preparationId ? modal_actions.updateModal({ isOpen: false, content: null }) : close();
            }}
          >
            Fermer
          </button>
        </div>
      </div>
    </div>
  );
};

export default PreparationModule;
