import React, { useEffect, useState } from "react";
import dataService from "../../../../helpers/dataService";

const DeliveryForm = ({
  delivery,
  providerIndex,
  dayIndex,
  deliveryIndex,
  expenseIndex,
  datas,
  setDatas,
  productionSite,
}) => {
  const [deliveryData, setDeliveryData] = useState({
    id: delivery.id,
    deliveryNumber: delivery.deliveryNumber,
    deliveryAmount: delivery.deliveryAmount,
  });
  const [needSave, setNeedSave] = useState(false);

  useEffect(() => {
    setDeliveryData({
      id: delivery.id,
      deliveryNumber: delivery.deliveryNumber,
      deliveryAmount: delivery.deliveryAmount,
    });
  }, [delivery]);

  useEffect(() => {
    if (
      deliveryData.deliveryNumber != delivery.deliveryNumber ||
      deliveryData.deliveryAmount != delivery.deliveryAmount ||
      deliveryData.deliveryNumber == "" ||
      deliveryData.deliveryAmount == ""
    ) {
      setNeedSave(true);
    } else {
      setNeedSave(false);
    }
  }, [deliveryData]);

  const save = () => {
    let newDatas = [...datas];
    newDatas[providerIndex].days[dayIndex].expenses[deliveryIndex] =
      deliveryData;

    let provider = newDatas[providerIndex].providerId;
    let date = newDatas[providerIndex].days[dayIndex].date;

    console.log("------------");
    console.log("provider", provider);
    console.log("day", date);
    console.log("productionSite", productionSite);
    console.log("------------");

    //AJOUTER LE CALL POUR SAVE
    if (delivery.id) {
      //patch
      dataService.patch(
        `expenses/${delivery.id}`,
        {
          finalPriceHT: deliveryData.deliveryAmount,
          reference: deliveryData.deliveryNumber,
        },
        (datas) => {
          setDatas(newDatas);
        }
      );
    } else {
      //post
      dataService.post(
        `expenses`,
        {
          date: date,
          productionSite,
          provider,
          purchaseHistoric: null,
          finalPriceHT: deliveryData.deliveryAmount,
          reference: deliveryData.deliveryNumber,
        },
        (datas) => {
          newDatas[providerIndex].days[dayIndex].expenses[deliveryIndex].id =
            datas.id;
          setDatas(newDatas);
        }
      );
    }
  };

  const remove = () => {
    let newDatas = [...datas];
    let newDeliveries = newDatas[providerIndex].days[dayIndex].expenses;
    newDeliveries = newDeliveries.filter((d, di) => di != deliveryIndex);
    newDatas[providerIndex].days[dayIndex].expenses = newDeliveries;

    //on supprime toute l'entrée expense si elle est non liée et que les deliveries sont vide
    // if (
    //   !newDeliveries.length &&
    //   !newDatas[providerIndex].days[dayIndex].expenses[expenseIndex].purchaseId
    // ) {
    //   newDatas[providerIndex].days[dayIndex].expenses = newDatas[
    //     providerIndex
    //   ].days[dayIndex].expenses.filter((ex, exi) => exi != expenseIndex);
    // }
    if (delivery.id) {
      dataService.remove(`expenses/${delivery.id}`, {});
    }
    setDatas(newDatas);
  };

  return (
    <div className="w-100 d-flex flex-row align-items-center">
      <div className="w-50">
        <input
          className="border rounded w-100"
          type="text"
          placeholder="N° BL"
          namme="deliveryNumber"
          value={deliveryData.deliveryNumber}
          onChange={(e) =>
            setDeliveryData({
              ...deliveryData,
              deliveryNumber: e.target.value,
            })
          }
        />
      </div>
      <div className="w-50 d-flex flex-row align-items-center position-relative ml-1">
        <input
          className="border rounded w-100"
          type="text"
          placeholder="Montant €"
          namme="deliveryAmount"
          value={deliveryData.deliveryAmount}
          onChange={(e) =>
            setDeliveryData({
              ...deliveryData,
              deliveryAmount: e.target.value,
            })
          }
        />
        <span
          style={{
            position: "absolute",
            right: 2,
            top: "50%",
            transform: "translateY(-50%)",
          }}
        >
          €
        </span>
      </div>
      <button
        className={`btn btn-sm ${
          needSave ? "animated heartBeat infinite " : "text-secondary"
        }`}
        onClick={save}
      >
        <i className={`fa ${needSave ? "fa-save" : "fa-check"}`} />
      </button>
      <button className={`btn btn-sm`} onClick={remove}>
        <i className={`fa fa-times text-danger`} />
      </button>
    </div>
  );
};

export default DeliveryForm;
