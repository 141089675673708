import { atom, useAtom } from "jotai";
import { useEffect, useState } from "react";
import dataService from "../helpers/dataService";

const texturesAtom = atom([]);

const useTextures = (fetchOnLoad = true) => {
  const [textures, setTextures] = useAtom(texturesAtom);

  useEffect(() => {
    if (fetchOnLoad) {
      getTextures();
    }
  }, []);

  const getTextures = () => {
    dataService.get(`textures`, setTextures);
  };

  return {
    textures,
    setTextures,
    getTextures,
  };
};

export default useTextures;
