import actionTools from "../actions/actionTools";

const IS_LOADING = "IS_LOADING";
const IS_INIT = "IS_INIT";
const INIT_ITEMS = "INIT_ITEMS";
const UPDATE_ITEM = "UPDATE_ITEM";
const SET_ERRORS = "SET_ERRORS";
const SAVE_DISH_SLOT = "SAVE_DISH_SLOT";
const SAVE_DISH_SLOTS = "SAVE_DISH_SLOTS";
const SET_EDIT_SLOT_ID = "SET_EDIT_SLOT_ID";
const DELETE_DISH_SLOT = "DELETE_DISH_SLOT";
const LOADING_DISH_SLOTS = "LOADING_DISH_SLOTS";
const DUPLICATE_DISH_SLOTS = "DUPLICATE_DISH_SLOTS";
const ADD_DISH_SLOT = "ADD_DISH_SLOT";
const LOAD_MENUREF = "LOAD_MENUREF";
const LOADING_MENUREF = "LOADING_MENUREF";
const LOAD_SLOT_CODES = "LOAD_SLOT_CODES";
const UPDATE_DAYS_COMMENTS = "UPDATE_DAYS_COMMENTS";
const UPDATE_SELECTED_SITE = "UPDATE_SELECTED_SITE";
const SET_CONSUMPTION_PROILES = "SET_CONSUMPTION_PROILES";
const ADD_CONSUMPTION_PROFILE = "ADD_CONSUMPTION_PROFILE";
const UPDATE_CONSUMPTION_PROFILE = "UPDATE_CONSUMPTION_PROFILE";
const SET_CLIENT_MENU = "SET_CLIENT_MENU";
const LOAD_CLIENT_MENU = "LOAD_CLIENT_MENU";

const initialState = {
  isLoading: false,
  items: {},
  actionUrls: {},
  errors: {},
  isInit: false,
};

const isSlotIdentical = (s, slot) => {
  return s.id == slot.id;
};

const initItems = (payload, state) => {
  const { items, actionUrls } = payload;
  return { ...state, items, actionUrls, isLoading: false, isInit: true };
};

const updateItem = ({ item, value }, state) => {
  return { ...state, items: { ...state.items, [item]: value } };
};

const setEditDishSlot = (editSlotId, state) => {
  var utils = { ...state.items.utils };
  utils.editSlotId = editSlotId;
  return { ...state, items: { ...state.items, utils } };
};

const loadingDishSlots = (payload, state) => {
  var utils = { ...state.items.utils };
  utils.loadingDishSlots = [...utils.loadingDishSlots, ...payload];
  return { ...state, items: { ...state.items, utils } };
};

const saveDishSlot = (payload, state) => {
  const { originDishSlot, newDishSlot } = payload;
  console.log("newslot", newDishSlot);
  var menu = { ...state.items.menu };
  var utils = { ...state.items.utils };
  utils.loadingDishSlots = utils.loadingDishSlots.filter(
    (s) => s !== actionTools.getDishSlotUid(newDishSlot)
  );
  menu.slots = menu.slots.map((s, sk) => {
    if (
      isSlotIdentical(s, originDishSlot) &&
      s.day == newDishSlot.day &&
      s.moment == newDishSlot.moment &&
      s.order == newDishSlot.order
    ) {
      return newDishSlot;
    }
    return s;
  });
  utils.editSlotId = "";
  utils.loadingDishSlots = [];
  return { ...state, items: { ...state.items, menu, utils } };
};

const saveDishSlots = (payload, state) => {
  const newDishSlots = payload;
  var menu = { ...state.items.menu };
  var utils = { ...state.items.utils };

  utils.loadingDishSlots = utils.loadingDishSlots.filter(
    (s) => !newDishSlots.find((f) => actionTools.getDishSlotUid(f) == s)
  );

  menu.slots = menu.slots.map(
    (s) =>
      newDishSlots.find(
        (ns) =>
          isSlotIdentical(s, ns) && s.day == ns.day && s.moment == ns.moment
      ) || s
  );

  return { ...state, items: { ...state.items, menu, utils } };
};

const deleteDishSlot = (payload, state) => {
  const { removed, modified } = payload;
  console.log("REM", payload);
  var menu = { ...state.items.menu };
  var utils = { ...state.items.utils };
  utils.loadingDishSlots = utils.loadingDishSlots.filter(
    (s) => s !== actionTools.getDishSlotUid(removed)
  );
  menu.slots = menu.slots.filter((s) => !isSlotIdentical(s, removed));
  if (modified.length) {
    menu.slots = menu.slots.map(
      (s) =>
        modified.find(
          (ns) =>
            isSlotIdentical(s, ns) && s.day == ns.day && s.moment == ns.moment
        ) || s
    );
  }

  return { ...state, items: { ...state.items, menu, utils } };
};
const AddDishSlots = (payload, state) => {
  var utils = { ...state.items.utils };
  console.log("payload", payload);
  var menu = { ...state.items.menu };
  payload.forEach((p) => {
    var moment = p.moment;
    var day = p.day;
    var slotCode = p.slotCode;
    menu.slots = menu.slots.filter((s) => {
      if (s.day == day && s.moment == moment && s.slotCode == slotCode) {
        return false;
      }
      return true;
    });
  });
  utils.loadingMenuRef = false;

  menu.slots = [...menu.slots, ...payload];
  return { ...state, items: { ...state.items, menu, utils } };
};
const AddDishSlot = (payload, state) => {
  var menu = { ...state.items.menu };
  menu.slots = [...menu.slots, payload];
  return { ...state, items: { ...state.items, menu } };
};
const loadingMenuRef = (payload, state) => {
  var utils = { ...state.items.utils };

  utils.loadingMenuRef = payload;

  return { ...state, items: { ...state.items, utils } };
};
const loadMenuRef = (payload, state) => {
  var { menu, utils } = { ...state.items };
  var menuRef = { ...state.items.menuRef };
  menuRef = payload;
  menu.slots = payload.slots;
  utils.loadingMenuRef = false;

  return { ...state, items: { ...state.items, menu, menuRef, utils } };
};
const loadSlotCodes = (payload, state) => {
  var slotCodes = { ...state.items.slotCodes };

  slotCodes = payload;

  return { ...state, items: { ...state.items, slotCodes } };
};
const updateDaysComments = (payload, state) => {
  var { utils } = { ...state.items };
  var menuRef = { ...state.items.menuRef };
  menuRef.daysComments = payload;

  utils.loadingMenuRef = false;

  return { ...state, items: { ...state.items, menuRef, utils } };
};
const updateSelectedSite = (payload, state) => {
  var { utils } = { ...state.items };

  utils.selectedSite = payload;

  return { ...state, items: { ...state.items, utils } };
};
const setConsumptionProfiles = (payload, state) => {
  var { consumptionProfiles } = { ...state.items };

  consumptionProfiles = payload;

  return { ...state, items: { ...state.items, consumptionProfiles } };
};
const addConsumptionProfile = (payload, state) => {
  var { consumptionProfiles } = { ...state.items };

  consumptionProfiles.push(payload);

  return { ...state, items: { ...state.items, consumptionProfiles } };
};
const updateConsumptionProfile = (payload, state) => {
  var { consumptionProfiles } = { ...state.items };

  consumptionProfiles = consumptionProfiles.map((c) => {
    if (c.id == payload.id) {
      return payload;
    }
    return c;
  });

  return { ...state, items: { ...state.items, consumptionProfiles } };
};
const loadClientMenu = (payload, state) => {
  var { utils } = { ...state.items };

  utils.loadingClientMenu = payload;

  return { ...state, items: { ...state.items, utils } };
};
const setClientMenu = (payload, state) => {
  var { clientMenu, utils } = { ...state.items };

  clientMenu.slots = payload;
  utils.loadingClientMenu = false;

  return { ...state, items: { ...state.items, clientMenu, utils } };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case IS_LOADING:
      return { ...state, isLoading: action.payload };
    case INIT_ITEMS:
      return initItems(action.payload, state);
    case IS_INIT:
      return { ...state, isInit: action.payload };
    case LOADING_DISH_SLOTS:
      return loadingDishSlots(action.payload, state);
    case UPDATE_ITEM:
      return updateItem(action.payload, state);
    case SET_EDIT_SLOT_ID:
      return setEditDishSlot(action.payload, state);
    case SET_ERRORS:
      return { ...state, errors: action.payload };
    case SAVE_DISH_SLOT:
      return saveDishSlot(action.payload, state);
    case SAVE_DISH_SLOTS:
      return saveDishSlots(action.payload, state);
    case DELETE_DISH_SLOT:
      return deleteDishSlot(action.payload, state);
    case DUPLICATE_DISH_SLOTS:
      return AddDishSlots(action.payload, state);
    case ADD_DISH_SLOT:
      return AddDishSlot(action.payload, state);
    case LOAD_MENUREF:
      return loadMenuRef(action.payload, state);
    case LOADING_MENUREF:
      return loadingMenuRef(action.payload, state);
    case LOAD_SLOT_CODES:
      return loadSlotCodes(action.payload, state);
    case UPDATE_DAYS_COMMENTS:
      return updateDaysComments(action.payload, state);
    case UPDATE_SELECTED_SITE:
      return updateSelectedSite(action.payload, state);
    case SET_CONSUMPTION_PROILES:
      return setConsumptionProfiles(action.payload, state);
    case ADD_CONSUMPTION_PROFILE:
      return addConsumptionProfile(action.payload, state);
    case UPDATE_CONSUMPTION_PROFILE:
      return updateConsumptionProfile(action.payload, state);
    case LOAD_CLIENT_MENU:
      return loadClientMenu(action.payload, state);
    case SET_CLIENT_MENU:
      return setClientMenu(action.payload, state);
    default:
      return state;
  }
};
