import React from "react";
import useProductionSites from "../../../../../hooks/useProductionSites";
import Control from "../../../../common/Controls/Control";

const ProductionSiteSelector = ({ productionSite, setProductionSite }) => {
  const { productionSites } = useProductionSites();

  return (
    <Control
      label="Cuisine"
      name="productionSite"
      type="select"
      value={productionSite}
      change={(e) => setProductionSite(e.target.value)}
      datas={productionSites}
      dataIndex="id"
      dataLabel="name"
      placeholder="Toutes"
    />
  );
};

export default ProductionSiteSelector;
