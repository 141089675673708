import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Loader from "../Loader";
import { BarLoader } from "react-spinners";
import Colors from "../../../theme/variables.scss";
import tools from "../../../helpers/tools";

const Page = ({
  container = "container",
  containerClassname = "",
  title,
  children,
  errors = null,
  back = null,
  action = null,
  style = { maxWidth: "80%" },
  margin = "mt-3 ",
  titleMargin = "mb-lg-5 mb-md-4 mb-3",
  notAnimated = false,
  isLoading = false,
  contentStyle = {},
  contentClassname = "",
  isInit = true,
  printOrientation = "portrait",
}) => {
  const [printZoom, setPrintZoom] = useState(100);

  return (
    <motion.div
      exit={{ opacity: 0, y: -50 }}
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
    >
      <div
        className={" page-container " + container + " " + margin + " " + containerClassname}
        style={style}
      >
        {title && (
          <div className={" d-flex align-items-center " + titleMargin}>
            {back && (
              <Link to={back} className="btn mr-3 shadow-none" style={{ padding: "15px 18px" }}>
                <i className="fa fa-arrow-left"></i>
              </Link>
            )}

            <h1
              className="align-items-center d-flex justify-content-between mb-0 mx-0 text-center w-100"
              style={{ fontSize: 24 }}
            >
              {title}

              {action && (
                <Link
                  to={action.to}
                  data-priv={action.dataPriv}
                  className="btn btn-primary float-right ml-3 d-center"
                >
                  <i className="fa fa-plus mr-2"></i>
                  {action.text}
                </Link>
              )}
            </h1>
            <div className="noprint d-flex flex-column justify-content-end">
              <button
                title="Imprimer la page"
                className="btn btn-default"
                onClick={() => tools.print(printOrientation, printZoom)}
              >
                <i className="fa fa-print" />
              </button>
              <div>
                <input
                  type="range"
                  min={20}
                  max={100}
                  step={5}
                  value={printZoom}
                  onChange={(e) => setPrintZoom(e.target.value)}
                />
                <div style={{ fontSize: 12, textAlign: "center" }}>Zoom : {printZoom}%</div>
              </div>
            </div>
          </div>
        )}
        {errors && errors.other && (
          <div className="alert alert-danger" dangerouslySetInnerHTML={{ __html: errors.other }} />
        )}
        <div className={contentClassname} style={contentStyle}>
          {isInit ? children : <Loader />}
        </div>
      </div>
      {isLoading && (
        <div
          style={{
            position: "fixed",
            bottom: 0,
            right: 0,
            zIndex: "9999",
            width: "100%",
          }}
        >
          <BarLoader height={8} width={"100%"} color={Colors.secondary} />
        </div>
      )}
    </motion.div>
  );
};

export default Page;
