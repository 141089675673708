import React from "react";

const ClientComment = () => {
  return (
    <div className="border-top">
      <div className="row ">
        <div className="col-12">
          <i>Modalité de paiement: 30 jours fin de mois.</i>
        </div>
      </div>
      <div className="row" style={{ height: 200 }}>
        <div className="col-6">
          <strong>Commentaire : </strong>
        </div>
        <div className="col-3">
          <strong>Signature client : </strong>
        </div>
        <div className="col-3">
          <strong>Signature livreur : </strong>
        </div>
      </div>
    </div>
  );
};

export default ClientComment;
