import React, { useState } from "react";
import { CSVLink } from "react-csv";
import { DateTime } from "luxon";

const ExportCSV = ({
  fields,
  datas,
  exportOptions = {
    excludedFields: [],
    arrayFields: [],
    objectFields: {},
    additionalFields: [],
  },
  title = "",
}) => {
  const [launch, setLaunch] = useState(false);

  var { excludedFields, arrayFields, objectFields, additionalFields } = exportOptions;
  additionalFields = additionalFields || [];
  arrayFields = arrayFields || [];
  objectFields = objectFields || [];
  function formatToCsv() {
    setLaunch(false);
    var dateFrom = DateTime.local().toFormat("dd-MM-yyyy");
    var fieldsFiltered = [
      ...fields.filter((f) => !excludedFields.includes(f.name)),
      ...additionalFields,
    ];

    var fieldsFormat = fieldsFiltered.map((f) => f.name);
    var csvData = [fieldsFormat];
    var filename = title || "EXPORT_" + dateFrom;

    datas.forEach((data) => {
      var entry = [];
      [...fields, ...additionalFields].forEach((field) => {
        if (!excludedFields.includes(field.name)) {
          var entryData = "";
          if (arrayFields[field.path]) {
            data[field.path].forEach((subEntryData) => {
              arrayFields[field.path].dataIndexes.forEach((ind) => {
                entryData += subEntryData[ind] + " ";
              });
              entryData += "\n";
            });
          } else if (objectFields[field.path]) {
            objectFields[field.path].dataIndexes.forEach((ind) => {
              entryData += data[field.path][ind] + " ";
            });
          } else {
            entryData = data[field.path];
          }
          entry.push(entryData);
        }
      });
      csvData.push(entry);
    });

    console.log("csvData", csvData);
    setLaunch({ data: csvData, filename });
  }

  return (
    <div className="d-center flex-row noprint">
      <button className="btn btn-primary" onClick={formatToCsv}>
        Exporter en CSV
      </button>

      {launch && (
        <CSVLink
          data={launch.data}
          separator={";"}
          enclosingCharacter={`"`}
          filename={launch.filename + ".csv"}
          onClick={() => setLaunch(false)}
          className="btn btn-link"
        >
          Télécharger
        </CSVLink>
      )}
    </div>
  );
};

export default ExportCSV;
