import React, { useEffect, useState } from "react";
import useStore from "../../../../context/useStore";
import tools from "../../../../helpers/tools";
import Page from "../../../common/layout/Page";
import dataService from "../../../../helpers/dataService";
import Filters from "./Filters";
import NowTime from "../NowTime";
import { DateTime } from "luxon";

const OutcomeSummary = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [state, dispatch] = useStore();
  const constants = state.constants.items;
  const { PREPARATION_CATEGORIES, INGREDIENT_UNITS } = constants;
  var searchState = tools.getState("OutcomeSummary", null);
  const [searchDone, setSearchDone] = useState(true);

  const [search, setSearch] = useState(
    searchState
      ? JSON.parse(searchState.search)
      : {
          productionSite: "",
          outletDate: DateTime.local().toISO(),
          date: DateTime.local().toISO(),
        }
  );

  const [datas, setDatas] = useState([]);

  const getDatas = () => {
    setSearchDone(false);

    tools.saveState("OutcomeSummary", "search", JSON.stringify(search));
    setIsLoading(true);
    dataService.post(
      `summaries/outlet`,
      search,
      setDatas,
      (err) => {},
      () => {
        setIsLoading(false);
        setSearchDone(true);
      }
    );
  };

  return (
    <Page
      title="Récapitulatif des sorties"
      titleMargin="mb-3"
      isLoading={isLoading}
      printOrientation="landscape"
      container="container-fluid"
      style={{ maxWidth: "100%" }}
    >
      <NowTime />
      <Filters setSearch={setSearch} search={search} getDatas={getDatas} isLoading={isLoading} />
      {!datas.length ? (
        searchDone ? (
          <p>Aucune donnée trouvée</p>
        ) : null
      ) : (
        PREPARATION_CATEGORIES.map((prepCat) => {
          var nbEntries = datas.filter((data) => data.preparationCategory == prepCat.id).length;

          return nbEntries == 0 ? null : (
            <>
              <div>
                <strong className="text-primary">{prepCat.name}</strong>
              </div>
              <table className="table table-bordered table-sm table-small-padding w-100 bg-white shadow-sm hover-table ">
                <thead>
                  <tr>
                    {/* <th className="text-center">Produit</th> */}
                    <th className="text-center">Ingrédient</th>

                    <th className="font-weight-bold text-center">Qté brute</th>
                    <th className="font-weight-bold text-center">Qté nette</th>
                    <th className="font-weight-bold text-center only-print">Check</th>
                    <th className="font-weight-bold text-center only-print">Observation</th>
                    <th className="font-weight-bold text-center only-print">N° de lot</th>
                    <th className="font-weight-bold text-center only-print">Qté réelle sortie</th>
                  </tr>
                </thead>
                <tbody>
                  {datas
                    .filter((data) => data.preparationCategory == prepCat.id)
                    .map((data, dk) => {
                      var unit = INGREDIENT_UNITS.find((iu) => iu.id == data.unit).name;
                      return (
                        <tr key={`prepcat${prepCat.id}data${dk}`}>
                          {/* <td>{data.preparation.name}</td> */}
                          <td>{data.ingredient.name}</td>
                          <td>
                            {tools.transformQtyKg(data.qtyBrut, data.unit)}
                            {/* {data.qtyBrut} {unit} */}
                          </td>
                          <td>
                            {tools.transformQtyKg(data.qtyNet, data.unit)}
                            {/* {data.qtyNet} {unit} */}
                          </td>
                          <td className="only-print"></td>
                          <td className="only-print"></td>
                          <td className="only-print"></td>
                          <td className="only-print"></td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </>
          );
        })
      )}
    </Page>
  );
};

export default OutcomeSummary;
