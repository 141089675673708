import React, { useState } from "react";
import useStoreState from "../useStoreState";
import ContractsContainer from "./ContractsContainer";

const SitesContainer = () => {
  const { state, items, items_actions } = useStoreState();
  const { client, sites, contracts } = { ...items };

  return (
    <div className="row mt-4">
      {sites.map((s, sk) => {
        return (
          <div className="col" key={`site${s.id}`}>
            <div className="bg-white shadow-sm p-3 rounded">
              <strong className="d-block w-100 ">Site</strong>
              <button
                className="btn btn-secondary w-100 btn-sm d-flex"
                onClick={(e) => items_actions.setEntitySelected("site", s.id)}
              >
                {s.name}
              </button>
              <button
                data-priv="create_modify_site"
                className="btn btn-sm"
                onClick={() => items_actions.removeSite(s.id)}
                style={{
                  position: "absolute",
                  top: 2,
                  right: 16,
                }}
              >
                <i className="fa fa-times text-danger" />
              </button>

              <strong className="d-block w-100 mt-2">Contrats</strong>
              <ContractsContainer site={s} />
            </div>
          </div>
        );
      })}
      <div className="col-12 d-center my-3">
        <button
          data-priv="create_modify_site"
          className="btn btn-default d-flex flex-fill mx-1 btn-sm my-1 d-center"
          onClick={(e) => items_actions.setEntitySelected("site", "")}
        >
          <i className="fa fa-plus mr-2" />
          Ajouter un site
        </button>
        <button
          data-priv="create_modify_contract"
          className="btn btn-default btn-sm my-1 d-flex flex-fill mx-1 d-center"
          onClick={(e) => items_actions.setEntitySelected("contract", "")}
        >
          <i className="fa fa-plus mr-2" />
          Ajouter un contrat
        </button>
      </div>
    </div>
  );
};

export default SitesContainer;
