import React, { useState } from "react";
import { sidePanelActions } from "../../../../../../../../context/actions/sidePanelActions";
import useStore from "../../../../../../../../context/useStore";
import dataService from "../../../../../../../../helpers/dataService";
import Control from "../../../../../../../common/Controls/Control";
import Loader from "../../../../../../../common/Loader";
import SimpleDynamicList from "../../../../../../../common/SimpleDynamicList";
import ColorPicker from "../../../../GlobalMenuPage/Panels/Slot/DishSlot/ColorPicker";

const AddConsumerProfile = ({ items, id, client_actions, autoOrder }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [state, dispatch] = useStore();
  const spActions = sidePanelActions(state, dispatch);
  const [errorsDelete, setErrorsDelete] = useState({});
  const [consumerProfile, setConsumerProfile] = useState(
    id
      ? items.consumptionProfiles.find((c) => c.id == id)
      : {
          name: "",
          color: "",
          site: items.utils.selectedSite,
          clientContract: "",
          consumerType: "",
          texture: "",
          specificities: [],
          order: autoOrder,
        }
  );

  const contractsFiltered = () => {
    return items.contracts
      .filter((c) => c.sites.includes(items.utils.selectedSite))
      .map((c) => {
        return {
          ...c,
          slotCodes: c.slotCodes
            .map((s) =>
              items.slotCodes.find((is) => is.id == s)
                ? items.slotCodes.find((is) => is.id == s).name
                : ""
            )
            .join(", "),
        };
      });
  };

  const saveConsumerProfile = () => {
    setIsLoading(true);
    client_actions.saveConsumerProfile(consumerProfile);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  const removeConsumerProfile = () => {
    dataService.remove(
      `consumptionprofiles/${consumerProfile.id}`,
      {},
      (datas) => {
        spActions.updateSidePanel({
          isOpen: false,
          content: null,
        });
        client_actions.fetchClientMenu();
      },
      setErrorsDelete
    );
  };

  return (
    <div>
      <Control
        label="Nom du profil de consommation"
        type="text"
        value={consumerProfile.name}
        change={(e) =>
          setConsumerProfile({ ...consumerProfile, name: e.target.value })
        }
      />
      <Control
        label="Contrats"
        type="select"
        value={consumerProfile.clientContract}
        datas={contractsFiltered()}
        change={(e) =>
          setConsumerProfile({
            ...consumerProfile,
            clientContract: e.target.value,
          })
        }
        dataIndex="id"
        dataLabel="name"
      />
      <Control
        label="Type de consommateur"
        type="select"
        value={consumerProfile.consumerType}
        datas={items.consumerTypes}
        change={(e) =>
          setConsumerProfile({
            ...consumerProfile,
            consumerType: e.target.value,
          })
        }
        dataIndex="id"
        dataLabel="name"
      />

      <SimpleDynamicList
        label="Spécificités"
        name="specificities"
        change={(e) =>
          setConsumerProfile({
            ...consumerProfile,
            specificities: e.target.value,
          })
        }
        field={{
          type: "select",
          datas: items.specificities,
          name: "specificity",
          dataIndex: "id",
          dataLabel: "name",
        }}
        value={consumerProfile.specificities}
        btnLabel="Ajouter"
        className="mb-0"
      />

      <Control
        label="Texture"
        type="select"
        datas={items.textures}
        dataIndex="id"
        dataLabel="name"
        value={consumerProfile.texture}
        change={(e) =>
          setConsumerProfile({ ...consumerProfile, texture: e.target.value })
        }
        btnInline
        name="texture"
      />
      <Control
        label="Ordre"
        type="number"
        value={consumerProfile.order}
        change={(e) =>
          setConsumerProfile({ ...consumerProfile, order: e.target.value })
        }
        name="order"
      />

      <div>
        <div>
          <span className="label-text">Couleur</span>
        </div>
        <ColorPicker
          color={consumerProfile.color}
          setColor={(color) =>
            setConsumerProfile({ ...consumerProfile, color })
          }
        />
      </div>

      <div
        className="d-center flex-column mt-3"
        data-priv="create_modify_consumption_profile"
      >
        <button className="btn btn-primary mb-2" onClick={saveConsumerProfile}>
          {isLoading ? <Loader /> : "Enregistrer"}
        </button>
        <button
          className="btn btn-link text-danger"
          onClick={removeConsumerProfile}
        >
          Supprimer ce profil
        </button>
      </div>
      {errorsDelete.other && (
        <div
          className="alert alert-danger"
          dangerouslySetInnerHTML={{ __html: errorsDelete.other }}
        />
      )}
    </div>
  );
};

export default AddConsumerProfile;
