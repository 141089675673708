import { DateTime } from "luxon";
import React from "react";
import useStore from "../../../../context/useStore";
import useProviders from "../../../../hooks/useProviders";
import Control from "../../../common/Controls/Control";
import Loader from "../../../common/Loader";

const Filters = ({ search, setSearch, getDatas, isLoading, ...props }) => {
  const [state, dispatch] = useStore();
  const { TIME_SCALES } = state.constants.items;
  const { providers, setProviders } = useProviders(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearch({ ...search, [name]: value });
  };

  return (
    <div className="row mb-3">
      <div className="col-4">
        <Control
          label="Fournisseurs"
          type="selectList"
          datas={providers || []}
          value={search.providers}
          dataIndex="id"
          dataLabel="name"
          name="providers"
          change={handleChange}
        />
      </div>
      <div className="col-8">
        <Control
          label="Échelon"
          name="timeScale"
          type="btnList"
          dataIndex="id"
          dataLabel="name"
          btnInline
          datas={TIME_SCALES}
          value={search.timeScale}
          change={(e) => setSearch({ ...search, timeScale: e.target.value })}
        />
        <div className="row">
          <div className="col-6">
            <Control
              label="Entre le"
              type="date"
              name="beginAt"
              change={(e) =>
                setSearch({
                  ...search,
                  beginAt: DateTime.fromISO(e.target.value).startOf("day").toISO(),
                })
              }
              value={search.beginAt}
            />
          </div>
          <div className="col-6">
            <Control
              label="Entre le"
              type="date"
              name="endAt"
              change={(e) =>
                setSearch({
                  ...search,
                  endAt: DateTime.fromISO(e.target.value).endOf("day").toISO(),
                })
              }
              value={search.endAt}
            />
          </div>
          <div className="col-12 mt-3 justify-content-between align-items-end d-flex noprint">
            <button className="btn btn-secondary btn-sm mb-1" onClick={getDatas}>
              {isLoading ? <Loader color="white" /> : "Afficher"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filters;
