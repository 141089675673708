import React, { useState } from "react";
import { VerticalTimelineElement } from "react-vertical-timeline-component";
import { DateTime } from "luxon";
import Colors from "../../../theme/variables.scss";
import Stepper from "../Stepper/Stepper";
import Avatar from "../Avatar";

const TimelineEventHistory = ({ event }) => {
  var eventColor = "rgb(69, 104, 0)";
  const [historicVisible, setHistoricVisible] = useState(false);
  return (
    <VerticalTimelineElement
      className="vertical-timeline-element--work"
      contentStyle={{
        background: "#fff",
        color: "#404040",
        padding: 11,
        paddingTop: 4,
        paddingBottom: 5,
        boxShadow: "0 -3px 0 " + eventColor + " ,0 0 15px -3px #afafaf",
      }}
      contentArrowStyle={{ borderRight: "7px solid " + eventColor }}
      date={DateTime.fromISO(event.updatedAt).toFormat("dd/MM/yyyy à hh'h'mm")}
      iconStyle={{
        background: eventColor,
        color: "#fff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 30,
        height: 30,
      }}
      icon={<i className={"fa fa-1x fa-history"} />}
    >
      <ul className="pl-3" style={{ fontSize: 12 }}>
        {event.details.map((ev, evk) => (
          <li key={`ev${ev.id}`}>{ev}</li>
        ))}
      </ul>

      {event.updatedBy && (
        <div
          className="d-flex justify-content-end mt-2 position-absolute"
          style={{ color: "#bdbdbd", right: 5, bottom: 5, fontSize: 12 }}
        >
          <i className="d-flex align-items-center">
            <span>par</span>
            <b className="mx-1">
              {event.updatedBy.firstname} {event.updatedBy.lastname}
            </b>
          </i>
        </div>
      )}
    </VerticalTimelineElement>
  );
};

export default TimelineEventHistory;
