import React, { useEffect, useState } from "react";
import useStore from "../../../../context/useStore";
import dataService from "../../../../helpers/dataService";

const ConsumerTypeGrammages = ({ consumerTypeId }) => {
  const [state] = useStore();
  const { INGREDIENT_UNITS } = state.constants.items;
  const [consumerType, setConsumerType] = useState(null);
  const [dishTypes, setDishTypes] = useState(null);
  useEffect(() => {
    if (consumerTypeId) {
      dataService.get(`dishtypes`, setDishTypes);
      dataService.get(`consumertypes/${consumerTypeId}`, setConsumerType);
    }
  }, []);

  let dishTypesFormatted =
    consumerType && dishTypes
      ? consumerType.dishTypes.map((dt) => ({
          ...dt,
          name: dishTypes.find((d) => d.id == dt.dishType).name,
        }))
      : null;

  return (
    <div>
      {dishTypesFormatted && (
        <>
          <div>
            <strong>
              Grammages pour{" "}
              <span className="text-primary">{consumerType.name}</span>
            </strong>
          </div>
          <ul className="list-group">
            {dishTypesFormatted
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((dt) => {
                return (
                  <li className="list-group-item" key={`ct${dt.id}`}>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="font-weight-bold">{dt.name}</div>
                      <div className="text-right">
                        {dt.quantities.map((qty, qtyk) => {
                          var unit = INGREDIENT_UNITS.find(
                            (u) => u.id == qty.unit
                          );
                          return (
                            <div>
                              {qty.qty} {unit.name}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </li>
                );
              })}
          </ul>
        </>
      )}
    </div>
  );
};

export default ConsumerTypeGrammages;
