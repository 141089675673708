import React, { useState } from "react";
import Control from "../../../common/Controls/Control";
import DynamicList from "../../../common/DynamicList";
import useStoreState from "../../Clients/ClientDetail/useStoreState";
import ConsumerForm from "./ConsumerForm";

const SiteForm = ({ site, setSite, productionSites }) => {
  const { state, items, items_actions, gState } = useStoreState();
  const constants = gState.constants.items;
  const errors = { ...state.items.errors };

  return (
    <div className="row p-3 mx-0 bg-white shadow-sm rounded">
      <div className="col-12 col-md-6">
        <Control
          label="Cuisine"
          type="select"
          name="productionSite"
          datas={productionSites}
          value={site.productionSite}
          change={(e) => setSite({ ...site, productionSite: e.target.value })}
          error={errors}
        />

        <Control
          label="Nom du site"
          type="text"
          name="name"
          value={site.name}
          change={(e) => setSite({ ...site, name: e.target.value })}
          error={errors}
        />

        <Control
          label="Adresse"
          type="text"
          name="address"
          value={site.address}
          change={(e) => setSite({ ...site, address: e.target.value })}
          error={errors}
        />

        <Control
          label="Code postal"
          type="text"
          name="postalCode"
          value={site.postalCode}
          change={(e) => setSite({ ...site, postalCode: e.target.value })}
          error={errors}
        />

        <Control
          label="Ville"
          type="text"
          name="city"
          value={site.city}
          change={(e) => setSite({ ...site, city: e.target.value })}
          error={errors}
        />

        <Control
          label="Téléphone"
          type="text"
          name="phone"
          value={site.phone}
          change={(e) => setSite({ ...site, phone: e.target.value })}
          error={errors}
        />
        <Control
          label="Mode de conditionnement"
          type="select"
          name="conditioningMode"
          value={site.conditioningMode}
          datas={constants.CONDITIONING_MODES}
          dataIndex="id"
          dataLabel="name"
          change={(e) => setSite({ ...site, conditioningMode: e.target.value })}
          error={errors}
        />

        <Control
          label="Mode d'allotissement"
          type="select"
          name="allotingMode"
          value={site.allotingMode}
          datas={constants.ALLOTING_MODES}
          dataIndex="id"
          dataLabel="name"
          change={(e) => setSite({ ...site, allotingMode: e.target.value })}
          error={errors}
        />

        <Control
          label="Est archivé ?"
          type="checkbox"
          name="isArchived"
          checked={site.isArchived}
          change={(e) => setSite({ ...site, isArchived: e.target.checked })}
          error={errors}
          k={23}
        />
      </div>
      <div className="col-12 col-md-6">
        <DynamicList
          label="Périodes de fermeture"
          name="DynamicList"
          change={(e) =>
            setSite({
              ...site,
              closingPeriods: e.target.value,
            })
          }
          fields={[
            {
              label: "Début",
              type: "date",
              name: "start",
            },
            {
              label: "Fin",
              type: "date",
              name: "end",
            },
          ]}
          value={site.closingPeriods}
          btnLabel="Ajouter"
          error={errors}
        />

        <Control
          label="Jours d'activité"
          name="activeDays"
          type="checkBtnList"
          value={site.activeDays}
          datas={constants.DAYS}
          change={(e) => setSite({ ...site, activeDays: e.target.value })}
          btnInline
          error={errors}
        />
      </div>
      <div className="col-12 mt-3">
        <hr className="w-100" />
        <ConsumerForm />
      </div>
    </div>
  );
};

export default SiteForm;
