import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import Control from "../../../../../common/Controls/Control";
import useStoreState from "../../Context/Store/useStoreState";
import StipLoader from "./StipLoader";
import "react-calendar/dist/Calendar.css";
import "./menuref.scss";
import DateSelector from "./DateSelector";
import ConsumptionProfiles from "./ConsumptionProfiles/ConsumptionProfiles";
import tools from "../../../../../../helpers/tools";

const MenuClient = () => {
  const { dispatch, items, items_actions, client_actions, gState, gDispatch } =
    useStoreState();
  const {
    menuRef,
    utils,
    sites,
    clients,
    consumptionProfiles,
    productionSites,
  } = { ...items };

  const change = (e) => {
    let { name, value } = e.target;
    var mf = { ...menuRef, [name]: value };
    dispatch({
      type: "LOAD_CLIENT_MENU",
      payload: true,
    });
    items_actions.updateMenuRef(mf, name);
  };

  useEffect(() => {
    console.log("changed !!!");
    client_actions.fetchClientMenu();
  }, [menuRef.beginAt]);

  var searchState = tools.getState("menurefFilterClient", null);
  const [client, setClient] = useState(searchState?.selectedClient || "");

  useEffect(() => {
    tools.saveState("menurefFilterClient", "selectedClient", client);
  }, [client]);

  // useEffect(() => {
  //   if (utils.selectedSite.length) {
  //     client_actions.updateSelectedSite(utils.selectedSite);
  //   }
  // }, []);

  return (
    <>
      {utils.loadingClientMenu && <StipLoader />}
      {menuRef.productionSite && (
        <>
          <div className="p-2">
            <Control
              label="Client"
              type="select"
              datas={clients.sort((a, b) => (a.name > b.name ? 1 : -1))}
              dataIndex="id"
              dataLabel="name"
              value={client}
              change={(e) => setClient(e.target.value)}
            />
            <Control
              label="Site"
              type="select"
              datas={sites
                .filter(
                  (s) =>
                    s.productionSite == menuRef.productionSite &&
                    (client ? s.client == client : true)
                )
                .sort((a, b) => (a.name > b.name ? 1 : -1))}
              dataIndex="id"
              dataLabel="name"
              value={items.utils.selectedSite}
              change={(e) => client_actions.updateSelectedSite(e.target.value)}
            />
          </div>
          <DateSelector
            beginAt={menuRef.beginAt}
            disabled={utils.editSlotId}
            change={change}
          />

          <div className="h-100  text-primary d-flex p-2 d-flex flex-column  position-relative animated fadeInRight faster">
            <div className="position-relative">
              <div>Semaine N°{menuRef.num}</div>
              <div>
                du {DateTime.fromISO(menuRef.beginAt).toFormat("dd/MM/yyyy")}
              </div>
              <div>
                au {DateTime.fromISO(menuRef.endAt).toFormat("dd/MM/yyyy")}
              </div>
            </div>
          </div>

          <div className="d-flex align-items-center justify-content-start w-100 border-top">
            <ConsumptionProfiles />
          </div>
        </>
      )}
    </>
  );
};

export default MenuClient;
