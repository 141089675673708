import { DateTime } from "luxon";
import React, { useState } from "react";
import { modalActions } from "../../../../context/actions/modalActions";
import { sidePanelActions } from "../../../../context/actions/sidePanelActions";
import useStore from "../../../../context/useStore";
import Control from "../../../common/Controls/Control";
import SimpleDynamicList from "../../../common/SimpleDynamicList";
import Table from "../../../common/Table/Table";
import useStoreState from "../../Clients/ClientDetail/useStoreState";
import SlotCodesDetail from "../../SlotCodes/SlotCodesDetail/SlotCodesDetail";

const ClientContractForm = ({ clientContract, setClientContract }) => {
  const { state, items, items_actions, gState, gDispatch } = useStoreState();
  const constants = gState.constants.items;
  const errors = { ...state.items.errors };
  const { productionSites, slotCodes, sites } = items;
  const sidepanel_actions = sidePanelActions(gState, gDispatch);

  const onSaveSlotCode = (slotCode) => {
    console.log(slotCode);
    sidepanel_actions.updateSidePanel({
      isOpen: false,
      content: null,
    });
    items_actions.addSlotCode(slotCode);
    var newSlots = [...clientContract.slotCodes];
    newSlots.push(slotCode.id);
    setClientContract({
      ...clientContract,
      slotCodes: newSlots,
    });
  };

  const createSlotCode = () => {
    sidepanel_actions.updateSidePanel({
      isOpen: true,
      content: (
        <SlotCodesDetail
          match={{ params: { id: "create" } }}
          modal={true}
          onSave={onSaveSlotCode}
        />
      ),
    });
  };

  const addPriceToHistoric = () => {
    if (
      !window.confirm(
        "Êtes-vous certain(e) de vouloir archiver le prix existant et en renseigner un nouveau ?"
      )
    )
      return false;
    var prep = JSON.parse(JSON.stringify(clientContract));
    var newEntry = {};
    if (prep.priceHistorics.length > 0) {
      newEntry = {
        price: prep.price,
        beginAt: DateTime.fromISO(
          prep.priceHistorics[prep.priceHistorics.length - 1].endAt
        ),
        endAt: DateTime.local().toISO(),
      };
    } else {
      newEntry = {
        price: prep.price,
        beginAt: prep.createdAt,
        endAt: DateTime.local().toISO(),
      };
    }
    prep.priceHistorics.push(newEntry);
    prep.price = 0;
    setClientContract(prep);
  };

  return (
    <div
      className="row mx-0 p-3 bg-white rounded shadow-sm"
      style={{ minHeight: 200 }}
    >
      <div className="col-12 ">
        <Control
          label="Cuisine"
          type="select"
          name="productionSite"
          value={clientContract.productionSite}
          change={(e) => {
            if (clientContract.productionSite.length) {
              if (
                !window.confirm(
                  "Modifier la cuisine aura pour effet de vider tous les autres champs, confirmez-vous cette action ?"
                )
              ) {
                return false;
              }
            }
            setClientContract({
              name: "",
              client: clientContract.client,
              sites: [],
              slotCodes: [],
              price: 0,
              priceHistorics: [],
              productionSite: e.target.value,
            });
          }}
          datas={productionSites}
          dataIndex="id"
          dataLabel="name"
          error={errors}
          error={errors}
        />
      </div>
      {clientContract.productionSite.length ? (
        <>
          <div className="col-12 col-md-6">
            <Control
              label="Nom du contrat"
              name="name"
              type="text"
              value={clientContract.name}
              change={(e) =>
                setClientContract({
                  ...clientContract,
                  name: e.target.value,
                })
              }
              error={errors}
            />
            <Control
              label="Sites"
              type="checkBtnList"
              name="sites"
              value={clientContract.sites}
              change={(e) =>
                setClientContract({ ...clientContract, sites: e.target.value })
              }
              datas={sites.filter(
                (s) => s.productionSite == clientContract.productionSite
              )}
              btnInline
              dataIndex="id"
              dataLabel="name"
              error={errors}
              error={errors}
            />
          </div>
          <div className="col-12 col-md-6">
            <SimpleDynamicList
              label="Références plan alimentaire "
              name="slotCodes"
              change={(e) =>
                setClientContract({
                  ...clientContract,
                  slotCodes: e.target.value,
                })
              }
              field={{
                type: "select",
                datas: slotCodes.filter((s) =>
                  s.productionSites.includes(clientContract.productionSite)
                ),
                name: "slotCode",
                dataIndex: "id",
                dataLabel: "name",
              }}
              value={clientContract.slotCodes}
              error={errors}
              btnLabel="Ajouter"
            />
            <button className="btn btn-default btn-sm" onClick={createSlotCode}>
              Créer une nouvelle référence plan alimentaire
            </button>
          </div>

          <div className="col-12 col-md-6">
            <Control
              label="Prix"
              name="price"
              type="float"
              value={clientContract.price}
              change={(e) =>
                setClientContract({ ...clientContract, price: e.target.value })
              }
              error={errors}
              suffix={"€"}
            />
            <button className="btn btn-default" onClick={addPriceToHistoric}>
              Ajouter ce prix à l'historique et en renseigner un nouveau
            </button>
          </div>

          <div className="col-12 col-md-6">
            <Control
              label="Afficher dans le menu imprimé ?"
              name="isDisplayToPrintedMenu"
              type="checkbox"
              checked={clientContract.isDisplayToPrintedMenu}
              change={(e) =>
                setClientContract({
                  ...clientContract,
                  isDisplayToPrintedMenu: e.target.checked ? true : false,
                })
              }
              error={errors}
            />
          </div>

          <div className="col-12 col-md-6">
            <Table
              cardBg="bg-control"
              fields={[
                {
                  name: "Prix",
                  path: "price",
                },
                {
                  name: "Période",
                  path: "*",
                  render: (el) => (
                    <>
                      {DateTime.fromISO(el.beginAt).toFormat("dd/MM/yyyy")}{" "}
                      <i className="fa fa-arrow-right mx-1" />
                      {DateTime.fromISO(el.endAt).toFormat("dd/MM/yyyy")}
                    </>
                  ),
                },
              ]}
              datas={clientContract.priceHistorics}
            />
          </div>
        </>
      ) : null}
    </div>
  );
};

export default ClientContractForm;
