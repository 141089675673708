import axios from "axios";
import { authActions } from "./context/actions/authActions";
import { constantActions } from "./context/actions/constantActions";
import useStore from "./context/useStore";
import { API_URL } from "./config";
import jwt_decode from "jwt-decode";
import { useEffect } from "react";
import setProductionSite from "./helpers/setProductionSite";
var firstLoad = true;

const useApp = () => {
  const [state, dispatch] = useStore();
  console.log("state", state);
  var actions = authActions(state, dispatch);
  var c_actions = constantActions(state, dispatch);
  axios.defaults.withCredentials = true;
  axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      if (error.response.status == 401) {
        console.log("UNAUTHORIZED - login out", error.response.config.url);
        if (error.response.config.url !== API_URL + "auth/logout") {
          actions.logoutUser();
        }
      }
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    }
  );
  if (localStorage.jwtToken && firstLoad) {
    firstLoad = false;
    // Set auth token header auth
    //tools.setAuthToken(localStorage.jwtToken);
    // Decode token and get user info and exp
    const decoded = jwt_decode(localStorage.jwtToken);

    // Set user and isAuthenticated
    dispatch({
      type: "SET_CURRENT_USER",
      payload: decoded,
    });

    // Check for expired token
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      // Logout user

      actions.logoutUser();
      // Clear current Profile
      // store.dispatch(clearCurrentProfile())
      // Redirect to login
    }
    if (localStorage.ps) {
      setProductionSite(localStorage.ps);
      // Set user and isAuthenticated
      authActions.dispatch({
        type: "SELECT_PRODUCTION_SITE",
        payload: localStorage.ps,
      });
    }
  }

  useEffect(() => {
    if (!localStorage.jwtToken) {
      axios
        .get(API_URL + "auth/me")
        .then((res) => {
          const decoded = jwt_decode(res.data);
          dispatch({
            type: "SET_CURRENT_USER",
            payload: decoded,
          });
        })
        .catch((err) => {
          actions.logoutUser();
        });
    }
    c_actions.getConstants();
  }, []);

  return { state };
};

export default useApp;
