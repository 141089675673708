import React, { useEffect, useState } from "react";
import Page from "../../../common/layout/Page";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import timeGridPlugin from "@fullcalendar/timegrid"; // a plugin!
import frLocale from "@fullcalendar/core/locales/fr";
import useSites from "../../../../hooks/useSites";
import tools from "../../../../helpers/tools";
import useClients from "../../../../hooks/useClients";
import ClientFilter from "../../Summaries/common/ClientFilter";
import AddClosingPeriodForm from "./AddClosingPeriodForm";

const ClosingPeriods = () => {
  const { sites } = useSites();
  const { clients } = useClients();
  const [hiddenSites, setHiddenSites] = useState([]);

  const formatEventTitle = (data) => {
    return `${data.name} (${tools.findIn(clients, data.client, "id").name}) ${tools.formatDate(
      data.start,
      "dd/MM"
    )} > ${tools.formatDate(data.end, "dd/MM")}`;
  };

  const closingPeriods = [];
  sites
    .filter((site) => !hiddenSites.includes(site.id))
    .forEach((site) => {
      if (site.closingPeriods.length > 0) {
        site.closingPeriods.forEach((period) => {
          closingPeriods.push({
            title: formatEventTitle({ name: site.name, ...period, client: site.client }),
            ...period,
          });
        });
      }
    });

  return (
    <Page title="Période de fermeture des sites">
      <AddClosingPeriodForm />
      {closingPeriods.length > 0 && (
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin]}
          initialView="dayGridMonth"
          locale={frLocale}
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth",
          }}
          events={closingPeriods}
        />
      )}

      <ClientFilter clients={sites} hiddenClients={hiddenSites} setHiddenClients={setHiddenSites} />
    </Page>
  );
};

export default ClosingPeriods;
