import React, { useState, useEffect } from "react";
import useStore from "../../../context/useStore";
import { authActions } from "../../../context/actions/authActions";
import ForgotPassword from "./ForgotPassword";
import Control from "../../common/Controls/Control";
import SaveBtn from "../../common/SaveBtn";
import { APP_NAME } from "../../../config";
import tools from "../../../helpers/tools";
import LoginErrorBox from "./LoginErrorBox";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

var timerInterval = null;
var recaptcha;

const Login = () => {
  const [state, dispatch] = useStore();
  var actions = authActions(state, dispatch);
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [forgotPassword, setForgotPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [timer, setTimer] = useState(0);
  const [captchaToken, setCaptchaToken] = useState(false);
  const [register, setRegister] = useState(false);

  const [canLoggin, setCanLoggin] = useState(true);

  useEffect(() => {
    document.querySelector(".login-form").scrollIntoView();
  }, []);

  useEffect(() => {
    if (Object.values(state.errors).length > 0) {
      console.log("CALLED");
      setIsLoading(false);
      if (state.errors.password) {
        setPassword("");
      }
      if (state.errors.timer) {
        clearInterval(timerInterval);
        setTimer(parseInt(state.errors.timer));
      }
    }
  }, [state.errors]);

  useEffect(() => {
    console.log("CALLED");
    setTimer(false);
    setCanLoggin(true);
    setCaptchaToken(false);
    // updateToken();
  }, [forgotPassword]);

  function loginHandle(e) {
    console.log("OK");
    e.preventDefault();
    //actions.loginUser({ login, password });
    setIsLoading(true);
  }

  useEffect(() => {
    if (timer > 0) {
      setCanLoggin(false);
    }
  }, [timer]);

  function timerFinish() {
    setCanLoggin(true);
  }

  const verifyCallback = (recaptchaToken) => {
    // Here you will get the final recaptchaToken!!!
    setIsLoading(false);
    if (!forgotPassword) {
      actions.loginUser({ login, password, captchaToken: recaptchaToken });
    }
    setCaptchaToken(recaptchaToken);
  };

  const updateToken = () => {
    // you will get a new token in verifyCallback
    if (recaptcha) {
      recaptcha.execute();
    }
  };

  const handleClickForgotPassword = (isTrue) => {
    setForgotPassword(isTrue);
  };

  return (
    <div className="container-fluid">
      <div className="row ">
        <div className="col-5 mx-auto mt-5">
          {forgotPassword ? (
            <ForgotPassword
              handleClickForgotPassword={() => handleClickForgotPassword(false)}
              captchaToken={captchaToken}
            />
          ) : (
            <form
              onSubmit={(e) => e.preventDefault()}
              className="d-flex align-items-center custom-card justify-content-start flex-column mx-auto animated fadeInLeft faster login-form "
            >
              <div className="mt-5 d-center">
                <img src="/images/logo.png" style={{ width: 150 }} />
              </div>
              <div className=" text-center">
                <h1 className="pt-5 pb-4 text-secondary main-title">
                  Je me connecte sur {APP_NAME}
                  <hr className="w-50 mt-4" />
                </h1>
              </div>
              <Control
                className="w-75"
                autoFocus={true}
                label="Email"
                name="login"
                id="login"
                type="email"
                value={login}
                change={(e) => setLogin(e.target.value)}
                error={state.errors}
                isValid={tools.validateEmail(login)}
              />
              <div className="w-75 m-auto">
                <Control
                  className="w-100"
                  label="Mot de passe"
                  name="password"
                  id="password"
                  type="password"
                  value={password}
                  change={(e) => setPassword(e.target.value)}
                  error={state.errors}
                  isValid={password.length > 6}
                  showValidation={false}
                />
              </div>
              <LoginErrorBox
                timer={timer}
                timerFinish={timerFinish}
                canLoggin={canLoggin}
              />
              <div className="w-75 mx-auto d-flex justify-content-center mt-3">
                {canLoggin && (
                  <SaveBtn
                    className="animated zoomIn"
                    isSaving={isLoading}
                    save={loginHandle}
                    text="Je me connecte"
                    type="primary"
                    typeBtn="button"
                  />
                )}
              </div>
              <div className="w-75 mx-auto d-flex justify-content-center mt-3 mt-2">
                <button
                  type="button"
                  style={{ fontSize: 12 }}
                  onClick={() => handleClickForgotPassword(true)}
                  className="btn btn-link p-0"
                >
                  mot de passe oublié ?
                </button>
              </div>
            </form>
          )}
        </div>

        {isLoading && <GoogleReCaptcha onVerify={verifyCallback} />}
      </div>
      <div
        className="mt-4 text-center px-3"
        style={{
          fontSize: 9,
          right: 0,
        }}
      >
        This site is protected by reCAPTCHA and the Google{" "}
        <a href="https://policies.google.com/privacy">Privacy Policy</a> and{" "}
        <a href="https://policies.google.com/terms">Terms of Service</a> apply.
      </div>
    </div>
  );
};

export default Login;
