import { DateTime } from "luxon";
import React from "react";
import Pagination from "../../../../common/Pagination";
import Table from "../../../../common/Table/Table";

const OrderReceiptTable = ({ state, actions }) => {
  return (
    <>
      <Table
        datas={state.orderReceipts}
        fields={[
          {
            name: "Fournisseur",
            path: "provider",
            render: (el) => el.name,
          },
          {
            name: "Référence",
            path: "reference",
          },
          {
            name: "Début",
            path: "beginAt",
            render: (el) => DateTime.fromISO(el).toFormat("dd/MM/yyyy"),
          },
          {
            name: "Fin",
            path: "endAt",
            render: (el) => DateTime.fromISO(el).toFormat("dd/MM/yyyy"),
          },
          {
            name: "Bon de commande",
            path: "*",
            render: (el) => (
              <button
                className="btn btn-primary"
                onClick={() => actions.setCurrentOrderReceipt(el)}
              >
                Afficher
              </button>
            ),
          },
        ]}
      />
      <Pagination
        nbPages={state.pagination.nbPages}
        page={state.search.page}
        changePage={(p) => actions.setSearch({ ...state.search, page: p })}
      />
    </>
  );
};

export default OrderReceiptTable;
