import { atom, useAtom } from "jotai";
import dataService from "../../../../helpers/dataService";
import useSpecificities from "../../../../hooks/useSpecificities";
import useTextures from "../../../../hooks/useTextures";

const isLoadingAtom = atom(false);
const specSummaryAtom = atom([]);

const filtersAtom = atom({
  productionSite: "",
  beginAt: "",
  sites: [],
  specificities: [],
  textures: [],
});

const useSpecificitiesSummary = () => {
  const [isLoading, setIsLoading] = useAtom(isLoadingAtom);
  const [specSummary, setSpecSummary] = useAtom(specSummaryAtom);
  const [filters, setFilters] = useAtom(filtersAtom);

  const onGetSummary = () => {
    setIsLoading(true);
    const search = {
      productionSiteId: filters.productionSite,
      date: filters.beginAt,
    };
    dataService.post(
      `summaries/specificities`,
      search,
      setSpecSummary,
      (err) => {},
      () => setIsLoading(false)
    );
  };

  return {
    isLoading,
    specSummary,
    filters,
    setFilters,
    onGetSummary,
  };
};

export default useSpecificitiesSummary;
