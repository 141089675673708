import React, { useContext, useState } from "react";

const IngredientContext = React.createContext();
export const IngredientProvider = ({ providers, children }) => {
  const [selectedProviders, setSelectedProviders] = useState(providers.map((p) => p.id));

  const handleChangeSelectedProviders = (e) => {
    setSelectedProviders(e.target.value);
  };

  return (
    <IngredientContext.Provider
      value={{
        providers,
        selectedProviders,
        actions: { handleChangeSelectedProviders },
      }}
    >
      {children}
    </IngredientContext.Provider>
  );
};

const useIngredientContext = () => {
  const { selectedProviders, providers, actions } = useContext(IngredientContext);

  return {
    providers,
    selectedProviders,
    actions,
  };
};

export default useIngredientContext;
