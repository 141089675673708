import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import dataService from "../../../../helpers/dataService";
import Control from "../../../common/Controls/Control";
import Loader from "../../../common/Loader";

const Filters = ({ search, setSearch, getDatas, isLoading, openCsv, allotDate, setAllotDate }) => {
  const [productionSites, setProductionSites] = useState([]);

  useEffect(() => {
    getProductionSites();
  }, []);
  const getProductionSites = () => {
    dataService.get("productionsites", setProductionSites);
  };

  return (
    <div className="row mb-3">
      <div className="col-6">
        <Control
          label="Cuisine"
          type="select"
          datas={productionSites}
          value={search.productionSite}
          dataIndex="id"
          dataLabel="name"
          change={(e) => setSearch({ ...search, productionSite: e.target.value })}
        />
      </div>
      <div className="col-6">
        <Control
          withNav
          label={<>Date de livraison </>}
          type="date"
          value={search.date}
          change={(e) => setSearch({ ...search, date: e.target.value })}
          suffix={
            <span className="text-danger">
              <i className="fa fa-warning mr-1" /> utilisée pour les calculs
            </span>
          }
        />
      </div>
      <div className="col-4">
        <Control
          withNav
          label="Date d'allotissement"
          type="date"
          value={allotDate}
          change={(e) => setAllotDate(e.target.value)}
        />
      </div>
      <div className="col-3 justify-content-start align-items-end d-flex noprint">
        <button className="btn btn-secondary btn-sm mb-1" onClick={getDatas}>
          {isLoading ? <Loader color="white" /> : "Afficher"}
        </button>
      </div>
    </div>
  );
};

export default Filters;
