import React, { useState } from "react";
import useStoreState from "../../Context/Store/useStoreState";
import Slot from "./Slot/Slot";
import PanelDay from "./PanelDay";
import PanelMoment from "./PanelMoment";
import "./panels.scss";
import PanelsActions from "./PanelsActions";
import { sidePanelActions } from "../../../../../../context/actions/sidePanelActions";
import DuplicateDishSlots from "./Slot/DishSlot/DuplicateDishSlots/DuplicateDishSlots";

const Panels = () => {
  const { gState, items, gDispatch, items_actions } = useStoreState();
  const { DAYS, CONSUME_TIMES } = gState.constants.items;
  const spActions = sidePanelActions(gState, gDispatch);
  const [momentsVisible, setMomentsVisible] = useState([]);

  const toggleMomentVisible = (id) => {
    if (momentsVisible.includes(id)) {
      setMomentsVisible(momentsVisible.filter((v) => v != id));
    } else {
      setMomentsVisible([...momentsVisible, id]);
    }
  };

  const duplicateDishSlot = (momentId) => {
    const dishSlots = items.menu.slots.filter((s) => s.moment == momentId);

    var ps = "";
    // if (items.utils.selectedSite) {
    //   ps = items.productionSites.find(
    //     (p) =>
    //       p.id ==
    //       items.sites.find((s) => s.id == items.utils.selectedSite)
    //         .productionSite
    //   );
    // } else {
    ps = items.productionSites.find((p) => p.id == items.menuRef.productionSite);
    //}
    spActions.updateSidePanel({
      isOpen: true,
      content: (
        <DuplicateDishSlots
          days={DAYS.map((d) => d.id)}
          moments={[momentId]}
          dishSlots={dishSlots}
          itemsActions={items_actions}
          allDishSlots={items.menu.slots}
          slotCodes={items.slotCodes}
          beginAt={items.menuRef.beginAt}
          nbWeeksByCycle={ps.nbWeeksByCycle}
        />
      ),
    });
  };

  let canModify = ["ADMIN", "SUPERVISOR"].includes(gState.auth.user.role);

  return (
    <div className="row">
      <PanelsActions momentsVisible={momentsVisible} setMomentsVisible={setMomentsVisible} />
      {CONSUME_TIMES.map((ct, ctk) => (
        <div className="w-100 position-relative" key={`moment${ctk}`}>
          {!canModify && (
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: "999",
                background: "rgba(255,255,255,0.1)",
                width: "100%",
                height: "100%",
              }}
            ></div>
          )}
          <button
            className="btn btn-light font-italic font-weight-bold w-100 text-left"
            onClick={() => toggleMomentVisible(ct.id)}
          >
            {ct.name}
          </button>
          <button
            className={`btn btn-sm  position-absolute animated faster ${
              true ? "fadeInUp" : "fadeOut"
            }`}
            onClick={() => duplicateDishSlot(ct.id)}
            style={{ right: 0 }}
          >
            <i className="fa fa-copy" />
          </button>
          <div
            className="col-panels"
            style={{
              display: momentsVisible.includes(ct.id) ? "flex" : "none",
              overflowX: "scroll",
            }}
          >
            {DAYS.map((day, dayk) => (
              <PanelDay day={day} moment={ct} key={`col-day-${dayk}`}>
                <PanelMoment consumeTime={ct} key={`col-moment-${ctk}`}>
                  <Slot
                    day={day}
                    moment={ct}
                    slots={items.menu.slots.filter((s) => s.day == day.id && s.moment == ct.id)}
                  />
                </PanelMoment>
              </PanelDay>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Panels;
