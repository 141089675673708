import React, { useEffect, useState } from "react";
import dataService from "../../../helpers/dataService";
import Control from "../../common/Controls/Control";
import Page from "../../common/layout/Page";
import TimelineCycle from "../../common/Timeline/TimelineCycle";

const DuplicateMenuRef = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [cycles, setCycles] = useState([]);
  const [productionSites, setProductionSites] = useState([]);
  const [productionSite, setProductionSite] = useState("");

  useEffect(() => {
    getProductionSites();
  }, []);

  useEffect(() => {
    if (productionSite.length) {
      getCycles();
    }
  }, [productionSite]);

  const getProductionSites = () => {
    setIsLoading(true);
    dataService.get(
      "productionsites",
      (datas) => {
        setProductionSites(datas);
      },
      setErrors,
      () => setIsLoading(false)
    );
  };

  const getCycles = () => {
    setIsLoading(true);
    dataService.get(
      `productionsites/${productionSite}/cycles`,
      setCycles,
      setErrors,
      () => setIsLoading(false)
    );
  };

  const duplicateCycle = (menuRefs, includeComments) => {
    var saveData = menuRefs.filter((m) => m.id).map((m) => m.id);
    setIsLoading(true);
    dataService.post(
      `menurefs/duplicate/cycle`,
      { menuRefOrigins: saveData, isDaysComments: includeComments },
      (datas) => getCycles(),
      setErrors,
      () => setIsLoading(false)
    );
  };

  return (
    <Page title="Duplication des cycles" errors={errors} isLoading={isLoading}>
      <div className="row">
        <div className="col-12">
          <Control
            label="Cuisine"
            type="select"
            name="productionSite"
            value={productionSite}
            datas={productionSites}
            change={(e) => setProductionSite(e.target.value)}
          />
        </div>
        {cycles.length ? (
          <div className="col-12 mt-3">
            <TimelineCycle
              events={cycles}
              duplicateCycle={duplicateCycle}
              isSaving={isSaving}
            />
          </div>
        ) : null}
      </div>
    </Page>
  );
};

export default DuplicateMenuRef;
