import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { sidePanelActions } from "../../../../../../context/actions/sidePanelActions";
import tools from "../../../../../../helpers/tools";
import useStoreState from "../../Context/Store/useStoreState";
import DayComment from "./Slot/DayComment/DayComment";
import DuplicateDishSlots from "./Slot/DishSlot/DuplicateDishSlots/DuplicateDishSlots";

const PanelDay = ({ day, moment, ...props }) => {
  const { items, items_actions, gState, gDispatch } = useStoreState();
  const spActions = sidePanelActions(gState, gDispatch);
  const [mouseOver, setMouseOver] = useState(false);
  const { editSlotId } = items.utils;
  const menuRef = { ...items.menuRef };
  const dayComments = menuRef.daysComments.find((d) => d.day == day.id);

  useEffect(() => {
    const panels = document.querySelectorAll(".col-panel");
    function toggleOpen(e) {
      if (!e.target.classList.contains("forward-click")) return;
      var pans = document.querySelectorAll(`.col-panel${this.dataset.panel}`);
      pans.forEach((pan) => pan.classList.toggle("open"));
    }
    function toggleActive(e) {
      if (e.propertyName.includes("flex")) {
        this.classList.toggle("open-active");
      }
    }
    var toggleBtns = document.querySelectorAll(".panel-toggle-btn");
    panels.forEach((panel) => panel.addEventListener("click", toggleOpen));

    panels.forEach((panel) => panel.addEventListener("transitionend", toggleActive));
  }, []);

  const duplicateDishSlot = () => {
    const dishSlots = items.menu.slots.filter((s) => s.day == day.id && s.moment == moment.id);

    var nbWeeksByCycle = null;

    if (items.utils.selectedSite) {
      nbWeeksByCycle = items.productionSites.find(
        (p) => p.id == items.sites.find((s) => s.id == items.utils.selectedSite).productionSite
      ).nbWeeksByCycle;
    } else {
      nbWeeksByCycle = items.productionSites.find(
        (p) => p.id == items.menuRef.productionSite
      ).nbWeeksByCycle;
    }

    spActions.updateSidePanel({
      isOpen: true,
      content: (
        <DuplicateDishSlots
          days={dishSlots.map((d) => d.day)}
          moments={[moment.id]}
          dishSlots={dishSlots}
          itemsActions={items_actions}
          allDishSlots={items.menu.slots}
          slotCodes={items.slotCodes}
          beginAt={items.menuRef.beginAt}
          nbWeeksByCycle={nbWeeksByCycle}
        />
      ),
    });
  };

  const addComment = () => {
    spActions.updateSidePanel({
      isOpen: true,
      content: <DayComment day={day} itemsActions={items_actions} menuRef={menuRef} />,
    });
  };

  const dayDate = DateTime.fromISO(items.menuRef.beginAt)
    .minus({ days: 1 })
    .plus({ days: parseInt(day.id) });

  let isHoliday = tools.isHolyday(dayDate);

  return (
    <div
      className={`col-panel forward-click col-panel${day.id}`}
      data-panel={`${day.id}`}
      onMouseEnter={(e) => setMouseOver(true)}
      onMouseLeave={(e) => setMouseOver(false)}
    >
      <div className="d-center flex-column" style={{ maxHeight: 34 }}>
        <div className="panel-toggle-btn position-relative font-weight-bold d-center py-2 w-100 forward-click">
          <button
            className={`btn btn-sm   position-absolute animated faster ${
              (mouseOver && !editSlotId) || dayComments ? "fadeInUp" : "fadeOut"
            }`}
            onClick={addComment}
            style={{ left: 0 }}
          >
            <i
              className={`fa ${
                dayComments ? "fa-comment-dots text-danger" : "fa-comment text-primary"
              }`}
            />
          </button>
          {day.name} {dayDate.toFormat("dd/MM")}{" "}
          {isHoliday && (
            <span className="text-danger pl-2">
              <i className="fa fa-warning mr-1" />
              Férié
            </span>
          )}
          <button
            className={`btn btn-sm text-primary  position-absolute animated faster ${
              mouseOver && !editSlotId ? "fadeInUp" : "fadeOut"
            }`}
            onClick={duplicateDishSlot}
            style={{ right: 0 }}
          >
            <i className="fa fa-copy" />
          </button>
        </div>
      </div>
      {props.children}
    </div>
  );
};

export default PanelDay;
