import React from "react";
import useClients from "../../../../../hooks/useClients";
import Control from "../../../../common/Controls/Control";

const ClientSelector = ({ client, setClient }) => {
  const { clients } = useClients();

  return (
    <Control
      label="Client"
      name="client"
      type="select"
      value={client}
      change={(e) => setClient(e.target.value)}
      datas={clients}
      dataIndex="id"
      dataLabel="name"
      placeholder="Tous"
    />
  );
};

export default ClientSelector;
