import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import tools from "../../../../helpers/tools";
import Control from "../../../common/Controls/Control";
import Page from "../../../common/layout/Page";
import Loader from "../../../common/Loader";
import TableNav from "../BillingGlobal/TableNav";
import NowTime from "../NowTime";
import Filters from "../Purchase/Filters";
import OrderReceipt from "./OrderReceipt";
import TwoPartColumn from "./TwoPartColumn";
import usePurchaseSummary from "./usePurchaseSummary";
import SaveBtn from "../../../common/SaveBtn";

const PurchaseSummary = () => {
  const {
    errors,
    isLoading,
    isSaving,
    purchases,
    search,
    setSearch,
    productionSites,
    providers,
    setProviders,
    getPurchases,
    searchDone,
    daysArray,
    productsByProvider,
    changeFinalQty,
    savePurchase,
    saveOrderReceipt,
    orderReceiptFormatted,
    isGenerating,
    constants,
    searchChanged,
    areDatesEqual,
  } = usePurchaseSummary();

  const [alertIsOpen, setAlertIsOpen] = useState(true);
  const [orderReceipt, setOrderReceipt] = useState(null);

  const calculateTheoreticalQty = ({ providerId, productId }) => {
    var pur = purchases.filter((pu) => pu.preparation.id == productId && pu.provider == providerId);
    var total = pur.reduce(
      (a, b) => {
        return {
          theoreticalQty: a.theoreticalQty + b.theoreticalQty,
        };
      },
      { theoreticalQty: 0 }
    );
    return tools.round(total.theoreticalQty);
  };
  const calculateFinalQty = ({ providerId, productId }) => {
    var pur = purchases.filter((pu) => pu.preparation.id == productId && pu.provider == providerId);

    var total = pur.reduce(
      (a, b) => {
        return {
          finalQty: a.finalQty + b.finalQty,
        };
      },
      { finalQty: 0 }
    );
    return tools.round(total.finalQty);
  };
  const scrollTable = (backward, toEnd, providerId) => {
    var container = document.querySelector("#table-scroll-anchor" + providerId);
    if (backward) {
      container.scrollLeft -= toEnd ? 100000 : 500;
    } else {
      container.scrollLeft += toEnd ? 100000 : 500;
    }
  };

  useEffect(() => {
    if (orderReceipt) {
      saveOrderReceipt(orderReceipt);
    }
  }, [orderReceipt]);

  useEffect(() => {
    console.log("orderReceiptFormatted", orderReceiptFormatted);
  }, [search]);

  const openOrderReceipt = (providerEntry) => {
    setOrderReceipt({
      beginAt: search.beginAt,
      endAt: search.endAt,
      provider: providerEntry.provider,
      comment: providers.find((p) => p.id == providerEntry.provider).comment,
      preparations: providerEntry.products.map((p) => ({
        preparation: p.id,
        finalQty: calculateFinalQty({
          providerId: providerEntry.provider,
          productId: p.id,
        }),
        theoreticalQty: calculateTheoreticalQty({
          providerId: providerEntry.provider,
          productId: p.id,
        }),
      })),
    });
  };

  return (
    <Page
      title={<span className="noprint">Programmation des achats</span>}
      errors={errors}
      isLoading={isLoading}
      printOrientation="landscape"
      container="container-fluid"
      style={{ maxWidth: "100%" }}
    >
      <NowTime />
      <Filters
        search={search}
        setSearch={setSearch}
        productionSites={productionSites}
        providers={providers}
        getPurchases={getPurchases}
      />
      {/* {!purchases.length ? (
        searchDone ? (
          <p>Aucune donnée trouvée</p>
        ) : null
      ) : ( */}
      <>
        {alertIsOpen && (
          <div className="alert alert-primary d-flex justify-content-between align-items-center noprint">
            <div>
              <i className="fa fa-exclamation-triangle mr-2" />
              Attention, les données de ce tableau sont enregistrées automatiquement dès que vous
              les modifiez.
            </div>
            <i className="fa fa-times cursor-pointer" onClick={() => setAlertIsOpen(false)} />
          </div>
        )}
        {/* REPEAT FOR EACH PROVIDER */}
        {orderReceiptFormatted ? (
          <>
            <button className="btn btn-default noprint mb-3" onClick={() => setOrderReceipt(null)}>
              Fermer le bon de commande
            </button>
            <OrderReceipt orderReceipt={orderReceiptFormatted} />
          </>
        ) : isLoading ? (
          <Loader />
        ) : searchChanged ? (
          <p>Lancez la recherche</p>
        ) : (
          providers
            .filter((provider) => {
              return !search.provider ? true : provider.id == search.provider;
            })
            .map((provider) => {
              let providerEntry = productsByProvider.find((p) => p.provider == provider.id);
              return !providerEntry ? (
                <span key={`tableprovider${provider.id}`}>-</span>
              ) : (
                <div className="mb-4" key={`tableprovider${provider.id}`}>
                  <div
                    className="font-weight-bold text-secondary my-2"
                    style={{
                      fontSize: 18,
                    }}
                  >
                    {provider.name}
                  </div>
                  <div
                    id={`table-scroll-anchor${provider.id}`}
                    style={{
                      overflowX: "auto",
                      scrollBehavior: "smooth",
                      width: "100%",
                    }}
                  >
                    <table className="table table-bordered table-sm table-small-padding  bg-white shadow-sm hover-table ">
                      <thead>
                        <tr>
                          <th className="text-center">Produits</th>
                          {/* REPEAT FOR EACH DAY */}

                          {daysArray.map((day) => (
                            <th className="text-center">
                              {DateTime.fromISO(day).setLocale("FR").toFormat("dd LLLL ")}
                            </th>
                          ))}

                          <th className="text-center">
                            <strong>TOTAL</strong>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td></td>

                          {/* REPEAT FOR EACH DAY */}
                          {daysArray.map((day) => (
                            <>
                              <td>
                                <TwoPartColumn
                                  col1Text="Qté théorique (Kg)"
                                  col2Text="Qté réelle (Kg)"
                                  col1ClassName="font-weight-bold"
                                  col2ClassName="font-weight-bold"
                                />
                              </td>
                            </>
                          ))}
                          <td>
                            <TwoPartColumn
                              col1Text="Qté théorique (Kg)"
                              col2Text="Qté réelle (Kg)"
                              col1ClassName="font-weight-bold"
                              col2ClassName="font-weight-bold"
                            />
                          </td>
                        </tr>

                        {/* REPEAT FOR EACH PRODUCT */}
                        {providerEntry &&
                          providerEntry.products
                            .sort((a, b) => (a.name > b.name ? 1 : -1))
                            .map((product) => {
                              return (
                                <tr>
                                  <td>
                                    <div className="d-flex justify-content-between flex-row ">
                                      <div>
                                        <strong>{product.name}</strong>
                                      </div>
                                      <div>
                                        {(() => {
                                          var val = "";
                                          if (product.isSpecificEquivalentPacking) {
                                            val = `${product.packingWeight} kg`;
                                            val += ` / ${product.specificEquivalentPackingQty} ${
                                              tools.findInConstant(
                                                constants.PREPARATION_UNITS,
                                                product.specificEquivalentPackingUnit
                                              ).name
                                            }`;
                                          }
                                          return (
                                            <div>
                                              <div className="text-right ">
                                                <div
                                                  className="badge badge-primary font-weight-bold"
                                                  style={{ fontSize: 12 }}
                                                >
                                                  {Math.round(
                                                    (parseFloat(product.price) /
                                                      parseFloat(product.packingWeight)) *
                                                      100
                                                  ) / 100}
                                                  € / Kg
                                                </div>
                                              </div>
                                              <div className="text-right">{val}</div>
                                            </div>
                                          );
                                        })()}
                                      </div>
                                    </div>
                                  </td>
                                  {daysArray.map((day) => {
                                    var pur = purchases.find(
                                      (pu) =>
                                        pu.preparation.id == product.id &&
                                        areDatesEqual(pu.day, day) &&
                                        pu.provider == providerEntry.provider
                                    );

                                    var isDisabled =
                                      DateTime.fromISO(day)
                                        .diff(DateTime.local().startOf("day"), "days")
                                        .toObject().days < 0;
                                    return !pur ? (
                                      <td>
                                        <TwoPartColumn col1Text="x" col2Text="x" />
                                      </td>
                                    ) : (
                                      <td>
                                        <TwoPartColumn
                                          col1Text={
                                            <span style={{ fontSize: "1rem" }}>
                                              {tools.round(pur.theoreticalQty)}
                                            </span>
                                          }
                                          col2Text={
                                            <Control
                                              type="float"
                                              value={pur.finalQty}
                                              onBlur={() => {
                                                savePurchase(pur);
                                              }}
                                              change={(e) =>
                                                changeFinalQty(
                                                  e.target.value,
                                                  day,
                                                  pur.preparation.id,
                                                  provider.id
                                                )
                                              }
                                              inputStyle={{
                                                textAlign: "center",
                                                background: isDisabled
                                                  ? "rgba(0,0,0,0)"
                                                  : "#e0e0E0",
                                              }}
                                              disabled={isDisabled || isSaving}
                                              suffix={isSaving ? <Loader /> : null}
                                            />
                                          }
                                        />
                                      </td>
                                    );
                                  })}
                                  <td>
                                    <TwoPartColumn
                                      col1Text={
                                        <span
                                          className="text-primary font-weight-bold d-center"
                                          style={{
                                            height: 30,
                                            fontSize: "1rem",
                                          }}
                                        >
                                          {calculateTheoreticalQty({
                                            providerId: providerEntry.provider,
                                            productId: product.id,
                                          })}
                                        </span>
                                      }
                                      col2Text={
                                        <span
                                          className="text-primary font-weight-bold d-center"
                                          style={{
                                            height: 30,
                                            fontSize: "1rem",
                                          }}
                                        >
                                          {calculateFinalQty({
                                            providerId: providerEntry.provider,
                                            productId: product.id,
                                          })}
                                        </span>
                                      }
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                      </tbody>
                    </table>
                  </div>
                  <TableNav scrollTable={(dir, end) => scrollTable(dir, end, provider.id)} />
                  <div className="d-flex justify-content-end noprint flex-column">
                    {providerEntry && purchases.length && searchDone ? (
                      <>
                        <Control
                          label="Commentaire"
                          type="textarea"
                          value={providers.find((p) => p.id == providerEntry.provider).comment}
                          change={(e) => {
                            setProviders(
                              providers.map((p) =>
                                p.id == providerEntry.provider
                                  ? { ...p, comment: e.target.value }
                                  : p
                              )
                            );
                          }}
                        />
                        <SaveBtn
                          isSaving={isGenerating}
                          save={() => openOrderReceipt(providerEntry)}
                          text="Générer le bon de commande"
                        />
                      </>
                    ) : null}
                  </div>
                </div>
              );
            })
        )}
      </>
      {/* )} */}
    </Page>
  );
};

export default PurchaseSummary;
