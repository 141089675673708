import React from "react";

const PanelsActions = ({ momentsVisible, setMomentsVisible }) => {
  const isExpanded = momentsVisible.length;

  return (
    <>
      <button
        className="btn btn-light mb-2"
        onClick={() => setMomentsVisible(isExpanded ? [] : [1, 2, 3, 4, 5])}
      >
        <i
          className={`fa mr-2 fa-${isExpanded ? "compress-alt" : "expand-alt"}`}
        />
        Tout {isExpanded ? "replier" : "déplier"}
      </button>
    </>
  );
};

export default PanelsActions;
