import { DateTime } from "luxon";
import React, { useState } from "react";
import { modalActions } from "../../../../context/actions/modalActions";
import useStore from "../../../../context/useStore";
import dataService from "../../../../helpers/dataService";
import tools from "../../../../helpers/tools";
import Page from "../../../common/layout/Page";
import NowTime from "../NowTime";
import Filters from "./Filters";
import BillingTotal from "./BillingTotal";
import ContractTotal from "./ContractTotal";

const BillingSummary = () => {
  const [state, dispatch] = useStore();
  const mActions = modalActions(state, dispatch);
  const constants = state.constants.items;
  const [isLoading, setIsLoading] = useState(false);
  const [datas, setDatas] = useState([]);
  const [searchDone, setSearchDone] = useState(false);

  var searchState = tools.getState("BillingSummary", null);
  const [search, setSearch] = useState(
    searchState
      ? JSON.parse(searchState.search)
      : {
          client: "",
          beginAt: DateTime.local().startOf("month").toISO(),
          endAt: DateTime.local().endOf("month").toISO(),
        }
  );

  const getDatas = () => {
    setSearchDone(false);

    tools.saveState("BillingSummary", "search", JSON.stringify(search));
    setIsLoading(true);
    dataService.post(
      `summaries/activity`,
      search,
      (datas) => setDatas(datas.slots),
      (err) => {},
      () => {
        setIsLoading(false);
        setSearchDone(true);
      }
    );
  };

  const getAllMoments = () => {
    var moments = [];
    datas.forEach((data) => {
      data.sites.forEach((site) => {
        site.moments
          .sort((a, b) => (a.moment > b.moment ? 1 : -1))
          .forEach((moment) => {
            if (!moments.find((m) => m == moment.moment)) {
              moments.push(moment.moment);
            }
          });
      });
    });
    return moments;
  };
  const getAllContracts = () => {
    var contracts = [];
    datas.forEach((data) => {
      data.sites.forEach((site) => {
        site.moments.forEach((moment) => {
          moment.contracts.forEach((contract) => {
            if (!contracts.find((c) => c.id == contract.id && c.moment == moment.moment)) {
              contracts.push({ ...contract, moment: moment.moment });
            }
          });
        });
      });
    });
    return contracts;
  };

  var recap = datas.length ? tools.getQtyPriceRecap(datas) : [];
  var qtyByContract = datas.length ? tools.getQtyByContract(datas) : [];
  var allMoments = datas.length ? getAllMoments() : [];
  var allContracts = datas.length ? getAllContracts() : [];
  console.log("allMoments", allMoments);
  console.log("allContracts", allContracts);

  return (
    <Page
      title="Récapitulatif d'activité"
      titleMargin="mb-3"
      isLoading={isLoading}
      printOrientation="landscape"
      container="container-fluid"
      style={{ maxWidth: "100%" }}
    >
      <NowTime />
      <Filters setSearch={setSearch} search={search} getDatas={getDatas} isLoading={isLoading} />
      {!datas.length ? (
        searchDone ? (
          <p>Aucune donnée trouvée</p>
        ) : null
      ) : (
        <>
          <table className="no-padding-table w-100 bg-white hover-table">
            <thead>
              <tr>
                <th
                  className="text-center border"
                  style={{
                    width: 75,
                  }}
                >
                  Date
                </th>
                <th className="text-center border">Sites</th>
                <th className="text-center border">Moments</th>
                <th
                  className="text-center border"
                  style={{
                    width: 70,
                  }}
                >
                  Qté site
                </th>
                <th
                  data-priv="view_billing_summary_costs"
                  className="text-center border"
                  style={{
                    width: 70,
                  }}
                >
                  Qté jour
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="cell-div bg-default"></div>
                </td>
                <td>
                  <div className="cell-div bg-default"></div>
                </td>
                <td>
                  <div className="d-flex flex-row justify-content-between w-100">
                    {allMoments.map((m) => (
                      <div
                        className="cell-div bg-default text-center font-weight-bold text-secondary"
                        style={{
                          width: 100 / allMoments.length + "%",
                        }}
                      >
                        {constants.CONSUME_TIMES.find((ct) => ct.id == m).name}
                      </div>
                    ))}
                  </div>
                </td>
                <td>
                  <div className="cell-div bg-default"></div>
                </td>
                <td data-priv="view_billing_summary_costs">
                  <div className="cell-div bg-default"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="cell-div bg-default"></div>
                </td>
                <td>
                  <div className="cell-div bg-default"></div>
                </td>
                <td>
                  <div className="d-flex flex-row justify-content-between w-100">
                    {allMoments.map((m) => {
                      var contractsOfMoment = allContracts.filter((c) => c.moment == m);
                      return (
                        <div
                          className="font-weight-bold bg-default text-center d-flex flex-row justify-content-between"
                          style={{
                            width: 100 / allMoments.length + "%",
                          }}
                        >
                          {contractsOfMoment.map((c) => (
                            <div
                              className="cell-div bg-default text-center"
                              style={{
                                width: 100 / contractsOfMoment.length + "%",
                              }}
                            >
                              {c.name}
                            </div>
                          ))}
                        </div>
                      );
                    })}
                  </div>
                </td>
                <td>
                  <div className="cell-div bg-default"></div>
                </td>
                <td data-priv="view_billing_summary_costs">
                  <div className="cell-div bg-default"></div>
                </td>
              </tr>
              {datas.map((date) => {
                const sites = date.sites;
                const moments = tools.getMomentsFromSites(date.sites);
                const contracts = tools.getContractsOfMoments(sites, moments);
                return (
                  <>
                    <tr>
                      <td className="text-center border text-secondary font-weight-bold bg-white">
                        {DateTime.fromISO(date.deliveryDate).toFormat("dd/MM/yyyy")}
                      </td>
                      <td className="border">
                        {sites.map((site) => (
                          <div className="cell-div  bg-white">{site.name}</div>
                        ))}
                        {sites.length > 1 && (
                          <div className="cell-div  font-weight-bold bg-default  font-weight-bold">
                            TOTAL
                          </div>
                        )}
                      </td>
                      <td className="border">
                        {date.sites.map((site) => (
                          <div className="d-flex flex-row justify-content-between w-100">
                            {allMoments.map((m) => {
                              var contractsOfMoment = allContracts.filter((c) => c.moment == m);
                              return (
                                <div
                                  className=" bg-default text-center d-flex flex-row justify-content-between"
                                  style={{
                                    width: 100 / allMoments.length + "%",
                                  }}
                                >
                                  {contractsOfMoment.map((c) => {
                                    var siteMomentEntry = site.moments.find((sm) => sm.moment == m);
                                    var contractEntry = siteMomentEntry
                                      ? siteMomentEntry.contracts.find((sc) => sc.id == c.id)
                                      : null;
                                    return (
                                      <div
                                        className="cell-div bg-white text-center"
                                        style={{
                                          width: 100 / contractsOfMoment.length + "%",
                                        }}
                                      >
                                        {contractEntry ? contractEntry.qty : 0}
                                      </div>
                                    );
                                  })}
                                </div>
                              );
                            })}
                          </div>
                        ))}
                        {date.sites.length > 1 && (
                          <div className="d-flex flex-row w-100 justify-content-between">
                            {allMoments.map((m) => {
                              var contractsOfMoment = allContracts.filter((c) => c.moment == m);
                              return (
                                <div
                                  className=" font-weight-bold bg-default text-center d-flex flex-row justify-content-between"
                                  style={{
                                    width: 100 / allMoments.length + "%",
                                  }}
                                >
                                  {contractsOfMoment.map((c) => {
                                    var totalSite = 0;
                                    date.sites.forEach((si) => {
                                      si.moments
                                        .filter((sim) => sim.moment == m)
                                        .forEach((sim) => {
                                          sim.contracts
                                            .filter((simc) => simc.id == c.id)
                                            .forEach((simc) => {
                                              totalSite += simc.qty;
                                            });
                                        });
                                    });
                                    return (
                                      <div
                                        className="cell-div font-weight-bold bg-default  text-center"
                                        style={{
                                          width: 100 / contractsOfMoment.length + "%",
                                        }}
                                      >
                                        {totalSite}
                                      </div>
                                    );
                                  })}
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </td>
                      <td className="border">
                        {date.sites.map((site) => {
                          return <div className="cell-div bg-white text-center">{site.qty}</div>;
                        })}
                        {date.sites.length > 1 && (
                          <div className="cell-div font-weight-bold bg-default text-center ">
                            {date.qty}
                          </div>
                        )}
                      </td>

                      <td className="border" data-priv="view_billing_summary_costs">
                        {" "}
                        {date.sites.map((site) => {
                          return (
                            <div className="cell-div bg-white text-center">
                              {tools.round(site.price)} €
                            </div>
                          );
                        })}
                        {date.sites.length > 1 && (
                          <div className="cell-div font-weight-bold bg-default text-center ">
                            {tools.round(date.price)} €
                          </div>
                        )}
                      </td>
                    </tr>
                  </>
                );
              })}
              {/* <tr>
                <td
                  colSpan="5"
                  className="pt-1"
                  style={{
                    background: Colors.primary,
                  }}
                ></td>
              </tr> */}
              <tr className="bg-dark">
                <td className="cell-div text-center font-weight-bold text-white">TOTAL</td>
                <td></td>
                <td></td>
                <td className="cell-div text-center font-weight-bold text-white ">
                  {
                    recap.reduce(
                      (sa, sb) => {
                        return {
                          qty: sa.qty + sb.qty,
                        };
                      },
                      { qty: 0 }
                    ).qty
                  }{" "}
                </td>

                <td
                  className="cell-div text-center font-weight-bold text-white "
                  data-priv="view_billing_summary_costs"
                >
                  {tools.round(
                    recap.reduce(
                      (sa, sb) => {
                        return {
                          price: sa.price + sb.price,
                        };
                      },
                      { price: 0 }
                    ).price
                  )}{" "}
                  €
                </td>
              </tr>
            </tbody>
          </table>
          <BillingTotal recap={recap} />
          <ContractTotal contracts={qtyByContract} />
        </>
      )}
    </Page>
  );
};

export default BillingSummary;
