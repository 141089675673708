import React, { useState } from "react";
import Loader from "../../../../../../common/Loader";
import useStoreState from "../../../Context/Store/useStoreState";
import { sidePanelActions } from "../../../../../../../context/actions/sidePanelActions";
import DuplicateDishSlots from "./DishSlot/DuplicateDishSlots/DuplicateDishSlots";
import actionTools from "../../../Context/actions/actionTools";

const SlotActions = ({ dishSlot, mouseOver }) => {
  const { items, items_actions, gState, gDispatch } = useStoreState();
  const [confirmRemove, setConfirmRemove] = useState(false);
  const spActions = sidePanelActions(gState, gDispatch);

  const deleteDishSlot = () => {
    if (confirmRemove == dishSlot.id) items_actions.deleteDishSlot(dishSlot);
    setConfirmRemove(confirmRemove ? false : dishSlot.id);
  };

  const duplicateDishSlot = () => {
    var ps = "";
    // var ps = items.productionSites.find(
    //   (p) =>
    //     p.id ==
    //     items.sites.find((s) => s.id == items.utils.selectedSite).productionSite
    // );
    ps = items.productionSites.find(
      (p) => p.id == items.menuRef.productionSite
    );
    spActions.updateSidePanel({
      isOpen: true,
      content: (
        <DuplicateDishSlots
          moments={[dishSlot.moment]}
          days={[dishSlot.day]}
          dishSlots={[dishSlot]}
          itemsActions={items_actions}
          allDishSlots={items.menu.slots}
          slotCodes={items.slotCodes}
          beginAt={items.menuRef.beginAt}
          nbWeeksByCycle={ps.nbWeeksByCycle}
        />
      ),
    });
  };

  return (
    <span
      className="slot-action-container text-white  btn-sm w-100 align-items-center justify-content-between rounded-0 animated flipInX faster"
      style={{
        background: dishSlot.color,
        borderTop: `1px solid ${dishSlot.color}`,
        display: !items.utils.editSlotId ? "flex" : "none",
      }}
    >
      <a
        title="Déplacer"
        href="#"
        className={`btn  border-0 btn-sm drag-btn p-0 animated faster rounded-0 d-flex justify-content-start flex-fill ${
          mouseOver ? "fadeInUp" : ""
        }`}
        style={{
          display: !items.utils.editSlotId ? "block" : "none",
          color: mouseOver ? "white" : "transparent",
        }}
      >
        {items.utils.loadingDishSlots.includes(
          actionTools.getDishSlotUid(dishSlot)
        ) ? (
          <Loader color="white" />
        ) : (
          <div className="d-flex align-items-center justify-content-start">
            <div className="mr-2" style={{ opacity: ".7" }}>
              {dishSlot.order}
            </div>
            <i className="fa fa-grip-lines" />
          </div>
        )}
      </a>
      <div
        style={{
          display: !items.utils.editSlotId ? "block" : "none",
        }}
        className={`animated faster ${mouseOver ? "fadeInUp" : ""}`}
      >
        <button
          title="Modifier"
          className={`btn  border-0 btn-sm drag-btn p-0  rounded-0`}
          style={{
            color: mouseOver ? "white" : "transparent",
          }}
          onClick={() => items_actions.editDishSlot(dishSlot)}
        >
          <i className="fa fa-pen pr-2" />
        </button>
        <button
          title="Dupliquer"
          className={`btn  border-0 btn-sm drag-btn p-0  rounded-0`}
          onClick={duplicateDishSlot}
          style={{
            color: mouseOver ? "white" : "transparent",
          }}
        >
          <i className="fa fa-copy pr-2" />
        </button>
        <button
          title="Supprimer (confirmez)"
          className="btn  border-0 btn-sm drag-btn p-0 faster rounded-0"
          onClick={deleteDishSlot}
          style={{
            color: mouseOver ? "white" : "transparent",
          }}
        >
          {confirmRemove == dishSlot.id ? (
            <i className="fa fa-check animated fadeInRight faster" />
          ) : (
            <i className="fa fa-times" />
          )}
        </button>
      </div>
    </span>
  );
};

export default SlotActions;
