import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import useStore from "../../../../context/useStore";
import dataService from "../../../../helpers/dataService";
import tools from "../../../../helpers/tools";

const PurchaseDetail = ({ purchase, search }) => {
  const [state, dispatch] = useStore();
  const { TIME_SCALES, PREPARATION_CATEGORIES } = state.constants.items;
  const { date, productionSite } = purchase;
  const { purchaseIds } = productionSite;
  const [purchases, setPurchases] = useState([]);

  useEffect(() => {
    getDetail();
  }, [purchase]);

  const getDetail = () => {
    dataService.post(`purchases/ids`, { purchaseIds: purchaseIds }, setPurchases);
  };

  return (
    <div>
      <div>
        {TIME_SCALES.find((ts) => ts.id == search.timeScale).name} du{" "}
        {DateTime.fromISO(date).toFormat("dd/MM/yyyy")}
      </div>
      <div className="text-primary font-weight-bold">Fournisseur : {purchase.provider.name}</div>
      <div className="text-primary font-weight-bold">
        Site de prod : {productionSite.productionSite.name}
      </div>
      <table className="table table-bordered table-sm table-small-padding  bg-white shadow-sm hover-table ">
        <thead>
          <tr>
            <th>Ingrédient</th>
            <th>Quantité (Kg)</th>
          </tr>
        </thead>
        <tbody>
          {purchases
            .sort((a, b) =>
              a.preparation.ingredient.name > b.preparation.ingredient.name ? 1 : -1
            )
            .map((purchase, pk) => (
              <tr key={`purkey${pk}`}>
                <td>
                  {purchase.preparation.ingredient.name +
                    " - " +
                    tools.findInConstant(PREPARATION_CATEGORIES, purchase.preparation.category)
                      .name}
                </td>
                <td>{purchase.finalQty}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default PurchaseDetail;
