import React from "react";

const TwoPartColumn = ({
  col1Text,
  col2Text,
  col1ClassName = "",
  col2ClassName = "",
  spaceBetween = null,
}) => {
  return (
    <div className={`d-center flex-row w-100 h-100`}>
      <div
        className={` text-center  ${
          !spaceBetween
            ? "d-center"
            : "d-flex justify-content-start align-items-center"
        }   flex-fill w-100 h-100 ${col1ClassName}`}
      >
        {col1Text}
      </div>
      <div
        className={` text-center  ${
          !spaceBetween
            ? "d-center"
            : "d-flex justify-content-end align-items-center"
        }    flex-fill w-100 h-100 ${col2ClassName}`}
      >
        {col2Text}
      </div>
    </div>
  );
};

export default TwoPartColumn;
