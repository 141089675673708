import axios from "axios";

const setAuthToken = (psid) => {
  if (psid) {
    // Apply to every request
    axios.defaults.headers.common["X-Production-Site"] = psid;
    localStorage.setItem("ps", psid);
  } else {
    // Delete Auth header
    delete axios.defaults.headers.common["X-Production-Site"];
  }
};

export default setAuthToken;
