import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useStore from "../../../../context/useStore";
import dataService from "../../../../helpers/dataService";
import Control from "../../../common/Controls/Control";
import DynamicList from "../../../common/DynamicList";
import Page from "../../../common/layout/Page";
import SaveBtn from "../../../common/SaveBtn";

const DishTypeDetail = (props) => {
  const [state, dispatch] = useStore();
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [dishType, setDishType] = useState({
    name: "",
    order: "",
    prodConsDelayByDefault: "",
    isDisplayToPrintedMenu: true,
    isStatistic: false,
    exceptions: [],
  });
  const [productionsites, setProductionSites] = useState([]);
  const itemId = props.match.params.id;
  const isEdit = itemId == "create" ? false : true;
  const constants = state.constants.items;

  useEffect(() => {
    if (isEdit) {
      getDatas();
    }
  }, []);

  const getDatas = () => {
    getDependencies();
    if (isEdit) {
      dataService.get(
        "dishTypes/" + itemId,
        (datas) => setDishType(datas),
        (error) => setErrors(error),
        () => setIsLoading(false)
      );
    }
  };
  const getDependencies = () => {
    setIsLoading(true);

    dataService.get(
      "productionsites/",
      (datas) => setProductionSites(datas),
      (error) => setErrors(error),
      () => setIsLoading(false)
    );
  };

  const save = () => {
    const onError = (error) => {
      setErrors(error);
    };
    setIsLoading(true);
    if (isEdit) {
      const onSuccess = (datas) => {
        toast.success("Type de plat enregistré avec succès");
      };
      dataService.patch(
        "dishTypes/" + itemId,
        dishType,
        onSuccess,
        onError,
        () => setIsLoading(false)
      );
    } else {
      const onSuccess = (datas) => {
        toast.success("Type de plat créé avec succès");
        window.location.href = "/dishTypes/" + datas.id;
      };

      dataService.post("dishTypes", dishType, onSuccess, onError, () =>
        setIsLoading(false)
      );
    }
  };

  return (
    <Page
      container="container"
      back="/dishTypes"
      title={(isEdit ? "Modification" : "Détail") + " du type de plat"}
      errors={errors}
    >
      <div className="row custom-card">
        <div className="col-12 p-0">
          <Control
            label="Nom"
            name="name"
            type="text"
            value={dishType.name}
            change={(e) => setDishType({ ...dishType, name: e.target.value })}
            error={errors}
            setErrors={setErrors}
            required={true}
          />
          <Control
            label="Ordre"
            name="order"
            type="number"
            value={dishType.order}
            change={(e) => setDishType({ ...dishType, order: e.target.value })}
            error={errors}
            setErrors={setErrors}
            required={true}
          />
          <Control
            label="Délai de production / consommation par défaut (en nombre de jours)"
            name="prodConsDelayByDefault"
            type="number"
            value={dishType.prodConsDelayByDefault}
            change={(e) =>
              setDishType({
                ...dishType,
                prodConsDelayByDefault: e.target.value,
              })
            }
            error={errors}
            setErrors={setErrors}
            required={true}
          />
          <DynamicList
            label="Exceptions au délai de production"
            name="exceptions"
            change={(e) =>
              setDishType({
                ...dishType,
                exceptions: e.target.value,
              })
            }
            fields={[
              {
                label: "Cuisine",
                type: "select",
                name: "productionSite",
                datas: productionsites,
              },
              {
                label: "Délai de prod./cons.",
                type: "number",
                name: "delay",
              },
            ]}
            value={dishType.exceptions}
            btnLabel="Ajouter une exception"
          />
          <Control
            label="Ce type de plat apparait dans les menus imprimés"
            type="checkbox"
            checked={dishType.isDisplayToPrintedMenu}
            name="isDisplayToPrintedMenu"
            change={(e) =>
              setDishType({
                ...dishType,
                isDisplayToPrintedMenu: e.target.checked,
              })
            }
            error={errors}
            setError={setErrors}
          />
          <Control
            label="Ce type de plat est pris en compte dans le calcul des statistiques"
            type="checkbox"
            checked={dishType.isStatistic}
            name="isStatistic"
            change={(e) =>
              setDishType({
                ...dishType,
                isStatistic: e.target.checked,
              })
            }
            error={errors}
            setError={setErrors}
          />
        </div>
      </div>
      <div className="row" data-priv="create_modify_dish_types">
        <div className="d-flex justify-content-end w-100">
          <SaveBtn
            save={save}
            isSaving={isLoading}
            text="Enregistrer"
            type="primary"
          />
        </div>
      </div>
    </Page>
  );
};

export default DishTypeDetail;
