import React, { useEffect, useState } from "react";
import {
  PDFViewer,
  Page,
  Text,
  Image,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  Font,
} from "@react-pdf/renderer";
import { DateTime } from "luxon";
import Loader from "../../common/Loader";
import Colors from "../../../theme/variables.scss";
import tools from "../../../helpers/tools";
import rubik from "./rubik.ttf";
import useStore from "../../../context/useStore";
import Control from "../../common/Controls/Control";
import dataService from "../../../helpers/dataService";

const MyDoc = ({
  datas,
  styles,
  constants,
  consumerType,
  technicalSheet,
  isPrintClient,
  specificities,
  preparations,
  ingredients,
}) => {
  const { recipeData, texture, multiplicator } = datas;
  const { ALLERGENS, INGREDIENT_UNITS } = constants;
  // Font.register({
  //   family: "Rubik",
  //   format: "truetype",
  //   fonts: [
  //     {
  //       src: rubik,
  //     },
  //   ],
  // });

  var styles = StyleSheet.create({
    textBig: {
      fontSize: 18,
    },
    textMedium: {
      fontSize: 14,
    },
    textSmall: {
      fontSize: 9,
    },
    subTitle: {
      fontSize: 12,
      marginBottom: 5,
      textTransform: "uppercase",
      color: Colors.primary,
    },
    info: {
      fontSize: 12,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      maxWidth: 400,
      borderBottom: "1pt solid grey",
      marginBottom: 5,
    },
    label: {
      color: Colors.darkLight,
    },
    value: {
      color: Colors.primary,
    },
    col6: {
      width: "47%",
      marginBottom: 15,
    },
    listTitle: {
      fontSize: 11,
    },
    listItem: {
      fontSize: 9,
      color: Colors.lightDark,
    },
    row: {
      fontSize: 12,
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "space-between",
    },
    td: {
      padding: 5,
      border: "1pt solid grey",
      fontSize: 10,
    },
  });

  const allergenList = () => {
    var hasAllergens = false;
    var allergensUniq = [];
    recipeData.components.forEach((comp) => {
      var ing = ingredients.find((i) => i.id == comp.ingredient);
      if (ing.allergens.length) hasAllergens = true;
    });
    if (hasAllergens) {
      recipeData.components.forEach((comp, compk) => {
        var ing = ingredients.find((i) => i.id == comp.ingredient);
        if (ing.allergens.length) {
          ing.allergens.forEach((al) => {
            if (!allergensUniq.includes(al)) allergensUniq.push(al);
          });
        }
      });
    }
    // else (
    //   <View>
    //     <Text style={{ ...styles.listItem }}>-aucun allergène-</Text>
    //   </View>
    //   );

    return allergensUniq.length ? (
      allergensUniq.map((al, alk) => (
        <Text style={{ ...styles.listItem }} key={`allist${alk}`}>
          - {ALLERGENS.find((a) => a.id == al).name}
        </Text>
      ))
    ) : (
      <View>
        <Text style={{ ...styles.listItem }}>-aucun allergène-</Text>
      </View>
    );
  };

  const componentsList = () => {
    var comps = recipeData.components.map((ing) => ({
      ...ing,
      qty: (parseInt(multiplicator) * parseFloat(ing.qty)) / 10,
    }));

    return comps.map((ingr, ingrKey) => {
      var preparation = preparations.find((p) => p.id == ingr.preparation);
      var suffix = "";
      if (
        preparation.isSpecificEquivalentPacking &&
        preparation.specificEquivalentPackingUnit == "3"
      ) {
        var weight = preparation.packingWeight * 1000;
        var nbLiters = preparation.specificEquivalentPackingQty;
        var qty = ingr.qty;
        var qtyEq = Math.round(((nbLiters * qty) / weight) * 100) / 100;
        suffix = <>{"\n" + qtyEq + " LITRES"}</>;
      }
      return (
        <View
          key={"comp" + ingrKey}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <Text style={{ ...styles.td, width: "50%", height: "100%" }}>
            {ingredients.find((i) => i.id == ingr.ingredient)?.name}
          </Text>
          <Text style={{ ...styles.td, width: "50%", height: "100%" }}>
            {ingr.qty} {INGREDIENT_UNITS.find((i) => i.id == ingr.unit)?.name}
            {suffix}
          </Text>
        </View>
      );
    });
  };

  const stripHtml = (html) => {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };

  const renderSpecitifities = () => {
    var specs = [];
    specificities.forEach((s, sk) => {
      var sp = technicalSheet.specificities.includes(s.id);
      if (sp)
        specs.push(
          <Text
            style={{
              margin: "5 2px",
              backgroundColor: Colors.secondary,
              color: "white",
              padding: 5,
            }}
            key={`spec${sp.id}${Math.random()}`}
          >
            {s.name}
          </Text>
        );
    });
    return specs;
  };

  return (
    <Page wrap size="A4" orientation="portrait" style={{ padding: "20px" }}>
      <View
        style={{
          textAlign: "center",
          color: Colors.secondary,
        }}
      >
        <Text style={{ marginBottom: 5 }}>
          Fiche technique - {technicalSheet.name}
        </Text>
        <Text
          style={{
            fontSize: 9,
            marginBottom: 5,
          }}
        >
          pour 10 personnes
        </Text>
        <Text
          style={{
            fontSize: 12,
          }}
        >
          {consumerType.name} - Texture {datas.texture.name}
        </Text>
      </View>
      <View
        style={{
          fontSize: 7,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
          marginBottom: 20,
        }}
      >
        {renderSpecitifities()}
      </View>
      <View style={{ ...styles.row }}>
        <View style={{ ...styles.col6 }}>
          <View style={{ ...styles.info }}>
            <Text style={{ ...styles.label }}>% de perte brut->net</Text>
            <Text style={{ ...styles.value }}>
              {tools.round(recipeData.total1Dish.ratioBrutToCooked)}%
            </Text>
          </View>

          {isPrintClient && (
            <View style={{ ...styles.info }}>
              <Text style={{ ...styles.label }}>Coût total</Text>
              <Text style={{ ...styles.value }}>
                {tools.round(recipeData.total1Dish.totalCost * multiplicator)}€
              </Text>
            </View>
          )}

          <View style={{ ...styles.info }}>
            <Text style={{ ...styles.label }}>Poids total final cuit</Text>
            <Text style={{ ...styles.value }}>
              {tools.round(
                recipeData.total1Dish.totalWeightNet * multiplicator
              )}{" "}
              GRAMMES
            </Text>
          </View>

          <View style={{ ...styles.info }}>
            <Text style={{ ...styles.label }}>Total de pièces</Text>
            <Text style={{ ...styles.value }}>
              {tools.round(recipeData.total1Dish.totalPieces * multiplicator)}{" "}
            </Text>
          </View>

          <View style={{ ...styles.info }}>
            <Text style={{ ...styles.label }}>Valeur nutri. </Text>
            <Text style={{ fontSize: 8 }}>
              (1 portion de {tools.round(recipeData.total1Dish.totalWeightNet)}
              g)
            </Text>
            <Text style={{ ...styles.value }}>
              {tools.roundInt(recipeData.nutritionalValues?.perPortion)} kcal
            </Text>
          </View>
          <View style={{ ...styles.info }}>
            <Text style={{ ...styles.label }}>Valeur nutri.</Text>
            <Text style={{ fontSize: 8 }}>(100g)</Text>
            <Text style={{ ...styles.value }}>
              {tools.roundInt(recipeData.nutritionalValues?.per100g)} kcal
            </Text>
          </View>

          <View style={{ ...styles.info }}>
            <Text style={{ ...styles.label }}>
              % de produits origine BIO (Grammes)
            </Text>
            <Text style={{ ...styles.value }}>
              {tools.round(recipeData.totalPercentBio.weight)} %
            </Text>
          </View>
          <View style={{ ...styles.info }}>
            <Text style={{ ...styles.label }}>
              % de produits origine BIO (€)
            </Text>
            <Text style={{ ...styles.value }}>
              {tools.round(recipeData.totalPercentBio.cost)} %
            </Text>
          </View>
          <View style={{ ...styles.info }}>
            <Text style={{ ...styles.label }}>
              % de produits Locaux (Grammes)
            </Text>
            <Text style={{ ...styles.value }}>
              {tools.round(recipeData.totalPercentLocal.weight)} %
            </Text>
          </View>
          <View style={{ ...styles.info }}>
            <Text style={{ ...styles.label }}>% de produits Locaux (€)</Text>
            <Text style={{ ...styles.value }}>
              {tools.round(recipeData.totalPercentLocal.cost)} %
            </Text>
          </View>
        </View>
        <View style={{ ...styles.col6 }}>
          <Text style={{ ...styles.subTitle }}>Liste des allergènes</Text>
          {allergenList()}
        </View>
      </View>
      <View style={{ ...styles.row }}>
        <View style={{ ...styles.col6 }}>
          <Text style={{ ...styles.subTitle }}>
            Composants pour {multiplicator} portion(s)
          </Text>
          {componentsList()}
        </View>
        <View style={{ ...styles.col6 }}>
          <Text style={{ ...styles.subTitle }}>Méthodologie</Text>
          <Text style={{ ...styles.textSmall }}>
            {stripHtml(recipeData.methodology)}
          </Text>
        </View>
      </View>
    </Page>
  );
};

const RecipeTemplateAll = ({
  params,
  initCallback,
  ingredients,
  specificities,
  preparations,
}) => {
  const [state, dispatch] = useStore();
  const constants = state.constants.items;
  const [isInit, setIsInit] = useState(true);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isPrintClient, setIsPrintClient] = useState(false);

  const [canDownload, setCanDownload] = useState(false);

  // useEffect(() => {
  //   console.log("CLICK on", id);
  //   document.querySelector(`#btn-pdf-init-${id}`).click();
  // }, []);

  useEffect(() => {
    if (canDownload) {
      document.querySelector(`.btn-pdf-download-0`).click();

      setTimeout(() => {
        initCallback();
      }, 1000);
    }
  }, [canDownload]);

  return isLoading || !preparations ? (
    <Loader />
  ) : errors.other ? (
    <div className="alert alert-danger">{errors.other}</div>
  ) : (
    <div className="text-center ">
      {isInit && (
        <>
          <Control
            type="checkbox"
            label="Afficher les prix ?"
            checked={isPrintClient}
            change={(e) => setIsPrintClient((s) => !s)}
          />
          <PDFDownloadLink
            className={`btn btn-primary mx-auto my-3 d-center btn-pdf-download btn-pdf-download-0`}
            document={
              <Document>
                {params.recipes.map((p, pk) => (
                  <MyDoc
                    key={"tex" + pk}
                    datas={p}
                    consumerType={p.consumerType}
                    constants={constants}
                    technicalSheet={p.technicalSheet}
                    isPrintClient={isPrintClient}
                    specificities={specificities}
                    preparations={preparations}
                    ingredients={ingredients}
                  />
                ))}
              </Document>
            }
            fileName={`Fiches techniques.pdf`}
          >
            {({ blob, url, loading, error }) => {
              if (!loading) {
                setCanDownload(true);
              }
              return loading ? "Chargement..." : "Télécharger le document";
            }}
          </PDFDownloadLink>
        </>
      )}
      {/* <PDFViewer style={{ width: "100%", height: "100%", border: 0 }}>
        <MyDoc datas={datas}  />
      </PDFViewer> */}
    </div>
  );
};

export default RecipeTemplateAll;
