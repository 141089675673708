import React from "react";
import itemReducer from "./Context/reducers/itemsReducer";
import ClientPage from "./ClientPage";
import ItemStore from "./Context/ItemStore";
import ClientContainer from "./components/ClientContainer";
import ContractsContainer from "./components/ContractsContainer";
import SitesContainer from "./components/SitesContainer";
import EntityView from "./components/EntityView";
import UserContainer from "./components/UserContainer";

const ClientDetail = (props) => {
  const fetchId = props.match.params.id;

  const items = [
    {
      name: "client",
      fetchOnLoad: fetchId == "create" ? false : true,
      paths: {
        page: "clients",
        get: fetchId !== "create" ? `clients/${fetchId}` : null,
        post: "clients",
        patch: `clients/${fetchId}`,
        remove: `clients/${fetchId}`,
      },

      initialState: {
        name: "",
        code: "",
        fixFees: [],
        isArchived: false,
      },
    },
    {
      name: "sites",
      fetchOnLoad: fetchId !== "create" ? true : false,
      paths: {
        get: `clients/${fetchId}/sites`,
      },

      initialState: [],
    },
    {
      name: "productionSites",
      fetchOnLoad: true,
      paths: {
        get: `productionsites`,
      },

      initialState: [],
    },
    {
      name: "contracts",
      fetchOnLoad: fetchId !== "create" ? true : false,
      paths: {
        get: `clients/${fetchId}/clientcontracts`,
      },

      initialState: [],
    },
    {
      name: "users",
      fetchOnLoad: fetchId !== "create" ? true : false,
      paths: {
        get: `clients/${fetchId}/users`,
      },

      initialState: [],
    },
    {
      name: "slotCodes",
      fetchOnLoad: true,
      paths: {
        get: `slotcodes`,
      },

      initialState: [],
    },
    {
      name: "consumerTypes",
      fetchOnLoad: true,
      paths: {
        get: `consumertypes`,
      },

      initialState: [],
    },
    {
      name: "specificities",
      fetchOnLoad: true,
      paths: {
        get: `specificities`,
      },

      initialState: [],
    },
    {
      name: "textures",
      fetchOnLoad: true,
      paths: {
        get: `textures`,
      },

      initialState: [],
    },
    {
      name: "utils",
      fetchOnLoad: false,

      initialState: {
        clientId: fetchId,
        savingSite: false,
        savingContract: false,
        savingUser: false,
        entitySelected: {
          type: "",
          id: "",
        },
      },
    },
    { name: "errors", initialState: {} },
  ];

  return (
    <ItemStore rootReducer={itemReducer}>
      <ClientPage
        pageTitle={`${
          fetchId == "create" ? "Création" : "Modification"
        } du client`}
        back="/clients"
        items={items}
      >
        <ClientContainer />
        <UserContainer />

        {fetchId !== "create" && <SitesContainer />}
        <EntityView />
      </ClientPage>
    </ItemStore>
  );
};

export default ClientDetail;
