import React from "react";
import Colors from "../../../theme/variables.scss";

const ProgressBar = ({
  progress,
  text,
  height = 3,
  color = Colors.danger,
  bgColor = "white",
}) => {
  return (
    <div className="d-center flex-column w-100">
      <div
        className="progress_container border w-100"
        style={{
          background: bgColor,
          display: "flex",
          position: "relative",
          borderRadius: 0,
        }}
      >
        <div
          style={{
            background: color,
            width: progress + "%",
            height: height,
            borderRadius: 0,
            transition: "1s",
            opacity: "1",
          }}
          className="progress_bar"
        ></div>
      </div>
      <div className="progress_text text-center mt-2">{text}</div>
    </div>
  );
};

export default ProgressBar;
