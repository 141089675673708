import React, { useEffect, useState } from "react";
import { sidePanelActions } from "../../../../../../context/actions/sidePanelActions";
import useStoreState from "../../Context/Store/useStoreState";

const PanelDay = ({ day, moment, ...props }) => {
  const { items, items_actions, gState, gDispatch } = useStoreState();
  const spActions = sidePanelActions(gState, gDispatch);
  const [mouseOver, setMouseOver] = useState(false);
  const { editSlotId } = items.utils;
  const menuRef = { ...items.menuRef };
  const dayComments = menuRef.daysComments.find((d) => d.day == day.id);

  useEffect(() => {
    const panels = document.querySelectorAll(".client-col-panel");
    function toggleOpen(e) {
      if (!e.target.classList.contains("forward-click")) return;
      var pans = document.querySelectorAll(
        `.client-col-panel${this.dataset.panel}`
      );
      pans.forEach((pan) => pan.classList.toggle("open"));
    }
    function toggleActive(e) {
      if (e.propertyName.includes("flex")) {
        this.classList.toggle("open-active");
      }
    }
    panels.forEach((panel) => panel.addEventListener("click", toggleOpen));

    panels.forEach((panel) =>
      panel.addEventListener("transitionend", toggleActive)
    );
  }, []);

  return (
    <div
      className={`client-col-panel forward-click client-col-panel${day.id}`}
      data-panel={`${day.id}`}
      onMouseEnter={(e) => setMouseOver(true)}
      onMouseLeave={(e) => setMouseOver(false)}
    >
      <div className="d-center flex-column" style={{ maxHeight: 34 }}>
        <div className="panel-toggle-btn position-relative font-weight-bold d-center py-2 w-100 forward-click">
          {day.name}
        </div>
      </div>
      {props.children}
    </div>
  );
};

export default PanelDay;
