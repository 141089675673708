import { DateTime } from "luxon";
import React from "react";
import useStore from "../../../../context/useStore";
import tools from "../../../../helpers/tools";

const SlotQuantities = ({ quantities, unit }) => {
  const [state, dispa] = useStore();
  const constants = state.constants.items;
  const { UNITS, CONSUME_TIMES } = constants;

  return (
    <>
      <div>{tools.transformQtyKg(quantities.total, unit)}</div>
      {quantities.detail.map((det) => {
        return (
          <div style={{ fontSize: 10 }} className="text-right">
            {DateTime.fromISO(det.date).setLocale("FR").toFormat("EEE")}{" "}
            <span style={{ textTransform: "lowercase" }}>
              {CONSUME_TIMES.find((ct) => ct.id == det.moment).name}
            </span>{" "}
            {tools.transformQtyKg(det.qty, unit)}
          </div>
        );
      })}
    </>
  );
};

export default SlotQuantities;
