import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import useStore from "../../../../context/useStore";
import dataService from "../../../../helpers/dataService";
import tools from "../../../../helpers/tools";

const usePurchaseSummary = () => {
  const [state] = useStore();
  const { PREPARATION_CATEGORIES } = state.constants.items;
  const [errors, setErrors] = useState({});
  const [searchChanged, setSearchChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [purchases, setPurchases] = useState([]);
  var searchState = tools.getState("PurchaseSummary", null);
  const [search, setSearch] = useState(
    searchState
      ? JSON.parse(searchState.search)
      : {
          productionSite: "",
          provider: "",
          beginAt: "",
          endAt: "",
        }
  );
  const [searchDone, setSearchDone] = useState(false);
  const [providers, setProviders] = useState([]);
  const [productionSites, setProductionSites] = useState([]);
  const [daysArray, setDaysArray] = useState([]);

  const [orderReceiptFormatted, setOrderReceiptFormatted] = useState(null);

  useEffect(() => {
    getProductionSites();
    getProviders();
  }, []);
  const getProductionSites = () => {
    dataService.get("productionsites", setProductionSites);
  };
  const getProviders = () => {
    dataService.get("providers", (datas) =>
      setProviders(datas.map((d) => ({ ...d, comment: "" })))
    );
  };
  const getPurchases = () => {
    setErrors({});
    tools.saveState("PurchaseSummary", "search", JSON.stringify(search));
    setSearchDone(true);
    setIsLoading(true);
    setSearchChanged(false);
    dataService.post(
      `purchases/search`,
      {
        ...search,
        providers: !search.provider
          ? providers.map((p) => p.id)
          : [search.provider],
      },
      (datas) => {
        setPurchases(datas);
      },
      setErrors,
      () => setIsLoading(false)
    );
  };

  useEffect(() => {
    if (search.beginAt && search.endAt) {
      var days = [];
      var diff = DateTime.fromISO(search.endAt)
        .diff(DateTime.fromISO(search.beginAt), "days")
        .toObject();

      for (var i = 0; i <= diff.days; i++) {
        var date = DateTime.fromISO(search.beginAt).plus({ days: i }).toISO();
        days.push(date);
      }
      setDaysArray(days);
      console.log("diff", diff);
    }
  }, [search.beginAt, search.endAt]);

  useEffect(() => {
    setSearchChanged(true);
  }, [search]);

  const changeFinalQty = (newQty, day, preparationId, providerId) => {
    setPurchases([
      ...purchases.map((p) => {
        if (
          areDatesEqual(p.day, day) &&
          p.preparation.id == preparationId &&
          p.provider == providerId
        ) {
          return { ...p, finalQty: newQty };
        }
        return p;
      }),
    ]);
  };

  const savePurchase = (purchase) => {
    console.log("saving purchase", purchase);
    setIsSaving(true);
    if (!purchase.id) {
      dataService.post(
        `purchases`,
        {
          ...purchase,
          preparation: purchase.preparation.id,
          productionSite: search.productionSite,
        },
        (datas) => {
          setPurchases([
            ...purchases.map((p) => {
              if (
                areDatesEqual(p.day, purchase.day) &&
                p.preparation.id == purchase.preparation.id &&
                p.provider == purchase.provider
              ) {
                return {
                  ...datas,
                  preparation: purchase.preparation,
                  day: purchase.day,
                  theoreticalQty: purchase.theoreticalQty,
                };
              }
              return p;
            }),
          ]);
        },
        setErrors,
        () => setIsSaving(false)
      );
    } else {
      dataService.patch(
        `purchases/${purchase.id}`,
        purchase,
        (datas) => {
          setPurchases([
            ...purchases.map((p) => {
              if (p.id == purchase.id) {
                return {
                  ...datas,
                  preparation: purchase.preparation,
                  day: purchase.day,
                  theoreticalQty: purchase.theoreticalQty,
                };
              }
              return p;
            }),
          ]);
        },
        setErrors,
        () => setIsSaving(false)
      );
    }
  };

  const saveOrderReceipt = (orderReceipt) => {
    console.log("orderReceipt", orderReceipt);

    setIsGenerating(true);
    dataService.post(
      `purchasehistorics`,
      {
        ...orderReceipt,
        productionSite: search.productionSite,
      },
      (datas) =>
        setOrderReceiptFormatted({
          ...datas,
          preparations: [
            ...datas.preparations.map((prep) => {
              var catName = PREPARATION_CATEGORIES.find(
                (p) => p.id == prep.preparation.category
              ).name;
              return {
                ...prep,
                preparation: {
                  ...prep.preparation,
                  name: prep.preparation.ingredient.name + " " + catName,
                },
              };
            }),
          ],
        }),
      setErrors,
      () => setIsGenerating(false)
    );
  };

  var productsByProvider = []; //construction d'un array de {provider:<id>, products:[{id:<preparationID>, name:<preparationName>}]}
  purchases.forEach((purchase) => {
    if (!productsByProvider.find((p) => p.provider == purchase.provider)) {
      productsByProvider.push({
        provider: purchase.provider,
        products: [],
      });
    }
    var providerProducts = productsByProvider.find(
      (p) => p.provider == purchase.provider
    ).products;

    if (!providerProducts.find((p) => p.id == purchase.preparation.id)) {
      productsByProvider
        .find((p) => p.provider == purchase.provider)
        .products.push({ ...purchase.preparation });
    }
  });

  const areDatesEqual = (d1, d2) => {
    var day1 = DateTime.fromISO(d1).day;
    var month1 = DateTime.fromISO(d1).month;
    var year1 = DateTime.fromISO(d1).year;
    var day2 = DateTime.fromISO(d2).day;
    var month2 = DateTime.fromISO(d2).month;
    var year2 = DateTime.fromISO(d2).year;
    console.log(`${day1}/${month1}/${year1} == ${day2}/${month2}/${year2}`);
    return day1 == day2 && month1 == month2 && year1 == year2;
  };

  return {
    errors,
    isLoading,
    isSaving,
    isGenerating,
    purchases,
    search,
    setSearch,
    productionSites,
    providers,
    setProviders,
    getPurchases,
    searchDone,
    daysArray,
    productsByProvider,
    changeFinalQty,
    savePurchase,
    saveOrderReceipt,
    orderReceiptFormatted,
    constants: state.constants.items,
    searchChanged,
    areDatesEqual,
  };
};

export default usePurchaseSummary;
