import React, { useState, useEffect } from "react";
import Control from "../../../common/Controls/Control";
import useApiDatas from "../../../common/Hooks/useApiDatas";
import Loader from "../../../common/Loader";
import Progress from "../../../common/Progress";
import RecipePrintAll from "../../../print/RecipeTemplate/RecipePrintAll";
import datas from "./datas.json";

const PrintTechnicalSheets = () => {
  const { otherDatas } = useApiDatas({
    dependenciesPath: [
      "textures",
      "consumertypes",
      "technicalsheets",
      "ingredients",
      "dishTypes",
      "specificities",
      "preparations",
      "recipes",
    ],
    searchParams: {
      technicalSheet: { searchString: "" },
      page: 1,
    },
  });

  const [downloadDatas, setDownloadDatas] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const {
    recipes,
    textures,
    consumertypes,
    technicalsheets,
    specificities,
    ingredients,
    preparations,
    dishTypes,
  } = otherDatas;

  const [isPrintClient, setIsPrintClient] = useState(false);
  const [filterDishTypes, setFilterDishTypes] = useState([]);

  useEffect(() => {
    if (Object.values(otherDatas).length) {
      printRecipes();
    }
  }, [otherDatas]);

  useEffect(() => {
    if (dishTypes) {
      setFilterDishTypes(dishTypes.map((d) => d.id));
    }
  }, [dishTypes]);

  const printRecipes = () => {
    // setIsLoading(true);
    // dataService.get(
    //   `recipes`,
    //   (recipesDatas) => {

    var recipesDatas = recipes;

    //const recipeDatas = <Array de consumerTypes>
    var exaustRecipeData = [];
    technicalsheets.forEach((technicalSheet) => {
      consumertypes.forEach((consumerType) => {
        textures.forEach((t) => {
          if (
            recipesDatas[technicalSheet.id] &&
            recipesDatas[technicalSheet.id][consumerType.id] &&
            recipesDatas[technicalSheet.id][consumerType.id][t.id]
          ) {
            exaustRecipeData.push({
              texture: t,
              recipeData:
                recipesDatas[technicalSheet.id][consumerType.id][t.id],

              multiplicator: 10,
              consumerType,
              technicalSheet: technicalSheet,
            });
          }
        });
      });
    });
    const groupeRecipesByFT = [];
    technicalsheets.forEach((technicalSheet) => {
      groupeRecipesByFT.push(
        exaustRecipeData.filter(
          (ex) => ex.technicalSheet.id == technicalSheet.id
        )
      );
    });

    var tempDownloadDatas = [];
    groupeRecipesByFT.forEach((data) => {
      tempDownloadDatas.push({
        recipes: data,
      });
    });
    // tempDownloadDatas.forEach((td, tdk) => {
    //   localStorage.setItem(
    //     `doc-to-download-${tdk}`,
    //     JSON.stringify({
    //       isDone: false,
    //       datas: td,
    //     })
    //   );
    // });
    setIsLoading(false);
    setDownloadDatas(tempDownloadDatas);

    //   },
    //   setErrors,
    //   () => setIsLoading(false)
    // );
  };

  const filterDownloadDatas = (datas) => {
    console.log("before", datas, filterDishTypes);
    var newDatas = datas.map((d) => {
      var filteredRecipes = d.recipes
        .filter((r) => filterDishTypes.includes(r.technicalSheet.dishType))
        .sort((a, b) => {
          var dtOrderA = dishTypes.find(
            (dt) => dt.id == a.technicalSheet.dishType
          ).order;
          var dtOrderB = dishTypes.find(
            (dt) => dt.id == b.technicalSheet.dishType
          ).order;
          return dtOrderA > dtOrderB ? 1 : -1;
        });
      return { recipes: filteredRecipes };
    });
    console.log("after", newDatas);
    return newDatas;
  };

  // const initCallback = () => {
  //   setDocToDownload([]);
  //   setDocsDownloaded(docsDownloaded + 1);
  // };

  // useEffect(() => {
  //   if (!docToDownload.length && downloadDatas.length) {
  //     var dldd = JSON.parse(JSON.stringify(downloadDatas)).slice(1);
  //     setTimeout(() => {
  //       setDocToDownload([dldd[0]]);
  //     }, 1000);
  //   }
  // }, [docToDownload]);

  // useEffect(() => {
  //   if (downloadDatas.length) {
  //     setDocToDownload([downloadDatas[0]]);
  //   }
  // }, [downloadDatas]);
  console.log("downloadDatas", downloadDatas);

  return isLoading ? (
    <div className="w-100 h-100 d-center">
      <div className="custom-card d-center flex-column">
        <Loader />
        <div className="mt-2">
          Récupération de toutes les fiches techniques en cours...
        </div>
        <div className="animated pulse infinite">
          cela peut prendre plusieurs minutes ☕
        </div>
        <div>Evitez d'utiliser la machine si possible</div>
      </div>
    </div>
  ) : (
    <div className="row mx-auto" style={{ maxWidth: "80%" }}>
      <div className="col-12 d-center flex-column noprint">
        <button className="btn btn-primary" onClick={() => window.print()}>
          <i className="fa fa-print mr-2" />
          Tout imprimer (l'aperçu mettra plusieurs minutes à charger)
        </button>
        <Control
          className="noprint"
          type="checkbox"
          label="Afficher les prix ?"
          checked={isPrintClient}
          change={(e) => setIsPrintClient((s) => !s)}
        />
        <Control
          label="Filtrer par type de plat"
          type="checkBtnList"
          value={filterDishTypes}
          datas={dishTypes}
          dataIndex="id"
          dataLabel="name"
          dataLabel2="order"
          change={(e) => setFilterDishTypes(e.target.value)}
          btnInline
        />
      </div>
      <div className="col-12">
        <div>
          {filterDownloadDatas(downloadDatas).map((data, dk) => (
            <RecipePrintAll
              key={`dlrecipe${Math.random()}`}
              id={dk}
              params={{
                recipes: data.recipes,
                preparations: data.preparations,
              }}
              ingredients={ingredients}
              specificities={specificities}
              preparations={preparations}
              isPrintClient={isPrintClient}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PrintTechnicalSheets;
