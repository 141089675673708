import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import BarChart from "../CommonCharts/BarChart";
import { filterSameValues } from "../statTools";
import useStats from "../useStats/useStats";

const NbClientsChart = ({ search }) => {
  const { getStat } = useStats();
  const [isLoading, setIsLoading] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);

  const [chartData, setChartData] = useState({
    categories: [],
    series: [],
  });

  const fetchData = async () => {
    setIsLoading(true);
    var results = await getStat({ statName: "nbclients", search }).catch(
      (err) => ({ errors: err })
    );
    setIsLoading(false);
    if (!results.errors) {
      let filteredSeries = filterSameValues(results);
      setChartData({
        categories: filteredSeries.map((r) =>
          DateTime.fromISO(r.date).toFormat("dd/MM")
        ),
        series: filteredSeries.map((r) => r.value),
      });
      setHasChanged(false);
    } else {
      toast.error(
        "une erreur est survenue à la récupération des données sur statistics/nbclients"
      );
    }
  };

  useEffect(() => {
    if (search) {
      setHasChanged(true);
    }
  }, [search]);

  return (
    <div className="custom-card">
      <div className="d-flex justify-content-between align-items-center">
        <strong>Nombre de clients</strong>
        {hasChanged && (
          <button onClick={fetchData} className="btn btn-default load-stat">
            <i
              className={`fa fa-refresh ${
                isLoading ? "animated rotate infinite" : ""
              }`}
            />
          </button>
        )}
      </div>

      {!hasChanged && (
        <BarChart
          categories={chartData.categories}
          series={chartData.series}
          seriesName="Nombre de clients"
        />
      )}
    </div>
  );
};

export default NbClientsChart;
