import { DateTime } from "luxon";
import React from "react";
import tools from "../../../../../helpers/tools";
import StipLoader from "../../../Menus/GlobalMenu/GlobalMenuPage/MenuRef/StipLoader";
import useStoreState from "../../Context/Store/useStoreState";

const DayBlock = ({ day, moment, nbDays, lockedDays, beginAt, ...props }) => {
  const { gState } = useStoreState();

  var isLocked = lockedDays[day.id] < 3;
  var isBO = gState.auth.user.platform == "BO";

  const dayDate = DateTime.fromISO(beginAt)
    .minus({ days: 1 })
    .plus({ days: parseInt(day.id) });

  let isHoliday = tools.isHolyday(dayDate);

  return (
    <div
      className={`custom-card-order flex-fill d-center flex-column  day-${day.id} moment-${moment.id} position-relative`}
      style={{
        maxWidth: 100 / nbDays + "%",
      }}
    >
      {isLocked && !isBO ? (
        <StipLoader
          onClick={() => alert("Vous ne pouvez pas modifier ce jour car il est dans moins de 72h")}
        />
      ) : null}
      <strong className="d-center w-100 py-2">
        {isLocked && isBO ? (
          <i
            className="fa fa-exclamation-triangle mr-2 cursor-pointer text-warning"
            onClick={() => alert("Ce jour est à moins de 72h par rapport à aujourd'hui.")}
          />
        ) : null}
        {day.name} {dayDate.toFormat("dd/MM")}{" "}
        {isHoliday && (
          <span className="text-danger pl-2">
            <i className="fa fa-warning mr-1" />
            Férié
          </span>
        )}
      </strong>
      {props.children}
    </div>
  );
};

export default DayBlock;
