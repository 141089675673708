import React, { Component } from "react";
import useStore from "../../../../context/useStore";
import tools from "../../../../helpers/tools";

const PackingCsv = ({ datas, date }) => {
  const [state, dispatch] = useStore();
  const { UNITS } = state.constants.items;

  var csvStr = "";

  var i = 0;

  datas.forEach((dt) => {
    dt.technicalSheets.forEach((dish) => {
      console.log("dish", dish);
      dish.sites.forEach((site) => {
        i++;

        for (var key in site.quantities) {
          var weight = site.quantities[key].net;
          var unit = dish.textures.find((t) => t.id == key).unit;
          var texture = dish.textures.find((t) => t.id == key).name;
          var ingredients = dish.textures.find((t) => t.id == key).ingredients;
          console.log("ingredients", ingredients);
          var allergens = [];
          dish.textures
            .find((t) => t.id == key)
            .ingredients.forEach((ing) => ing.allergens.forEach((al) => allergens.push(al)));
          var unit = UNITS.find((u) => u.id == unit);
          // if (unit == 1) {
          //   unit = "KG";
          //   weight = weight > 0 ? (weight / 1000).toFixed(2) : weight;
          // }
          var qty = tools.transformQtyKg(weight, unit.id);

          var qtyStr = qty.toString().replace(".", ",").split(" ");
          qtyStr = qtyStr[0] + "\t" + qtyStr[1];

          csvStr += i;
          csvStr += "\t";
          csvStr += site.name;
          csvStr += "\t";
          csvStr += site.day;
          csvStr += "\t";
          csvStr += site.moment;
          csvStr += "\t";
          csvStr += dish.technicalSheet.name;
          csvStr += "\t";
          csvStr += texture;
          csvStr += "\t";
          csvStr += qtyStr;
          csvStr += "\t";
          csvStr += ingredients.map((ing) => ing.name).join(", ");
          csvStr += "\t";
          csvStr += allergens.join(", ");
          csvStr += "\t";
          csvStr += dish.technicalSheet?.dishType;
          csvStr += "\n";
        }
      });
    });
  });

  function copy() {
    var el = document.getElementById("etiq");
    el.select();
    document.execCommand("copy");
    alert("Copié dans le presse-papier !");
  }

  return (
    <>
      <h5>Cliquez sur le bouton ci-dessous puis collez (ctrl+v) sur une feuille Excel</h5>
      <strong>{date}</strong>
      <div className="text-center">
        <button className="btn btn-primary mb-2" onClick={copy}>
          <i className="fa fa-copy mr-2"></i>Copier dans le presse-papier
        </button>
        <textarea id="etiq" style={{ width: "100%", height: 300 }}>
          {csvStr}
        </textarea>
      </div>
    </>
  );
};
export default PackingCsv;
