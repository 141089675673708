import React, { useState } from "react";
import useStoreState from "../../../Context/Store/useStoreState";
import ConsumerProfileSlot from "./ConsumerProfileSlot/ConsumerProfileSlot";

const Slot = ({ day, moment, slots }) => {
  const { items } = useStoreState();
  const consumptionProfiles = [...items.consumptionProfiles];

  var consumerProfiles = Object.keys(slots);

  return (
    <div className="d-flex flex-column h-100 w-100 pb-5 forward-click slot-container">
      {consumerProfiles.map((c, ck) => {
        let cp = consumptionProfiles.find((cp) => cp.id == c);
        let dishSlots = slots[c];
        return (
          <ConsumerProfileSlot
            key={`conspro${ck}`}
            consumerProfile={cp}
            dishSlots={dishSlots}
          />
        );
      })}
    </div>
  );
};

export default Slot;
