import { useEffect, useState } from "react";
import dataService from "../helpers/dataService";

const useClients = (fetchOnLoad = true) => {
  const [clients, setClients] = useState([]);

  useEffect(() => {
    if (fetchOnLoad) {
      getClients();
    }
  }, []);

  const getClients = () => {
    dataService.get(`clients`, setClients);
  };

  return {
    clients,
    setClients,
    getClients,
  };
};

export default useClients;
