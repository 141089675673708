import React, { useState } from "react";
import ItemStore from "./Context/Store/ItemStore";
import itemReducer from "./Context/reducers/itemsReducer";
import GlobalMenuPage from "./GlobalMenuPage/GlobalMenuPage";
import { DateTime } from "luxon";
import "./globalmenu.scss";
import ClientMenuPage from "./ClientMenuPage/ClientMenuPage";
import tools from "../../../../helpers/tools";
import MenuSwitcher from "./MenuSwitcher";

const GlobalMenu = (props) => {
  const fetchId = props.match.params.id;
  var slots = [];
  const [showClientMenu, setShowClientMenu] = useState(false);
  var searchState = tools.getState("menuref", null);
  searchState = searchState
    ? { search: JSON.parse(searchState.search) }
    : {
        search: {
          productionSite: "",
          beginAt: DateTime.local().startOf("week").toISO(),
        },
      };

  var clientMenuState = tools.getState("clientMenu", null);
  clientMenuState = clientMenuState
    ? { selectedSite: clientMenuState.selectedSite }
    : { selectedSite: "" };

  const items = [
    {
      name: "menu",
      paths: {
        page: "menu-reference",
        get: `menus/${props.match.params.id}`,
        post: "menus",
        patch: `menus/${fetchId}`,
        remove: `menus/${fetchId}`,
      },
      initialState: {
        name: "",
        code: "",
        slots: slots,
        isArchived: false,
      },
    },
    {
      name: "clientMenu",
      paths: {
        get: `menus/${props.match.params.id}`,
      },
      initialState: {
        slots: slots,
      },
    },
    {
      name: "technicalSheets",
      fetchOnLoad: true,
      paths: {
        get: `technicalsheets/`,
      },
      initialState: [],
    },
    {
      name: "specificities",
      fetchOnLoad: true,
      paths: {
        get: `specificities/`,
      },
      initialState: [],
    },
    {
      name: "productionSites",
      fetchOnLoad: true,
      paths: {
        get: `productionsites/`,
      },
      initialState: [],
    },
    {
      name: "contracts",
      fetchOnLoad: true,
      paths: {
        get: `clientcontracts/`,
      },
      initialState: [],
    },
    {
      name: "sites",
      fetchOnLoad: true,
      paths: {
        get: `sites/`,
      },
      initialState: [],
    },
    {
      name: "clients",
      fetchOnLoad: true,
      paths: {
        get: `clients/`,
      },
      initialState: [],
    },
    {
      name: "consumerTypes",
      fetchOnLoad: true,
      paths: {
        get: `consumertypes/`,
      },
      initialState: [],
    },
    {
      name: "textures",
      fetchOnLoad: true,
      paths: {
        get: `textures/`,
      },
      initialState: [],
    },
    {
      name: "consumptionProfiles",
      //fetchOnLoad: true,
      paths: {
        get: `consumptionprofiles/`,
      },
      initialState: [],
    },
    {
      name: "slotCodes",
      initialState: [],
    },
    {
      name: "menuRef",
      initialState: {
        id: "",
        beginAt: searchState.search.beginAt,
        productionSite: searchState.search.productionSite,
        daysComments: [],
      },
    },
    {
      name: "utils",
      initialState: {
        editSlotId: "",
        duplicateDishSlots: [],
        loadingDishSlots: [],
        specificitiesFilter: [],
        loadingMenuRef: false,
        loadingClientMenu: false,
        selectedSite: clientMenuState.selectedSite,
      },
    },
  ];

  return (
    <ItemStore rootReducer={itemReducer}>
      <div
        className="d-flex justify-content-end container"
        style={{
          maxWidth: "95%",
        }}
      >
        <MenuSwitcher showClientMenu={showClientMenu} setShowClientMenu={setShowClientMenu} />
      </div>
      <div className="position-relative">
        <div className={showClientMenu ? "hidden" : ""}>
          <GlobalMenuPage pageTitle={`Menu de référence`} items={items} />
        </div>
        <div
          className={showClientMenu ? "" : "hidden"}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
          }}
        >
          <ClientMenuPage showClientMenu={showClientMenu} />
        </div>
      </div>
    </ItemStore>
  );
};

export default GlobalMenu;
