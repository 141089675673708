import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useStore from "../../../../context/useStore";
import dataService from "../../../../helpers/dataService";
import Control from "../../../common/Controls/Control";
import DynamicList from "../../../common/DynamicList";
import Page from "../../../common/layout/Page";
import SaveBtn from "../../../common/SaveBtn";

const SpecificitiesDetail = (props) => {
  const [state, dispatch] = useStore();
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [dishType, setDishType] = useState({
    name: "",
    description: "",
    allergen: "",
  });
  const itemId = props.match.params.id;
  const isEdit = itemId == "create" ? false : true;
  const constants = state.constants.items;

  useEffect(() => {
    if (isEdit) {
      getDatas();
    }
  }, []);

  const getDatas = () => {
    if (isEdit) {
      dataService.get(
        "specificities/" + itemId,
        (datas) => setDishType(datas),
        (error) => setErrors(error),
        () => setIsLoading(false)
      );
    }
  };

  const save = () => {
    setIsLoading(true);
    if (isEdit) {
      dataService.patch(
        "specificities/" + itemId,
        dishType,
        (datas) => toast.success("Type de plat enregistré avec succès"),
        setErrors,
        () => setIsLoading(false)
      );
    } else {
      dataService.post(
        "specificities",
        dishType,
        (datas) => {
          toast.success("Spécificité créée avec succès");
          window.location.href = "/specificities";
        },
        setErrors,
        () => setIsLoading(false)
      );
    }
  };

  return (
    <Page
      container="container"
      back="/specificities"
      title={(isEdit ? "Modification" : "Détail") + " de la spécificité"}
      errors={errors}
    >
      <div className="row custom-card">
        <div className="col-12 p-0">
          <Control
            label="Nom"
            name="name"
            type="text"
            value={dishType.name}
            change={(e) => setDishType({ ...dishType, name: e.target.value })}
            error={errors}
            setErrors={setErrors}
            required={true}
          />
        </div>
        <div className="col-12 p-0">
          <Control
            label="Description"
            name="description"
            type="text"
            value={dishType.description}
            change={(e) =>
              setDishType({ ...dishType, description: e.target.value })
            }
            error={errors}
            setErrors={setErrors}
            required={true}
          />
        </div>
        <div className="col-12 p-0">
          <Control
            label="Allergène"
            name="allergen"
            type="select"
            value={dishType.allergen}
            datas={constants.ALLERGENS}
            dataIndex="id"
            dataLabel="name"
            change={(e) =>
              setDishType({ ...dishType, allergen: e.target.value })
            }
            error={errors}
            setErrors={setErrors}
            required={true}
          />
        </div>
      </div>
      <div className="row" data-priv="create_modify_specificities">
        <div className="d-flex justify-content-end w-100">
          <SaveBtn
            save={save}
            isSaving={isLoading}
            text="Enregistrer"
            type="primary"
          />
        </div>
      </div>
    </Page>
  );
};

export default SpecificitiesDetail;
