import { atom, useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import dataService from "../helpers/dataService";

const ingredientsAtom = atom([]);

const useIngredients = (fetchOnLoad = true) => {
  const [ingredients, setIngredients] = useAtom(ingredientsAtom);

  useEffect(() => {
    if (fetchOnLoad) {
      getIngredients();
    }
  }, []);

  const getIngredients = () => {
    dataService.get(`ingredients`, (data) =>
      setIngredients(data.sort((a, b) => (a.name > b.name ? 1 : -1)))
    );
  };

  return {
    ingredients,
    setIngredients,
    getIngredients,
  };
};

export default useIngredients;
