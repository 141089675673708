export const sidenavItems = [
  {
    name: "Accueil",
    path: "/dashboard",
    icon: "home",
  },
  {
    name: "Client",
    path: "#",
    icon: "caret-down",
    items: [
      {
        name: "Liste des clients",
        path: "/clients",
        icon: "user",
      },
      {
        name: "Périodes de fermeture",
        path: "/closing-periods",
        icon: "calendar",
      },
      {
        name: "Liste des sites",
        path: "/sites",
        icon: "sitemap",
      },
      {
        name: "Commandes",
        path: "/orders",
        icon: "truck",
      },
    ],
  },
  {
    name: "Production",
    path: "#",
    icon: "caret-down",
    items: [
      {
        name: "Menu de référence",
        path: "/menu-reference",
        icon: "calendar-alt",
      },
      {
        name: "Duplication de cycle",
        path: "/duplicate-cycle",
        icon: "calendar-alt",
        dataPriv: "duplicate_cycle",
      },
      {
        name: "Fiches techniques",
        path: "/technicalsheets",
        icon: "book",
      },
      {
        name: "Ingrédients",
        path: "/ingredients",
        icon: "pepper-hot",
      },
      {
        name: "Fournisseurs",
        path: "/providers",
        icon: "truck",
      },
    ],
  },
  {
    name: "Paramétrage",
    path: "#",
    icon: "caret-down",
    items: [
      {
        name: "Types de plat",
        path: "/dishtypes",
        icon: "fish",
      },
      {
        name: "Textures",
        path: "/textures",
        icon: "cookie",
      },
      {
        name: "Spécificités",
        path: "/specificities",
        icon: "cookie",
      },
      {
        name: "Référence plan alimentaire",
        path: "/slotcodes",
        icon: "cookie",
      },
      {
        name: "Types de consommateur",
        path: "/consumertypes",
        icon: "street-view",
      },
      {
        name: "Cuisines",
        path: "/productionsites",
        icon: "globe",
      },
    ],
  },

  {
    name: "Récapitulatifs",
    path: "#",
    icon: "caret-down",
    items: [
      {
        name: "Production",
        path: "/sum-prod",
        icon: "file-signature",
      },
      {
        name: "Conditionnement",
        path: "/sum-pack",
        icon: "file-signature",
      },
      {
        name: "Allotissement",
        path: "/sum-allot",
        icon: "file-signature",
      },
      {
        name: "Livraison",
        path: "/sum-deliv",
        icon: "file-signature",
      },
      {
        name: "Activité",
        path: "/sum-act",
        icon: "file-signature",
      },
      {
        name: "CA",
        path: "/sum-ca",
        icon: "file-signature",
        dataPriv: "view_billing_global_summary",
      },
      {
        name: "Achats",
        path: "/sum-achat",
        icon: "file-signature",
        dataPriv: "view_purchase_summary",
      },
      {
        name: "Achats/fourn.",
        path: "/sum-achat-provider",
        icon: "file-signature",
        dataPriv: "view_purchase_provider_summary",
      },
      {
        name: "Achats/ingr.",
        path: "/sum-achat-ingredient",
        icon: "file-signature",
        dataPriv: "view_purchase_ingredient_summary",
      },
      {
        name: "Sorties",
        path: "/sum-sortie",
        icon: "file-signature",
      },
      {
        name: "Dépenses",
        path: "/sum-depenses",
        icon: "file-signature",
      },
      {
        name: "Régimes",
        path: "/sum-spec",
        icon: "cookie",
      },
    ],
  },
  {
    name: "Utilisateurs",
    path: "/managers",
    icon: "user-tie",
  },
];
