import React from "react";

const RecipesList = ({ recipes }) => {
  return (
    <>
      <h4>Recettes liées au produit</h4>
      <ul className="list-group">
        {recipes.map((rec, reck) => (
          <li className="list-group-item" key={"recspit" + reck}>
            <strong className="text-secondary d-flex align-items-center">
              <span className="badge badge-secondary mr-2">FT</span>
              {rec.name}
            </strong>
            <ul className="list-group">
              {rec.recipes.map((cip, cipk) => (
                <li className="list-group-item p-0" key={`recspit${reck}${cipk}`}>
                  <a
                    target="_BLANK"
                    className="btn btn-link text-left d-center p-2"
                    href={`/technicalsheets/${rec.id}`}
                  >
                    {cip.consumerType} / Texture {cip.texture}
                  </a>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </>
  );
};

export default RecipesList;
