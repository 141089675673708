import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import useStore from "../../../../context/useStore";
import dataService from "../../../../helpers/dataService";
import Control from "../../../common/Controls/Control";
import Loader from "../../../common/Loader";
import SaveBtn from "../../../common/SaveBtn";

const Filters = ({ search, setSearch, getDatas, isLoading }) => {
  const [state, dispatch] = useStore();
  const { TIME_SCALES } = state.constants.items;

  const [productionSites, setProductionSites] = useState([]);

  useEffect(() => {
    getProductionSites();
  }, []);
  const getProductionSites = () => {
    dataService.get("productionsites", setProductionSites);
  };

  return (
    <div className="custom-card mb-3">
      <div className="row mb-3">
        <div className="col-4">
          <Control
            label="Cuisine"
            name="productionSites"
            type="checkBtnList"
            dataIndex="id"
            dataLabel="name"
            btnInline
            datas={productionSites}
            value={search.productionSites}
            dataIndex="id"
            dataLabel="name"
            change={(e) =>
              setSearch({ ...search, productionSites: e.target.value })
            }
          />
        </div>
        <div className="col-8">
          <Control
            label="Échelon"
            name="timeScale"
            type="btnList"
            dataIndex="id"
            dataLabel="name"
            btnInline
            datas={TIME_SCALES}
            value={search.timeScale}
            dataIndex="id"
            dataLabel="name"
            change={(e) => setSearch({ ...search, timeScale: e.target.value })}
          />
          <div className="row">
            <div className="col-6">
              <Control
                withNav
                label="Début"
                type="date"
                value={search.beginAt}
                change={(e) =>
                  setSearch({
                    ...search,
                    beginAt: DateTime.fromISO(e.target.value)
                      .startOf("day")
                      .toISO(),
                  })
                }
              />
            </div>
            <div className="col-6">
              <Control
                withNav
                label="Fin"
                type="date"
                value={search.endAt}
                change={(e) =>
                  setSearch({
                    ...search,
                    endAt: DateTime.fromISO(e.target.value)
                      .endOf("day")
                      .toISO(),
                  })
                }
              />
            </div>
          </div>
          <div className="mt-3">
            <SaveBtn
              className="font-weight-bold"
              save={getDatas}
              isSaving={isLoading}
              text="Afficher"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filters;
