import { DateTime } from "luxon";
import React from "react";
import useStore from "../../../../context/useStore";
import tools from "../../../../helpers/tools";
import useIngredients from "../../../../hooks/useIngredients";
import Control from "../../../common/Controls/Control";
import Loader from "../../../common/Loader";

const Filters = ({ search, setSearch, getDatas, isLoading, ...props }) => {
  const [state, dispatch] = useStore();
  const { TIME_SCALES } = state.constants.items;
  const { ingredients, setIngredients } = useIngredients(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearch({ ...search, [name]: value });
  };

  const addValueToList = (e) => {
    if (e.target.value) {
      handleChange({
        target: {
          name: "ingredients",
          value: [...search.ingredients, e.target.value],
        },
      });
    }
  };

  const removeFromValueList = (val) => {
    handleChange({
      target: {
        name: "ingredients",
        value: search.ingredients.filter((ing) => ing !== val),
      },
    });
  };

  return (
    <div className="row mb-3">
      <div className="col-4">
        <div className="d-flex flex-row flex-wrap">
          {search.ingredients.map((val, valk) => (
            <div
              className="badge badge-primary d-flex align-items-center justify-content-between py-1 mb-1 mr-1"
              style={{ fontSize: 12 }}
              key={`badval${val}`}
              onClick={() => removeFromValueList(val)}
            >
              {tools.findIn(ingredients, val, "id").name}
              <i className="fa fa-times ml-1" />
            </div>
          ))}
        </div>
        <Control
          label="Ingrédient"
          type="autocomplete"
          value={null}
          name="ingredient"
          autoCompleteDisplayIndex="name"
          change={addValueToList}
          datas={ingredients.filter((ing) => !search.ingredients.includes(ing.id))}
          dataIndex="id" //default
          dataLabel="name" //default (sera inclut dans la recherche)
          dataLabel2="code" //index dans datas
          required={true}
          btnInline={false}
          hideAutocompleteOnFocusOut={false} //buggé
          noResults={false} //si aucun résultat api
          autoCompleteHasApiDatas={false} // true si afficher tous les résultats api
        />
      </div>
      <div className="col-8">
        <Control
          label="Échelon"
          name="timeScale"
          type="btnList"
          dataIndex="id"
          dataLabel="name"
          btnInline
          datas={TIME_SCALES}
          value={search.timeScale}
          change={(e) => setSearch({ ...search, timeScale: e.target.value })}
        />
        <div className="row">
          <div className="col-6">
            <Control
              label="Entre le"
              type="date"
              name="beginAt"
              change={(e) =>
                setSearch({
                  ...search,
                  beginAt: DateTime.fromISO(e.target.value).startOf("day").toISO(),
                })
              }
              value={search.beginAt}
            />
          </div>
          <div className="col-6">
            <Control
              label="Entre le"
              type="date"
              name="endAt"
              change={(e) =>
                setSearch({
                  ...search,
                  endAt: DateTime.fromISO(e.target.value).endOf("day").toISO(),
                })
              }
              value={search.endAt}
            />
          </div>
          <div className="col-12 mt-3 justify-content-between align-items-end d-flex noprint">
            <button className="btn btn-secondary btn-sm mb-1" onClick={getDatas}>
              {isLoading ? <Loader color="white" /> : "Afficher"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filters;
