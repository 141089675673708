import React, { useEffect, useState } from "react";
import useStore from "../../../../context/useStore";
import tools from "../../../../helpers/tools";
import Page from "../../../common/layout/Page";
import SlotQuantities from "./SlotQuantities";
import dataService from "../../../../helpers/dataService";
import Filters from "./Filters";
import NowTime from "../NowTime";
import { DateTime } from "luxon";
import { withRouter } from "react-router";

const ProductionSummary = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [state, dispatch] = useStore();
  const constants = state.constants.items;
  const { UNITS, CONSUME_TIMES } = constants;
  var searchState = tools.getState("ProductionSummary", null);
  const [searchDone, setSearchDone] = useState(false);

  var { productionsite, date } = props.match.params;
  var urlParams = productionsite
    ? {
        productionSite: productionsite,
        date,
      }
    : null;

  const [search, setSearch] = useState(
    urlParams
      ? urlParams
      : searchState
      ? JSON.parse(searchState.search)
      : {
          productionSite: "",
          date: DateTime.local().toISO(),
        }
  );
  const [hiddenDishTypes, setHiddenDishTypes] = useState([]);

  const [datas, setDatas] = useState({
    consumerTypes: [],
    dishTypes: [],
    slots: [],
    comments: [],
  });

  useEffect(() => {
    if (urlParams) {
      getDatas();
    }
  }, []);

  const getDatas = () => {
    setSearchDone(false);

    tools.saveState("ProductionSummary", "search", JSON.stringify(search));
    setIsLoading(true);
    dataService.post(
      `summaries/production`,
      search,
      setDatas,
      (err) => {},
      () => {
        setIsLoading(false);
        setSearchDone(true);
      }
    );
  };

  const toggleDishType = (id) => {
    if (hiddenDishTypes.includes(id)) {
      setHiddenDishTypes(hiddenDishTypes.filter((d) => d != id));
    } else {
      setHiddenDishTypes([...hiddenDishTypes, id]);
    }
  };

  return (
    <Page
      title="Récapitulatif de production"
      titleMargin="mb-3"
      isLoading={isLoading}
      printOrientation="landscape"
      container="container-fluid"
      style={{ maxWidth: "100%" }}
    >
      <NowTime />
      <Filters
        setSearch={setSearch}
        search={search}
        getDatas={getDatas}
        isLoading={isLoading}
      />
      {!datas.slots.length ? (
        searchDone ? (
          <p>Aucune donnée trouvée</p>
        ) : null
      ) : (
        <>
          <table className="table table-bordered table-sm table-small-padding w-100 bg-white shadow-sm hover-table ">
            <thead>
              <tr>
                <th className="text-center">Liste des plats</th>
                {datas.consumerTypes.map((ct) => (
                  <th className="text-center" key={`ct${ct.id}`}>
                    {ct.displayName}
                  </th>
                ))}
                <th className="font-weight-bold text-center">TOTAL</th>
                <th className="font-weight-bold text-center only-print">
                  Check
                </th>
                <th className="font-weight-bold text-center only-print">
                  Observation
                </th>
              </tr>
            </thead>
            <tbody>
              {datas.dishTypes
                .sort((a, b) => (a.order > b.order ? 1 : -1))
                .map((dt) => {
                  if (!datas.slots.find((sl) => sl.dishType == dt.id)) {
                    return null;
                  }
                  return (
                    <>
                      <tr
                        className={
                          hiddenDishTypes.includes(dt.id) ? "noprint" : ""
                        }
                      >
                        <td
                          colSpan={datas.consumerTypes.length + 2}
                          className="font-weight-bold bg-default "
                        >
                          <i
                            className={`fa mr-2 fa-${
                              hiddenDishTypes.includes(dt.id)
                                ? "low-vision"
                                : "eye"
                            }`}
                            onClick={() => toggleDishType(dt.id)}
                          />
                          {dt.name}
                        </td>
                        <td
                          colSpan="2"
                          style={{ borderBottom: "1px solid #ffffff" }}
                          className="only-print"
                        ></td>
                      </tr>

                      {datas.slots
                        .filter((s) => s.dishType == dt.id)
                        .map((slot) => (
                          <tr
                            style={{
                              display: hiddenDishTypes.includes(dt.id)
                                ? "none"
                                : "table-row",
                            }}
                          >
                            <td style={{ fontSize: 12 }}>
                              <div>{slot.technicalSheet}</div>
                              <small className="text-black-50">
                                {slot.texture}
                              </small>
                            </td>
                            {datas.consumerTypes.map((ct) => {
                              var slotConsumerType = slot.consumerTypes.find(
                                (sc) => sc.consumerType == ct.id
                              );
                              return (
                                <td
                                  className="text-center"
                                  style={{
                                    verticalAlign: "middle",
                                    fontSize: 12,
                                  }}
                                >
                                  {slotConsumerType ? (
                                    <SlotQuantities
                                      quantities={slotConsumerType.quantities}
                                      unit={slot.unit}
                                    />
                                  ) : (
                                    <i className="fa fa-times text-default" />
                                  )}
                                </td>
                              );
                            })}
                            <td
                              className=" text-center"
                              style={{ fontSize: 12 }}
                            >
                              {tools.transformQtyKg(
                                slot.consumerTypes.reduce(
                                  (a, b) => {
                                    return {
                                      quantities: {
                                        total:
                                          a.quantities.total +
                                          b.quantities.total,
                                      },
                                    };
                                  },
                                  { quantities: { total: 0 } }
                                ).quantities.total,
                                slot.unit
                              )}
                            </td>
                            <td
                              colSpan="2"
                              style={{ borderBottom: "1px solid #f7f7f7" }}
                              className="only-print"
                            ></td>
                          </tr>
                        ))}
                    </>
                  );
                })}
            </tbody>
          </table>
          <div className="row">
            {datas.comments.map((comment, ck) => (
              <div className="col-4" key={`comment${ck}`}>
                <div className="custom-card mb-2" style={{ fontSize: 13 }}>
                  <div>
                    <i className="fa fa-comment mr-2" />
                    <strong>
                      {comment.site} - {comment.client}
                    </strong>
                  </div>
                  <p>{comment.comment}</p>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </Page>
  );
};

export default ProductionSummary;
