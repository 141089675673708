import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useStore from "../../../context/useStore";
import dataService from "../../../helpers/dataService";
import tools from "../../../helpers/tools";
//import useApiReducer from "./useApiReducer";

var searchTimeout = "";

const useApiDatas = ({
  /////////////////////////////////////
  //POUR RECUPERER UNE LISTE SEARCH
  /////////////////////////////////////
  page = "", //nom de la page pour le stockage de la recherche en localstorage
  resourcePath = false, //url de la resource pour le search et le delete ex : productionsites
  searchParams = {}, //state du search item: {searchString:"",page:1}
  formatSearchDatasFn = false, //fonction à appeler sur les datas reçues du search
  /////////////////////////////////////
  //POUR CRUD SUR UN ITEM
  /////////////////////////////////////
  itemPaths = false, // {path:"",get:"",post:"",patch:""} url du crud sur item
  itemId = false, //passer props.match.params.id
  itemParams = {}, //state de l'item {name: "", code:"", ...}
  formatItemDatasFn = false, //fonction à appeler sur les datas reçues pour l'item
  /////////////////////////////////////
  //POUR RECUPERER LES DEPENDANCES
  /////////////////////////////////////
  dependenciesPath = [], //array de strings ex : productionsites
  /////////////////////////////////////
}) => {
  const isEdit = itemId == "create" ? false : true;
  const [state, dispatch] = useStore();
  const constants = state.constants.items;
  // const { apiState, apiDispatch, actions } = useApiReducer({
  //   searchDatasFinish: resourcePath ? false : true,
  //   getItemFinish: itemId && isEdit ? false : true,
  //   search: searchParams,
  //   item: itemParams,
  // });

  // const {
  //   isInit,
  //   errors,
  //   searchDatas,
  //   searchDatasFinish,
  //   getItemFinish,
  //   otherDatas,
  //   isLoading,
  //   nbPages,
  //   search,
  //   item,
  // } = apiState;
  // const {
  //   setIsInit,
  //   setErrors,
  //   setSearchDatas,
  //   setSearchDatasFinish,
  //   setGetItemFinish,
  //   setOtherDatas,
  //   setIsLoading,
  //   setNbPages,
  //   setSearch,
  //   setItem,
  // } = actions;
  var searchState = tools.getState(page, null);

  const [isInit, setIsInit] = useState(false);
  const [errors, setErrors] = useState({});
  const [searchDatas, setSearchDatas] = useState([]);
  const [searchDatasFinish, setSearchDatasFinish] = useState(
    resourcePath ? false : true
  );
  const [getItemFinish, setGetItemFinish] = useState(
    itemId && isEdit ? false : true
  );
  const [otherDatas, setOtherDatas] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [nbPages, setNbPages] = useState(1);
  const [nbResults, setNbResults] = useState(0);
  const [search, setSearch] = useState(
    searchState ? JSON.parse(searchState.search) : searchParams
  );
  const [oldSeach, setOldSearch] = useState(
    searchState ? JSON.parse(searchState.search) : searchParams
  );
  const [item, setItem] = useState(itemParams);

  useEffect(() => {
    if (itemId && isEdit) {
      getItem();
    }
    if (resourcePath) {
      getSearchDatas();
    }
    if (dependenciesPath.length) {
      getAllDependencies();
    }
  }, []);

  useEffect(() => {
    if (isInit) {
      clearTimeout(searchTimeout);

      searchTimeout = setTimeout(() => {
        tools.saveState(page, "search", JSON.stringify(search));
        getSearchDatas();
      }, 1500);
    }
  }, [search]);

  useEffect(() => {
    if (isLoading) setErrors({});
  }, [isLoading]);

  useEffect(() => {
    if (
      Object.keys(otherDatas).length == dependenciesPath.length &&
      searchDatasFinish &&
      getItemFinish
    ) {
      setIsInit(true);
    }
  }, [otherDatas, searchDatasFinish, getItemFinish]);

  const getSearchDatas = () => {
    setIsLoading(true);

    dataService.post(
      resourcePath + "/search",
      search,
      (datas) => {
        setSearchDatas(
          formatSearchDatasFn ? formatSearchDatasFn(datas.datas) : datas.datas
        );
        setNbResults(datas.count);
        setNbPages(Math.ceil(datas.count / 25));
        setSearchDatasFinish(true);
      },
      setErrors,
      () => {
        setIsLoading(false);
      }
    );
  };
  const getItem = () => {
    setIsLoading(true);
    dataService.get(
      itemPaths.get,
      (datas) => {
        setItem(formatItemDatasFn ? formatItemDatasFn(datas) : datas);
        setGetItemFinish(true);
      },
      setErrors,
      () => {
        setIsLoading(false);
      }
    );
  };

  const getAllDependencies = async () => {
    var deps = {};
    for (var key in dependenciesPath) {
      let dependency = dependenciesPath[key];
      deps[dependency] = await getDependency(dependency).catch((err) => {
        console.log(err);
      });
    }

    setOtherDatas(deps);
  };

  const getDependency = (dependencyPath) => {
    return new Promise((resolve, reject) => {
      dataService.get(
        dependencyPath,
        (datas) => {
          resolve(datas);
        },
        (error) => reject(error),
        () => {}
      );
    });
  };
  const deleteData = (id, path = false) => {
    setIsLoading(true);
    dataService.remove(
      path || resourcePath + "/" + id,
      {},
      (datas) => getSearchDatas(),
      setErrors,
      () => setIsLoading(false)
    );
  };

  const saveItem = (shouldReload = true, callBack = () => {}) => {
    if (isEdit) {
      patchItem(callBack);
    } else {
      postItem(shouldReload, callBack);
    }
  };

  const postItem = (shouldReload = true, callBack = false) => {
    setIsLoading(true);
    dataService.post(
      itemPaths.post,
      item,
      (datas) => {
        toast.success("Entrée créée avec succès");
        if (shouldReload) {
          window.location.href = itemPaths.path + datas.id;
        } else {
          if (callBack) callBack();
        }
      },
      setErrors,
      () => setIsLoading(false)
    );
  };

  const patchItem = (callBack = false) => {
    setIsLoading(true);
    dataService.patch(
      itemPaths.patch,
      item,
      (datas) => {
        setItem({
          ...item,
          createdAt: datas.createdAt,
          updatedAt: datas.updatedAt,
        });
        toast.success("Entrée enregistrée avec succès");
        if (callBack) callBack();
      },
      setErrors,
      () => setIsLoading(false)
    );
  };

  return {
    state, //state global
    dispatch,
    constants, //constantes
    isInit, //true quand tout est chargé (datas + dépendances)
    errors, //erreurs ex : {name:"valeur requise"}
    isLoading, //chargement en cours
    search, //objet du state SEARCH
    searchDatas, //datas issues du SEARCH
    nbPages, //nombre de pages du SEARCH
    nbResults, //nombre de résultats du search (count)
    otherDatas, //dépendances { dependance1: <datas>, dependance2: <datas>, ... }
    itemId, //id de l'item create ou id
    isEdit, //true si id de l'item présent
    item, //objet du state de l'item
    setErrors, //set les erreurs
    setIsLoading, //set le chargement
    setSearch, //set l'objet du state SEARCH
    getSearchDatas, //lancer la requête SEARC
    setSearchDatas, //set les datas issues du SEARCH
    setNbPages, //set le nombre de pages du SEARCH
    deleteData, //DELETE un item en passant son id
    setOtherDatas, //set les dépendances
    setItem, //set l'objet du state de l'item
    saveItem, //post ou patch l'item
  };
};

export default useApiDatas;
