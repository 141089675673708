import React from "react";
import { Link } from "react-router-dom";
import Control from "../../../../common/Controls/Control";
import Loader from "../../../../common/Loader";

const Filters = ({
  isLoading,
  productionSites,
  providers,
  search,
  setSearch,
  searchData,
}) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearch({
      ...search,
      purchaseHistoric: { ...search.purchaseHistoric, [name]: value },
    });
  };

  return (
    <div className="row mb-3 noprint">
      <div className="col-6">
        <div className="row">
          <div className="col-12">
            <Control
              label="Cuisine"
              type="select"
              datas={productionSites}
              value={search.purchaseHistoric.productionSite}
              dataIndex="id"
              dataLabel="name"
              name="productionSite"
              change={handleChange}
            />
          </div>
          <div className="col-12">
            <Control
              placeholder="Tous"
              label="Fournisseur"
              name="provider"
              type="select"
              datas={providers}
              value={search.purchaseHistoric.provider}
              dataIndex="id"
              dataLabel="name"
              change={handleChange}
            />
          </div>
        </div>
      </div>
      <div className="col-6">
        <div className="row">
          <div className="col-12">
            <Control
              label="Entre le"
              type="date"
              withNav
              name="beginAt"
              value={search.purchaseHistoric.beginAt}
              change={handleChange}
            />
          </div>
          <div className="col-12">
            <Control
              label="Et le"
              type="date"
              withNav
              name="endAt"
              value={search.purchaseHistoric.endAt}
              change={handleChange}
            />
          </div>

          <div className="col-12 mt-3 justify-content-between align-items-end d-flex noprint">
            <button
              className="btn btn-secondary btn-sm mb-1"
              onClick={searchData}
            >
              {isLoading ? <Loader color="white" /> : "Afficher"}
            </button>
            <Link to="/sum-achat" className="btn btn-default btn-sm ml-2">
              <i className="fa fa-link mr-2" />
              Revenir aux achats
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filters;
