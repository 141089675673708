import React, { useEffect, useState } from "react";

import { DateTime } from "luxon";
import Loader from "../../common/Loader";
import Colors from "../../../theme/variables.scss";
import tools from "../../../helpers/tools";
import rubik from "./rubik.ttf";
import useStore from "../../../context/useStore";
import Control from "../../common/Controls/Control";
import dataService from "../../../helpers/dataService";

const MyDoc = ({
  datas,
  styles,
  constants,
  consumerType,
  technicalSheet,
  isPrintClient,
  specificities,
  preparations,
  ingredients,
}) => {
  const { recipeData, texture, multiplicator } = datas;
  const { ALLERGENS, INGREDIENT_UNITS } = constants;
  // Font.register({
  //   family: "Rubik",
  //   format: "truetype",
  //   fonts: [
  //     {
  //       src: rubik,
  //     },
  //   ],
  // });

  var styles = {
    textBig: {
      fontSize: 20,
    },
    textMedium: {
      fontSize: 16,
    },
    textSmall: {
      fontSize: 11,
    },
    subTitle: {
      fontSize: 14,
      marginBottom: 5,
      textTransform: "uppercase",
      color: Colors.primary,
    },
    info: {
      fontSize: 14,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      maxWidth: 400,
      borderBottom: "1pt solid grey",
      marginBottom: 5,
    },
    label: {
      color: Colors.darkLight,
    },
    value: {
      color: Colors.primary,
    },
    col6: {
      width: "47%",
      marginBottom: 17,
    },
    listTitle: {
      fontSize: 13,
    },
    listItem: {
      fontSize: 11,
      color: Colors.lightDark,
    },
    row: {
      fontSize: 14,
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "space-between",
    },
    td: {
      padding: 5,

      fontSize: 12,
    },
  };

  const allergenList = () => {
    var hasAllergens = false;
    var allergensUniq = [];
    recipeData.components.forEach((comp) => {
      var ing = ingredients.find((i) => i.id == comp.ingredient);
      if (ing.allergens.length) hasAllergens = true;
    });
    if (hasAllergens) {
      recipeData.components.forEach((comp, compk) => {
        var ing = ingredients.find((i) => i.id == comp.ingredient);
        if (ing.allergens.length) {
          ing.allergens.forEach((al) => {
            if (!allergensUniq.includes(al)) allergensUniq.push(al);
          });
        }
      });
    }

    return allergensUniq.length ? (
      allergensUniq.map((al, alk) => (
        <div style={{ ...styles.listItem }} key={`allist${alk}`}>
          - {ALLERGENS.find((a) => a.id == al).name}
        </div>
      ))
    ) : (
      <div>
        <div style={{ ...styles.listItem }}>-aucun allergène-</div>
      </div>
    );
  };

  const componentsList = () => {
    var comps = recipeData.components.map((ing) => ({
      ...ing,
      qty: (parseInt(multiplicator) * parseFloat(ing.qty)) / 10,
    }));

    return comps.map((ingr, ingrKey) => {
      var preparation = preparations.find((p) => p.id == ingr.preparation);
      var suffix = "";
      if (
        preparation.isSpecificEquivalentPacking &&
        preparation.specificEquivalentPackingUnit == "3"
      ) {
        var weight = preparation.packingWeight * 1000;
        var nbLiters = preparation.specificEquivalentPackingQty;
        var qty = ingr.qty;
        var qtyEq = Math.round(((nbLiters * qty) / weight) * 100) / 100;
        suffix = <>{"\n" + qtyEq + " LITRES"}</>;
      }
      return (
        <div
          key={"comp" + ingrKey}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            flexDirection: "row",
            width: "100%",
            borderBottom: "1pt solid grey",
          }}
        >
          <div style={{ ...styles.td, width: "50%", height: "100%" }}>
            {ingredients.find((i) => i.id == ingr.ingredient)?.name}
          </div>
          <div style={{ ...styles.td, width: "50%", height: "100%" }}>
            {ingr.qty} {INGREDIENT_UNITS.find((i) => i.id == ingr.unit)?.name}
            {suffix}
          </div>
        </div>
      );
    });
  };

  const stripHtml = (html) => {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };

  const renderSpecitifities = () => {
    var specs = [];
    specificities.forEach((s, sk) => {
      var sp = technicalSheet.specificities.includes(s.id);
      if (sp)
        specs.push(
          <div
            style={{
              margin: "5 2px",
              backgroundColor: Colors.secondary,
              color: "white",
              padding: 5,
            }}
            key={`spec${sp.id}${Math.random()}`}
          >
            {s.name}
          </div>
        );
    });
    return specs;
  };

  return (
    <div style={{ padding: "20px", pageBreakAfter: "always" }}>
      <div
        style={{
          textAlign: "center",
          color: Colors.secondary,
        }}
      >
        <div style={{ marginBottom: 5 }}>
          <img src="/logo.png" height="40" />
        </div>
      </div>
      <div
        style={{
          textAlign: "center",
          color: Colors.secondary,
          marginBottom: 30,
        }}
      >
        <div style={{ marginBottom: 5 }}>
          Fiche technique - {technicalSheet.name}
        </div>
        <div
          style={{
            fontSize: 11,
            marginBottom: 5,
          }}
        >
          pour 10 personnes
        </div>
        <div
          style={{
            fontSize: 14,
          }}
        >
          {consumerType.name} - Texture {datas.texture.name}
        </div>
        <div
          style={{
            fontSize: 14,
            color: "black",
          }}
        >
          imprimé le {DateTime.local().toFormat("dd/MM/yyyy HH:mm")}
        </div>
        <div
          style={{
            fontSize: 14,
            color: "black",
          }}
        >
          modifié le{" "}
          {recipeData.updatedAt
            ? DateTime.fromISO(recipeData.updatedAt).toFormat(
                "dd/MM/yyyy HH:mm"
              )
            : "-inconnu-"}
        </div>
      </div>
      <div
        style={{
          fontSize: 9,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
          marginBottom: 20,
        }}
      >
        {renderSpecitifities()}
      </div>
      <div style={{ ...styles.row }}>
        <div style={{ ...styles.col6 }}>
          <div style={{ ...styles.info }}>
            <div style={{ ...styles.label }}>% de perte brut->net</div>
            <div style={{ ...styles.value }}>
              {tools.round(recipeData.total1Dish.ratioBrutToCooked)}%
            </div>
          </div>

          {isPrintClient && (
            <div style={{ ...styles.info }}>
              <div style={{ ...styles.label }}>Coût total</div>
              <div style={{ ...styles.value }}>
                {tools.round(recipeData.total1Dish.totalCost * multiplicator)}€
              </div>
            </div>
          )}

          <div style={{ ...styles.info }}>
            <div style={{ ...styles.label }}>Poids total final cuit</div>
            <div style={{ ...styles.value }}>
              {tools.round(
                recipeData.total1Dish.totalWeightNet * multiplicator
              )}{" "}
              GRAMMES
            </div>
          </div>

          <div style={{ ...styles.info }}>
            <div style={{ ...styles.label }}>Total de pièces</div>
            <div style={{ ...styles.value }}>
              {tools.round(recipeData.total1Dish.totalPieces * multiplicator)}{" "}
            </div>
          </div>

          <div style={{ ...styles.info }}>
            <div style={{ ...styles.label }}>Valeur nutri. </div>
            <div style={{ fontSize: 10 }}>
              (1 portion de {tools.round(recipeData.total1Dish.totalWeightNet)}
              g)
            </div>
            <div style={{ ...styles.value }}>
              {tools.roundInt(recipeData.nutritionalValues?.perPortion)} kcal
            </div>
          </div>
          <div style={{ ...styles.info }}>
            <div style={{ ...styles.label }}>Valeur nutri.</div>
            <div style={{ fontSize: 10 }}>(100g)</div>
            <div style={{ ...styles.value }}>
              {tools.roundInt(recipeData.nutritionalValues?.per100g)} kcal
            </div>
          </div>

          <div style={{ ...styles.info }}>
            <div style={{ ...styles.label }}>
              % de produits origine BIO (Grammes)
            </div>
            <div style={{ ...styles.value }}>
              {tools.round(recipeData.totalPercentBio.weight)} %
            </div>
          </div>
          <div style={{ ...styles.info }}>
            <div style={{ ...styles.label }}>% de produits origine BIO (€)</div>
            <div style={{ ...styles.value }}>
              {tools.round(recipeData.totalPercentBio.cost)} %
            </div>
          </div>
          <div style={{ ...styles.info }}>
            <div style={{ ...styles.label }}>
              % de produits Locaux (Grammes)
            </div>
            <div style={{ ...styles.value }}>
              {tools.round(recipeData.totalPercentLocal.weight)} %
            </div>
          </div>
          <div style={{ ...styles.info }}>
            <div style={{ ...styles.label }}>% de produits Locaux (€)</div>
            <div style={{ ...styles.value }}>
              {tools.round(recipeData.totalPercentLocal.cost)} %
            </div>
          </div>
        </div>
        <div style={{ ...styles.col6 }}>
          <div style={{ ...styles.subTitle }}>Liste des allergènes</div>
          {allergenList()}
        </div>
      </div>
      <div style={{ ...styles.row }}>
        <div style={{ ...styles.col6 }}>
          <div style={{ ...styles.subTitle }}>
            Composants pour {multiplicator} portion(s)
          </div>
          {componentsList()}
        </div>
        <div style={{ ...styles.col6 }}>
          <div style={{ ...styles.subTitle }}>Méthodologie</div>
          <div style={{ ...styles.textSmall }}>
            {recipeData.methodology ? (
              stripHtml(recipeData.methodology)
            ) : (
              <div>
                <div style={{ ...styles.listItem }}>-aucune méthodologie-</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const RecipePrintAll = ({
  id,
  params,
  ingredients,
  specificities,
  preparations,
  isPrintClient,
}) => {
  const [state, dispatch] = useStore();
  const constants = state.constants.items;
  const [isInit, setIsInit] = useState(true);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [canDownload, setCanDownload] = useState(false);

  return isLoading || !preparations ? (
    <Loader />
  ) : errors.other ? (
    <div className="alert alert-danger">{errors.other}</div>
  ) : (
    <div className="text-center ">
      {isInit && (
        <>
          {params.recipes.map((p, pk) => (
            <MyDoc
              key={"tex" + pk}
              datas={p}
              consumerType={p.consumerType}
              constants={constants}
              technicalSheet={p.technicalSheet}
              isPrintClient={isPrintClient}
              specificities={specificities}
              preparations={preparations}
              ingredients={ingredients}
            />
          ))}
        </>
      )}
    </div>
  );
};

export default RecipePrintAll;
