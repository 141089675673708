import React from "react";
import {
  PDFViewer,
  Page,
  Text,
  Image,
  View,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import { DateTime } from "luxon";
import tools from "../../../../../helpers/tools";

const HistoryPrint = ({ items }) => {
  const { order, clients, utils } = items;

  var clientName = clients.find((c) => c.id == utils.selectedClient).name;
  var period = `du ${DateTime.fromISO(utils.selectedDate)
    .setLocale("fr")
    .toFormat("dd MMMM yyyy")} au ${DateTime.fromISO(utils.selectedDate)
    .setLocale("fr")
    .endOf("week")
    .toFormat("dd MMMM yyyy")}`;

  const { historics } = order.order;

  const styles = StyleSheet.create({
    detailItem: {
      fontSize: 9,
      marginBottom: 3,
    },
    histoBlock: {
      borderBottomStyle: "solid",
      borderBottomWidth: 1,
      marginBottom: 5,
      paddingBottom: 3,
    },
    histoTitle: {
      fontSize: 12,
      marginBottom: 4,
    },
  });

  return (
    <PDFViewer style={{ width: "100%", height: "100%", border: 0 }}>
      <Document>
        <Page size="A4" style={{ padding: 30 }} orientation="portrait">
          <View
            style={{
              textAlign: "center",
              paddingBottom: 5,
              borderBottomStyle: "solid",
              borderBottomWidth: 1,
              marginBottom: 5,
            }}
          >
            <Text>{clientName}</Text>
            <Text
              style={{
                fontSize: 12,
              }}
            >
              Historique des modifications sur la commande {period}
            </Text>
          </View>
          {historics.map((h, hk) => (
            <View style={{ ...styles.histoBlock }}>
              <Text style={{ ...styles.histoTitle }}>
                {DateTime.fromISO(h.updatedAt)
                  .setLocale("fr")
                  .toFormat("dd MMMM yyyy")}{" "}
                par {tools.getFullName(h.updatedBy)}
              </Text>
              <View>
                {h.details.map((d, dk) => (
                  <View style={{ ...styles.detailItem }}>
                    <Text>- {d}</Text>
                  </View>
                ))}
              </View>
            </View>
          ))}
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default HistoryPrint;
