import React from "react";
import Control from "../../../../../common/Controls/Control";
import useStoreState from "../../../Context/Store/useStoreState";

const ContractQty = ({ day, moment, csId, contractId, consumerId }) => {
  const { items, items_actions } = useStoreState();
  const { slotOrders } = items.order;

  var slotOrderOrigin = slotOrders.find(
    (c) =>
      c.day == day.id &&
      c.moment == moment.id &&
      c.consumptionProfile == csId &&
      c.consumer == consumerId
  );
  const quantity = slotOrderOrigin?.qty || 0;

  const setQuantity = (value) => {
    var val = value;
    if (isNaN(value)) {
      val = 0;
    }
    console.log("updating to -> " + val);
    items_actions.setSlotOrderQty(val, day, moment, csId, contractId, consumerId);
  };

  const handleFocusout = () => {
    if (!(quantity + "").length || isNaN(quantity)) {
      setQuantity(0);
    }
  };

  const buttonStyle = {
    padding: 0,
    width: "100%",
  };
  const buttonClassname = quantity > 0 ? "btn-dark" : "btn-default";

  return (
    <div
      className={`w-100 d-flex flex-row align-items-center justify-content-between  bg-white p-1 shadow contract-quantity`}
      style={{ borderBottom: "4px solid #828282" }}
    >
      <button
        className={`btn btn-sm d-center flex-fill h-100 ${buttonClassname}`}
        onClick={() => {
          if (quantity - 1 >= 0) {
            setQuantity(quantity - 1);
          }
        }}
        style={{
          ...buttonStyle,
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        }}
      >
        <i className="fa fa-minus" />
      </button>
      <Control
        type="number"
        value={quantity}
        onBlur={handleFocusout}
        change={(e) => setQuantity(e.target.value)}
        inputStyle={{ fontSize: 13, textAlign: "center" }}
        noPadding
        margin="mb-0"
        className="d-center flex-fill"
        inputGroupStyle={{ padding: 0, borderRadius: 0 }}
      />
      <button
        className={`btn btn-sm d-center flex-fill h-100 ${buttonClassname}`}
        onClick={() => setQuantity(quantity + 1)}
        style={{
          ...buttonStyle,
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        }}
      >
        <i className="fa fa-plus" />
      </button>
    </div>
  );
};

export default ContractQty;
