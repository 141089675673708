import React from "react";
import ItemStore from "./Context/Store/ItemStore";
import itemReducer from "./Context/reducers/itemsReducer";
import OrderPage from "./OrderPage/OrderPage";
import { DateTime } from "luxon";
import tools from "../../../helpers/tools";

const Order = (props) => {
  const fetchId = props.match.params.id;

  const search = tools.getState("orders")
    ? tools.getState("orders").search
    : {};

  const items = [
    {
      name: "clients",
      paths: {
        get: `clients`,
      },
      initialState: [],
      fetchOnLoad: true,
    },
    {
      name: "productionSites",
      paths: {
        get: `productionsites`,
      },
      initialState: [],
      fetchOnLoad: true,
    },
    {
      name: "sites",
      initialState: [],
    },
    {
      name: "order",
      initialState: {
        contracts: [],
        consumptionProfiles: [],
        slotOrders: [],
        slotRefs: [],
        order: null,
      },
    },
    {
      name: "refSlotOrders",
      initialState: [],
    },
    {
      name: "utils",
      initialState: {
        selectedClient: search.selectedClient || "",
        selectedSite: search.selectedSite || "",
        selectedDate:
          search.selectedDate || DateTime.local().startOf("week").toISO(),
      },
    },
  ];

  return (
    <ItemStore rootReducer={itemReducer}>
      <div>
        <OrderPage pageTitle="Commande client" items={items} />
      </div>
    </ItemStore>
  );
};

export default Order;
