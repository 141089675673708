import React, { useEffect, useState } from "react";
import dataService from "../../../../helpers/dataService";
import Control from "../../../common/Controls/Control";
import DisplayDates from "../../../common/DisplayDates";
import useApiDatas from "../../../common/Hooks/useApiDatas";
import Page from "../../../common/layout/Page";
import SaveBtn from "../../../common/SaveBtn";
import IngredientPreparationList from "../../PreparationModule/IngradientPreparationList";
var searchTimeout = null;

const IngredientDetail = (props) => {
  var pathId = props.id || props.match.params.id;
  const { constants, errors, setErrors, isLoading, setIsLoading, isInit, itemId, isEdit, item, setItem, saveItem } =
    useApiDatas({
      itemPaths: {
        path: "/ingredients/", //url de redirection
        get: "ingredients/" + pathId, //get item
        post: "ingredients", //post item
        patch: "ingredients/" + pathId, //patch item
      },
      itemId: pathId, //create ou id
      itemParams: {
        name: "",
        code: "",
        nutritionalValue: "",
        allergens: [],
        preparations: [],
        type: "",
        typeCoef: "",
      },
    });

  const [ingredients, setIngredients] = useState([]);
  const [showAllergens, setShowAllergens] = useState(false);

  useEffect(() => {
    clearTimeout(searchTimeout);
    if (item.name?.length >= 3) {
      searchTimeout = setTimeout(() => {
        searhDishByName();
      }, 1000);
    }
  }, [item.name]);

  const searhDishByName = () => {
    setIsLoading("ingredients");
    setIngredients([]);

    dataService.post(
      "ingredients/search",
      {
        ingredient: { searchString: item.name },
        page: 1,
      },
      (datas) => setIngredients(datas.datas),
      setErrors,
      () => setIsLoading(false)
    );
  };

  var similarIngredients = ingredients.filter((ing, ingk) => ing.id != itemId);

  return (
    <Page
      container="container"
      back={props.id ? false : "/ingredients"}
      title={(isEdit ? "Modification" : "Détail") + " de l'ingrédient"}
      errors={errors}
      isInit={isInit}
    >
      {isInit && (
        <div className="row custom-card">
          <div className="col-12">
            <Control
              label="Nom"
              name="name"
              type="text"
              value={item.name}
              change={(e) => setItem({ ...item, name: e.target.value })}
              error={errors}
              setErrors={setErrors}
              required={true}
            />
            {similarIngredients.length ? (
              <div className="alert alert-danger mb-0">
                Attention, {similarIngredients.length} ingrédient(s) avec un nom similaire
              </div>
            ) : (
              ""
            )}
            <div className="d-flex align-items-center justify-content-start bg-light animated fadeInDown faster flex-wrap">
              <>
                {similarIngredients.map((ing, ingk) => {
                  return (
                    <a href={"/ingredients/" + ing.id} className="btn btn-danger  btn-sm mb-1 d-center">
                      {ing.name}
                    </a>
                  );
                })}
              </>
            </div>
            <Control
              label="Code"
              name="code"
              type="text"
              value={item.code}
              change={(e) => setItem({ ...item, code: e.target.value })}
              error={errors}
              setErrors={setErrors}
              required={true}
            />
            <Control
              label="Valeur nutritionnelle (kcal/100g cru)"
              name="nutritionalValue"
              type="float"
              value={item.nutritionalValue}
              change={(e) => setItem({ ...item, nutritionalValue: e.target.value })}
              error={errors}
              setErrors={setErrors}
              required={true}
              suffix={"kcal/100g cru"}
            />
            <Control
              label="Famille d'aliment"
              name="type"
              type="select"
              value={item.type}
              datas={constants.INGREDIENT_TYPES}
              dataIndex="id"
              dataLabel="name"
              change={(e) => setItem({ ...item, type: e.target.value })}
              error={errors}
              setErrors={setErrors}
              required={true}
            />
            {/* <Control
              label={`Pourcentage de ${
                constants.INGREDIENT_TYPES.find((i) => i.id == item.type)
                  ?.name || "type d'ingrédient"
              } dans l'ingrédient une fois cuit`}
              name="typeCoef"
              type="float"
              value={item.typeCoef}
              change={(e) => setItem({ ...item, typeCoef: e.target.value })}
              error={errors}
              setErrors={setErrors}
              required={true}
              suffix={"%"}
            /> */}
            <div className="row">
              <div className="col-12">
                <h4>
                  Liste des allergènes ({item.allergens.length}){" "}
                  <button className="btn btn-default float-right" onClick={() => setShowAllergens((s) => !s)}>
                    {showAllergens ? "Masquer" : "Afficher"}
                  </button>
                </h4>
                {item.allergens.map((al, alk) => (
                  <span className="badge badge-primary mx-1" key={`allerg${al}`}>
                    {constants.ALLERGENS.find((a) => a.id == al).name}
                  </span>
                ))}
                {showAllergens && (
                  <Control
                    name="allergens"
                    type="checkBtnList"
                    value={item.allergens}
                    datas={constants.ALLERGENS}
                    change={(e) =>
                      setItem({
                        ...item,
                        allergens: e.target.value,
                      })
                    }
                    error={errors}
                    setErrors={setErrors}
                    required={true}
                  />
                )}
              </div>
            </div>
            {!props.id && (
              <div className="row">
                <div className="col-12">
                  <h4>Liste des produits</h4>
                  {isEdit ? (
                    <IngredientPreparationList ingredientId={itemId} />
                  ) : (
                    <span>Enregistrez avant de pouvoir ajouter des produits</span>
                  )}
                </div>
              </div>
            )}
            <div className="d-flex justify-content-end w-100 mt-3" data-priv="create_modify_ingredients">
              <DisplayDates createdAt={item.createdAt} updatedAt={item.updatedAt} />
              {props.id ? (
                <SaveBtn
                  save={() => saveItem(false, props.successCallback)}
                  isSaving={isLoading}
                  text={props.id ? "Créer" : "Enregistrer"}
                  type="primary"
                />
              ) : (
                <SaveBtn
                  save={saveItem}
                  isSaving={isLoading}
                  text={props.id ? "Créer" : "Enregistrer"}
                  type="primary"
                />
              )}
              {props.id && (
                <button className="btn btn-default" onClick={props.close}>
                  Annuler
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </Page>
  );
};

export default IngredientDetail;
