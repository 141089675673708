import { DateTime } from "luxon";
import React, { useState } from "react";
import Control from "../../../common/Controls/Control";
import CAChart from "./CAChart/CAChart";
import DishQtyChart from "./DishQtyChart/DishQtyChart";
import DishWeightChart from "./DishWeightChart/DishWeightChart";
import Egalim from "./Egalim/Egalim";
import Filters from "./Filters/Filters";
import NbClientsChart from "./NbClientsChart/NbClientsCharts";
import NbSites from "./NbSites/NbSites";
import Operational from "./Operational/Operational";

const Stats = () => {
  const [search, setSearch] = useState({
    beginAt: DateTime.local().startOf("month").toISO(),
    endAt: DateTime.local().toISO(),
    productionSite: "",
    timeScale: "DAY",
    client: "",
    site: "",
  });

  const [layout, setLayout] = useState(2);

  var colClassName = layout == 1 ? "" : "col-md-6";

  return (
    <div className="row">
      <div className="col-12 mb-3">
        <Filters search={search} setSearch={setSearch} />
      </div>
      <div className="col-12 mb-3">
        <Control
          label="Disposition"
          type="btnList"
          value={layout}
          btnInline
          inputGroupStyle={{ paddingLeft: 0, background: "transparent" }}
          labelStyle={{ width: "auto" }}
          change={(e) => setLayout(e.target.value)}
          datas={[
            { id: 1, name: "1 colonne" },
            { id: 2, name: "2 colonnes" },
          ]}
          dataIndex="id"
          dataLabel="name"
        />
      </div>
      <div className={`col-12 mb-3`} data-priv="view_billing_global_summary">
        <CAChart search={search} />
      </div>
      <div className={`col-12 ${colClassName} mb-3`}>
        <DishWeightChart search={search} />
      </div>
      <div className={`col-12 ${colClassName} mb-3`}>
        <DishQtyChart search={search} />
      </div>
      <div className={`col-12 ${colClassName} mb-3`}>
        <NbClientsChart search={search} />
      </div>
      <div className={`col-12 ${colClassName} mb-3`}>
        <NbSites search={search} />
      </div>
      {/* <div className={`col-12 ${colClassName} mb-3`}>
        <Operational search={search} />
      </div> */}
      <div className={`col-12 mb-3`}>
        <Egalim search={search} />
      </div>
    </div>
  );
};

export default Stats;
