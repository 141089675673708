import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useStore from "../../../../context/useStore";
import dataService from "../../../../helpers/dataService";
import Control from "../../../common/Controls/Control";
import DynamicList from "../../../common/DynamicList";
import useApiDatas from "../../../common/Hooks/useApiDatas";
import Page from "../../../common/layout/Page";
import SaveBtn from "../../../common/SaveBtn";
import SiteForm from "./SiteForm";

const SiteDetail = (props) => {
  var clientId = props.match.params.clientid;
  var pathId = props.match.params.id;
  const {
    constants,
    errors,
    setErrors,
    isLoading,
    setIsLoading,
    isInit,
    itemId,
    isEdit,
    item,
    setItem,
    saveItem,
    otherDatas,
  } = useApiDatas({
    itemPaths: {
      path: "/sites/" + clientId + "/", //url de redirection
      get: "sites/" + pathId, //get item
      post: "sites", //post item
      patch: "sites/" + pathId, //patch item
    },
    dependenciesPath: ["productionsites", `clients/${clientId}/`],
    itemId: pathId, //create ou id
    itemParams: {
      client: clientId,
      productionSite: "",
      name: "",
      address: "",
      postalCode: "",
      city: "",
      phone: "",
      closingPeriods: [],
      activeDays: [1, 2, 3, 4, 5],
      conditioningMode: "",
      allotingMode: "",
      isArchived: false,
    },
  });
  console.log(item, otherDatas, isInit);
  const site = item;
  const setSite = setItem;
  const productionSites = otherDatas.productionsites;
  const client = otherDatas[`clients/${clientId}/`];
  console.log("CLIENT", client);
  return (
    <Page
      container="container"
      back={`/sites/${clientId}`}
      title={`${isEdit ? "Modification" : "Création"}
         du site du client 
        ${client?.name || ""}`}
      errors={errors}
      contentClassname="mx-auto"
      isInit={isInit}
    >
      {isInit && (
        <div className="row">
          <SiteForm
            site={site}
            setSite={setSite}
            productionSites={productionSites}
          />
          <div className="col-12 mt-4">
            <div className="d-flex justify-content-center w-100">
              <SaveBtn
                save={saveItem}
                isSaving={isLoading}
                text="Enregistrer"
                type="primary"
              />
            </div>
          </div>
        </div>
      )}
    </Page>
  );
};

export default SiteDetail;
