import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useStore from "../../../../context/useStore";
import dataService from "../../../../helpers/dataService";
import Control from "../../../common/Controls/Control";
import DisplayDates from "../../../common/DisplayDates";
import Page from "../../../common/layout/Page";
import SaveBtn from "../../../common/SaveBtn";

const TextureDetail = (props) => {
  const [state, dispatch] = useStore();
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [texture, setTexture] = useState({
    name: "",
  });
  const itemId = props.match.params.id;
  const isEdit = itemId == "create" ? false : true;
  const constants = state.constants.items;

  useEffect(() => {
    if (isEdit) {
      getDatas();
    }
  }, []);

  const getDatas = () => {
    dataService.get(
      "textures/" + itemId,
      (datas) => setTexture(datas),
      (error) => setErrors(error),
      () => setIsLoading(false)
    );
  };

  const save = () => {
    const onError = (error) => {
      setErrors(error);
    };
    setIsLoading(true);
    if (isEdit) {
      const onSuccess = (datas) => {
        setTexture({
          ...texture,
          createdAt: datas.createdAt,
          updatedAt: datas.updatedAt,
        });
        toast.success("Texture enregistré avec succès");
      };
      dataService.patch("textures/" + itemId, texture, onSuccess, onError, () =>
        setIsLoading(false)
      );
    } else {
      const onSuccess = (datas) => {
        toast.success("Texture créé avec succès");
        window.location.href = "/textures/" + datas.id;
      };

      dataService.post("textures", texture, onSuccess, onError, () =>
        setIsLoading(false)
      );
    }
  };

  return (
    <Page
      container="container"
      back="/textures"
      title={(isEdit ? "Modification" : "Détail") + " du texture"}
      errors={errors}
    >
      <div className="row custom-card">
        {texture.isReferent && (
          <div className="col-12 text-primary mb-2">
            <i className="fa fa-star mr-2" />
            Texture référente lors des calculs dans les fiches techniques
          </div>
        )}
        <div className="col-12 p-0">
          <Control
            label="Nom"
            name="name"
            type="text"
            value={texture.name}
            change={(e) => setTexture({ ...texture, name: e.target.value })}
            error={errors}
            setErrors={setErrors}
            required={true}
          />
        </div>
      </div>
      <div className="row" data-priv="create_modify_textures">
        <div className="d-flex justify-content-end w-100">
          <DisplayDates
            createdAt={texture.createdAt}
            updatedAt={texture.updatedAt}
          />
          <SaveBtn
            save={save}
            isSaving={isLoading}
            text="Enregistrer"
            type="primary"
          />
        </div>
      </div>
    </Page>
  );
};

export default TextureDetail;
