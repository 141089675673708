import React from "react";
import tools from "../../../../../helpers/tools";
import Table from "../../../../common/Table/Table";
import useStoreState from "../useStoreState";

const ClientUserList = ({ setShowUserForm }) => {
  const { state, gState, items, items_actions } = useStoreState();
  const { users, client } = { ...items };

  const constants = gState.constants.items;

  return (
    <Table
      datas={users || []}
      fields={[
        {
          name: "Rôle",
          path: "role",
          render: (el) => {
            return constants.ROLES_CUSTOMER.find((r) => r.id == el).name;
          },
        },
        {
          name: "Nom",
          path: "*",
          render: (el) => tools.getFullName(el),
        },
        {
          name: "Email",
          path: "email",
        },
        {
          name: "Téléphone",
          path: "phone",
        },
        {
          name: "Activé",
          path: "isActivated",
          render: (el) => <i className={`fa fa-${el ? "check" : "times"} text-${el ? "success" : "dark"}`} />,
        },
        {
          name: "Actions",
          path: "*",
          render: (el) => (
            <>
              <button
                data-priv="create_modify_client_user"
                className="btn btn-primary"
                onClick={() => setShowUserForm(el)}
              >
                <i className="fa fa-edit" />
              </button>
              <button
                data-priv="create_modify_client_user"
                className="btn btn-danger ml-2"
                onClick={() => items_actions.removeUser(el.id)}
              >
                <i className="fa fa-times" />
              </button>
            </>
          ),
        },
      ]}
    />
  );
};

export default ClientUserList;
