import React from "react";
import tools from "../../../../helpers/tools";

const RecipeTotals = ({ title, totals, nbPortions }) => {
  return (
    <>
      <h4>{title}</h4>
      <div>
        <strong>
          Poids total Brut :{" "}
          <span className="text-primary">
            {tools.round(totals.totalWeightBrut)} GRAMMES
          </span>
        </strong>
      </div>
      <div>
        <strong>
          Poids total Net :{" "}
          <span className="text-primary">
            {tools.round(totals.totalWeightNet)} GRAMMES
          </span>
        </strong>
      </div>
      {/* AJOUTER % PERTE */}

      <div>
        <strong>
          % pertes brut->net :{" "}
          <span className="text-primary">
            {tools.round(totals.ratioBrutToCooked)}%
          </span>
        </strong>
      </div>
      <div>
        <strong>
          Coût total {nbPortions} portion(s) :{" "}
          <span className="text-primary">{tools.round(totals.totalCost)}€</span>
        </strong>
      </div>
      <div>
        <strong>
          Total de pièces :{" "}
          <span className="text-primary">
            {tools.round(totals.totalPieces)}
          </span>
        </strong>
      </div>
    </>
  );
};

export default RecipeTotals;
