import React, { useEffect, useState } from "react";
import useStore from "../../../../../../../../../context/useStore";
import Control from "../../../../../../../../common/Controls/Control";
import actionTools from "../../../../../Context/actions/actionTools";
import DeselectButton from "./DeselectButton";
import DishSlotItem from "./DishSlotItem";
import DateSelector from "../../../../MenuRef/DateSelector";
import tools from "../../../../../../../../../helpers/tools";
import SaveBtn from "../../../../../../../../common/SaveBtn";

const DuplicateDishSlots = ({
  moments,
  days,
  dishSlots,
  itemsActions,
  entireMomentId = false,
  allDishSlots = [],
  slotCodes,
  beginAt,
  nbWeeksByCycle,
}) => {
  const [state, dispatch] = useStore();
  const constants = state.constants.items;
  const { DAYS, CONSUME_TIMES } = constants;
  const daysAvailable = DAYS;
  const momentsAvailable = CONSUME_TIMES;
  const [daysSelected, setDaysSelected] = useState([]);
  const [momentsSelected, setMomentsSelected] = useState([]);
  const [daysOriginSelected, setDaysOriginSelected] = useState(days);
  const [momentsOriginSelected, setMomentsOriginSelected] = useState(moments);
  const [targetDate, setTargetDate] = useState(beginAt);
  const [dishSlotsSelected, setDishSlotsSelected] = useState(dishSlots);
  const [isLoading, setIsLoading] = useState(false);
  const [displayDishSlotsNotSelected, setDisplayDishSlotsNotSelected] =
    useState(false);

  const validateDuplication = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 10000);
    itemsActions.duplicateDishSlots(
      dishSlotsSelected,
      daysSelected,
      momentsSelected,
      targetDate
    );
  };
  var weekNum = tools.getNumOfWeek(targetDate, nbWeeksByCycle);

  useEffect(() => {
    setDishSlotsSelected(
      dishSlotsSelected.filter(
        (d) =>
          daysOriginSelected.includes(d.day) &&
          momentsOriginSelected.includes(d.moment)
      )
    );
  }, [daysOriginSelected, momentsOriginSelected]);

  const dishSlotsNotSelected = allDishSlots.filter(
    (d) =>
      !dishSlotsSelected.find((di) => di.id == d.id) &&
      daysOriginSelected.includes(d.day) &&
      momentsOriginSelected.includes(d.moment)
  );

  return !dishSlots.length ? (
    <div className="alert alert-danger">Il n'y a aucun plat à dupliquer</div>
  ) : (
    <>
      <div className="font-weight-bold">Dupliquer</div>

      <div className="mt-2">
        <Control
          label="Depuis les jours"
          name="daysOriginSelected"
          type="checkBtnList"
          value={daysOriginSelected}
          datas={daysAvailable}
          change={(e) => setDaysOriginSelected(e.target.value)}
          dataIndex="id"
          dataLabel="name"
          btnInline
        />
      </div>
      <div className="mt-2">
        <Control
          label="Depuis les moments"
          name="momentsOriginSelected"
          type="checkBtnList"
          value={momentsOriginSelected}
          datas={momentsAvailable}
          change={(e) => setMomentsOriginSelected(e.target.value)}
          dataIndex="id"
          dataLabel="name"
          btnInline
        />
      </div>

      <div className="d-flex align-items-center justify-content-start flex-column mt-2">
        <div
          className="w-100 text-primary"
          style={{ fontSize: 14, fontWeight: "500" }}
        >
          Les plats suivants
        </div>

        {dishSlotsSelected.map((ds, dsk) => {
          return (
            <DishSlotItem
              ds={ds}
              DAYS={DAYS}
              CONSUME_TIMES={CONSUME_TIMES}
              key={`dsk${dsk}`}
              setDishSlotsSelected={setDishSlotsSelected}
              dishSlotsSelected={dishSlotsSelected}
              slotCodes={slotCodes}
            />
          );
        })}

        <button
          className="btn btn-default"
          onClick={() =>
            setDisplayDishSlotsNotSelected(!displayDishSlotsNotSelected)
          }
        >
          {dishSlotsNotSelected.length} autres plats disponibles
          <i className="fa fa-caret-down ml-2" />
        </button>
        {displayDishSlotsNotSelected
          ? dishSlotsNotSelected.map((ds, dsk) => (
              <DishSlotItem
                ds={ds}
                DAYS={DAYS}
                CONSUME_TIMES={CONSUME_TIMES}
                key={`dskns${dsk}`}
                setDishSlotsSelected={setDishSlotsSelected}
                dishSlotsSelected={dishSlotsSelected}
                slotCodes={slotCodes}
              />
            ))
          : null}
      </div>
      <div className="d-center py-3">
        <i className="fa fa-arrow-down fa-2x" />
      </div>
      <div className="mt-2 d-center flex-column">
        <DateSelector
          text={`Vers la semaine N°${weekNum} du`}
          beginAt={targetDate}
          change={(e) => setTargetDate(e.target.value)}
        />
      </div>
      <div className="mt-2">
        <Control
          name="daysSelected"
          label="Dans les jours"
          type="checkBtnList"
          value={daysSelected}
          datas={daysAvailable}
          change={(e) => setDaysSelected(e.target.value)}
          dataIndex="id"
          dataLabel="name"
          btnInline
        />

        <DeselectButton
          isActive={!daysSelected.length}
          onClick={() => setDaysSelected([])}
          text={"Conserver les jours des plats d'origine"}
        />
      </div>

      <div className="mt-2">
        <Control
          name="momentsSelected"
          label="Dans les moments"
          type="checkBtnList"
          value={momentsSelected}
          datas={momentsAvailable}
          change={(e) => setMomentsSelected(e.target.value)}
          dataIndex="id"
          dataLabel="name"
          btnInline
        />

        <DeselectButton
          isActive={!momentsSelected.length}
          onClick={() => setMomentsSelected([])}
          text={"Conserver les moments des plats d'origine"}
        />
      </div>

      <div className="alert alert-warning">
        {
          actionTools.getDuplicatedSlots(
            dishSlotsSelected,
            daysSelected,
            momentsSelected,
            null
          ).length
        }{" "}
        plats vont être créés
      </div>

      <SaveBtn
        save={validateDuplication}
        isSaving={isLoading}
        text="Dupliquer"
      />
    </>
  );
};

export default DuplicateDishSlots;
