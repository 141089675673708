import { useState } from "react";
import useStore from "../../../../../context/useStore";
import dataService from "../../../../../helpers/dataService";
import tools from "../../../../../helpers/tools";
import useProductionSites from "../../../../../hooks/useProductionSites";
import useProviders from "../../../../../hooks/useProviders";

const useOrderReceiptList = () => {
  const [gState] = useStore();
  const { PREPARATION_CATEGORIES } = gState.constants.items;
  const [orderReceipts, setOrderReceipts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  var searchState = tools.getState("OrderReceipts", null);
  const [search, setSearch] = useState(
    searchState
      ? JSON.parse(searchState.search)
      : {
          purchaseHistoric: {
            productionSite: "",
            provider: "",
            beginAt: "",
            endAt: "",
          },
          page: 1,
        }
  );
  const [nbPages, setNbPages] = useState(1);
  const [nbResults, setNbResults] = useState(0);

  const [currentOrderReceipt, setCurrentOrderReceipt] = useState(null);
  const { productionSites } = useProductionSites();
  const { providers } = useProviders();

  const searchOrderReceipts = () => {
    setErrors({});
    setIsLoading(true);
    tools.saveState("OrderReceipts", "search", JSON.stringify(search));
    dataService.post(
      `purchasehistorics/search`,
      {
        ...search,
        purchaseHistoric: {
          ...search.purchaseHistoric,
          providers: !search.purchaseHistoric.provider
            ? providers.map((p) => p.id)
            : [search.purchaseHistoric.provider],
        },
      },
      (datas) => {
        setOrderReceipts(
          datas.datas.map((d) => {
            return {
              ...d,
              preparations: d.preparations.map((prep) => {
                var catName = PREPARATION_CATEGORIES.find(
                  (p) => p.id == prep.preparation.category
                ).name;
                console.log(catName);
                return {
                  ...prep,
                  preparation: {
                    ...prep.preparation,
                    name: prep.preparation.ingredient.name + " " + catName,
                  },
                };
              }),
            };
          })
        );
        setNbResults(datas.count);
        setNbPages(Math.ceil(datas.count / 25));
      },
      setErrors,
      () => setIsLoading(false)
    );
  };

  return {
    state: {
      isLoading,
      errors,
      orderReceipts,
      search,
      productionSites,
      providers,
      currentOrderReceipt,
      pagination: {
        nbResults,
        nbPages,
      },
    },
    actions: {
      setIsLoading,
      setSearch,
      setOrderReceipts,
      searchOrderReceipts,
      setCurrentOrderReceipt,
    },
  };
};

export default useOrderReceiptList;
