import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useStore from "../../../../context/useStore";
import dataService from "../../../../helpers/dataService";
import Control from "../../../common/Controls/Control";
import DisplayDates from "../../../common/DisplayDates";
import Page from "../../../common/layout/Page";
import SaveBtn from "../../../common/SaveBtn";

const ProductionSiteDetail = (props) => {
  const [state, dispatch] = useStore();
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [productionSite, setProductionSite] = useState({
    name: "",
    productionDaysOff: [],
    packingDaysOff: [],
    allotmentDaysOff: [],
    deliveryDaysOff: [],
    nbWeeksByCycle: 6,
  });
  const itemId = props.match.params.id;
  const isEdit = itemId == "create" ? false : true;
  const constants = state.constants.items;

  useEffect(() => {
    if (isEdit) {
      getDatas();
    }
  }, []);

  const getDatas = () => {
    const onSuccess = (datas) => setProductionSite(datas);

    dataService.get(
      "productionsites/" + itemId,
      (datas) => setProductionSite(datas),
      (error) => setErrors(error),
      () => setIsLoading(false)
    );
  };

  const save = () => {
    const onError = (error) => {
      setErrors(error);
    };
    setIsLoading(true);
    if (isEdit) {
      const onSuccess = (datas) => {
        setProductionSite({
          ...productionSite,
          createdAt: datas.createdAt,
          updatedAt: datas.updatedAt,
        });
        toast.success("Cuisine enregistrée avec succès");
      };
      dataService.patch(
        "productionsites/" + itemId,
        productionSite,
        onSuccess,
        onError,
        () => setIsLoading(false)
      );
    } else {
      const onSuccess = (datas) => {
        toast.success("Cuisine créée avec succès");
        window.location.href = "/productionsites/" + datas.id;
      };

      dataService.post(
        "productionsites",
        productionSite,
        onSuccess,
        onError,
        () => setIsLoading(false)
      );
    }
  };

  const weekDays = [
    { id: 1, name: "Lundi" },
    { id: 2, name: "Mardi" },
    { id: 3, name: "Mercredi" },
    { id: 4, name: "Jeudi" },
    { id: 5, name: "Vendredi" },
    { id: 6, name: "Samedi" },
    { id: 7, name: "Dimanche" },
  ];

  return (
    <Page
      container="container"
      back="/productionsites"
      title={(isEdit ? "Modification" : "Détail") + " de la cuisine"}
      errors={errors}
    >
      <div className="custom-card">
        <div className="row mx-0">
          <div className="col-12 p-0">
            <Control
              label="Nom"
              name="name"
              type="text"
              value={productionSite.name}
              change={(e) =>
                setProductionSite({ ...productionSite, name: e.target.value })
              }
              error={errors}
              setErrors={setErrors}
              required={true}
            />
            <div className="alert alert-warning mb-0">
              <i className="fa fa-exclamation-triangle mr-2"></i> Attention,
              pour les jours off ne pas configurer plus de 3 jours consécutifs !
            </div>
            <Control
              label="Planning des jours off pour Production"
              name="productionDaysOff"
              type="checkBtnList"
              btnInline={true}
              value={productionSite.productionDaysOff}
              datas={weekDays}
              change={(e) =>
                setProductionSite({
                  ...productionSite,
                  productionDaysOff: e.target.value,
                })
              }
              error={errors}
              setErrors={setErrors}
              required={true}
            />
            <Control
              label="Planning des jours off pour Conditionnement"
              name="packingDaysOff"
              type="checkBtnList"
              btnInline={true}
              value={productionSite.packingDaysOff}
              datas={weekDays}
              change={(e) =>
                setProductionSite({
                  ...productionSite,
                  packingDaysOff: e.target.value,
                })
              }
              error={errors}
              setErrors={setErrors}
              required={true}
            />
            <Control
              label="Planning des jours off pour Allotissement"
              name="allotmentDaysOff"
              type="checkBtnList"
              btnInline={true}
              value={productionSite.allotmentDaysOff}
              datas={weekDays}
              change={(e) =>
                setProductionSite({
                  ...productionSite,
                  allotmentDaysOff: e.target.value,
                })
              }
              error={errors}
              setErrors={setErrors}
              required={true}
            />
            <Control
              label="Planning des jours off pour Livraison"
              name="deliveryDaysOff"
              type="checkBtnList"
              btnInline={true}
              value={productionSite.deliveryDaysOff}
              datas={weekDays}
              change={(e) =>
                setProductionSite({
                  ...productionSite,
                  deliveryDaysOff: e.target.value,
                })
              }
              error={errors}
              setErrors={setErrors}
              required={true}
            />
            <Control
              label="Nombre de semaines par cycle"
              type="number"
              name="nbWeeksByCycle"
              value={productionSite.nbWeeksByCycle}
              change={(e) =>
                setProductionSite({
                  ...productionSite,
                  nbWeeksByCycle: e.target.value,
                })
              }
              error={errors}
              setErrors={setErrors}
              required={true}
            />
          </div>
        </div>
      </div>
      <div
        className="d-flex justify-content-end w-100"
        data-priv="create_modify_production_sites"
      >
        <DisplayDates
          createdAt={productionSite.createdAt}
          updatedAt={productionSite.updatedAt}
        />
        <SaveBtn
          save={save}
          isSaving={isLoading}
          text="Enregistrer"
          type="primary"
        />
      </div>
    </Page>
  );
};

export default ProductionSiteDetail;
