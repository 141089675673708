import React from "react";
import { modalActions } from "../../../../context/actions/modalActions";
import { sidePanelActions } from "../../../../context/actions/sidePanelActions";
import useStore from "../../../../context/useStore";
import tools from "../../../../helpers/tools";
import useApiDatas from "../../../common/Hooks/useApiDatas";
import RecipeTemplate from "../../../print/RecipeTemplate/RecipeTemplate";
import AllergenList from "./AllergenList";
import IngredientsTable from "./IngredientsTable";
import RecipeDetail from "./RecipeDetail";

const RecipeList = (props) => {
  const [state, dispatch] = useStore();
  const technicalSheet = props.apiProps;

  console.log("apiProps", props.apiProps);

  const {
    consumerType,
    recipeDatas,
    hasReferent,
    recipes,
    getRecipes,
    multiplicator,
    specificities,
  } = props;

  var apiProps = useApiDatas({
    itemParams: {
      name: "",
    },
  });

  const { isInit } = apiProps;
  const modal_actions = modalActions(state, dispatch);
  const sidePanel_actions = sidePanelActions(state, dispatch);
  const { consumerTypes, textures, dishtypes, ingredients } =
    technicalSheet.otherDatas;
  const { ALLERGENS } = state.constants.items;

  const openRecipe = (texture) => {
    modal_actions.updateModal({
      isOpen: true,
      content: (
        <RecipeDetail
          technicalSheet={technicalSheet}
          texture={texture}
          consumerType={consumerType}
          recipeData={recipeDatas[texture.id]}
          getRecipes={getRecipes}
        />
      ),
    });
  };

  const printRecipes = (recipes) => {
    sidePanel_actions.updateSidePanel({
      isOpen: true,
      content: (
        <RecipeTemplate
          params={{
            recipes: recipes,
            consumerType,
            technicalSheet: props.apiProps.item,
            specificities: specificities,
          }}
        />
      ),
    });
  };

  const toggleVisible = (id) => {
    document.querySelector(".coltex" + id).classList.toggle("hidden");
  };

  const allergenList = (components) => {
    var hasAllergens = false;
    var allergensUniq = [];
    components.forEach((comp) => {
      var ing = ingredients.find((i) => i.id == comp.ingredient);
      if (ing.allergens.length) hasAllergens = true;
    });
    if (hasAllergens) {
      components.forEach((comp, compk) => {
        var ing = ingredients.find((i) => i.id == comp.ingredient);
        if (ing.allergens.length) {
          ing.allergens.forEach((al) => {
            if (!allergensUniq.includes(al)) allergensUniq.push(al);
          });
        }
      });
    }
    // else (
    //   <View>
    //     <Text style={{ ...styles.listItem }}>-aucun allergène-</Text>
    //   </View>
    //   );

    return allergensUniq.length ? (
      allergensUniq.map((al, alk) => (
        <div key={`allist${alk}`}>
          - {ALLERGENS.find((a) => a.id == al).name}
        </div>
      ))
    ) : (
      <div>-aucun allergène-</div>
    );
  };

  return (
    isInit && (
      <div>
        <button
          className="btn btn-default my-2"
          onClick={() =>
            printRecipes(
              textures.map((t) => ({
                texture: t,
                recipeData: recipeDatas[t.id],
                dishtypes,
                ingredients,
                multiplicator,
              }))
            )
          }
        >
          <i className="fa fa-print mr-2" />
          Imprimer toutes les recettes
        </button>
        {textures.map((t, tk) => {
          let recipeData = recipeDatas[t.id];
          if (!hasReferent && (!consumerType.isReferent || !t.isReferent))
            return false;
          return (
            <div
              className={"row mb-4 print-texture-" + tk}
              key={"rectype" + tk + consumerType.name}
              style={!recipeData.id ? { filter: "grayscale(1)" } : {}}
            >
              <div
                className="col-12 mb-2"
                onClick={() => toggleVisible(tk + consumerType.id)}
              >
                <h5
                  className="d-flex align-items-center justify-content-between pointer pt-0 px-2 mb-0 bg-white shadow-sm p-2"
                  style={{
                    // border: "1px solid",
                    // borderLeft: "4px solid",
                    borderRadius: 8,
                  }}
                >
                  Texture {t.name}
                  <div className="d-center">
                    {!recipeData.id && (
                      <span className="text-muted" style={{ fontSize: 12 }}>
                        {" "}
                        identique à la texture Normale
                      </span>
                    )}
                    <button
                      className="btn btn-default mx-2"
                      onClick={() =>
                        printRecipes([
                          {
                            texture: t,
                            recipeData,
                            dishtypes,
                            ingredients,
                            multiplicator,
                          },
                        ])
                      }
                    >
                      <i className="fa fa-print" />
                    </button>
                    <button
                      className={
                        "btn   " +
                        (recipeData.id ? "btn-secondary" : "btn-primary")
                      }
                      onClick={(e) => openRecipe(t)}
                    >
                      <i className="fa fa-edit mr-2" />
                      Modifier
                    </button>
                    <i className="fa fa-chevron-down ml-2" />
                  </div>
                </h5>
              </div>
              <div
                className={`w-100 overflow-hidden d-flex flex-column mx-3 shadow-sm rounded ${
                  tk == 0 ? "" : "hidden"
                } coltex${tk + consumerType.id}`}
              >
                <div className="row">
                  <div className="col-12 col-md-6 pr-md-0 border-right">
                    <ul className="list-group h-100 bg-white">
                      <li className="list-group-item border-0">
                        % de perte brut
                        <i className="fa fa-chevron-right mx-1" />
                        net :{" "}
                        <span className="text-primary">
                          {tools.round(recipeData.total1Dish.ratioBrutToCooked)}
                          %
                        </span>
                      </li>

                      <li className="list-group-item  border-0">
                        Poids total final cuit :{" "}
                        <span className="text-primary">
                          {tools.round(
                            recipeData.total1Dish.totalWeightNet * multiplicator
                          )}{" "}
                          GRAMMES
                        </span>
                      </li>
                      <li className="list-group-item  border-0">
                        Total de pièces :{" "}
                        <span className="text-primary">
                          {tools.round(
                            recipeData.total1Dish.totalPieces * multiplicator
                          )}
                        </span>
                      </li>
                      <li className="list-group-item  border-0">
                        % produits origine BIO (Grammes) :{" "}
                        <span className="text-primary">
                          {tools.round(recipeData.totalPercentBio.weight)}%
                        </span>
                      </li>
                      <li className="list-group-item  border-0">
                        % produits origine BIO (€) :{" "}
                        <span className="text-primary">
                          {tools.round(recipeData.totalPercentBio.cost)}%
                        </span>
                      </li>
                      <li className="list-group-item  border-0">
                        % produits Locaux (Grammes) :{" "}
                        <span className="text-primary">
                          {tools.round(recipeData.totalPercentLocal.weight)}%
                        </span>
                      </li>
                      <li className="list-group-item  border-0">
                        % produits Locaux (€) :{" "}
                        <span className="text-primary">
                          {tools.round(recipeData.totalPercentLocal.cost)}%
                        </span>
                      </li>
                      <li className="list-group-item  border-0">
                        Valeur nutri. (1 portion) :{" "}
                        <span className="text-primary">
                          {tools.roundInt(
                            recipeData.nutritionalValues?.perPortion
                          )}{" "}
                          kcal
                        </span>{" "}
                        <span className="text-black-50"> / </span>
                        <span className="text-primary">
                          {tools.roundInt(
                            recipeData.nutritionalValues?.per100g
                          )}{" "}
                          kcal/100g
                        </span>
                      </li>
                      <li className="list-group-item  border-0">
                        Coût total :{" "}
                        <span className="text-primary">
                          {tools.round(
                            recipeData.total1Dish.totalCost * multiplicator
                          )}
                          €
                        </span>
                      </li>
                      <li className="list-group-item  border-0">
                        Allergènes : {allergenList(recipeData.components)}
                      </li>
                    </ul>
                  </div>
                  <div className="col-12 col-md-6 pl-md-0">
                    <ul className="list-group h-100 bg-white">
                      <li className="list-group-item  border-0 pb-1">
                        <div className="bg-light pt-1 h-100">
                          <h6>
                            <i className="fa fa-carrot mr-2" />
                            Composants de la recette pour {multiplicator}{" "}
                            portion(s)
                          </h6>
                          <IngredientsTable
                            ingredients={ingredients}
                            recipeIngredients={recipeData.components}
                            multiplicator={multiplicator}
                          />
                        </div>
                      </li>
                      <li className="list-group-item  border-0">
                        <div className="bg-default rounded p-3 h-100">
                          <h6>
                            <i className="fa fa-clipboard-list mr-2" />
                            Méthodologie
                          </h6>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: recipeData.methodology,
                            }}
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="d-center">
                  <button
                    className={
                      "btn w-100 " +
                      (recipeData.id ? "btn-secondary" : "btn-primary")
                    }
                    onClick={(e) => openRecipe(t)}
                  >
                    {recipeData.id ? "Modifier" : "Créer"} la recette {t.name}
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    )
  );
};

export default RecipeList;
