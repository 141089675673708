import { DateTime } from "luxon";
import React, { useEffect, useMemo } from "react";
import tools from "../../../../helpers/tools";
import useSites from "../../../../hooks/useSites";
import useSpecificities from "../../../../hooks/useSpecificities";
import useTextures from "../../../../hooks/useTextures";
import ExportCSV from "../../../common/ExportCsv/ExportCsv";
import Page from "../../../common/layout/Page";
import TableNav from "../BillingGlobal/TableNav";
import NowTime from "../NowTime";
import Filters from "./Filters";
import SpecSummaryTotalTable from "./SpecSummaryTotalTable";
import useSpecificitiesSummary from "./useSpecificitiesSummary";

function debounce(fn, ms) {
  let timer;
  return (_) => {
    clearTimeout(timer);
    timer = setTimeout((_) => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

const SpecificitiesSummary = () => {
  const { isLoading, specSummary, filters } = useSpecificitiesSummary();
  const { specificities } = useSpecificities();
  const { textures } = useTextures();

  const filteredSpecificities = useMemo(
    () => specificities.filter((spec) => !filters.specificities.includes(spec.id)),
    [filters.specificities, specificities]
  );
  const filteredTextures = useMemo(
    () => textures.filter((tex) => filters.textures.includes(tex.id)),
    [textures, filters.textures]
  );

  const siteHasData = (site) => {
    var hasValue = false;
    Object.keys(site.specificities).forEach((specK) => {
      if (!filteredSpecificities.find((s) => s.id == specK)) return;
      let specificity = site.specificities[specK];
      Object.keys(specificity).forEach((texK) => {
        if (!filteredTextures.find((t) => t.id == texK)) return;
        let texture = specificity[texK];
        if (texture > 0) hasValue = true;
      });
    });
    return hasValue;
  };
  const filteredSites = specSummary.filter((site) => {
    return !filters.sites.includes(site.site.id) && siteHasData(site);
  });

  useEffect(() => {
    var scrollDiv = document.querySelector("#table-scroll-anchor");
    const debouncedHandleScroll = debounce(function handleScroll() {
      var scrollDiv = document.querySelector("#table-scroll-anchor");
      var stickyDiv = document.querySelector("#sticky-column");
      if (scrollDiv && stickyDiv) {
        stickyDiv.style.transform = "translateX(" + scrollDiv.scrollLeft + "px)";
      }
    }, 10);
    if (scrollDiv) {
      scrollDiv.addEventListener("scroll", debouncedHandleScroll);
    }
    return () => {
      var scrollDiv = document.querySelector("#table-scroll-anchor");
      if (scrollDiv) {
        scrollDiv.removeEventListener("scroll", debouncedHandleScroll);
      }
    };
  });

  const csvDatas = filteredSites.map((site) => {
    const specificitiesObj = {};
    filteredSpecificities.forEach((spec) => {
      filteredTextures.forEach((tex) => {
        let qty = site.specificities[spec.id] || 0;
        if (qty) qty = qty[tex.id] || 0;
        specificitiesObj[`${spec.name} - ${tex.name}`] = qty;
      });
    });

    return {
      site: site.site.name,
      ...specificitiesObj,
    };
  });

  console.log("filteredSites", filteredSites);

  return (
    <Page
      title="Récapitulatif des régimes"
      titleMargin="mb-3"
      isLoading={isLoading}
      printOrientation="landscape"
      container="container-fluid"
      style={{ maxWidth: "100%" }}
    >
      <NowTime />
      <Filters />
      {csvDatas.length > 0 && (
        <ExportCSV
          datas={csvDatas}
          fields={Object.keys(csvDatas[0]).map((ob, key) => {
            return {
              name: ob,
              path: ob,
            };
          })}
          title={`Export Récap Régimes du ${DateTime.fromISO(filters.beginAt).toFormat(
            "dd-MM-yyyy"
          )}`}
        />
      )}
      <div
        id={`table-scroll-anchor`}
        style={{
          overflowX: "auto",
          scrollBehavior: "smooth",
          width: "100%",
          position: "relative",
        }}
      >
        <table className="table table-bordered table-sm table-small-padding  bg-white shadow-sm hover-table ">
          <thead>
            <tr>
              <th style={{ minWidth: 200, maxWidth: 200, width: 200, height: 69 }}>
                Clients/Spécificités
              </th>

              {filteredSpecificities.map((specificity) => {
                return (
                  <th
                    className="text-center"
                    style={{ minWidth: 200, maxWidth: 200 }}
                    key={`thcol${specificity.id}`}
                  >
                    {specificity.name}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {filteredSites.map((site) => (
              <tr key={`cl${site.site.name}`} style={{ height: 67 }}>
                <td>{site.site.name}</td>
                {filteredSpecificities.map((specificity) => {
                  return (
                    <td key={`cl${site.site.name}sp${specificity.id}`}>
                      <table className="table-borderless w-100">
                        <thead>
                          <tr>
                            {filteredTextures.map((texture, tk) => (
                              <th
                                className={`${
                                  tk < filteredTextures.length - 1 && "border-right"
                                } text-black-50 text-center`}
                              >
                                {tools.getFirstLetters(texture.name, 3)}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {filteredTextures.map((texture, tk) => {
                              let qty = site.specificities[specificity.id] || 0;
                              let qtyClassName = "font-weight-lighter text-black-50";
                              if (qty) {
                                qty = qty[texture.id] || 0;
                                if (qty) qtyClassName = "font-weight-bolder text-dark";
                              }
                              return (
                                <td
                                  className={`text-center ${
                                    tk < filteredTextures.length - 1 && "border-right"
                                  } ${qtyClassName}`}
                                >
                                  {qty}
                                </td>
                              );
                            })}
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>

        <table
          className="table table-bordered table-sm table-small-padding bg-white  "
          id="sticky-column"
          style={{
            position: "absolute",
            width: 200,
            top: 0,
            left: 0,
            boxShadow: "7px 0px 18px -11px black",
            transition: "all 200ms linear 0s",
          }}
        >
          <thead>
            <tr style={{ height: 69.59 }}>
              <th>Clients/Spécificités</th>
            </tr>
          </thead>
          <tbody>
            {filteredSites.map((site) => (
              <tr key={`cl${site.site.name}`} style={{ height: 67 }}>
                <td>{site.site.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <SpecSummaryTotalTable
          sites={filteredSites}
          specificities={filteredSpecificities}
          textures={filteredTextures}
        />
      </div>
      <TableNav scrollTable={(dir, end) => tools.scrollTable(dir, end, ``)} />
    </Page>
  );
};

export default SpecificitiesSummary;
