import { atom, useAtom } from "jotai";
import { useMemo } from "react";
import useStore from "../../../../context/useStore";
import tools from "../../../../helpers/tools";
import useClients from "../../../../hooks/useClients";
import useProductionSites from "../../../../hooks/useProductionSites";
import useSites from "../../../../hooks/useSites";

const filtersAtom = atom({
  lastOrderAt: "",
});

const useAllSitesList = () => {
  const [state] = useStore();
  const { ALLOTING_MODES, CONDITIONING_MODES } = state.constants.items;
  const { isLoadingSites, orderedSitesSearchAlpha, searchSites } = useSites(false);
  const { clients, getClients } = useClients(true);
  const { productionSites, getProductionSites } = useProductionSites(true);
  const [filters, setFilters] = useAtom(filtersAtom);
  const weekDays = tools.getWeekdays();

  const formatedSites = useMemo(
    () =>
      orderedSitesSearchAlpha.map((s) => ({
        ...s,
        client: tools.findIn(clients, s.client, "id").name,
        emails: s.emails,
        lastOrderAt: s.lastOrderAt ? tools.formatDate(s.lastOrderAt) : "inconnue",
        productionSite: tools.findIn(productionSites, s.productionSite, "id").name,
        address: `${s.address} ${s.postalCode} ${s.city}`,
        createdAt: tools.formatDate(s.createdAt),
        updatedAt: tools.formatDate(s.updatedAt),
        isArchived: s.isArchived ? "OUI" : "NON",
        activeDays: s.activeDays
          .map((ac) => tools.findIn(weekDays, ac, "id").name.slice(0, 2))
          .join(","),
        allotingMode: tools.findIn(ALLOTING_MODES, s.allotingMode, "id").name,
        conditioningMode: tools.findIn(CONDITIONING_MODES, s.conditioningMode, "id").name,
      })),
    [orderedSitesSearchAlpha, clients, productionSites]
  );

  const actions = {
    onChangeFilters: (e) => {
      const { name, value } = e.target;
      setFilters({ ...filters, [name]: value });
    },
    onResetFilters: () => {
      setFilters({ lastOrderAt: "" });
    },
    searchSites,
    getProductionSites,
    getClients,
  };

  return {
    formatedSites,
    isLoadingSites,
    filters,
    actions,
  };
};

export default useAllSitesList;
