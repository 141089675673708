/*
"create_modify_client" //création modification client
"create_modify_client_user" //création modif utilisateur d'un client
"create_modify_site" //création modif site
"create_modify_consumption_profile" // création modif profil conso
"create_modify_contract" //création modif contrat
"create_modify_menuref" //création modif menu ref
"create_modify_menusite" //création modif menu site
"duplicate_cycle" //dupliquer les cycles
"create_modify_technical_sheet" //création modif fiches techniques
"create_modify_ingredients" //création modif ingrédients
"create_modify_provider" //création modif fournisseurs
"create_modify_dish_types" //création modif types de plats
"create_modify_textures" //création modif textures
"create_modify_specificities" //création modif spécificités
"create_modify_slotcodes" //création modif plan ref alim
"create_modify_consumer_types" //création modif type de conso
"create_modify_production_sites" //création modif cuisines
"view_billing_summary_costs" //voir les euros du recap d'activité
"view_billing_global_summary" //voir le recap CA
"view_purchase_summary" //voir le recap achats
"view_purchase_provider_summary" //voir le recap achat fournisseur
"view_purchase_ingredient_summary" //voir le recap achat ingredient
"create_modify_managers" //création modif managers
*/

const roleConfig = {
  SUPER_ADMIN: [],
  ADMIN: [],
  SUPERVISOR: [
    "create_modify_client", //création modification client
    "create_modify_managers", //création modif managers
  ],

  PURCHASE: [
    "create_modify_client", //création modification client
    "create_modify_client_user", //création modif utilisateur d'un client
    "create_modify_site", //création modif site
    "create_modify_consumption_profile", // création modif profil conso
    "create_modify_contract", //création modif contrat
    "create_modify_menuref", //création modif menu ref
    "create_modify_menusite", //création modif menu site
    "duplicate_cycle", //dupliquer les cycles
    "create_modify_technical_sheet", //création modif fiches techniques
    "create_modify_ingredients", //création modif ingrédients
    "create_modify_provider", //création modif fournisseurs
    "create_modify_dish_types", //création modif types de plats
    "create_modify_textures", //création modif textures
    "create_modify_specificities", //création modif spécificités
    "create_modify_slotcodes", //création modif plan ref alim
    "create_modify_consumer_types", //création modif type de conso
    "create_modify_production_sites", //création modif cuisines
    "view_billing_summary_costs", //voir les euros du recap d'activité
    "view_billing_global_summary", //voir le recap CA
    "view_purchase_provider_summary", //voir le recap achat fournisseur
    "view_purchase_ingredient_summary", //voir le recap achat ingredient
    "create_modify_managers", //création modif managers
  ],
  PRODUCTION: [
    "create_modify_client", //création modification client
    "create_modify_site", //création modif site
    "create_modify_consumption_profile", // création modif profil conso
    "create_modify_contract", //création modif contrat
    "create_modify_menuref", //création modif menu ref
    "create_modify_menusite", //création modif menu site
    "duplicate_cycle", //dupliquer les cycles
    "create_modify_technical_sheet", //création modif fiches techniques
    "create_modify_ingredients", //création modif ingrédients
    "create_modify_provider", //création modif fournisseurs
    "create_modify_dish_types", //création modif types de plats
    "create_modify_textures", //création modif textures
    "create_modify_specificities", //création modif spécificités
    "create_modify_slotcodes", //création modif plan ref alim
    "create_modify_consumer_types", //création modif type de conso
    "create_modify_production_sites", //création modif cuisines
    "view_billing_summary_costs", //voir les euros du recap d'activité
    "view_billing_global_summary", //voir le recap CA
    "view_purchase_summary", //voir le recap achats
    "view_purchase_provider_summary", //voir le recap achat fournisseur
    "view_purchase_ingredient_summary", //voir le recap achat ingredient
    "create_modify_managers", //création modif managers
  ],
};

export default roleConfig;
