import React, { useState, useEffect } from "react";
import useStore from "../../../context/useStore";
import roleConfig from "./roleConfig";

const RoleStyle = (props) => {
  const [state, dispatch] = useStore();
  const [hiddenPriv, setHiddenPriv] = useState([]);
  const [privCss, setPrivCss] = useState("");

  useEffect(() => {
    if (state.auth.isAuthenticated) getConfig();
  }, [state.auth.isAuthenticated]);

  function getConfig() {
    var userRole = state.auth.user.role;
    if (state.auth.user && state.auth.user.role != "SUPER_ADMIN") {
      generateCss();
    }
  }

  function generateCss() {
    var css = "";
    if (roleConfig[state.auth.user.role]) {
      roleConfig[state.auth.user.role].map((h) => {
        css += "[data-priv='" + h + "'] { display: none !important;}";
      });
      setPrivCss(css);
    }
  }

  return (
    <style
      dangerouslySetInnerHTML={{
        __html: `
          ${privCss}
      `,
      }}
    />
  );
};

export default RoleStyle;
