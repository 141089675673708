import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useStore from "../../../../context/useStore";
import dataService from "../../../../helpers/dataService";
import tools from "../../../../helpers/tools";
import purchaseTools from "./purchaseTools";
import useProductionSites from "../../../../hooks/useProductionSites";
import Page from "../../../common/layout/Page";
import TableNav from "../BillingGlobal/TableNav";
import NowTime from "../NowTime";
import TwoPartColumn from "../Purchase/TwoPartColumn";
import Filters from "./Filters";
import { sidePanelActions } from "../../../../context/actions/sidePanelActions";
import PurchaseDetail from "./PurchaseDetail";
import useProviders from "../../../../hooks/useProviders";

const PurchaseProviderSummary = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [state, dispatch] = useStore();
  const mActions = sidePanelActions(state, dispatch);
  const constants = state.constants.items;
  const { PREPARATION_CATEGORIES } = constants;
  var searchState = tools.getState("SummaryProvider", null);
  const [searchDone, setSearchDone] = useState(true);
  const { productionSites, setProductionSites } = useProductionSites();
  const [datas, setDatas] = useState([]);
  const [search, setSearch] = useState(
    searchState
      ? JSON.parse(searchState.search)
      : {
          providers: [],
          beginAt: DateTime.local().toISO(),
          endAt: DateTime.local().toISO(),
          timeScale: "DAY",
        }
  );
  const { calcTotal, calcTotalBySite } = purchaseTools;
  const { providers } = useProviders();

  const getDatas = () => {
    if (checkPeriod()) {
      setSearchDone(false);

      tools.saveState("SummaryProvider", "search", JSON.stringify(search));
      setIsLoading(true);
      dataService.post(
        `summaries/purchases/providers`,
        search,
        setDatas,
        (err) => {},
        () => {
          setIsLoading(false);
          setSearchDone(true);
        }
      );
    }
  };

  const openDetail = (purchase) => {
    mActions.updateSidePanel({
      isOpen: true,
      content: (
        <PurchaseDetail
          purchase={{
            ...purchase,
            productionSite: {
              ...purchase.productionSite,
              productionSite: tools.findInConstant(
                productionSites,
                purchase.productionSite.productionSite
              ),
            },
          }}
          search={search}
        />
      ),
    });
  };

  useEffect(() => {
    setDatas([]);
  }, [search]);

  const scrollTable = (backward, toEnd, id) => {
    var container = document.querySelector(`#table-scroll-anchor${id}`);
    if (backward) {
      container.scrollLeft -= toEnd ? 100000 : 500;
    } else {
      container.scrollLeft += toEnd ? 100000 : 500;
    }
  };

  const getPeriodDateFormat = () => {
    switch (search.timeScale) {
      case "DAY":
        return "EEEE dd LLLL yy";
      case "WEEK":
        return "dd LLLL yyyy";
      case "MONTH":
        return "LLLL yyyy";
      case "YEAR":
        return "yyyy";
      default:
        return "dd MM yyyy";
    }
  };

  const checkPeriod = () => {
    var period = "";
    switch (search.timeScale) {
      case "DAY":
        period = DateTime.fromISO(search.beginAt)
          .diff(DateTime.fromISO(search.endAt), "days")
          .toObject();
        break;
      case "WEEK":
        period = DateTime.fromISO(search.beginAt)
          .diff(DateTime.fromISO(search.endAt), "weeks")
          .toObject();
        break;
      case "MONTH":
        period = DateTime.fromISO(search.beginAt)
          .diff(DateTime.fromISO(search.endAt), "months")
          .toObject();
        break;
      case "YEAR":
        period = DateTime.fromISO(search.beginAt)
          .diff(DateTime.fromISO(search.endAt), "years")
          .toObject();
        break;
    }
    var diffValue = Object.values(period)[0];
    if (diffValue > 0) {
      toast.warning("La date de fin ne peut être antérieure à la date de début");
      return false;
    }
    if (diffValue > 0) {
      toast.warning("La période n'a pas une durée suffisante pour l'échelon sélectionné");
      return false;
    }
    return true;
  };

  return (
    <Page
      title="Récapitulatif des achats fournisseurs"
      titleMargin="mb-3"
      isLoading={isLoading}
      printOrientation="landscape"
      container="container-fluid"
      style={{ maxWidth: "100%" }}
    >
      <NowTime />
      <Filters search={search} setSearch={setSearch} getDatas={getDatas} isLoading={isLoading} />
      {!datas.length
        ? null
        : datas.map((provider) => {
            const providerRef = tools.findIn(providers, provider.provider, "id");
            return (
              <>
                <h4>{tools.findIn(providers, provider.provider, "id").name}</h4>
                <div
                  id={`table-scroll-anchor${provider.provider}`}
                  style={{
                    overflowX: "auto",
                    scrollBehavior: "smooth",
                    width: "100%",
                  }}
                >
                  <table className="table table-bordered table-sm table-small-padding  bg-white shadow-sm hover-table ">
                    <thead>
                      <tr>
                        <th style={{ minWidth: 170 }}>Date</th>
                        {productionSites.map((ps) => (
                          <th
                            style={{ minWidth: 170 }}
                            className="text-center"
                            key={`thps${ps.id}`}
                          >
                            {ps.name}
                          </th>
                        ))}
                        <th style={{ minWidth: 170 }} className="text-center bg-default">
                          TOTAL
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-right">
                          <i>PRIX</i>
                        </td>
                        {productionSites.map((ps, psk) => (
                          <td>
                            <TwoPartColumn
                              spaceBetween
                              col1Text="N-1"
                              col1ClassName="font-italic text-black-50"
                              col2Text="N"
                              key={`thcol${ps.id}`}
                            />
                          </td>
                        ))}
                        <td className="bg-default">
                          <TwoPartColumn
                            spaceBetween
                            col1Text="N-1"
                            col1ClassName="font-italic text-black-50"
                            col2Text="N"
                          />
                        </td>
                      </tr>
                      {provider.data.map((data, datak) => {
                        var totals = calcTotal(data);

                        return (
                          <tr key={`row${datak}`}>
                            <td>
                              {DateTime.fromISO(data.date)
                                .setLocale("FR")
                                .toFormat(getPeriodDateFormat())}
                            </td>
                            {productionSites.map((ps, psk) => {
                              let entryPs = data.productionSites.find(
                                (p) => p.productionSite == ps.id
                              );
                              var prevPrice = entryPs ? entryPs.previousPrice : "x";
                              var price = entryPs ? entryPs.price : "x";
                              var evolPct = entryPs ? tools.getPct(price - prevPrice, price) : null;

                              return (
                                <td>
                                  <TwoPartColumn
                                    spaceBetween
                                    col1Text={
                                      <>
                                        {tools.round(prevPrice)}
                                        {prevPrice != "x" && "€"}
                                      </>
                                    }
                                    col2Text={
                                      <>
                                        <span
                                          className="hover-underline cursor-pointer"
                                          onClick={() =>
                                            openDetail({
                                              date: data.date,
                                              productionSite: entryPs,
                                              provider: providerRef,
                                            })
                                          }
                                        >
                                          {tools.round(price)}
                                        </span>
                                        {price != "x" && "€"}
                                        {evolPct && evolPct != "" && (
                                          <sup
                                            className={`${
                                              evolPct > 0 ? "text-danger" : "text-success"
                                            }`}
                                          >
                                            {evolPct > 0 ? "+" : ""}
                                            {tools.round(evolPct)}%
                                          </sup>
                                        )}
                                      </>
                                    }
                                    col1ClassName="font-italic text-black-50"
                                    col2ClassName="font-weight-bold"
                                  />
                                </td>
                              );
                            })}
                            <td className="bg-default">
                              <TwoPartColumn
                                spaceBetween
                                col1Text={<>{tools.round(totals.previousTotal)}€</>}
                                col2Text={
                                  <>
                                    {tools.round(totals.total)}€
                                    {totals.evolPct != "" && (
                                      <sup
                                        className={
                                          totals.evolPct > 0 ? "text-danger" : "text-success"
                                        }
                                      >
                                        {totals.evolPct > 0 ? "+" : ""}
                                        {tools.round(totals.evolPct)}%
                                      </sup>
                                    )}
                                  </>
                                }
                                col1ClassName="font-italic text-black-50"
                                col2ClassName="font-weight-bold"
                              />
                            </td>
                          </tr>
                        );
                      })}
                      <tr className="bg-default">
                        <td className="font-weight-bold">TOTAL</td>
                        {productionSites.map((ps, psk) => {
                          const totalForSite = calcTotalBySite(datas, ps.id);

                          return (
                            <td>
                              <TwoPartColumn
                                spaceBetween
                                col1Text={<>{tools.round(totalForSite.previousTotal)}€ </>}
                                col1ClassName="font-italic text-black-50"
                                col2Text={
                                  <>
                                    {tools.round(totalForSite.total)}€
                                    {totalForSite.evolPct != "" && (
                                      <sup
                                        className={
                                          totalForSite.evolPct > 0 ? "text-danger" : "text-success"
                                        }
                                      >
                                        {totalForSite.evolPct > 0 ? "+" : ""}
                                        {tools.round(totalForSite.evolPct)}%
                                      </sup>
                                    )}
                                  </>
                                }
                                col2ClassName="font-weight-bold"
                                key={`thcoltot${ps.id}`}
                              />
                            </td>
                          );
                        })}
                        <td>
                          {(() => {
                            let totalForSite = calcTotalBySite(datas);
                            return (
                              <TwoPartColumn
                                spaceBetween
                                col1Text={<>{tools.round(totalForSite.previousTotal)}€</>}
                                col1ClassName="font-italic text-black-50"
                                col2Text={
                                  <>
                                    {tools.round(totalForSite.total)}€
                                    {totalForSite.evolPct != "" && (
                                      <sup
                                        className={
                                          totalForSite.evolPct > 0 ? "text-danger" : "text-success"
                                        }
                                      >
                                        {totalForSite.evolPct > 0 ? "+" : ""}
                                        {tools.round(totalForSite.evolPct)}%
                                      </sup>
                                    )}
                                  </>
                                }
                                col2ClassName="font-weight-bold"
                              />
                            );
                          })()}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <TableNav scrollTable={(dir, end) => scrollTable(dir, end, provider.provider)} />
              </>
            );
          })}
    </Page>
  );
};

export default PurchaseProviderSummary;
