import { DateTime } from "luxon";
import React, { useState } from "react";
import { toast } from "react-toastify";
import dataService from "../../../../helpers/dataService";
import tools from "../../../../helpers/tools";
import ExportCSV from "../../../common/ExportCsv/ExportCsv";
import Page from "../../../common/layout/Page";
import NowTime from "../NowTime";
import Filters from "./Filters";
import TableNav from "./TableNav";

const BillingGlobalSummary = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [datas, setDatas] = useState({});
  const [searchDone, setSearchDone] = useState(true);

  var searchState = tools.getState("BillingGlobalSummary", null);
  const [search, setSearch] = useState(
    searchState
      ? JSON.parse(searchState.search)
      : {
          productionSites: [],
          timeScale: "MONTH",
          beginAt: DateTime.local().startOf("month").toISO(),
          endAt: DateTime.local().endOf("month").toISO(),
        }
  );

  const getDatas = () => {
    if (checkPeriod()) {
      setSearchDone(false);

      tools.saveState("BillingGlobalSummary", "search", JSON.stringify(search));
      setIsLoading(true);
      dataService.post(
        `summaries/turnover`,
        search,
        (datas) => {
          setDatas(formatData(datas));
        },
        (err) => {},
        () => {
          setIsLoading(false);
          setSearchDone(true);
        }
      );
    }
  };

  const formatData = (data) => {
    var beginAt = search.beginAt;
    var periods = [];
    var diff = Math.abs(getPeriodDiff());
    console.log("diff", diff);
    for (var i = 0; i <= diff; i++) {
      switch (search.timeScale) {
        case "DAY":
          beginAt = DateTime.fromISO(search.beginAt).plus({ days: i }).toISO();
          periods.push(beginAt);
          break;
        case "WEEK":
          beginAt = DateTime.fromISO(search.beginAt).plus({ weeks: i }).toISO();
          periods.push(beginAt);
          break;
        case "MONTH":
          beginAt = DateTime.fromISO(search.beginAt).plus({ months: i }).toISO();
          periods.push(beginAt);
          break;
        case "YEAR":
          beginAt = DateTime.fromISO(search.beginAt).plus({ years: i }).toISO();
          periods.push(beginAt);
          break;
      }
    }
    console.log("periods", periods);

    let newData = {
      period: {
        ...data.period,
        slots: data.period.slots.map((slot) => {
          return {
            ...slot,
            stats: periods.map((p) => {
              var existingData = slot.stats.find((s) => tools.areDatesEqual(s.timeAt, p));
              return (
                existingData || {
                  fixFees: 0,
                  productPrice: 0,
                  productQty: 0,
                  timeAt: p,
                  total: 0,
                }
              );
            }),
            clients: slot.clients
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((client) => {
                return {
                  ...client,
                  stats: periods.map((p) => {
                    var existingData = client.stats.find((s) => tools.areDatesEqual(s.timeAt, p));
                    console.log("existingData", existingData);
                    return (
                      existingData || {
                        fixFees: 0,
                        productPrice: 0,
                        productQty: 0,
                        timeAt: p,
                        total: 0,
                      }
                    );
                  }),
                };
              }),
          };
        }),
      },
      previousPeriod: {
        ...data.previousPeriod,
        slots: [
          ...data.previousPeriod.slots.map((s) => {
            return {
              ...s,
              clients: s.clients.sort((a, b) => (a.name > b.name ? 1 : -1)),
            };
          }),
        ],
      },
    };

    console.log("newData", newData);
    return newData;
  };

  const getPeriodDateFormat = () => {
    switch (search.timeScale) {
      case "DAY":
        return "EEEE dd LLLL yy";
      case "WEEK":
        return "dd LLLL yyyy";
      case "MONTH":
        return "LLLL yyyy";
      case "YEAR":
        return "yyyy";
    }
  };

  const getPeriodDiff = () => {
    var period = "";
    switch (search.timeScale) {
      case "DAY":
        period = DateTime.fromISO(search.beginAt)
          .diff(DateTime.fromISO(search.endAt), "days")
          .toObject();
        break;
      case "WEEK":
        period = DateTime.fromISO(search.beginAt)
          .diff(DateTime.fromISO(search.endAt), "weeks")
          .toObject();
        break;
      case "MONTH":
        period = DateTime.fromISO(search.beginAt)
          .diff(DateTime.fromISO(search.endAt), "months")
          .toObject();
        break;
      case "YEAR":
        period = DateTime.fromISO(search.beginAt)
          .diff(DateTime.fromISO(search.endAt), "years")
          .toObject();
        break;
    }
    return Object.values(period)[0];
  };
  const checkPeriod = () => {
    var diffValue = getPeriodDiff();
    console.log("diffValue", diffValue);
    if (diffValue > 0) {
      toast.warning("La date de fin ne peut être antérieure à la date de début");
      return false;
    }
    if (diffValue > 0) {
      toast.warning("La période n'a pas une durée suffisante pour l'échelon sélectionné");
      return false;
    }
    return true;
  };

  const getStatsBlock = (stats, className = "", cuisineName = "") => {
    return stats.map((tc) => {
      return (
        <td style={{ fontSize: 14 }} title={cuisineName}>
          <div className={`d-flex flex-row justify-content-between ${className}`}>
            <div className="flex-fill text-center w-25">{tc.productQty}</div>
            <div className="flex-fill text-center w-25">{tools.round(tc.productPrice)} €</div>
            <div className="flex-fill text-center w-25">{tools.round(tc.fixFees)} €</div>
            <div className="flex-fill text-center w-25">{tools.round(tc.total)} €</div>
          </div>
        </td>
      );
    });
  };

  const reduceTotal = (arr, entry, key) => {
    var fArr = [];
    arr.forEach((ar) => {
      var tarr = ar.stats.filter((s, sk) => sk == key);
      if (tarr.length) {
        fArr = [...fArr, ...tarr];
      }
    });
    return tools.round(
      fArr.reduce(
        (a, b) => {
          return {
            [entry]: a[entry] + b[entry],
          };
        },
        { [entry]: 0 }
      )[entry]
    );
  };

  const getPreviousPeriodCuisineTotal = (cuisineId) => {
    var pre = datas.previousPeriod.slots.find((cuisine) => cuisine.id == cuisineId);
    return pre ? pre.total : 0;
  };
  const getPreviousPeriodClientTotal = (cuisineId, clientId) => {
    var pre = datas.previousPeriod.slots.find((cuisine) => cuisine.id == cuisineId);
    return pre ? pre.clients.find((c) => c.id == clientId).total : 0;
  };

  const scrollTable = (backward, toEnd) => {
    var container = document.querySelector("#table-scroll-anchor");
    if (backward) {
      container.scrollLeft -= toEnd ? 100000 : 500;
    } else {
      container.scrollLeft += toEnd ? 100000 : 500;
    }
  };

  var timeChunks = [];
  var periodDatas = [];
  var previousPeriodDatas = [];
  var caDelta = 0;

  if (datas.period?.slots.length) {
    timeChunks = datas.period.slots[0].stats;
    periodDatas = datas.period;
    previousPeriodDatas = datas.previousPeriod;
    caDelta = Math.abs(previousPeriodDatas.total - periodDatas.total) / previousPeriodDatas.total;
    caDelta = previousPeriodDatas.total > periodDatas.total ? -caDelta : caDelta;
    caDelta = tools.round(caDelta * 100);
  }

  const fixedTdStyle = {
    // position: "static",
    // background: "white",
    // width: 200,
    // overflow: "hidden",
    // whiteSpace: "nowrap",
    // textOverflow: "ellipsis",
    // boxShadow: "0 0.5rem 1rem rgb(0 0 0 / 15%)",
  };

  const csvDatas = [];
  if (periodDatas.slots) {
    periodDatas.slots.forEach((cuisine) => {
      cuisine.clients.forEach((client) => {
        csvDatas.push({
          code: client.code,
          letter: client.name[0],
          client: client.name,
          totalN: tools.round(client.total),
          totalN1: tools.round(getPreviousPeriodClientTotal(cuisine.id, client.id)),
        });
      });
    });
  }

  return (
    <Page
      title="Chiffre d'affaires"
      titleMargin="mb-3"
      isLoading={isLoading}
      printOrientation="landscape"
      container="container-fluid"
      style={{ maxWidth: "100%" }}
    >
      <NowTime />
      <Filters setSearch={setSearch} search={search} getDatas={getDatas} isLoading={isLoading} />
      {!periodDatas.slots ? (
        searchDone ? (
          <p>Aucune donnée trouvée</p>
        ) : null
      ) : (
        <>
          <ExportCSV
            datas={csvDatas}
            fields={[
              { name: "Code", path: "code" },
              { name: "Lettre", path: "letter" },
              { name: "Client", path: "client" },
              {
                name: `Total période du ${DateTime.fromISO(search.beginAt).toFormat(
                  "dd/MM"
                )} au ${DateTime.fromISO(search.endAt).toFormat("dd/MM")}`,
                path: "totalN",
              },
              { name: "Total période N-1", path: "totalN1" },
            ]}
          />
          <div
            id="table-scroll-anchor"
            style={{
              width: "100%",
              overflowX: "scroll",
              scrollBehavior: "smooth",
            }}
            className="mt-3"
          >
            <table
              className="no-padding-table bg-white table-bordered border-0 w-100 hover-table"
              style={{ minWidth: 900 }}
            >
              <thead>
                <tr>
                  <th className="border-0" style={{ background: "#f1f1f1", minWidth: 200 }}></th>
                  {timeChunks.map((time) => (
                    <th
                      className="text-center text-primary"
                      style={{
                        minWidth: 420,
                      }}
                    >
                      {DateTime.fromISO(time.timeAt)
                        .setLocale("FR")
                        .toFormat(getPeriodDateFormat())}
                    </th>
                  ))}
                  <th
                    className="text-center bg-warning"
                    style={{
                      width: 220,
                      minWidth: 220,
                    }}
                  >
                    Total période N
                  </th>
                  <th
                    className="text-center bg-default"
                    style={{
                      width: 220,
                      minWidth: 220,
                    }}
                  >
                    Période N-1
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td className="border-0" style={{ background: "#f1f1f1" }}></td>
                  {timeChunks.map((hts) => (
                    <td style={{ fontSize: 14 }}>
                      <div className="d-flex flex-row justify-content-between">
                        <div className="flex-fill text-primary text-center font-weight-bold w-25">
                          Qté
                        </div>
                        <div className="flex-fill text-primary text-center font-weight-bold w-25">
                          Prix
                        </div>
                        <div className="flex-fill text-primary text-center font-weight-bold w-25">
                          F.fixes
                        </div>
                        <div className="flex-fill text-primary text-center font-weight-bold w-25">
                          Total
                        </div>
                      </div>
                    </td>
                  ))}
                  <td className="bg-warning"></td>
                  <td className="bg-default"></td>
                </tr>
                {periodDatas.slots.map((cuisine) => {
                  let cuisineName = cuisine.name;
                  return (
                    <>
                      <tr className="bg-default">
                        <td
                          className="font-weight-bold text-secondary"
                          style={{ fontSize: 14, ...fixedTdStyle }}
                        >
                          {cuisine.name}
                        </td>
                        {getStatsBlock(
                          cuisine.stats,
                          "text-secondary font-weight-bold",
                          cuisineName
                        )}
                        <td
                          title={cuisineName}
                          className="text-center text-secondary font-weight-bold bg-warning"
                          style={{ fontSize: 12 }}
                        >
                          {tools.round(cuisine.total)} €
                        </td>
                        <td
                          title={cuisineName}
                          className="text-center text-secondary font-weight-bold bg-default"
                          style={{ fontSize: 12 }}
                        >
                          {tools.round(getPreviousPeriodCuisineTotal(cuisine.id))} €
                        </td>
                      </tr>
                      {cuisine.clients.map((client) => {
                        let clientName = client.name;
                        return (
                          <tr>
                            <td style={{ fontSize: 14, ...fixedTdStyle }}>{client.name}</td>
                            {getStatsBlock(client.stats, "", clientName)}
                            <td
                              title={clientName}
                              className="text-center bg-warning"
                              style={{ fontSize: 12 }}
                            >
                              {tools.round(client.total)} €
                            </td>
                            <td
                              title={clientName}
                              className="text-center bg-default"
                              style={{ fontSize: 12 }}
                            >
                              {tools.round(getPreviousPeriodClientTotal(cuisine.id, client.id))} €
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  );
                })}
                <tr className=" bg-dark ">
                  <td
                    className="font-weight-bold text-white"
                    style={{
                      fontSize: 12,
                      ...fixedTdStyle,
                      background: "black",
                    }}
                  >
                    TOTAL
                  </td>

                  {getStatsBlock(
                    timeChunks.map((ts, tsk) => {
                      return {
                        productQty: reduceTotal(periodDatas.slots, "productQty", tsk),
                        productPrice: reduceTotal(periodDatas.slots, "productPrice", tsk),
                        fixFees: reduceTotal(periodDatas.slots, "fixFees", tsk),
                        total: reduceTotal(periodDatas.slots, "total", tsk),
                      };
                    }),
                    "font-weight-bold text-white"
                  )}
                  <td
                    className="text-center font-weight-bold bg-danger text-white"
                    style={{ fontSize: 12 }}
                  >
                    {tools.round(periodDatas.total)} €
                  </td>
                  <td className="text-center font-weight-bold bg-default" style={{ fontSize: 12 }}>
                    {tools.round(previousPeriodDatas.total) || 0} €
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <TableNav scrollTable={scrollTable} />
          <div className="custom-card  mt-4 " style={{ maxWidth: 480 }}>
            <div className="row">
              <div className="col-12">
                <div className="d-between text-secondary">
                  <strong className="mr-2">Total sur la période N</strong>
                  <div>
                    <div className={`mr-2 badge badge-${caDelta > 0 ? "primary" : "danger"}`}>
                      {caDelta > 0 ? "+" : ""}
                      {caDelta} %
                    </div>
                    <strong>{tools.round(periodDatas.total)} €</strong>
                  </div>
                </div>
                <div className="d-between">
                  <strong className="mr-2">Total sur la période N-1</strong>
                  <div>
                    <strong>{tools.round(previousPeriodDatas.total || 0)} €</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Page>
  );
};

export default BillingGlobalSummary;
