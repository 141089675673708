import React, { useState, useEffect } from "react";
import Control from "../../common/Control";
import PasswordControl from "../../common/PasswordControl";
import Page from "../../common/layout/Page";
import Axios from "axios";
import { API_URL } from "../../../config";
import Loader from "../../common/Loader";
import { Link } from "react-router-dom";
import SaveBtn from "../../common/SaveBtn";
import { DateTime } from "luxon";
import Progress from "../../common/Progress";

var timerInterval = null;

const RenewPassword = (props) => {
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [errors, setErrors] = useState({});
  const [canSave, setCanSave] = useState(false);
  const [passwordsEqual, setPasswordsEqual] = useState(false);
  const [resetValid, setResetValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMissingParam, setIsMissionParam] = useState(false);
  const [tokenValid, setTokenValid] = useState(true);
  const [phoneCodeValid, setPhoneCodeValid] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [authId, setAuthId] = useState("");
  const [canResendAt, setCanResendAt] = useState(false);
  const [canReceiveNewCode, setCanReceiveNewCode] = useState(false);
  const [progress, setProgress] = useState(0);
  const [resendDuration, setResendDuration] = useState(60);
  const [timeBeforeNewCode, setTimeBeforeNewCode] = useState(resendDuration);

  useEffect(() => {
    if (!props.match.params.token) {
      setIsMissionParam(true);
    }
  }, []);

  useEffect(() => {
    clearInterval(timerInterval);
    timerInterval = setInterval(() => {
      if (!canResendAt) return;
      var timeLeft = DateTime.fromISO(canResendAt)
        .diff(DateTime.fromISO(new Date().toISOString()))
        .toObject();

      var progress = Math.round(
        ((resendDuration * 1000 - timeLeft.milliseconds) /
          1000 /
          resendDuration) *
          100
      );

      if (progress >= 100) {
        setCanReceiveNewCode(true);
      } else {
        setCanReceiveNewCode(false);
        setTimeBeforeNewCode(Math.round(timeLeft.milliseconds / 1000));

        setProgress(progress);
      }
    }, 1000);
  }, [canResendAt]);

  useEffect(() => {
    setErrors({});
    var errs = {};
    if (password != passwordConfirm || password.length == 0) {
      errs.passwordConfirm = "Doit-être identique au premier";
    }

    if (Object.keys(errs).length === 0 && errs.constructor === Object) {
      setPasswordsEqual(true);
    } else {
      setPasswordsEqual(false);
    }
    setErrors(errs);
  }, [password, passwordConfirm]);

  function save() {
    if (canSave && passwordsEqual) {
      //save
      setIsLoading(true);
      Axios.post(API_URL + "managers/renew-password", {
        password: password,
        email: props.match.params.email,
        token: props.match.params.token,
      })
        .then((res) => {
          setIsSuccess(true);
          setErrors({});
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            setErrors(err.response.data);
          }
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }

  return (
    <>
      <Page errors={errors}>
        <div className="row">
          <div className="col-12 col-md-6 col-lg-6 m-auto">
            <div className="card shadow-none">
              <div className=" text-center">
                <div className="mt-5">
                  <img src="/images/logo.png" style={{ width: 150 }} />
                </div>
                <h1 className="mt-5  text-secondary main-title">
                  Je change mon mot de passe
                  <hr className="w-50 mt-4" />
                </h1>
              </div>
              {isLoading ? (
                <div className="card-body  p-4 p-lg-5 p-md-4">
                  <Loader text="Vérification en cours" />
                </div>
              ) : isMissingParam ? (
                <div className="card-body  p-4 p-lg-5 p-md-4">
                  <p className="mb-0 text-danger">Ce lien est incorrect.</p>
                </div>
              ) : !tokenValid ? (
                <div className="card-body  p-4 p-lg-5 p-md-4">
                  <p className="mb-0 text-danger text-center">
                    Ce jeton n'est plus valide.
                  </p>
                </div>
              ) : resetValid ? (
                <div className="card-body  p-4 p-lg-5 p-md-4 text-center">
                  <p className="mb-4 text-success">
                    Votre mot de passe a bien été modifié, vous pouvez à présent
                    vous connecter.
                  </p>
                  <button
                    className="btn btn-link"
                    onClick={() => window.location.reload()}
                  >
                    Se connecter
                  </button>
                </div>
              ) : isSuccess ? (
                <div className="card-body text-center">
                  <i className="fa fa-check fa-4x text-success animated zoomIn" />
                  <p className="text-success  font-weight-bold mt-3 mb-4 font-weight-bold animated fadeIn">
                    Votre mot de passe a bien été modifié.
                  </p>
                  <button
                    onClick={() => (window.location.href = "/")}
                    className="btn btn-success animated fadeIn"
                  >
                    Se connecter
                  </button>
                </div>
              ) : (
                <div className="card-body p-4 p-lg-5 p-md-4">
                  <p>
                    Veuillez saisir un nouveau mot de passe pour votre compte et
                    le confirmer à l'aide des champs ci-dessous.
                  </p>
                  <Control
                    className="mt-5 mb-3"
                    label="Choisissez un nouveau mot de passe"
                    type="password"
                    name="password"
                    value={password}
                    change={(e) => setPassword(e.target.value)}
                    error={errors}
                    isValid={!errors.password && password != "" && canSave}
                  />
                  <PasswordControl
                    style={{}}
                    password={password}
                    minLength={6}
                    minUppercase={1}
                    minLowercase={1}
                    minNumber={1}
                    setCanSave={setCanSave}
                  />
                  <Control
                    label="Confirmez votre mot de passe"
                    type="password"
                    name="passwordConfirm"
                    value={passwordConfirm}
                    change={(e) => setPasswordConfirm(e.target.value)}
                    error={errors}
                    isValid={!errors.passwordConfirm && passwordConfirm != ""}
                  />
                  <span className="text-danger">{errors.other}</span>
                  {canSave && passwordsEqual && (
                    <button
                      className={
                        "btn mt-3 w-100 btn-primary animated zoomIn faster"
                      }
                      onClick={save}
                    >
                      Modifier mon mot de passe
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </Page>
    </>
  );
};

export default RenewPassword;
