import React from "react";
import useStoreState from "../../../../Context/Store/useStoreState";

const SlotStack = ({ dishSlot, dishSlots, dsk }) => {
  const { items } = useStoreState();
  const technicalSheets = [...items.technicalSheets];

  return (
    <>
      {dishSlot
        .sort((a, b) => (a.order > b.order ? 1 : -1))
        .map((dsl, dslk) => {
          return dsl.technicalSheet ? (
            <div
              className="text-white p-2 d-flex align-items-center justify-content-start flex-fill"
              key={`sc${dsl.id}${dslk}`}
              style={{
                background: dsl.color,
                borderTopRightRadius: dslk == 0 ? 5 : 0,
                borderBottomRightRadius: dslk == dishSlot.length - 1 ? 5 : 0,
              }}
            >
              <div style={{ fontSize: 10 }} className="d-center">
                <span className="mr-1 font-weight-bold">{dsl.slotCode}</span>
              </div>
              <div className="d-center">
                {technicalSheets.find((ts) => ts.id == dsl.technicalSheet).name}
              </div>
            </div>
          ) : (
            <div
              className="d-flex align-items-center justify-content-start bg-default p-2 flex-fill"
              style={{
                fontSize: 12,
                borderTopRightRadius: dslk == 0 ? 5 : 0,
                borderBottomRightRadius: dslk == dishSlot.length - 1 ? 5 : 0,
              }}
            >
              <i className="fa fa-exclamation-triangle mr-2" />
              aucune alternative disponible
            </div>
          );
        })}
      {dsk < dishSlots.length - 1 && (
        <div className="my-2 font-weight-bold">OU</div>
      )}
    </>
  );
};

export default SlotStack;
