import React, { useState } from "react";
import useStoreState from "../../../Context/Store/useStoreState";
import AddDishSlot from "./DishSlot/AddDishSlot/AddDishSlot";
import DishSlot from "./DishSlot/DishSlot";
import ReactDragListView from "react-drag-listview";
import Loader from "../../../../../../common/Loader";
import SlotActions from "./SlotActions";

const Slot = ({ day, moment, slots }) => {
  const { items, items_actions } = useStoreState();
  const specificitiesFilter = items.utils.specificitiesFilter;
  const technicalSheets = [...items.technicalSheets];
  const [displayAddDishSlot, setDisplayAddDishSlot] = useState(false);
  const [mouseOver, setMouseOver] = useState(false);

  const updateOrder = (fromIndex, toIndex) => {
    let data = [...slots];
    const item = data.splice(fromIndex, 1)[0];
    data.splice(toIndex, 0, item);
    const finalData = data.map((d, dk) => ({
      ...d,
      order: parseInt(dk) + 1,
    }));
    items_actions.saveDishSlots(finalData);
  };

  const shouldFilterFromSpecificities = (dslot) => {
    var dsSlotSpec = technicalSheets.find((t) => t.id == dslot.technicalSheet);
    var noSpe = false;
    if (dsSlotSpec) {
      noSpe = dsSlotSpec.specificities.find((s) => specificitiesFilter.includes(s));
    }
    return noSpe;
  };

  return (
    <div
      className="d-flex flex-column h-100 w-100 pb-5 forward-click slot-container"
      onMouseEnter={() => setDisplayAddDishSlot(true)}
      onMouseLeave={() => setDisplayAddDishSlot(false)}
    >
      <ReactDragListView onDragEnd={updateOrder} nodeSelector="section" handleSelector="a">
        <div
          onMouseEnter={() => setMouseOver(true)}
          onMouseLeave={() => setMouseOver(false)}
          className="slot-content"
        >
          {slots
            .sort((a, b) => (a.order < b.order ? -1 : 1))
            .filter((ds) =>
              specificitiesFilter.length > 0 ? shouldFilterFromSpecificities(ds) : true
            )
            .map((dishSlot, dishSlotk) => (
              <section
                key={`${dishSlot.id}${dishSlot.name}${dishSlot.day}${dishSlot.moment}${dishSlot.order}`}
                style={{ borderBottom: "3px solid rgb(247, 247, 247)" }}
              >
                <DishSlot dishSlot={dishSlot} />
                <SlotActions dishSlot={dishSlot} mouseOver={mouseOver} />
              </section>
            ))}
        </div>
      </ReactDragListView>

      {!items.utils.editSlotId && (
        <AddDishSlot day={day} moment={moment} isVisible={displayAddDishSlot} />
      )}
    </div>
  );
};

export default Slot;
