import React from "react";
import useStore from "../../../../context/useStore";
import Control from "../../../common/Controls/Control";

const IngredientListFilters = ({ search, setSearch, errors }) => {
  const [state, dispatch] = useStore();
  const constants = { ...state.constants.items };

  return (
    <>
      <div className="col-12 col-md-4">
        <Control
          label="Recherche"
          type="text"
          name="searchString"
          value={search.ingredient.searchString}
          change={(e) =>
            setSearch({
              ...search,
              page: 1,
              ingredient: {
                ...search.ingredient,
                searchString: e.target.value,
              },
            })
          }
          error={errors}
        />
      </div>
      <div className="col-12 col-md-4">
        <Control
          label="Famille"
          type="select"
          name="type"
          datas={constants.INGREDIENT_TYPES}
          dataIndex="id"
          dataLabel="name"
          value={search.ingredient.type}
          change={(e) =>
            setSearch({
              ...search,
              page: 1,
              ingredient: { ...search.ingredient, type: e.target.value },
            })
          }
          error={errors}
        />
      </div>
      <div className="col-12 col-md-4"></div>
      <div className="col-12 col-md-4"></div>
    </>
  );
};

export default IngredientListFilters;
