import React, { useEffect, useState } from "react";
import useStoreState from "../../Clients/ClientDetail/useStoreState";

const ConsumeMomentsTable = ({ consumerDays, updateConsumerDays }) => {
  const { gState } = useStoreState();
  const { DAYS, CONSUME_TIMES } = gState.constants.items;

  const [selected, setSelected] = useState([]);

  useEffect(() => {
    var replaceArr = DAYS.map((day) => {
      return {
        day: day.id,
        moments: [],
      };
    });
    consumerDays.forEach((cday) => {
      replaceArr.find((r) => r.day == cday.day).moments = cday.moments;
    });
    console.log("replaceArr", replaceArr);
    setSelected(replaceArr);
  }, [consumerDays]);

  const updateSelected = (day, moment) => {
    var momentInDay = selected.find((s) => s.day == day);
    if (momentInDay.moments.includes(moment)) {
      momentInDay.moments = momentInDay.moments.filter((m) => m != moment);
    } else {
      momentInDay.moments.push(moment);
    }
    console.log(momentInDay);
    let newSelected = selected.map((s) => (s.day == day ? momentInDay : s));
    setSelected(newSelected);

    updateConsumerDays(newSelected.filter((s) => s.moments.length));
  };

  return selected.length ? (
    <>
      <label>
        <span className="label-text">Jours et moments de consommation</span>
      </label>
      <div className="d-flex flex-row">
        {DAYS.map((day) => (
          <div
            className="d-center mx-1 flex-fill flex-column"
            key={`csday${day.id}`}
          >
            <div>{day.name}</div>
            {CONSUME_TIMES.map((ct) => {
              var isSelected = selected
                .find((s) => s.day == day.id)
                .moments.includes(ct.id);
              return (
                <button
                  className={`btn w-100 mb-1 btn-sm ${
                    isSelected ? "btn-primary" : "btn-default"
                  }`}
                  onClick={() => updateSelected(day.id, ct.id)}
                >
                  {ct.name}
                </button>
              );
            })}
          </div>
        ))}
      </div>
    </>
  ) : null;
};

export default ConsumeMomentsTable;
