import React, { useEffect } from "react";
import Page from "../../../../common/layout/Page";
import Loader from "../../../../common/Loader";
import useStoreState from "../Context/Store/useStoreState";
import MenuRef from "./MenuRef/MenuRef";
import Panels from "./Panels/Panels";
import Specificities from "./SpecificitiesFilter/Specificities";

const ItemsPage = (props) => {
  const { state, items, items_actions } = useStoreState();

  console.log("state local", state);
  useEffect(() => {
    items_actions.initItems(props.items);
  }, []);

  return (
    <Page
      title={props.pageTitle}
      isLoading={state.isLoading}
      back={props.back}
      style={{ maxWidth: "95%" }}
    >
      {state.isInit ? (
        <>
          <div className="row d-flex justify-content-between shadow-sm bg-white p-3 overflow-hidden position-relative rounded mb-2">
            <MenuRef />
            {/* {!items.menu.slots.length && items.menuRef.productionSite.length && (
              <>
                <div
                  className="d-flex justify-content-end align-items-end w-100 p-2 mb-2"
                  style={{ gap: "2rem" }}
                >
                  <button
                    className="btn btn-primary"
                    onClick={items_actions.generateSlots}
                  >
                    Générer automatiquement les références plan alimantaire
                  </button>
                </div>
              </>
            )} */}
          </div>
          {items.menuRef.id && items.menu.slots.length ? (
            <>
              <div
                className="row d-flex align-items-center justify-content-start p-3 shadow-sm bg-white rounded mb-2"
                style={{ gap: "2rem" }}
              >
                <Specificities />
              </div>
              <Panels />
            </>
          ) : null}
        </>
      ) : (
        <Loader />
      )}
    </Page>
  );
};

export default ItemsPage;
