import React, { useEffect } from "react";
import Page from "../../../common/layout/Page";
import Table from "../../../common/Table/Table";
import useAllSitesList from "./useAllSitesList";
import AllSitesFilters from "./AllSitesFilters";
import HorizontalScroll from "../../../common/HorizontalScroll/HorizontalScroll";
import tools from "../../../../helpers/tools";
import { DateTime } from "luxon";

const AllSitesList = () => {
  const { formatedSites, isLoadingSites, actions, filters } = useAllSitesList();

  useEffect(() => {
    actions.searchSites(filters);
  }, []);

  console.log("isLoadingSites", isLoadingSites);

  return (
    <Page title={`Liste des sites`} isLoading={isLoadingSites} style={{ maxWidth: "95%" }}>
      <div className="row ">
        <div className="col-12">
          <AllSitesFilters />
        </div>
        <div className="col-12">
          <Table
            isLoading={isLoadingSites}
            nbResults={formatedSites.length}
            noMargin
            exportOptions={{
              datas: formatedSites.map((site) => ({
                ...site,
                emails: site.emails.join(", "),
              })),
              excludedFields: ["Actions"],
            }}
            tableContainerStyle={{ overflowX: "scroll" }}
            fields={[
              { name: "Nom", path: "name" },
              {
                name: "Dern. semaine commandée",
                path: "lastOrderAt",
                render: (el) => {
                  if (el == "inconnue") return el;
                  var isPast7Days =
                    tools.daysBetweenDates(
                      DateTime.fromISO(filters.lastOrderAt).toISO(),
                      DateTime.fromFormat(el, "dd/MM/yyyy").toISO()
                    ) > 7;

                  return (
                    <div className={isPast7Days ? "text-danger" : ""}>
                      {isPast7Days && (
                        <i
                          className="fa fa-warning"
                          title="Commande passée il y a plus de 7 jours"
                        />
                      )}
                      {el}
                    </div>
                  );
                },
              },
              { name: "Adresse", path: "address" },
              { name: "Tél", path: "phone" },
              { name: "Site de prod.", path: "productionSite" },
              { name: "Client", path: "client" },
              {
                name: "Emails",
                path: "emails",
                render: (el) => el.map((email) => <div>{email}</div>),
              },
              { name: "Mode allot.", path: "allotingMode" },
              { name: "Mode condit.", path: "conditioningMode" },
              { name: "Activité", path: "activeDays" },
              { name: "Archivé?", path: "isArchived" },
              { name: "Créé le", path: "createdAt" },
              { name: "MAJ le", path: "updatedAt" },
            ]}
            datas={formatedSites}
            scrollable
            scrollableId="siteListTable"
          />
          {/* <Pagination
            nbPages={nbPages}
            page={search.page}
            changePage={(p) => setSearch({ ...search, page: p })}
          /> */}
        </div>
      </div>
    </Page>
  );
};

export default AllSitesList;
