import React from "react";
import { sidePanelActions } from "../../../../../../../context/actions/sidePanelActions";
import useStoreState from "../../../Context/Store/useStoreState";
import AddConsumerProfile from "../../Panels/Slot/AddConsumerProfile/AddConsumerProfile";

const ConsumptionProfiles = () => {
  const { items, items_actions, client_actions, gState, gDispatch } =
    useStoreState();
  const spActions = sidePanelActions(gState, gDispatch);

  const { consumptionProfiles } = items;

  const addConsumerProfile = (id) => {
    spActions.updateSidePanel({
      isOpen: true,
      content: (
        <AddConsumerProfile
          items={items}
          id={id}
          client_actions={client_actions}
          autoOrder={consumptionProfiles.length + 1}
        />
      ),
    });
  };

  return (
    <div className="p-2">
      <div>
        <span
          className="text-primary"
          style={{ fontSize: 14, fontWeight: "500" }}
        >
          Profils de consommation
        </span>
      </div>
      <div>
        {consumptionProfiles.map((c, ck) => (
          <button
            onClick={(e) => addConsumerProfile(c.id)}
            className="btn text-white mr-1"
            key={`pcs${ck}`}
            style={{ background: c.color }}
          >
            <span className="badge badge-default mr-2">{c.order}</span>
            {c.name}
          </button>
        ))}
        <button
          data-priv="create_modify_consumption_profile"
          onClick={(e) => addConsumerProfile(false)}
          className="btn btn-default"
        >
          <i className="fa fa-plus" />
        </button>
      </div>
    </div>
  );
};

export default ConsumptionProfiles;
