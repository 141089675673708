import React, { useState } from "react";
import Control from "../../../common/Controls/Control";

const CreateGuestsNb = ({ guests, updateConsumerGuests }) => {
  const [num, setNum] = useState(0);

  const createGuests = () => {
    var newGuests = [];
    for (var i = 0; i < num; i++) {
      newGuests.push({
        name: `Convive ${i + 1}`,
      });
    }
    updateConsumerGuests(newGuests);
  };

  return guests.length ? null : (
    <div>
      <Control
        label="Créer automatiquement le nombre de convive ci-dessous"
        type="number"
        value={num}
        change={(e) => setNum(e.target.value)}
      />
      <button
        disabled={num == 0 || isNaN(num)}
        className="btn btn-default"
        onClick={createGuests}
      >
        Créer {isNaN(num) ? "" : num} convives
      </button>
    </div>
  );
};

export default CreateGuestsNb;
