import React from "react";
import Page from "../../../../common/layout/Page";
import OrderReceipt from "../OrderReceipt";
import Filters from "./Filters";
import OrderReceiptTable from "./OrderReceiptTable";
import useOrderReceiptList from "./useOrderReceiptList";

const OrderReceiptList = () => {
  const { state, actions } = useOrderReceiptList();

  return (
    <Page
      title={<span className="noprint">Liste des bons de commande</span>}
      errors={state.errors}
      isLoading={state.isLoading}
      printOrientation="landscape"
      container="container-fluid"
      style={{ maxWidth: "100%" }}
    >
      <Filters
        search={state.search}
        setSearch={actions.setSearch}
        productionSites={state.productionSites}
        providers={state.providers}
        searchData={actions.searchOrderReceipts}
      />
      {!state.currentOrderReceipt && (
        <OrderReceiptTable state={state} actions={actions} />
      )}
      {state.currentOrderReceipt && (
        <>
          <div className="d-flex justify-content-end w-100">
            <button
              className="btn btn-default noprint"
              onClick={() => actions.setCurrentOrderReceipt(null)}
            >
              Revenir à la liste
            </button>
          </div>
          <OrderReceipt orderReceipt={state.currentOrderReceipt} />
        </>
      )}
    </Page>
  );
};

export default OrderReceiptList;
