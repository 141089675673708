import React, { useState, useEffect } from "react";
import useApiDatas from "../../../common/Hooks/useApiDatas";
import Loader from "../../../common/Loader";
import Progress from "../../../common/Progress";
import RecipeDownloadAll from "../../../print/RecipeTemplate/RecipeDownloadAll";
import datas from "./datas.json";

const DownloadTechnicalSheets = () => {
  const { otherDatas } = useApiDatas({
    resourcePath: "technicalsheets",
    page: "TechnicalSheetList",
    dependenciesPath: [
      "textures",
      "consumertypes",
      "technicalsheets",
      "ingredients",
      "dishTypes",
      "specificities",
      "preparations",
    ],
    searchParams: {
      technicalSheet: { searchString: "" },
      page: 1,
    },
  });

  const [downloadDatas, setDownloadDatas] = useState([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [docsDownloaded, setDocsDownloaded] = useState(0);
  const [docToDownload, setDocToDownload] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const {
    textures,
    consumertypes,
    technicalsheets,
    specificities,
    dishTypes,
    ingredients,
    preparations,
  } = otherDatas;

  useEffect(() => {
    if (Object.values(otherDatas).length) {
      setIsLoading(false);
      printRecipes();
    }
  }, [otherDatas]);

  const printRecipes = () => {
    setIsDownloading(true);
    // setIsLoading(true);
    // dataService.get(
    //   `recipes`,
    //   (recipesDatas) => {

    var recipesDatas = JSON.parse(JSON.stringify(datas));

    //const recipeDatas = <Array de consumerTypes>
    var exaustRecipeData = [];
    technicalsheets.forEach((technicalSheet) => {
      consumertypes.forEach((consumerType) => {
        textures.forEach((t) => {
          if (
            recipesDatas[technicalSheet.id] &&
            recipesDatas[technicalSheet.id][consumerType.id] &&
            recipesDatas[technicalSheet.id][consumerType.id][t.id]
          ) {
            exaustRecipeData.push({
              texture: t,
              recipeData:
                recipesDatas[technicalSheet.id][consumerType.id][t.id],

              multiplicator: 10,
              consumerType,
              technicalSheet: technicalSheet,
            });
          }
        });
      });
    });
    const groupeRecipesByFT = [];
    technicalsheets.forEach((technicalSheet) => {
      groupeRecipesByFT.push(
        exaustRecipeData.filter(
          (ex) => ex.technicalSheet.id == technicalSheet.id
        )
      );
    });

    var tempDownloadDatas = [];
    groupeRecipesByFT.forEach((data) => {
      tempDownloadDatas.push({
        recipes: data,
      });
    });
    // tempDownloadDatas.forEach((td, tdk) => {
    //   localStorage.setItem(
    //     `doc-to-download-${tdk}`,
    //     JSON.stringify({
    //       isDone: false,
    //       datas: td,
    //     })
    //   );
    // });
    setDownloadDatas(tempDownloadDatas);

    //   },
    //   setErrors,
    //   () => setIsLoading(false)
    // );
  };

  const initCallback = () => {
    setDocToDownload([]);
    setDocsDownloaded(docsDownloaded + 1);
  };

  useEffect(() => {
    if (!docToDownload.length && downloadDatas.length) {
      var dldd = JSON.parse(JSON.stringify(downloadDatas)).slice(1);
      setTimeout(() => {
        setDocToDownload([dldd[0]]);
      }, 1000);
    }
  }, [docToDownload]);

  useEffect(() => {
    if (downloadDatas.length) {
      setDocToDownload([downloadDatas[0]]);
    }
  }, [downloadDatas]);

  return isLoading ? (
    <div className="w-100 h-100 d-center">
      <div className="custom-card d-center flex-column">
        <Loader />
        <div className="mt-2">
          Récupération de toutes les fiches techniques en cours...
        </div>
        <div className="animated pulse infinite">
          cela peut prendre plusieurs minutes ☕
        </div>
        <div>Evitez d'utiliser la machine si possible</div>
      </div>
    </div>
  ) : (
    <div className="row mx-auto" style={{ maxWidth: "80%" }}>
      <div className="col-12">
        <div className="d-none">
          {docToDownload.map((data, dk) => (
            <RecipeDownloadAll
              key={`dlrecipe${Math.random()}`}
              id={dk}
              initCallback={initCallback}
              params={{
                recipes: data.recipes,
                preparations: data.preparations,
              }}
              ingredients={ingredients}
              specificities={specificities}
              preparations={preparations}
            />
          ))}
        </div>
        {isDownloading && (
          <div className="w-100">
            <div className="text-center">
              Téléchargement de toutes les fiches techniques en cours (
              {docsDownloaded}/{downloadDatas.length})
            </div>
            <Progress
              progress={(docsDownloaded * 100) / downloadDatas.length}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DownloadTechnicalSheets;
