import React, { useEffect, useState } from "react";
import Control from "../../../../common/Controls/Control";
import DynamicList from "../../../../common/DynamicList";
import ModifyPassword from "../../../../common/ModifyPassword/ModifyPassword";
import SaveBtn from "../../../../common/SaveBtn";
import useStoreState from "../useStoreState";

const ClientUserDetail = ({ user }) => {
  const { state, gState, items, items_actions } = useStoreState();
  const { client, utils, sites } = { ...items };
  const [showPassword, setShowPassword] = useState(false);
  const constants = gState.constants.items;
  const [clientUser, setClientUser] = useState(user);

  useEffect(() => {
    setClientUser(user);
  }, [user]);

  var isEdit = clientUser.id ? true : false;

  return (
    <div className="custom-card">
      <div className="row  mx-0">
        {isEdit && (
          <>
            <div className="col-12 mb-2">
              <ModifyPassword
                route={"customers/" + clientUser.id + "/password"}
                showPassword={showPassword}
                setShowPassword={setShowPassword}
              />
            </div>
            <div className="col-12 mb-2">
              {!clientUser.isActivated ? (
                <button
                  className="btn btn-success btn-sm"
                  onClick={() =>
                    items_actions.activateUser(clientUser.id, true)
                  }
                >
                  Activer le compte
                </button>
              ) : (
                <button
                  className="btn btn-dark btn-sm"
                  onClick={() =>
                    items_actions.activateUser(clientUser.id, false)
                  }
                >
                  Désactiver le compte
                </button>
              )}
            </div>
          </>
        )}
        <div className="col-12 col-md-6">
          <Control
            label="Rôle"
            type="select"
            datas={constants.ROLES_CUSTOMER}
            dataIndex="id"
            dataLabel="name"
            value={clientUser.role}
            change={(e) =>
              setClientUser({ ...clientUser, role: e.target.value })
            }
            errors={utils.errors}
          />
          <Control
            label="Civilité"
            type="select"
            datas={[
              { id: 1, name: "Monsieur" },
              { id: 2, name: "Madame" },
            ]}
            dataIndex="id"
            dataLabel="name"
            value={clientUser.gender}
            change={(e) =>
              setClientUser({ ...clientUser, gender: e.target.value })
            }
            errors={utils.errors}
          />
          <Control
            label="Nom"
            type="text"
            value={clientUser.lastname}
            name="lastname"
            change={(e) =>
              setClientUser({ ...clientUser, lastname: e.target.value })
            }
            errors={utils.errors}
          />
          <Control
            label="Prénom"
            type="text"
            value={clientUser.firstname}
            name="firstname"
            change={(e) =>
              setClientUser({ ...clientUser, firstname: e.target.value })
            }
            errors={utils.errors}
          />
        </div>
        <div className="col-12 col-md-6">
          <Control
            label="Email"
            type="email"
            value={clientUser.email}
            name="email"
            change={(e) =>
              setClientUser({ ...clientUser, email: e.target.value })
            }
            errors={utils.errors}
          />
          <Control
            label="Téléphone"
            type="tel"
            value={clientUser.phone}
            name="phone"
            change={(e) =>
              setClientUser({ ...clientUser, phone: e.target.value })
            }
            errors={utils.errors}
          />
          {!["OWNER", ""].includes(clientUser.role) ? (
            <Control
              label="Sites"
              name="sites"
              change={(e) =>
                setClientUser({ ...clientUser, sites: e.target.value })
              }
              btnInline
              type="checkBtnList"
              datas={sites}
              value={clientUser.sites}
              dataIndex="id"
              dataLabel="name"
              error={utils.errors}
            />
          ) : null}
          {!isEdit && (
            <Control
              label="Mot de passe"
              name="password"
              type="password"
              value={clientUser.password}
              change={(e) =>
                setClientUser({ ...clientUser, password: e.target.value })
              }
              error={utils.errors}
            />
          )}
        </div>
      </div>
      <div className="d-flex justify-content-end w-100">
        <SaveBtn
          save={() => items_actions.saveUser(clientUser)}
          isSaving={utils.savingUser}
          text="Enregistrer l'utilisateur"
        />
      </div>
    </div>
  );
};

export default ClientUserDetail;
