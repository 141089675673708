import { atom, useAtom } from "jotai";
import { useEffect } from "react";
import { toast } from "react-toastify";
import dataService from "../helpers/dataService";

const isLoadingSitesAtom = atom(false);
const isSavingSiteAtom = atom(false);
const sitesAtom = atom([]);
const sitesSearchAtom = atom([]);
const errorsAtom = atom({});

const useSites = (fetchOnLoad = true) => {
  const [isLoadingSites, setIsLoadingSites] = useAtom(isLoadingSitesAtom);
  const [isSavingSite, setIsSavingSite] = useAtom(isSavingSiteAtom);
  const [sites, setSites] = useAtom(sitesAtom);
  const [sitesSearch, setSitesSearch] = useAtom(sitesSearchAtom);
  const [errors, setErrors] = useAtom(errorsAtom);

  useEffect(() => {
    if (fetchOnLoad) {
      getSites();
    }
  }, []);

  const getSites = () => {
    setIsLoadingSites(true);
    dataService.get(
      `sites`,
      (datas) => {
        setSites(datas);
      },
      (err) => {},
      () => setIsLoadingSites(false)
    );
  };

  const searchSites = (filters) => {
    setIsLoadingSites(true);
    dataService.post(
      `sites/list-by`,
      filters,
      setSitesSearch,
      (err) => {},
      () => setIsLoadingSites(false)
    );
  };

  const patchSite = (site) => {
    setIsSavingSite(true);
    dataService.patch(
      `sites/${site.id}`,
      site,
      (data) => {
        toast.success("Le site a bien été enregistré");
      },
      setErrors,
      () => setIsSavingSite(false)
    );
  };

  const orderedSitesAlpha =
    sites.length > 0 ? sites.sort((a, b) => (a.name > b.name ? 1 : -1)) : [];
  const orderedSitesSearchAlpha =
    sitesSearch.length > 0 ? sitesSearch.sort((a, b) => (a.name > b.name ? 1 : -1)) : [];

  return {
    isLoadingSites,
    sites,
    sitesSearch,
    orderedSitesAlpha,
    orderedSitesSearchAlpha,
    errors,
    isSavingSite,
    setSites,
    getSites,
    searchSites,
    patchSite,
  };
};

export default useSites;
