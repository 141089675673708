import { toast } from "react-toastify";
import dataService from "../../../../../../helpers/dataService";
import tools from "../../../../../../helpers/tools";
import actionTools from "./actionTools";

export const itemsActions = (state, dispatch, gState, gDispatch, sidePanelActions) => {
  const initItems = async (loadUrls) => {
    dispatch({ type: "IS_LOADING", payload: true });
    var allPromises = [];
    var actionUrls = {};
    var items = {};
    loadUrls.forEach((url) => {
      actionUrls[url.name] = { ...url.paths };
      if (url.paths?.get && url.fetchOnLoad) {
        allPromises.push(
          new Promise((resolve, reject) => {
            dataService.get(url.paths.get, resolve, reject);
          })
        );
      }
      if (!url.fetchOnLoad) {
        allPromises.push(
          new Promise((resolve, reject) => {
            resolve(url.initialState);
          })
        );
        // items[url.name] = url.initialState;
        // console.log("it", items);
      }
    });
    if (allPromises.length) {
      Promise.all(allPromises)
        .then((values) => {
          values.forEach((value, key) => {
            items[loadUrls[key].name] = value;
          });

          dispatch({
            type: "INIT_ITEMS",
            payload: { items, actionUrls },
          });
        })
        .catch((err) => {
          console.log("error", err);
          Object.values(err).forEach((er) => toast.error(er));
        });
    } else {
      var defaultItems = {};
      loadUrls.forEach((url) => {
        defaultItems[url.name] = url.initialState;
      });

      dispatch({
        type: "INIT_ITEMS",
        payload: { items: defaultItems, actionUrls },
      });
    }
  };

  const setItem = (itemName, newItem) => {
    dispatch({
      type: "UPDATE_ITEM",
      payload: {
        item: itemName,
        value: newItem,
      },
    });
  };

  const updateSpecificitiesFilter = (specificities) => {
    var utils = { ...state.items.utils };
    utils.specificitiesFilter = specificities;
    utils.editSlotId = "";
    dispatch({
      type: "UPDATE_ITEM",
      payload: { item: "utils", value: utils },
    });
  };

  const generateSlots = () => {
    const menuRefId = state.items.menuRef.id;
    console.log("menuRefId", menuRefId);
    dispatch({
      type: "LOADING_MENUREF",
      payload: true,
    });
    dataService.post("slotrefs/generate", { menuRef: menuRefId }, (datas) =>
      dispatch({
        type: "DUPLICATE_DISH_SLOTS",
        payload: datas,
      })
    );
  };

  const updateMenuRef = async (menuRef, inputNameThatChanged) => {
    tools.saveState(
      "menuref",
      "search",
      JSON.stringify({
        productionSite: menuRef.productionSite,
        beginAt: menuRef.beginAt,
      })
    );
    dispatch({
      type: "LOADING_MENUREF",
      payload: true,
    });
    if (inputNameThatChanged == "productionSite") {
      dataService.get(`slotcodes/productionsites/${menuRef.productionSite}`, (datas) => {
        var slotsCodes = datas;
        dispatch({
          type: "LOAD_SLOT_CODES",
          payload: datas,
        });
        dataService.post("menurefs/search", { ...menuRef, slots: null }, (datas) => {
          console.log(datas);
          dispatch({
            type: "LOAD_MENUREF",
            payload: datas,
          });
        });
      });
    } else {
      dataService.post("menurefs/search", { ...menuRef, slots: null }, (datas) => {
        console.log(datas);
        dispatch({
          type: "LOAD_MENUREF",
          payload: datas,
        });
      });
    }
  };

  const cancelEdit = () => {
    dispatch({
      type: "SET_EDIT_SLOT_ID",
      payload: "",
    });
  };
  const editDishSlot = (dishSlot) => {
    if (state.items.utils.editSlotId) {
      return false;
    }

    let pans = document.querySelectorAll(`.col-panel${dishSlot.day}`);
    pans.forEach((pan) => pan.classList.add("open"));
    dispatch({
      type: "SET_EDIT_SLOT_ID",
      payload: dishSlot.id,
    });
  };

  const saveDishSlot = (dishSlot) => {
    dispatch({
      type: "LOADING_DISH_SLOTS",
      payload: [actionTools.getDishSlotUid(dishSlot)],
    });
    if (actionTools.getDishSlotUid(dishSlot) == dishSlot.id) {
      dataService.post("slotrefs", [dishSlot], (datas) => {
        dispatch({
          type: "SAVE_DISH_SLOT",
          payload: { originDishSlot: dishSlot, newDishSlot: datas[0] },
        });
      });
    } else {
      dataService.patch(`slotrefs`, [dishSlot], (datas) => {
        dispatch({
          type: "SAVE_DISH_SLOT",
          payload: { originDishSlot: dishSlot, newDishSlot: datas[0] },
        });
      });
    }
  };

  const saveDishSlots = (dishSlots) => {
    dispatch({
      type: "LOADING_DISH_SLOTS",
      payload: dishSlots.map((d) => actionTools.getDishSlotUid(d)),
    });

    dataService.patch(`slotrefs`, dishSlots, (datas) => {
      dispatch({
        type: "SAVE_DISH_SLOTS",
        payload: datas,
      });
    });
  };

  const deleteDishSlot = (dishSlot) => {
    dispatch({
      type: "LOADING_DISH_SLOTS",
      payload: [actionTools.getDishSlotUid(dishSlot)],
    });
    dataService.removeNoConfirm(`slotrefs/${dishSlot.id}`, {}, (datas) => {
      dispatch({
        type: "DELETE_DISH_SLOT",
        payload: { removed: dishSlot, modified: datas },
      });
    });
  };

  const duplicateDishSlots = (dishSlots, targetDays, targetMoments, targetDate) => {
    dataService.post(
      "menurefs/search",
      { ...state.items.menuRef, slots: null, beginAt: targetDate },
      (datas) => {
        console.log("getting menuRef from targetDate", datas);
        var datas = datas;
        var newDishSlots = actionTools.getDuplicatedSlots(
          dishSlots,
          targetDays,
          targetMoments,
          datas.id
        );

        console.log("NEW DUPLICATED", newDishSlots);

        dataService.post(
          "slotrefs/duplicate",
          newDishSlots,
          (datasd) => {
            toast.success("La duplication est un succès");
            sidePanelActions.updateSidePanel({
              isOpen: false,
              content: null,
            });
            if (state.items.menuRef.id == datas.id) {
              dispatch({
                type: "DUPLICATE_DISH_SLOTS",
                payload: datasd,
              });
            }
          },
          (err) => toast.error("Une erreur est survenue pendant la duplication")
        );
      }
    );
  };

  const addDishSlot = (dishSlot) => {
    console.log("adding", dishSlot);
    editDishSlot(dishSlot);
    dispatch({
      type: "ADD_DISH_SLOT",
      payload: dishSlot,
    });
  };

  const updateDaysComments = (daysComments) => {
    dispatch({
      type: "LOADING_MENUREF",
      payload: true,
    });
    dataService.patch(
      `menurefs/${state.items.menuRef.id}`,
      { ...state.items.menuRef, slots: null, daysComments },
      (datas) => {
        dispatch({
          type: "UPDATE_DAYS_COMMENTS",
          payload: daysComments,
        });
        sidePanelActions.updateSidePanel({
          isOpen: false,
          content: null,
        });
      }
    );
  };

  return {
    initItems,
    setItem,
    saveDishSlot,
    saveDishSlots,
    editDishSlot,
    deleteDishSlot,
    duplicateDishSlots,
    addDishSlot,
    sidePanelActions,
    updateSpecificitiesFilter,
    updateMenuRef,
    cancelEdit,
    generateSlots,
    updateDaysComments,
  };
};
