const IS_LOADING = "IS_LOADING";
const INIT_ITEMS = "INIT_ITEMS";
const UPDATE_ITEM = "UPDATE_ITEM";

const initialState = {
  isLoading: false,
  items: {},
  errors: {},
  isInit: false,
};

const initItems = (items, state) => {
  return { ...state, items, isLoading: false, isInit: true };
};
// const addItem = (item, state) => {
//   const newItem = [...state.items, item];
//   return { ...state, items: newItem };
// };
// const removeItem = (idx, state) => {
//   const newItems = [...state.items].filter((t, tk) => tk != idx);
//   return { ...state, items: newItems };
// };
const updateItem = ({ item, value }, state) => {
  return { ...state, items: { ...state.items, [item]: value } };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case IS_LOADING:
      return { ...state, isLoading: action.payload };
    case INIT_ITEMS:
      return initItems(action.payload, state);
    // case ADD_ITEM:
    //   return addItem(action.payload, state);
    // case REMOVE_ITEM:
    //   return removeItem(action.payload, state);
    case UPDATE_ITEM:
      return updateItem(action.payload, state);
    default:
      return state;
  }
};
