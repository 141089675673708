const actionTools = {
  getDishSlotUid: (dishSlot) => {
    return `${dishSlot.day}${dishSlot.moment}${dishSlot.order}`;
  },
  getDuplicatedSlots: (dishSlots, targetDays, targetMoments, menuRef) => {
    if (!dishSlots || !targetDays || !targetMoments) return 0;
    var newDishSlots = [];

    dishSlots.forEach((ds) => {
      let days = targetDays.length ? targetDays : [ds.day];
      let moments = targetMoments.length ? targetMoments : [ds.moment];
      days.forEach((day) => {
        moments.forEach((moment) => {
          newDishSlots.push({
            menuRef: menuRef || ds.menuRef,
            slotRefId: ds.id,
            day,
            moment,
          });
        });
      });
    });
    return newDishSlots;
  },
};

export default actionTools;
