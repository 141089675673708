import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import tools from "../../../../../helpers/tools";
import useProductionSites from "../../../../../hooks/useProductionSites";
import BarChart from "../CommonCharts/BarChart";
import { filterSameValues } from "../statTools";
import useStats from "../useStats/useStats";

const CAChart = ({ search }) => {
  const { productionSites } = useProductionSites();

  const { getStatCA } = useStats();
  const [hasChanged, setHasChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [chartData, setChartData] = useState({
    categories: [],
    series: [],
  });

  const fetchData = async () => {
    console.log({
      ...search,
      productionSites: search.productionSite ? [search.productionSite] : productionSites.map((p) => p.id),
    });

    setIsLoading(true);
    var results = await getStatCA({
      search: {
        ...search,
        productionSites: search.productionSite ? [search.productionSite] : productionSites.map((p) => p.id),
      },
    }).catch((err) => ({
      errors: err,
    }));
    setIsLoading(false);
    if (!results.errors) {
      var dates = results.period.slots[0].stats.map((d) => d.timeAt);
      var cas = [];
      dates.forEach((date) => {
        var subTotal = 0;
        results.period.slots.forEach((slot) => {
          var entry = slot.stats.find((d) => d.timeAt == date);
          if (entry) {
            subTotal += entry.total;
          }
        });
        cas.push(parseInt(subTotal));
      });

      setChartData({
        categories: dates.map((r) => DateTime.fromISO(r).toFormat("dd/MM")),
        series: cas.map((r) => tools.round(r)),
      });
      setHasChanged(false);
    }
  };

  useEffect(() => {
    if (search) {
      setHasChanged(true);
    }
  }, [search]);

  return (
    <div className="custom-card">
      <div className="d-flex justify-content-between align-items-center">
        <strong>Chiffre d'affaires en €</strong>
        {hasChanged && (
          <button onClick={fetchData} className="btn btn-default load-stat">
            <i className={`fa fa-refresh ${isLoading ? "animated rotate infinite" : ""}`} />
          </button>
        )}
      </div>
      {!hasChanged && (
        <BarChart categories={chartData.categories} series={chartData.series} seriesName="Chiffre d'affaires (€)" />
      )}
    </div>
  );
};

export default CAChart;
