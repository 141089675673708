import { atom, useAtom } from "jotai";
import { useEffect, useState } from "react";
import dataService from "../helpers/dataService";

const specificitiesAtom = atom([]);

const useSpecificities = (fetchOnLoad = true) => {
  const [specificities, setSpecificities] = useAtom(specificitiesAtom);

  useEffect(() => {
    if (fetchOnLoad) {
      getSpecificities();
    }
  }, []);

  const getSpecificities = () => {
    dataService.get(`specificities`, setSpecificities);
  };

  return {
    specificities,
    setSpecificities,
    getSpecificities,
  };
};

export default useSpecificities;
