import React from "react";
import useSites from "../../../../../hooks/useSites";
import Control from "../../../../common/Controls/Control";

const SiteSelector = ({ site, client, productionSite, setSite }) => {
  const { sites } = useSites();

  return (
    <Control
      label="Site"
      name="site"
      type="select"
      value={site}
      disabled={!productionSite}
      change={(e) => setSite(e.target.value)}
      datas={sites.filter((s) => {
        var isSameClient = true;
        var isSameProductionSite = true;
        if (productionSite) {
          isSameProductionSite = s.productionSite == productionSite;
        }
        if (client) {
          isSameClient = s.client == client;
        }

        return isSameClient && isSameProductionSite;
      })}
      dataIndex="id"
      dataLabel="name"
      placeholder="Tous"
    />
  );
};

export default SiteSelector;
