import React, { useState } from "react";
import { VerticalTimelineElement } from "react-vertical-timeline-component";
import { DateTime } from "luxon";
import Colors from "../../../theme/variables.scss";
import Stepper from "../Stepper/Stepper";
import Avatar from "../Avatar";
import SaveBtn from "../SaveBtn";

const TimelineEventCycle = ({ event, duplicateCycle, isSaving }) => {
  var eventColor = "rgb(69, 104, 0)";
  const [historicVisible, setHistoricVisible] = useState(false);
  return (
    <VerticalTimelineElement
      className="vertical-timeline-element--work cycle-timeline"
      contentStyle={{
        background: "#fff",
        color: "#404040",
        padding: 11,
        paddingTop: 4,
        paddingBottom: 5,
        boxShadow: "0 -3px 0 " + eventColor + " ,0 0 15px -3px #afafaf",
      }}
      contentArrowStyle={{ borderRight: "7px solid " + eventColor }}
      date={DateTime.fromISO(event.beginAt)
        .setLocale("fr")
        .toFormat("dd MMMM yyyy")}
      iconStyle={{
        background: eventColor,
        color: "#fff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 30,
        height: 30,
      }}
      icon={<i className={"fa fa-1x fa-calendar"} />}
    >
      <div
        className="d-flex flex-row justify-content-between align-items-center"
        style={{ fontSize: 16, listStyle: "none" }}
      >
        {event.menuRefs.map((ev, evk) => (
          <span
            className={`badge arrow-border-right mr-2 flex-fill badge-${
              ev.isSaved ? "success" : "danger"
            }`}
            style={{
              borderTopRightRadius: 3,
              borderBottomRightRadius: 3,
              borderLeftColor: ev.isSaved ? Colors.success : Colors.danger,
            }}
            key={`ev${ev.beginAt}`}
          >
            {ev.error ? (
              <i
                className="fa fa-exclamation-triangle mr-2"
                onClick={() => alert(ev.error)}
              />
            ) : null}
            {DateTime.fromISO(ev.beginAt).toFormat("dd/MM/yyyy")}
          </span>
        ))}
      </div>
      <div
        className="mt-3 d-flex justify-content-end"
        style={{
          bottom: 10,
          right: 10,
        }}
      >
        <div class="dropdown dropleft">
          <button
            class="btn btn-success dropdown-toggle"
            type="button"
            id={`dropdownMenuButton${event.beginAt}`}
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i className="fa fa-copy mr-2" />
            Dupliquer ce cycle
          </button>
          <div
            class="dropdown-menu bg-primary text-white rounded"
            aria-labelledby={`dropdownMenuButton${event.beginAt}`}
          >
            <button
              className="dropdown-item text-white bg-primary"
              onClick={() => duplicateCycle(event.menuRefs, true)}
            >
              Inclure les commentaires
            </button>
            <button
              className="dropdown-item text-white bg-primary"
              onClick={() => duplicateCycle(event.menuRefs, false)}
            >
              Ne pas inclure les commentaires
            </button>
          </div>
        </div>
      </div>
    </VerticalTimelineElement>
  );
};

export default TimelineEventCycle;
