import React, { useEffect } from "react";
import Control from "../../../../../../../../common/Controls/Control";
import DynamicList from "../../../../../../../../common/DynamicList";
import Loader from "../../../../../../../../common/Loader";
import actionTools from "../../../../../Context/actions/actionTools";
import useStoreState from "../../../../../Context/Store/useStoreState";
import ColorPicker from "../ColorPicker";

const DishSlotForm = ({
  tempDishSlot,
  setTempDishSlot,
  originTempDishSlot,
}) => {
  const { state, items, items_actions } = useStoreState();
  const technicalSheets = [...items.technicalSheets];
  const specificities = [...items.specificities];
  const loadingDishSlots = [...items.utils.loadingDishSlots];
  const slotCodes = [...items.slotCodes];
  const slots = [...items.menu.slots];

  const tempDishSlotName = slotCodes.find(
    (s) => s.id == tempDishSlot.slotCode
  ).name;

  const saveDishSlot = () => {
    items_actions.saveDishSlot(tempDishSlot);
  };

  const selectedTechnicalSheet = tempDishSlot.technicalSheet
    ? technicalSheets.find((t) => t.id == tempDishSlot.technicalSheet)
    : false;

  const getAvailableSlotsFromSpecificity = () => {
    var availableAlt = {};
    tempDishSlot.alternatives.forEach((alt) => {
      if (alt.specificity.length) {
        var tecSh = technicalSheets.filter(
          (t) =>
            !t.specificities.includes(alt.specificity) &&
            t.id != tempDishSlot.technicalSheet
        );

        availableAlt[alt.specificity] = slots
          .filter((s) => tecSh.find((t) => t.id == s.technicalSheet))
          .map((s) => s.id);
      }
    });
    console.log("availableAlt", availableAlt);
    return availableAlt;
  };

  const cancelEdit = () => {
    console.log("originTempDishSlot", originTempDishSlot);
    setTempDishSlot(originTempDishSlot);
    items_actions.cancelEdit();
  };

  return (
    <div
      className="d-flex px-2 py-1 flex-column w-100 dish-slot-form reducable  animated zoomIn faster"
      onClick={(e) => e.stopPropagation()}
    >
      <div
        className="position-absolute shadow"
        style={{
          top: 8,
          left: 8,
          borderRadius: "100%",
          height: 25,
        }}
      >
        <ColorPicker
          color={tempDishSlot.color}
          setColor={(color) => {
            setTempDishSlot({ ...tempDishSlot, color: color });
          }}
        />
      </div>
      <div
        className="position-absolute "
        style={{
          top: 8,
          right: 8,
          height: 25,
        }}
      >
        <button className="btn btn-sm text-white" onClick={cancelEdit}>
          annuler
          <i className="fa fa-window-close ml-2" />
        </button>
      </div>
      <div className="text-white font-weight-bold text-left pt-2 pb-3">
        <select
          type="text"
          className="bg-transparent border-0 text-white font-weight-bold"
          style={{
            textShadow: "0 0 6px black",
            lineHeight: "20.5px",
            marginLeft: 35,
          }}
          value={tempDishSlot.slotCode}
          onChange={(e) =>
            setTempDishSlot({ ...tempDishSlot, slotCode: e.target.value })
          }
        >
          {slotCodes.map((s, sk) => (
            <option key={`sc${s.id}`} value={s.id}>
              {s.name}
            </option>
          ))}
        </select>
      </div>
      <Control
        placeholder="Commencez à écrire le nom d'une fiche technique..."
        type="autocomplete"
        value={tempDishSlot.technicalSheet}
        name="ingredient"
        autoCompleteDisplayIndex="name"
        change={(e) =>
          setTempDishSlot({ ...tempDishSlot, technicalSheet: e.target.value })
        }
        datas={technicalSheets.filter((t) =>
          slotCodes
            .find((s) => s.id == tempDishSlot.slotCode)
            .dishTypes.includes(t.dishType)
        )}
        dataIndex="id" //default
        dataLabel="name" //default (sera inclut dans la recherche)
        required={true}
        btnInline={false}
        hideAutocompleteOnFocusOut={true} //buggé
        noResults={false} //si aucun résultat api
        autoCompleteHasApiDatas={false} // true si afficher tous les résultats api
        labelStyle={{ minHeight: 0 }}
      />

      <div>
        <DynamicList
          name="alternatives"
          displayNoDataMessage={false}
          change={(e) => {
            console.log(e.target);
            if (
              e.target.emmiter &&
              e.target.emmiter.target.name == "specificity"
            ) {
              var altOfLine = document.querySelector(
                `[data-key='${e.target.emmiter.target.k}1']`
              );
              var availableAlt = getAvailableSlotsFromSpecificity();
              var options = altOfLine.getElementsByTagName("option");
              for (var i = 0; i < options.length; i++) {
                var opt = options[i];
                console.log(
                  opt,
                  opt.value,
                  availableAlt[e.target.emmiter.target.value]
                );
                if (!opt.value || opt.value == "") continue;
                if (
                  availableAlt[e.target.emmiter.target.value].includes(
                    opt.value
                  )
                ) {
                  opt.style.display = "block";
                } else {
                  if (
                    e.target.value[e.target.emmiter.target.k].slotRef ==
                    opt.value
                  ) {
                    console.log("TO REMOVE");
                    e.target.value[e.target.emmiter.target.k].slotRef = "";
                  }
                  opt.style.display = "none";
                }
              }
            }
            setTempDishSlot({
              ...tempDishSlot,
              alternatives: e.target.value,
            });
          }}
          fields={[
            {
              label: "Si spécificité :",
              type: "select",
              name: "specificity",
              datas: selectedTechnicalSheet
                ? specificities.filter((s) =>
                    selectedTechnicalSheet.specificities.includes(s.id)
                  )
                : [],
              dataIndex: "id",
              dataLabel: "name",
              innerLabelStyle: {
                color: "white",
                overflow: "hidden",
                display: "block",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              },
            },
            {
              label: "Remplacer par :",
              type: "select",
              name: "slotRef",
              datas: slots
                .map((s) => ({
                  id: s.id,
                  name:
                    slotCodes.find((sc) => sc.id == s.slotCode)?.name ||
                    "aucune ref",
                  day: s.day,
                  moment: s.moment,
                }))
                .filter((s) => {
                  return (
                    s.id != tempDishSlot.id &&
                    s.day == tempDishSlot.day &&
                    s.moment == tempDishSlot.moment
                  );
                }),
              dataIndex: "id",
              dataLabel: "name",
              innerLabelStyle: {
                color: "white",
                overflow: "hidden",
                display: "block",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              },
            },
          ]}
          bgClassName="bg-transparent"
          colSizes={[40, 40]}
          mainLabelStyle={{
            minHeight: 0,
          }}
          labelStyle={{
            minHeight: 0,
            color: "white",
          }}
          value={tempDishSlot.alternatives}
          btnLabel="Alt."
          error={state.errors}
        />
      </div>

      <button
        className="btn btn-sm btn-default bg-white mb-2 rounded-0"
        onClick={saveDishSlot}
      >
        {loadingDishSlots.includes(actionTools.getDishSlotUid(tempDishSlot)) ? (
          <Loader color="black" />
        ) : (
          "Enregistrer"
        )}
      </button>
    </div>
  );
};

export default DishSlotForm;
