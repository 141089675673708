import React from "react";
import { Editor } from "@tinymce/tinymce-react";

const DynamicEditor = ({ page, setPage, height = 200 }) => {
  return (
    <Editor
      apiKey={process.env.REACT_APP_TINY_MCE}
      value={page}
      init={{
        height: height,
        menubar: false,
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount",
        ],
        toolbar:
          "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help",
      }}
      onEditorChange={(content, editor) => setPage(content)}
    />
  );
};

export default DynamicEditor;
