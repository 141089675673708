import React from "react";
import Page from "../../../common/layout/Page";
import Table from "../../../common/Table/Table";
import ManagerListFilters from "./ManagerListFilters";
import { Link } from "react-router-dom";
import Pagination from "../../../common/Pagination";
import tools from "../../../../helpers/tools";
import useApiDatas from "../../../common/Hooks/useApiDatas";

const ManagerList = () => {
  const {
    state,
    constants,
    errors,
    setErrors,
    isLoading,
    setIsLoading,
    searchDatas,
    setSearchDatas,
    nbPages,
    setNbPages,
    getSearchDatas,
    deleteData,
    isInit,
    search,
    setSearch,
    otherDatas,
    setOtherDatas,
    nbResults,
  } = useApiDatas({
    resourcePath: "managers",
    page: "ManagerList",
    searchParams: {
      manager: { searchString: "" },
      page: 1,
    },
  });

  return (
    <Page
      title="Liste des managers"
      errors={errors}
      isLoading={isLoading}
      action={{
        dataPriv: "create_modify_managers",
        to: "/managers/create",
        text: "Créer un manager",
      }}
    >
      <div className="row">
        <ManagerListFilters
          search={search}
          setSearch={setSearch}
          errors={errors}
        />
      </div>
      <div className="row ">
        <div className="col-12">
          <Table
            nbResults={nbResults}
            isLoading={isLoading}
            exportOptions={{
              excludedFields: ["Actions"],
            }}
            fields={[
              { name: "Nom", path: "*", render: (el) => tools.getFullName(el) },
              { name: "Email", path: "email" },
              { name: "Téléphone", path: "phone" },

              {
                name: "Rôle",
                path: "role",
                render: (el) =>
                  constants.ROLES_MANAGER.find((c) => c.id == el)
                    ? constants.ROLES_MANAGER.find((c) => c.id == el).name
                    : "",
              },
              {
                name: "Actions",
                path: "id",
                render: (el) => (
                  <>
                    <Link
                      to={`/managers/${el}`}
                      className="btn btn-default d-center d-inline-flex"
                    >
                      <i className="fa fa-edit mr-2" />
                      Modifier
                    </Link>
                    <button
                      data-priv="create_modify_managers"
                      className="btn btn-danger"
                      onClick={() => deleteData(el)}
                    >
                      <i className="fa fa-times" />
                    </button>
                  </>
                ),
              },
            ]}
            datas={searchDatas}
          />
          <Pagination
            nbPages={nbPages}
            page={search.page}
            changePage={(p) => setSearch({ ...search, page: p })}
          />
        </div>
      </div>
    </Page>
  );
};

export default ManagerList;
