import { atom, useAtom } from "jotai";
import { useEffect, useState } from "react";
import dataService from "../helpers/dataService";

const productionSitesAtom = atom([]);

const useProductionSites = (fetchOnLoad = true) => {
  const [productionSites, setProductionSites] = useAtom(productionSitesAtom);

  useEffect(() => {
    if (fetchOnLoad) {
      getProductionSites();
    }
  }, []);

  const getProductionSites = () => {
    dataService.get(`productionSites`, setProductionSites);
  };

  return {
    productionSites,
    setProductionSites,
    getProductionSites,
  };
};

export default useProductionSites;
