import React, { Fragment } from "react";
import Control from "./Controls/Control";

const DynamicList = ({
  label,
  uniqueKey,
  name,
  fields,
  value,
  btnLabel,
  change,
  error,
  dynamicTypes = false,
  labelClassname = "",
  elemk,
  colSizes = false,
  labelStyle = {},
  delBtnHeight = 80,
  bgClassName = "bg-light",
  mainLabelStyle = {},
  displayNoDataMessage = true,
}) => {
  function addItem() {
    var newItem = {};
    fields.forEach((f) => {
      if (f.type == "checkBtnList") {
        newItem[f.name] = [];
      } else {
        newItem[f.name] = "";
      }
    });
    value.push(newItem);
    change({ target: { name: name, value: value } });
  }

  function changeItem(e) {
    console.log(e);
    value[e.target.k][e.target.name] = e.target.value;

    change({ target: { name: name, value: value, emmiter: e } });
  }
  function removeItem(kk) {
    value = value.filter((i, k) => k !== kk);
    change({ target: { name: name, value: value } });
  }

  return (
    <div className="form-group">
      <label className={labelClassname} style={mainLabelStyle}>
        {label && (
          <span className="label-text text-secondary font-weight-bold">
            {label}
          </span>
        )}
        <ul
          className="list-group list-unstyled"
          // style={{ marginLeft: -30, marginRight: -30 }}
        >
          {!value.length && displayNoDataMessage && (
            <li className="p-2 rounded bg-control text-verylight">
              -aucune entrée-
            </li>
          )}
          {value.map((it, k) => {
            return (
              <>
                <li key={"dyn" + name + uniqueKey + "_" + k}>
                  <div className={`d-flex flex-wrap   ${bgClassName}`}>
                    {fields.map((fi, kk) => {
                      var datas = fi.datas;
                      if (fi.dataEntryId) {
                        datas = fi.datas[it[fi.dataEntryId]] || [];
                      }

                      return (
                        <Control
                          labelStyle={fi.labelStyle}
                          innerLabelStyle={fi.innerLabelStyle}
                          className={"mr-0 flex-fill"}
                          labelClassname="mb-0 mt-0 text-black-50 border-0"
                          inputStyle={kk == fields.length - 1 ? {} : {}}
                          k={k}
                          key={"dynlistinp" + kk}
                          label={fi.label}
                          name={fi.name}
                          type={
                            fi.isDynamic
                              ? dynamicTypes.find((d) => d.id == it.id)
                                ? dynamicTypes.find((d) => d.id == it.id).type
                                : fi.type
                              : fi.type
                          }
                          data-key={k + "" + kk}
                          data-value={it[fi.name]}
                          value={it[fi.name]}
                          change={changeItem}
                          id={fi.id ? fi.id : null}
                          suffix={fi.suffix ? fi.suffix : null}
                          datas={
                            Array.isArray(datas)
                              ? fi.formatDatas
                                ? fi.formatDatas(datas, it)
                                : datas
                              : []
                          }
                          margin={fi.margin ? fi.margin : ""}
                          dataIndex={fi.dataIndex ? fi.dataIndex : "id"}
                          dataLabel={fi.dataLabel ? fi.dataLabel : "name"}
                          dataLabel2={fi.dataLabel2 ? fi.dataLabel2 : false}
                          dataLabel3={fi.dataLabel3 ? fi.dataLabel3 : false}
                          autoCompleteDisplayIndex={
                            fi.autoCompleteDisplayIndex
                              ? fi.autoCompleteDisplayIndex
                              : false
                          }
                          autocomplete={fi.autocomplete}
                          error={it.error}
                          disabled={fi.disabled}
                          containerStyle={
                            colSizes ? { width: colSizes[kk] + "%" } : {}
                          }
                          btnInline={fi.btnInline}
                        />
                      );
                    })}
                    <div
                      style={{
                        height: delBtnHeight,

                        borderTopRightRadius: window.borderRadius,
                        borderBottomRightRadius: window.borderRadius,
                      }}
                      className="d-flex justify-content-end"
                    >
                      <button
                        className="btn text-danger btn-sm shadow-none  px-3 w-100"
                        onClick={() => removeItem(k)}
                      >
                        <i className="fa fa-times" />
                      </button>
                    </div>
                  </div>
                </li>
                {error && error[name] && error[name][k] && (
                  <li className="list-group-item">
                    <small className="form-text text-danger animated flash">
                      {error[name][k]}
                    </small>
                  </li>
                )}
              </>
            );
          })}
        </ul>
      </label>
      {btnLabel && (
        <div className="d-flex justify-content-end">
          <button
            className="btn btn-default btn-sm "
            type="button"
            onClick={addItem}
          >
            <i className="fa fa-plus mr-2" />
            {btnLabel}
          </button>
        </div>
      )}
      {error && (
        <small
          className={
            "form-text text-danger " + (error[name] ? "animated flash" : "")
          }
        >
          {typeof error[name] == "string" ? error[name] : ""}
        </small>
      )}
      {error &&
        error[name] &&
        typeof error[name] == "object" &&
        error[name][elemk] && (
          <small className="form-text text-danger">{error[name][elemk]}</small>
        )}
    </div>
  );
};

export default DynamicList;
