import React from "react";
import useStoreState from "../../../../../Context/Store/useStoreState";

const DishSlotView = ({ tempDishSlot }) => {
  const { items, items_actions } = useStoreState();
  const { technicalSheets, utils, slotCodes } = items;
  const { slots } = items.menu;
  const technicalSheet = technicalSheets.find(
    (t) => t.id == tempDishSlot.technicalSheet
  );
  const tempDishSlotName = slotCodes.find((s) => s.id == tempDishSlot.slotCode)?.name;

  return (
    <div
      className="d-center justify-content-between  flex-fill p-1 dish-slot-view reducable  animated flipInX h-100 flex-wrap"
      onClick={() => {
        items_actions.editDishSlot(tempDishSlot);
      }}
    >
      <div
        className="d-center text-white font-weight-bold text-left"
        style={{ textShadow: "0 0 6px black", lineHeight: "13.5px" }}
      >
        {tempDishSlotName}
        <i className="fa fa-circle mx-1" style={{ fontSize: 5 }} />
        {technicalSheet?.name}
      </div>
      <div className="d-flex justify-content-start flex-column  align-items-end flex-fill  h-100 ">
        {tempDishSlot.alternatives.map((rd, rdk) => {
          let originSlot = slots.find(
            (s) =>
              s.id == rd.slotRef &&
              s.day == tempDishSlot.day &&
              s.moment == tempDishSlot.moment
          );

          let originTecSheet = originSlot
            ? technicalSheets.find((t) => t.id == originSlot.technicalSheet)
            : null;
          return (
            originSlot &&
            originTecSheet && (
              <div
                className="d-center"
                key={`repdish${rdk}${rd.name}${tempDishSlot.name}${tempDishSlot.id}`}
              >
                <div
                  className="text-white mr-1"
                  style={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    fontWeight: "500",
                    textShadow: "0 0 2px black",
                  }}
                >
                  {originTecSheet.name}
                </div>
                <div
                  key={`rd${rdk}${tempDishSlot.day}${tempDishSlot.moment}${tempDishSlot.name}`}
                  className="badge badge-default text-white"
                  style={{ backgroundColor: originSlot?.color || "black" }}
                >
                  {originSlot.name}
                </div>
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};

export default DishSlotView;
