import React from "react";
import itemReducer from "./Context/reducers/itemsReducer";
import ItemsPage from "./itemsPage";
import ItemStore from "./Context/ItemStore";
import ProductionSitesList from "./components/ProductionSitesList";

const Items = () => {
  return (
    <ItemStore rootReducer={itemReducer}>
      <ItemsPage
        pageTitle="Cuisine"
        items={[
          {
            name: "productionSites",
            path: "productionsites",
          },
        ]}
      >
        <ProductionSitesList />
      </ItemsPage>
    </ItemStore>
  );
};

export default Items;
