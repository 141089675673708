import React, { useState, useEffect } from "react";
import useApiDatas from "../../../common/Hooks/useApiDatas";
import Loader from "../../../common/Loader";
import Progress from "../../../common/Progress";
import Table from "../../../common/Table/Table";
import RecipePrintAll from "../../../print/RecipeTemplate/RecipePrintAll";
import datas from "./datas.json";

const PrintTechnicalSheetsList = () => {
  const { otherDatas } = useApiDatas({
    dependenciesPath: [
      "textures",
      "consumertypes",
      "technicalsheets",
      "ingredients",
      "dishTypes",
      "specificities",
      "preparations",
      "recipes",
    ],
    searchParams: {
      technicalSheet: { searchString: "" },
      page: 1,
    },
  });

  const [downloadDatas, setDownloadDatas] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const {
    recipes,
    textures,
    consumertypes,
    technicalsheets,
    specificities,
    ingredients,
    preparations,
  } = otherDatas;

  useEffect(() => {
    if (Object.values(otherDatas).length) {
      printRecipes();
    }
  }, [otherDatas]);

  const printRecipes = () => {
    // setIsLoading(true);
    // dataService.get(
    //   `recipes`,
    //   (recipesDatas) => {

    var recipesDatas = recipes;

    //const recipeDatas = <Array de consumerTypes>
    var exaustRecipeData = [];
    technicalsheets.forEach((technicalSheet) => {
      consumertypes.forEach((consumerType) => {
        textures.forEach((t) => {
          if (
            recipesDatas[technicalSheet.id] &&
            recipesDatas[technicalSheet.id][consumerType.id] &&
            recipesDatas[technicalSheet.id][consumerType.id][t.id]
          ) {
            exaustRecipeData.push({
              texture: t,
              recipeData:
                recipesDatas[technicalSheet.id][consumerType.id][t.id],

              multiplicator: 10,
              consumerType,
              technicalSheet: technicalSheet,
            });
          }
        });
      });
    });
    const groupeRecipesByFT = [];
    technicalsheets.forEach((technicalSheet) => {
      groupeRecipesByFT.push(
        exaustRecipeData.filter(
          (ex) => ex.technicalSheet.id == technicalSheet.id
        )
      );
    });

    var tempDownloadDatas = [];
    groupeRecipesByFT.forEach((data) => {
      tempDownloadDatas.push({
        recipes: data,
      });
    });

    setIsLoading(false);
    console.log("tempDownloadDatas", tempDownloadDatas);
    setDownloadDatas(tempDownloadDatas);
  };

  return isLoading ? (
    <div className="w-100 h-100 d-center">
      <div className="custom-card d-center flex-column">
        <Loader />
        <div className="mt-2">
          Récupération de toutes les fiches techniques en cours...
        </div>
        <div className="animated pulse infinite">
          cela peut prendre plusieurs minutes ☕
        </div>
        <div>Evitez d'utiliser la machine si possible</div>
      </div>
    </div>
  ) : (
    <div className="row mx-auto" style={{ maxWidth: "80%" }}>
      <div className="col-12 d-center noprint">
        <button className="btn btn-primary" onClick={() => window.print()}>
          <i className="fa fa-print mr-2" />
          Tout imprimer (l'aperçu mettra plusieurs minutes à charger)
        </button>
      </div>
      <div className="col-12">
        <div>
          <Table
            datas={downloadDatas.filter((d) => d.recipes[0])}
            fields={[
              {
                name: "Fiche technique",
                path: "recipes",
                render: (el) => el[0].technicalSheet.name,
              },
              {
                name: "Texture",
                path: "recipes",
                render: (el) => el[0].texture.name,
              },
              {
                name: "Type de consommateur",
                path: "recipes",
                render: (el) => el[0].consumerType.name,
              },
            ]}
          />
          {/* {downloadDatas.map((data, dk) => (
            <RecipePrintAll
              key={`dlrecipe${Math.random()}`}
              id={dk}
              params={{
                recipes: data.recipes,
                preparations: data.preparations,
              }}
              ingredients={ingredients}
              specificities={specificities}
              preparations={preparations}
            />
          ))} */}
        </div>
      </div>
    </div>
  );
};

export default PrintTechnicalSheetsList;
