import dataService from "../../../../../../helpers/dataService";
import tools from "../../../../../../helpers/tools";

export const clientItemsActions = (state, dispatch, gState, gDispatch, sidePanelActions) => {
  const fetchClientMenu = (site) => {
    if (!site && !state.items.utils.selectedSite.length) return false;
    dispatch({
      type: "LOAD_CLIENT_MENU",
      payload: true,
    });
    dataService.get(
      `sites/${site || state.items.utils.selectedSite}/consumptionprofiles`,
      (datas) => {
        dispatch({
          type: "SET_CONSUMPTION_PROILES",
          payload: datas,
        });
        dataService.get(
          `menurefs/${state.items.menuRef.id}/sites/${site || state.items.utils.selectedSite}`,
          (dat) => {
            dispatch({
              type: "SET_CLIENT_MENU",
              payload: dat,
            });
          }
        );
      }
    );
  };

  const updateSelectedSite = (site) => {
    if (site.length) {
      fetchClientMenu(site);

      tools.saveState("clientMenu", "selectedSite", site);
    }
    dispatch({
      type: "UPDATE_SELECTED_SITE",
      payload: site,
    });
  };

  const saveConsumerProfile = (consumerProfile) => {
    console.log("TO SAVE", consumerProfile);
    if (!consumerProfile.id) {
      dataService.post("consumptionprofiles", consumerProfile, (datas) => {
        sidePanelActions.updateSidePanel({
          isOpen: false,
          content: null,
        });
        fetchClientMenu();
        dispatch({
          type: "ADD_CONSUMPTION_PROFILE",
          payload: datas,
        });
      });
    } else {
      dataService.patch(`consumptionprofiles/${consumerProfile.id}`, consumerProfile, (datas) => {
        sidePanelActions.updateSidePanel({
          isOpen: false,
          content: null,
        });
        dispatch({
          type: "UPDATE_CONSUMPTION_PROFILE",
          payload: datas,
        });
        fetchClientMenu();
      });
    }
  };

  return {
    fetchClientMenu,
    updateSelectedSite,
    saveConsumerProfile,
  };
};
