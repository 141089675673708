import React, { useEffect, useState } from "react";
import useStoreState from "../../../../Context/Store/useStoreState";
import DishSlotForm from "./DishSlotForm/DishSlotForm";
import DishSlotView from "./DishSlotView/DishSlotView";

const DishSlot = ({ dishSlot }) => {
  const { items } = useStoreState();
  const [tempDishSlot, setTempDishSlot] = useState({ ...dishSlot });
  const { editSlotId } = items.utils;
  const originTempDishSlot = { ...dishSlot };

  useEffect(() => {
    setTempDishSlot({ ...dishSlot });
  }, [dishSlot]);

  const saveDishSlot = () => {};

  return (
    <div
      className="d-flex flex-wrap align-items-center justify-content-start  animated flipInX faster dish-slot-container"
      style={{
        background: tempDishSlot.color,
      }}
    >
      {editSlotId == tempDishSlot.id ? (
        <DishSlotForm
          tempDishSlot={tempDishSlot}
          setTempDishSlot={setTempDishSlot}
          saveDishSlot={saveDishSlot}
          originTempDishSlot={originTempDishSlot}
        />
      ) : (
        <DishSlotView tempDishSlot={tempDishSlot} />
      )}
    </div>
  );
};

export default DishSlot;
