import React, { useState, useEffect } from "react";
import Table from "../../common/Table/Table";
import useStore from "../../../context/useStore";
import dataService from "../../../helpers/dataService";
import { modalActions } from "../../../context/actions/modalActions";
import PreparationModule from "./PreparationModule";
import Loader from "../../common/Loader";
import { DateTime } from "luxon";
import tools from "../../../helpers/tools";
import { toast } from "react-toastify";
import NbRecipesBtn from "./NbRecipesBtn";

const IngredientPreparationList = ({ ingredientId }) => {
  const [state, dispatch] = useStore();
  const constants = state.constants.items;
  const modal_actions = modalActions(state, dispatch);
  const [errors, setErrors] = useState({});
  const [datas, setDatas] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [nbPages, setNbPages] = useState(1);
  const [displayAddPreparation, setDisplayAddPreparation] = useState(false);

  useEffect(() => {
    if (ingredientId) getDatas();
  }, [ingredientId]);

  useEffect(() => {
    if (isLoading) setErrors({});
  }, [isLoading]);

  const getDatas = () => {
    setIsLoading(true);

    const onDataSuccess = (datas) => {
      console.log(datas);
      setDatas(datas);
      setDisplayAddPreparation(false);
    };

    dataService.get("preparations/ingredient/" + ingredientId, onDataSuccess, setErrors, () =>
      setIsLoading(false)
    );
  };

  const deleteData = (id) => {
    setIsLoading(true);
    const onRemoveSuccess = (datas) => {
      getDatas();
    };
    const onRemoveFinish = () => {
      setIsLoading(false);
    };
    dataService.remove("preparations/" + id, {}, onRemoveSuccess, setErrors, onRemoveFinish);
  };

  const openModal = (preparationId) => {
    if (preparationId) {
      modal_actions.updateModal({
        isOpen: true,
        content: (
          <PreparationModule
            ingredientId={ingredientId}
            preparationId={preparationId}
            successCallback={getDatas}
          />
        ),
        actions: null,
      });
    } else {
      setDisplayAddPreparation(true);
    }
  };

  const duplicatePreparation = (id) => {
    if (!window.confirm("Dupliquer ce produit ?")) return false;
    setIsLoading(true);
    var prepdup = datas.find((p) => p.id == id);
    prepdup.id = null;
    prepdup.ingredient = prepdup.ingredient.id;
    prepdup.isDefaultProvider = false;
    prepdup.nbDishes = 0;
    prepdup.provider = prepdup.provider.id;

    const onSuccess = (datas) => {
      toast.success("Produit créée avec succès");
      getDatas();
    };

    dataService.post(
      "preparations",
      prepdup,
      onSuccess,
      () => toast.error("Erreur de duplication, rechargez la page et réessayez"),
      () => setIsLoading(false)
    );
  };

  return (
    <>
      {isLoading && <Loader />}
      <Table
        exportOptions={false}
        fields={[
          {
            name: "Catégorie de trans.",
            path: "category",
            render: (el) => constants.PREPARATION_CATEGORIES.find((c) => c.id == el).name,
          },
          {
            name: "Par défaut",
            path: "isDefaultProvider",
            render: (el) => <i className={`${el ? "fa fa-star" : "far fa-star"}`} />,
          },
          {
            name: "Fourn.",
            path: "provider",
            render: (el) => el.name,
          },
          {
            name: "Cond.",
            path: "*",
            render: (el) => {
              var val = `${el.packingWeight} kg`;
              if (el.isSpecificEquivalentPacking) {
                val += ` / ${el.specificEquivalentPackingQty} ${
                  tools.findInConstant(
                    constants.PREPARATION_UNITS,
                    el.specificEquivalentPackingUnit
                  ).name
                }`;
              }
              return val;
            },
          },
          {
            name: "Prix / 1kg",
            path: "*",
            render: (el) => {
              var val = `${
                Math.round((parseFloat(el.price) / parseFloat(el.packingWeight)) * 100) / 100
              }€`;

              var maj = el.priceHistorics.length
                ? DateTime.fromISO(el.priceHistorics[el.priceHistorics.length - 1].endAt).toFormat(
                    "dd/MM/yyyy"
                  )
                : DateTime.fromISO(el.createdAt).toFormat("dd/MM/yyyy");
              val = (
                <>
                  {val}
                  <br />
                  <span style={{ fontWeight: "400", fontSize: 13 }}>mise à jour : {maj}</span>
                </>
              );
              return val;
            },
          },
          {
            name: "Prix net transf.",
            path: "*",
            render: (el) => (
              <div style={{ fontWeight: "500", fontSize: 14 }}>
                {tools.getPriceNetTransformed(
                  parseFloat(el.price),
                  parseFloat(el.packingWeight),
                  parseFloat(el.lossPercentBrutToNet),
                  parseFloat(el.percentNetToCooked)
                )}
                €/kg
              </div>
            ),
          },
          {
            name: "Recettes liées",
            path: "*",
            render: (el) =>
              parseInt(el.nbRecipes) > 0 ? (
                <NbRecipesBtn nbRecipes={el.nbRecipes} preparationId={el.id} />
              ) : (
                <span
                  className={"p-3 d-center "}
                  style={{
                    width: 30,
                    height: 30,
                    borderRadius: "100%",
                  }}
                >
                  {el.nbRecipes}
                </span>
              ),
          },
          {
            name: "Actions",
            path: "id",
            render: (el) => (
              <div className="d-flex align-items-center justify-content-end flex-row">
                <button
                  data-priv="create_modify_ingredients"
                  className="btn btn-secondary btn-sm"
                  onClick={() => duplicatePreparation(el)}
                >
                  <i className="fa fa-copy" />
                </button>
                <button className="btn btn-default btn-sm d-center" onClick={() => openModal(el)}>
                  <i className="fa fa-edit mr-2" />
                  Modifier
                </button>
                <button
                  data-priv="create_modify_ingredients"
                  className="btn btn-danger btn-sm"
                  onClick={() => deleteData(el)}
                >
                  <i className="fa fa-times" />
                </button>
              </div>
            ),
          },
        ]}
        datas={datas}
      />
      {displayAddPreparation && (
        <div className="p-3 border mb-3">
          <PreparationModule
            ingredientId={ingredientId}
            successCallback={getDatas}
            close={() => setDisplayAddPreparation(false)}
          />
        </div>
      )}
      <div className="d-flex justify-content-end" data-priv="create_modify_ingredients">
        <button className="btn btn-secondary" onClick={() => openModal(false)}>
          <i className="fa fa-plus mr-2" />
          Ajouter un produit
        </button>
      </div>
    </>
  );
};

export default IngredientPreparationList;
