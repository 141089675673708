import React from "react";
import Page from "../../../common/layout/Page";
import Table from "../../../common/Table/Table";
import ClientListFilters from "./ClientListFilters";
import { Link } from "react-router-dom";
import Pagination from "../../../common/Pagination";
import useApiDatas from "../../../common/Hooks/useApiDatas";
import tools from "../../../../helpers/tools";

const ClientList = () => {
  const {
    errors,
    isLoading,
    searchDatas,
    nbPages,
    deleteData,
    search,
    setSearch,
    nbResults,
    otherDatas,
    constants,
  } = useApiDatas({
    resourcePath: "clients",
    page: "ClientList",
    searchParams: {
      client: { searchString: "" },
      page: 1,
    },
    dependenciesPath: ["clients/exports/csv"],
  });

  const csvClients = otherDatas["clients/exports/csv"];

  return (
    <Page
      title="Liste des clients"
      errors={errors}
      isLoading={isLoading}
      action={{
        to: "/clients/create",
        text: "Créer un client",
        dataPriv: "create_modify_client",
      }}
    >
      <div className="row">
        <ClientListFilters search={search} setSearch={setSearch} errors={errors} />
      </div>
      <div className="row ">
        <div className="col-12">
          <Table
            nbResults={nbResults}
            isLoading={isLoading}
            exportOptions={{
              excludedFields: ["Actions"],
              datas: !csvClients
                ? searchDatas
                : csvClients
                    .filter((cl) => !cl.isArchived)
                    .map((client) => ({
                      ...client,
                      phones: client.owners.map((ow) => ({
                        phone: ow.phone || "-aucun-",
                      })),
                      emails: client.owners.map((ow) => ({ email: ow.email })),
                      createdAt: tools.formatDate(client.createdAt),
                    })),
              additionalFields: [
                {
                  name: "Propriétaires",
                  path: "owners",
                },
                {
                  name: "Téléphones",
                  path: "phones",
                },
                {
                  name: "Emails",
                  path: "emails",
                },
                {
                  name: "Sites",
                  path: "sites",
                },
                {
                  name: "Créé le",
                  path: "createdAt",
                },
              ],
              arrayFields: {
                owners: {
                  dataIndexes: ["fullName"],
                },
                phones: {
                  dataIndexes: ["phone"],
                },
                emails: {
                  dataIndexes: ["email"],
                },
                sites: {
                  dataIndexes: ["name", "postalCode", "city"],
                },
              },
            }}
            fields={[
              { name: "Nom", path: "name" },
              { name: "Code", path: "code" },
              {
                name: "Actions",
                path: "id",
                render: (el) => (
                  <>
                    <Link
                      to={`/clients/${el}`}
                      className="btn btn-default d-center d-inline-flex mr-2"
                    >
                      <i className="fa fa-edit mr-2" />
                      Modifier
                    </Link>
                    <button
                      data-priv="create_modify_client"
                      className="btn btn-danger"
                      onClick={() => deleteData(el)}
                    >
                      <i className="fa fa-times" />
                    </button>
                  </>
                ),
              },
            ]}
            datas={searchDatas.filter((d) => !d.isArchived)}
          />
          <Pagination
            nbPages={nbPages}
            page={search.page}
            changePage={(p) => setSearch({ ...search, page: p })}
          />
        </div>
      </div>
    </Page>
  );
};

export default ClientList;
