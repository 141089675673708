import React from "react";
import Loader from "../../../common/Loader";
import DateSelector from "../../Order/OrderPage/OrderFilters/DateSelector";
import useAllSitesList from "./useAllSitesList";

const AllSitesFilters = () => {
  const { filters, actions, isLoadingSites } = useAllSitesList();

  const onSubmit = (e) => {
    e.preventDefault();
    actions.searchSites(filters);
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="row">
        <div className="col-12 col-md-4">
          <DateSelector
            text="Dont la dernière commande est antérieure à la semaine du :"
            name="lastOrderAt"
            beginAt={filters.lastOrderAt}
            disabled={false}
            change={actions.onChangeFilters}
          />
        </div>
        <div className="col-12 col-md-4 justify-content-start align-items-start d-flex noprint">
          <button
            onClick={actions.onResetFilters}
            type="button"
            className="btn btn-default btn-sm mb-1"
          >
            Réinitialiser les filtres
          </button>
          <button className="btn btn-secondary btn-sm mb-1" type="submit">
            {isLoadingSites ? <Loader color="white" /> : "Afficher"}
          </button>
        </div>
      </div>
    </form>
  );
};

export default AllSitesFilters;
