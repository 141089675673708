import { toast } from "react-toastify";
import dataService from "../../../../../helpers/dataService";
import tools from "../../../../../helpers/tools";

export const itemsActions = (state, dispatch, gState, gDispatch, sidePanelActions) => {
  const initItems = async (loadUrls) => {
    setIsLoading(true);
    var allPromises = [];
    var actionUrls = {};
    var items = {};
    loadUrls.forEach((url) => {
      actionUrls[url.name] = { ...url.paths };
      if (url.paths?.get && url.fetchOnLoad) {
        allPromises.push(
          new Promise((resolve, reject) => {
            dataService.get(url.paths.get, resolve, reject);
          })
        );
      }
      if (!url.fetchOnLoad) {
        allPromises.push(
          new Promise((resolve, reject) => {
            resolve(url.initialState);
          })
        );
        // items[url.name] = url.initialState;
        // console.log("it", items);
      }
    });
    if (allPromises.length) {
      Promise.all(allPromises)
        .then((values) => {
          values.forEach((value, key) => {
            items[loadUrls[key].name] = value;
          });

          dispatch({
            type: "INIT_ITEMS",
            payload: { items, actionUrls },
          });
        })
        .catch((err) => {
          console.log("error", err);
          Object.values(err).forEach((er) => toast.error(er));
        });
    } else {
      var defaultItems = {};
      loadUrls.forEach((url) => {
        defaultItems[url.name] = url.initialState;
      });

      dispatch({
        type: "INIT_ITEMS",
        payload: { items: defaultItems, actionUrls },
      });
    }
  };

  const setIsLoading = (type = null, isLoading) => {
    dispatch({
      type: `IS_LOADING${type ? "_" + type : ""}`,
      payload: isLoading,
    });
  };

  const setItem = (itemName, newItem) => {
    dispatch({
      type: "UPDATE_ITEM",
      payload: {
        item: itemName,
        value: newItem,
      },
    });
  };

  const setSelectedClient = (client) => {
    dispatch({
      type: "UPDATE_SELECTED_CLIENT",
      payload: client,
    });
    loadSites(client);
  };

  const loadSites = (client = null) => {
    setIsLoading("SITES", true);
    dataService.get(`clients/${client || state.items.utils.selectedClient}/sites`, (sites) => {
      dispatch({
        type: "LOAD_SITES",
        payload: sites,
      });
    });
  };

  const setSelectedSite = (site) => {
    dispatch({
      type: "UPDATE_SELECTED_SITE",
      payload: site,
    });
  };
  const setSelectedDate = (date) => {
    dispatch({
      type: "UPDATE_SELECTED_DATE",
      payload: date,
    });
  };
  const loadOrder = (lockedDays) => {
    setIsLoading("ORDER", true);
    dataService.post(
      `orders/search`,
      {
        siteId: state.items.utils.selectedSite,
        beginAt: state.items.utils.selectedDate,
      },
      (order) => {
        dispatch({
          type: "LOAD_ORDER",
          payload: { order, lockedDays },
        });
      }
    );
  };
  const setSlotOrderQty = (qty, day, moment, consumptionProfile, contractId, consumerId) => {
    var { slotOrders } = state.items.order;
    var newSlotOrders = slotOrders.map((s) => {
      //console.log(consumptionProfile, s.consumptionProfile);
      if (
        s.day == day.id &&
        s.moment == moment.id &&
        s.consumptionProfile == consumptionProfile &&
        s.consumer == consumerId
      ) {
        var contractRef = state.items.order.consumptionProfiles.filter(
          (c) => c.clientContract == contractId
        );

        return { ...s, qty };
      }
      return s;
    });
    console.log("newSlotOrders", newSlotOrders);
    dispatch({
      type: "UPDATE_SLOT_ORDERS",
      payload: newSlotOrders,
    });
  };
  const resetQty = () => {
    if (
      window.confirm(
        "Voulez-vous réinitialiser cette commande et remettre toutes les quantités à 0 ? (ne sera appliqué qu'aux jours ou une modification est encore possible)"
      )
    ) {
      var { slotOrders } = state.items.order;
      var { lockedDays } = state.items;

      var newSlotOrders = slotOrders.map((s) => {
        var isLocked = lockedDays[s.day] < 3;
        return { ...s, qty: isLocked ? s.qty : 0 };
      });
      toast.success(
        "Quantités remises à 0, n'oubliez pas d'enregistrer pour que vos modifications soient prises en compte."
      );
      dispatch({
        type: "UPDATE_SLOT_ORDERS",
        payload: newSlotOrders,
      });
    }
  };

  const saveOrder = () => {
    dispatch({ type: "IS_LOADING_ORDER", payload: true });
    const saveObj = {
      beginAt: state.items.utils.selectedDate,
      siteId: state.items.utils.selectedSite,
      slotOrders: state.items.order.slotOrders,
    };

    saveObj.diff = tools.orderDiff(
      state.items.refSlotOrders,
      saveObj.slotOrders,
      state.items.order.consumptionProfiles,
      state.items.order.contracts,
      state.items.order.slotRefs,
      gState.constants.items.DAYS,
      gState.constants.items.CONSUME_TIMES
    );

    saveObj.comment = state.items.order?.order?.comment || "";

    console.log("saveObj", saveObj);
    if (state.items.order.order) {
      dataService.patch(`orders/${state.items.order.order.id}`, saveObj, (datas) => {
        toast.success("Enregistrement réussi");
        dispatch({
          type: "LOAD_ORDER",
          payload: { order: datas },
        });
      });
    } else {
      dataService.post(`orders`, saveObj, (datas) => {
        toast.success("Enregistrement réussi");

        dispatch({
          type: "LOAD_ORDER",
          payload: { order: datas },
        });
      });
    }
  };

  return {
    initItems,
    setItem,
    setSelectedClient,
    setSelectedSite,
    setSelectedDate,
    loadSites,
    loadOrder,
    setSlotOrderQty,
    saveOrder,
    setIsLoading,
    resetQty,
  };
};
