import React from "react";

const StipLoader = () => {
  return (
    <div
      className="progress w-100 h-100 position-absolute"
      style={{
        left: 0,
        top: 0,
        zIndex: "9",
        opacity: ".5",
      }}
    >
      <div
        className="progress-bar progress-bar-striped progress-bar-animated"
        role="progressbar"
        aria-valuenow="100"
        aria-valuemin="0"
        aria-valuemax="100"
        style={{ width: "100%" }}
      ></div>
    </div>
  );
};

export default StipLoader;
