import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useStore from "../../../../context/useStore";
import dataService from "../../../../helpers/dataService";
import Control from "../../../common/Controls/Control";
import DisplayDates from "../../../common/DisplayDates";
import Page from "../../../common/layout/Page";
import SaveBtn from "../../../common/SaveBtn";

const SectorDetail = (props) => {
  const [state, dispatch] = useStore();
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [sector, setSector] = useState({
    name: "",
  });
  const itemId = props.match.params.id;
  const isEdit = itemId == "create" ? false : true;
  const constants = state.constants.items;

  useEffect(() => {
    if (isEdit) {
      getDatas();
    }
  }, []);

  const getDatas = () => {
    const onSuccess = (datas) => setSector(datas);
    const onError = (error) => {
      setErrors(error);
    };
    dataService.get("sectors/" + itemId, onSuccess, onError, () =>
      setIsLoading(false)
    );
  };

  const save = () => {
    const onError = (error) => {
      setErrors(error);
    };
    setIsLoading(true);
    if (isEdit) {
      const onSuccess = (datas) => {
        setSector({
          ...sector,
          createdAt: datas.createdAt,
          updatedAt: datas.updatedAt,
        });
        toast.success("Secteur enregistré avec succès");
      };
      dataService.patch("sectors/" + itemId, sector, onSuccess, onError, () =>
        setIsLoading(false)
      );
    } else {
      const onSuccess = (datas) => {
        toast.success("Secteur créé avec succès");
        window.location.href = "/sectors/" + datas.id;
      };

      dataService.post("sectors", sector, onSuccess, onError, () =>
        setIsLoading(false)
      );
    }
  };

  return (
    <Page
      container="container"
      back="/sectors"
      title={(isEdit ? "Modification" : "Détail") + " du secteur"}
      errors={errors}
    >
      <div className="row">
        <div className="col-12 p-0">
          <Control
            label="Nom"
            name="name"
            type="text"
            value={sector.name}
            change={(e) => setSector({ ...sector, name: e.target.value })}
            error={errors}
            setErrors={setErrors}
            required={true}
          />
        </div>
        <div className="d-flex justify-content-end w-100">
          <DisplayDates
            createdAt={sector.createdAt}
            updatedAt={sector.updatedAt}
          />
          <SaveBtn
            save={save}
            isSaving={isLoading}
            text="Enregistrer"
            type="primary"
          />
        </div>
      </div>
    </Page>
  );
};

export default SectorDetail;
