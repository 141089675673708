import React, { useState } from "react";
import dataService from "../../../../helpers/dataService";
import Control from "../../../common/Controls/Control";
import SaveBtn from "../../../common/SaveBtn";
import SimpleDynamicList from "../../../common/SimpleDynamicList";
import ColorPicker from "../../Menus/GlobalMenu/GlobalMenuPage/Panels/Slot/DishSlot/ColorPicker";

const AddConsumerProfile = ({
  consumptionProfiles,
  selectedSite,
  id,
  contracts,
  slotCodes,
  consumerTypes,
  specificities,
  textures,
  savedCallback,
}) => {
  const [consumerProfile, setConsumerProfile] = useState(
    id
      ? consumptionProfiles.find((c) => c.id == id)
      : {
          name: "",
          color: "",
          site: selectedSite,
          clientContract: "",
          consumerType: "",
          texture: "",
          specificities: [],
          order: consumptionProfiles.length + 1,
        }
  );
  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState({});
  console.log("consumerProfile", consumerProfile);
  const contractsFiltered = () => {
    return contracts
      .filter((c) => c.sites.includes(selectedSite))
      .map((c) => {
        return {
          ...c,
          slotCodes: c.slotCodes
            .map((s) =>
              slotCodes.find((is) => is.id == s)
                ? slotCodes.find((is) => is.id == s).name
                : ""
            )
            .join(", "),
        };
      });
  };

  const saveConsumerProfile = () => {
    setIsSaving(true);
    if (id) {
      dataService.patch(
        `consumptionprofiles/${id}`,
        consumerProfile,
        (datas) => {
          savedCallback();
        },
        setErrors,
        () => setIsSaving(false)
      );
    } else {
      dataService.post(
        `consumptionprofiles`,
        consumerProfile,
        (datas) => {
          savedCallback();
        },
        setErrors,
        () => setIsSaving(false)
      );
    }
  };

  return (
    <div>
      <Control
        label="Nom du profil de consommation"
        type="text"
        value={consumerProfile.name}
        change={(e) =>
          setConsumerProfile({ ...consumerProfile, name: e.target.value })
        }
        error={errors}
      />
      <Control
        label="Contrats"
        type="select"
        value={consumerProfile.clientContract}
        datas={contractsFiltered()}
        change={(e) =>
          setConsumerProfile({
            ...consumerProfile,
            clientContract: e.target.value,
          })
        }
        dataIndex="id"
        dataLabel="name"
        error={errors}
      />
      <Control
        label="Type de consommateur"
        type="select"
        value={consumerProfile.consumerType}
        datas={consumerTypes}
        change={(e) =>
          setConsumerProfile({
            ...consumerProfile,
            consumerType: e.target.value,
          })
        }
        dataIndex="id"
        dataLabel="name"
        error={errors}
      />

      <SimpleDynamicList
        label="Spécificités"
        name="specificities"
        change={(e) =>
          setConsumerProfile({
            ...consumerProfile,
            specificities: e.target.value,
          })
        }
        field={{
          type: "select",
          datas: specificities,
          name: "specificity",
          dataIndex: "id",
          dataLabel: "name",
        }}
        value={consumerProfile.specificities}
        btnLabel="Ajouter"
        className="mb-0"
        error={errors}
      />

      <Control
        label="Texture"
        type="select"
        datas={textures}
        dataIndex="id"
        dataLabel="name"
        value={consumerProfile.texture}
        change={(e) =>
          setConsumerProfile({ ...consumerProfile, texture: e.target.value })
        }
        btnInline
        name="texture"
        error={errors}
      />
      <Control
        label="Ordre"
        type="number"
        value={consumerProfile.order}
        change={(e) =>
          setConsumerProfile({ ...consumerProfile, order: e.target.value })
        }
        name="order"
      />

      <div>
        <div>
          <span className="label-text">Couleur</span>
        </div>
        <ColorPicker
          color={consumerProfile.color}
          setColor={(color) =>
            setConsumerProfile({ ...consumerProfile, color })
          }
        />
      </div>

      <div className="d-center mt-3">
        <SaveBtn save={saveConsumerProfile} isSaving={isSaving} />
      </div>
    </div>
  );
};

export default AddConsumerProfile;
