import React from "react";
import useStore from "../../../../context/useStore";
import tools from "../../../../helpers/tools";
import Table from "../../../common/Table/Table";

const IngredientsTable = ({
  ingredients,
  recipeIngredients,
  multiplicator,
}) => {
  const [state, dispatch] = useStore();
  const constants = state.constants.items;

  return (
    <Table
      noMargin={true}
      fields={[
        {
          name: "Produit",
          path: "ingredient",
          render: (el) => ingredients.find((i) => i.id == el)?.name,
        },
        {
          name: "Quantité",
          path: "*",
          render: (el) => (
            <>
              {tools.round(el.qty)}{" "}
              {el.error?.ingredient && (
                <span className="text-danger d-block small">
                  {el.error.ingredient}
                </span>
              )}
            </>
          ),
        },
        {
          name: "Unité",
          path: "unit",
          render: (el) =>
            constants.INGREDIENT_UNITS.find((i) => i.id == el)?.name,
        },
      ]}
      datas={recipeIngredients.map((ing) => ({
        ...ing,
        qty: (parseInt(multiplicator) * parseFloat(ing.qty)) / 10,
      }))}
    />
  );
};

export default IngredientsTable;
