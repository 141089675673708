import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import dataService from "../../../../helpers/dataService";
import Control from "../../../common/Controls/Control";
import Loader from "../../../common/Loader";
import SaveBtn from "../../../common/SaveBtn";
import useProductionSites from "../../../../hooks/useProductionSites";

const Filters = ({ search, setSearch, getDatas, isLoading, ...props }) => {
  const { productionSites } = useProductionSites(true);

  let years = [];
  for (var i = 2021; i <= new Date().getFullYear(); i++) {
    years.push({
      id: i,
      name: i + "",
    });
  }

  return (
    <div className="row mb-3">
      <div className="col-6">
        <Control
          label="Cuisine"
          name="productionSite"
          type="select"
          value={search.productionSite}
          datas={productionSites}
          dataIndex="id"
          dataLabel="name"
          change={(e) =>
            setSearch({ ...search, productionSite: e.target.value })
          }
        />
      </div>
      <div className="col-6">
        <Control
          label="Année"
          type="select"
          datas={years}
          value={search.year}
          change={(e) => setSearch({ ...search, year: e.target.value })}
        />
        <Control
          label="Mois"
          type="select"
          datas={[
            {
              id: 1,
              name: "01 - Janvier",
            },
            {
              id: 2,
              name: "02 - Février",
            },
            {
              id: 3,
              name: "03 - Mars",
            },
            {
              id: 4,
              name: "04 - Avril",
            },
            {
              id: 5,
              name: "05 - Mai",
            },
            {
              id: 6,
              name: "06 - Juin",
            },
            {
              id: 7,
              name: "07 - Juillet",
            },
            {
              id: 8,
              name: "08 - Août",
            },
            {
              id: 9,
              name: "09 - Septembre",
            },
            {
              id: 10,
              name: "10 - Octobre",
            },
            {
              id: 11,
              name: "11 - Novembre",
            },
            {
              id: 12,
              name: "12 - Décembre",
            },
          ]}
          value={search.month}
          change={(e) => setSearch({ ...search, month: e.target.value })}
        />
      </div>

      <div className="col-12 offset-6 mt-2 justify-content-start align-items-center d-flex noprint">
        <button className="btn btn-secondary btn-sm mb-1" onClick={getDatas}>
          {isLoading ? <Loader color="white" /> : "Afficher"}
        </button>
      </div>
    </div>
  );
};

export default withRouter(Filters);
