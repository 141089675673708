import React, { useState } from "react";
import Control from "../../../common/Controls/Control";
import DisplayDates from "../../../common/DisplayDates";
import useApiDatas from "../../../common/Hooks/useApiDatas";
import Page from "../../../common/layout/Page";
import ModifyPassword from "../../../common/ModifyPassword/ModifyPassword";
import SaveBtn from "../../../common/SaveBtn";

const ManagerDetail = (props) => {
  const {
    constants,
    errors,
    setErrors,
    isLoading,
    isInit,
    otherDatas,
    itemId,
    isEdit,
    item,
    setItem,
    saveItem,
    searchDatas,
  } = useApiDatas({
    itemPaths: {
      path: "/managers/", //url de redirection
      get: "managers/" + props.match.params.id,
      post: "managers",
      patch: "managers/" + props.match.params.id,
    },
    itemId: props.match.params.id,
    dependenciesPath: ["productionsites"],
    itemParams: {
      name: "",
      code: "",
      productionSites: [],
      role: "",
    },
  });

  const [showPassword, setShowPassword] = useState(false);

  console.log("isInit", isInit, otherDatas.productionsites);

  return (
    <Page
      container="container"
      back="/managers"
      title={(isEdit ? "Modification" : "Détail") + " de l'utilisateur"}
      errors={errors}
      isInit={isInit}
    >
      <div className="row">
        {isEdit && (
          <div className="col-12 p-0">
            <ModifyPassword
              route={"managers/" + itemId + "/password"}
              showPassword={showPassword}
              setShowPassword={setShowPassword}
            />
          </div>
        )}
        {!showPassword && (
          <>
            <div className="col-12 p-0">
              <Control
                label="Rôle"
                name="role"
                type="select"
                value={item.role}
                change={(e) => setItem({ ...item, role: e.target.value })}
                datas={constants.ROLES_MANAGER}
                error={errors}
                setErrors={setErrors}
                required={true}
              />
              <Control
                label="Civilité"
                name="gender"
                type="select"
                value={item.gender}
                change={(e) => setItem({ ...item, gender: e.target.value })}
                datas={[
                  { id: 1, name: "Monsieur" },
                  { id: 2, name: "Madame" },
                ]}
                error={errors}
                setErrors={setErrors}
                required={true}
              />
              <Control
                label="Nom"
                name="lastname"
                type="text"
                value={item.lastname}
                change={(e) => setItem({ ...item, lastname: e.target.value })}
                error={errors}
                setErrors={setErrors}
                required={true}
              />
              <Control
                label="Prénom"
                name="firstname"
                type="text"
                value={item.firstname}
                change={(e) => setItem({ ...item, firstname: e.target.value })}
                error={errors}
                setErrors={setErrors}
                required={true}
              />
              <Control
                label="Email"
                name="email"
                type="email"
                value={item.email}
                change={(e) => setItem({ ...item, email: e.target.value })}
                error={errors}
                setErrors={setErrors}
                required={true}
              />
              {!isEdit && (
                <Control
                  label="Mot de passe"
                  name="password"
                  type="password"
                  value={item.password}
                  change={(e) => setItem({ ...item, password: e.target.value })}
                  error={errors}
                  setErrors={setErrors}
                  required={true}
                />
              )}
              <Control
                label="Téléphone"
                name="phone"
                type="tel"
                value={item.phone}
                change={(e) => setItem({ ...item, phone: e.target.value })}
                error={errors}
                setErrors={setErrors}
                required={true}
              />

              <Control
                label="Cuisines"
                name="productionSites"
                type="checkBtnList"
                change={(e) =>
                  setItem({
                    ...item,
                    productionSites: e.target.value,
                  })
                }
                datas={otherDatas.productionsites}
                dataIndex="id"
                dataLabel="name"
                value={item.productionSites}
                btnLabel="Ajouter"
                required={true}
              />
            </div>
            <div className="d-flex justify-content-end w-100" data-priv="create_modify_managers">
              <DisplayDates createdAt={item.createdAt} updatedAt={item.updatedAt} />
              <SaveBtn save={saveItem} isSaving={isLoading} text="Enregistrer" type="primary" />
            </div>
          </>
        )}
      </div>
    </Page>
  );
};

export default ManagerDetail;
