import React from "react";
const DeliveryBlockSpecs = ({ data }) => {
  return (
    <div>
      <div className="row">
        <div className="col-12">
          <div className="d-flex mb-0 alert alert-warning">
            {data.specificities.map((spec) => (
              <span className="mr-2 d-center">
                <span className="badge badge-danger mr-1">{spec.qty}</span>
                {spec.name}
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryBlockSpecs;
