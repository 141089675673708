import React, { useEffect, useState } from "react";
import tools from "../../../../helpers/tools";
import LabelContainer from "../LabelContainer";
import ButtonItem from "./ButtonItem";

const SelectListInput = (props) => {
  const { name, value, k, btnInline, datas, dataIndex = "id", dataLabel = "name", change } = props;

  const [valueList, setValueList] = useState([...value]);

  useEffect(() => {
    setValueList(value);
  }, [value]);

  const handleChangeValueList = (e) => {
    let newValueList = [...valueList];
    if (valueList.includes(e.target.value)) {
      newValueList = newValueList.filter((v) => v != e.target.value);
    } else {
      newValueList.push(e.target.value);
    }
    console.log("newValueList", newValueList);
    //setValueList(newValueList);
    valueChange(newValueList);
  };

  const removeFromValueList = (val) => {
    const newValueList = valueList.filter((v) => v != val);
    //setValueList(newValueList);
    valueChange(newValueList);
  };

  const valueChange = (newValue) => {
    change(
      {
        target: {
          name: props.name,
          value: newValue,
          k: k,
        },
      },
      k
    );
  };

  return (
    <LabelContainer {...props} value={value}>
      <div
        className={" d-flex flex-fill " + (btnInline ? "d-flex flex-row flex-wrap " : "btn-group")}
      >
        <div className="d-flex flex-row flex-wrap">
          {valueList.map((val, valk) => (
            <div
              className="badge badge-primary d-flex align-items-center justify-content-between py-1 mb-1 mr-1"
              style={{ fontSize: 12 }}
              key={`badval${val}`}
              onClick={() => removeFromValueList(val)}
            >
              {tools.findIn(datas, val, dataIndex)[dataLabel]}
              <i className="fa fa-times ml-1" />
            </div>
          ))}
        </div>
        <select
          className="form-control"
          value=""
          onChange={handleChangeValueList}
          style={{
            ...props.inputStyle,
          }}
        >
          <option value="">Ajouter...</option>
          {datas
            .filter((d) => !valueList.includes(d[dataIndex]))
            .map((data, dk) => (
              <option value={data[dataIndex]} key={`opt${dk}`}>
                {data[dataLabel]}
              </option>
            ))}
        </select>
      </div>
    </LabelContainer>
  );
};

export default SelectListInput;
