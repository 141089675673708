import React from "react";
import useStoreState from "./Context/Store/useStoreState";

const MenuSwitcher = ({ showClientMenu, setShowClientMenu }) => {
  const { items, client_actions } = useStoreState();

  return items.menu?.slots?.length ? (
    <button
      data-priv="create_modify_menusite"
      className="btn btn-tertiary"
      onClick={() => setShowClientMenu(!showClientMenu)}
    >
      {showClientMenu ? (
        <>
          <i className="fa fa-arrow-left mr-2" />
          Aller au menu de référence
        </>
      ) : (
        <>
          Aller au menu site
          <i className="fa fa-arrow-right ml-2" />
        </>
      )}
    </button>
  ) : null;
};

export default MenuSwitcher;
