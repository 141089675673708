import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import Control from "../../../../../common/Controls/Control";
import useStoreState from "../../Context/Store/useStoreState";
import StipLoader from "./StipLoader";
import "react-calendar/dist/Calendar.css";
import "./menuref.scss";
import DateSelector from "./DateSelector";

const MenuRef = () => {
  const { items, items_actions } = useStoreState();
  const productionSites = [...items.productionSites];
  const { menuRef, utils } = { ...items };

  const change = (e) => {
    let { name, value } = e.target;
    var mf = { ...menuRef, [name]: value };
    items_actions.updateMenuRef(mf, name);
  };

  useEffect(() => {
    if (menuRef.productionSite.length) {
      items_actions.updateMenuRef(menuRef, "productionSite");
    }
  }, []);

  return (
    <>
      {utils.loadingMenuRef && <StipLoader />}
      <div className="p-2">
        <Control
          label="Cuisine"
          type="select"
          name="productionSite"
          datas={productionSites}
          value={menuRef.productionSite}
          change={change}
          disabled={utils.editSlotId}
        />
      </div>
      {menuRef.productionSite && (
        <>
          <DateSelector
            beginAt={menuRef.beginAt}
            disabled={utils.editSlotId}
            change={change}
          />

          <div className="h-100  text-primary d-flex p-2 d-flex flex-column  position-relative animated fadeInRight faster">
            <div className="position-relative">
              <div>Semaine N°{menuRef.num}</div>
              <div>
                du {DateTime.fromISO(menuRef.beginAt).toFormat("dd/MM/yyyy")}
              </div>
              <div>
                au {DateTime.fromISO(menuRef.endAt).toFormat("dd/MM/yyyy")}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default MenuRef;
