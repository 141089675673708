import React, { useState, useEffect } from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
import useStore from "../../../context/useStore";
import { authActions } from "../../../context/actions/authActions";
import { sidenavItems } from "../../../sidenavItems";

const Header = withRouter((props) => {
  const [state, dispatch] = useStore();
  const actions = authActions(state, dispatch);

  const items = sidenavItems;
  const [subMenu, setSubMenu] = useState([]);
  const [activeLink, setActiveLink] = useState(-1);
  const [subActiveLink, setSubActiveLink] = useState(-1);

  useEffect(() => {
    setSubActiveLink(-1);
  }, [activeLink]);

  useEffect(() => {
    console.log(props.location.pathname);
    items.forEach((item, itk) => {
      if (item.items) {
        if (item.items.find((it) => it.path == props.location.pathname)) {
          console.log("found in " + item.name);
          setActiveLink(itk);
          setSubMenu(item.items);
          var subIndex = item.items.findIndex((it) => it.path == props.location.pathname);
          console.log("subIndex", subIndex);
          setTimeout(() => {
            setSubActiveLink(subIndex);
          }, 500);
        }
      }
    });
  }, [props.location.pathname]);

  useEffect(() => {
    window.addEventListener("resize", () => {});

    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, []);

  // const getMenu = (items, isDp = false) => {
  //   return items.map((it) =>
  //     it.path == "#" ? (
  //       <>
  //         <li key={`${it.path}`} className="nav-item dropdown">
  //           <a
  //             className={`nav-link dropdown-toggle text-center text-${
  //               isDp ? "secondary" : "white"
  //             }`}
  //             href="#"
  //             id="navbarDropdown"
  //             role="button"
  //             data-toggle="dropdown"
  //             aria-haspopup="true"
  //             aria-expanded="false"
  //           >
  //             {it.name}
  //           </a>
  //           <div
  //             className="dropdown-menu  bg-primary text-white rounded"
  //             aria-labelledby="navbarDropdown"
  //           >
  //             {getMenu(it.items, true)}
  //           </div>
  //         </li>
  //       </>
  //     ) : isDp ? (
  //       <Link key={it.path} to={it.path} className="dropdown-item text-white">
  //         <i className={`mr-2 fa fa-${it.icon}`} />
  //         {it.name}
  //       </Link>
  //     ) : (
  //       <li key={it.path} className="nav-item">
  //         <Link className="nav-link text-white" to={it.path}>
  //           <i className={`mr-2 fa fa-${it.icon}`} />
  //           {it.name}
  //         </Link>
  //       </li>
  //     )
  //   );
  // };

  var authData = state.auth;
  return authData.isAuthenticated && !authData.user.mustRenewPassword ? (
    <>
      <nav
        id="sidebar"
        className={
          "noprint navbar navbar-expand-lg shadow-sm bg-white mb-5 text-white p-0 d-flex flex-column  w-100"
        }
      >
        <div
          className="collapse navbar-collapse d-flex flex-row justify-content-center align-items-center  w-100 bg-white "
          id="navbarSupportedContent"
          style={{ transition: "400ms" }}
        >
          {/* <ul className="navbar-nav mr-auto">{getMenu(items)}</ul> */}
          <ul
            className=" top-menubar py-1 mb-0 d-flex flex-wrap"
            style={{ listStyle: "none", minHeight: 44 }}
          >
            {items.map((it, itk) => {
              var isActive = itk == activeLink;
              return (
                <li className="nav-item" key={`menuitem${itk}`}>
                  <Link
                    to={it.path}
                    data-priv={it.dataPriv}
                    data-attr={isActive ? "active" : ""}
                    className={` nav-link  btn btn-sm mx-1 btn-link ${
                      it.path == "#" ? "has-children" : ""
                    }`}
                    style={{
                      fontWeight: "700",
                    }}
                    onClick={() => {
                      setActiveLink(itk);
                      if (it.path == "#") {
                        setSubMenu(it.items);
                      } else {
                        setSubMenu([]);
                      }
                    }}
                  >
                    {it.path != "#" ? <i className={`fa fa-${it.icon} mr-2`} /> : null}
                    {it.name}
                    {/* {it.path == "#" ? (
                      <i className={`fa fa-${it.icon} ml-1`} />
                    ) : null} */}
                  </Link>
                </li>
              );
            })}
            <li className="nav-item">
              <button
                className={` nav-link  btn btn-sm mx-1 btn-link `}
                style={{
                  fontWeight: "500",
                }}
                onClick={() => {
                  actions.logoutUser();
                }}
              >
                <i className={`fa fa-sign-out mr-2`} />

                {authData.user.firstname}
              </button>
            </li>
          </ul>
        </div>
        {subMenu.length ? (
          <div className="w-100 d-center overflow-hidden ">
            <ul
              className=" px-2 mb-1 bottom-menubar d-flex flex-wrap"
              style={{ listStyle: "none" }}
            >
              {subMenu.map((it, itk) => {
                var isActive = itk == subActiveLink;
                return (
                  <li className="nav-item mt-2" key={`submenuitem${itk}${it.path}`}>
                    <Link
                      to={it.path}
                      data-priv={it.dataPriv}
                      className={` nav-link mx-1 btn btn-sm btn-link animated fadeInDown`}
                      style={{
                        animationDelay: itk * 30 + "ms",
                        fontWeight: "600",
                        animationDuration: "200ms",
                      }}
                      onClick={() => setSubActiveLink(itk)}
                      data-attr={isActive ? "active" : ""}
                    >
                      <i className={`fa fa-${it.icon} mr-2`} />

                      {it.name}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        ) : null}
      </nav>
    </>
  ) : (
    <></>
  );
});

export default withRouter(Header);
