import React from "react";
import useStoreState from "../../../../Context/Store/useStoreState";
import SlotStack from "./SlotStack";

const ConsumerProfileSlot = ({ dishSlots, consumerProfile }) => {
  return consumerProfile ? (
    <div className="d-flex align-items-start justify-content-start flex-row mb-2 pb-2 border-bottom">
      <div
        className="d-center p-2  text-white border-right"
        style={{
          background: consumerProfile.color,
          borderTopRightRadius: 5,
          borderBottomRightRadius: 5,
          writingMode: "tb-rl",
          transform: "rotate(180deg)",
        }}
      >
        {consumerProfile.name}
      </div>
      <div className="d-flex flex-column flex-fill h-100">
        {dishSlots.map((ds, dsk) => {
          /**
           * @todo 
           * Patch Romain du 11/08/21 pour éviter que ça plante mais sans comprendre
           * pourquoi on se retrouve avec un null
           */
          return (ds &&
            <SlotStack
              key={`dsk${ds.id}${dsk}`}
              dishSlot={ds}
              dsk={dsk}
              dishSlots={dishSlots}
            />
          );
        })}
      </div>
    </div>
  ) : null;
};

export default ConsumerProfileSlot;
