import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useStore from "../../../../context/useStore";
import dataService from "../../../../helpers/dataService";
import Control from "../../../common/Controls/Control";
import DynamicList from "../../../common/DynamicList";
import useApiDatas from "../../../common/Hooks/useApiDatas";
import Page from "../../../common/layout/Page";
import SaveBtn from "../../../common/SaveBtn";
import controller from "./consumerTypeController";

const ConsumerTypeDetail = (props) => {
  var pathId = props.match.params.id;
  const {
    constants,
    errors,
    setErrors,
    isLoading,
    setIsLoading,
    isInit,
    itemId,
    isEdit,
    item,
    setItem,
    saveItem,
    otherDatas,
  } = useApiDatas({
    itemPaths: {
      path: "/consumertypes/", //url de redirection
      get: "consumertypes/" + pathId, //get item
      post: "consumertypes", //post item
      patch: "consumertypes/" + pathId, //patch item
    },
    dependenciesPath: ["dishtypes"],
    itemId: pathId, //create ou id
    itemParams: {
      name: "",
      displayName: "",
      dishTypes: [], // {dishType:<id>, quantities: [{qty, unit}]}
    },
  });
  const consumerType = item;
  const setConsumerType = setItem;
  const dishTypes = otherDatas.dishtypes;

  if (isInit) {
    controller.addDishTypesTyConsumerType(consumerType, setConsumerType, dishTypes);
  }

  return (
    <Page
      container="container"
      back="/consumerTypes"
      title={(isEdit ? "Modification" : "Détail") + " du type de consommateur"}
      errors={errors}
      contentClassname="mx-auto"
      isInit={isInit}
    >
      {isInit && (
        <div className="row custom-card">
          {consumerType.isReferent && (
            <div className="col-12 text-primary mb-2">
              <i className="fa fa-star mr-2" />
              Type de consommateur référent lors des calculs dans les fiches techniques
            </div>
          )}
          <div className="col-12 col-md-6">
            <Control
              label="Nom"
              name="name"
              type="text"
              value={consumerType.name}
              change={(e) => setConsumerType({ ...consumerType, name: e.target.value })}
              error={errors}
              setErrors={setErrors}
              required={true}
            />
          </div>
          <div className="col-12 col-md-6 ">
            <Control
              label="Nom affiché au client"
              name="displayName"
              type="text"
              value={consumerType.displayName}
              change={(e) =>
                setConsumerType({
                  ...consumerType,
                  displayName: e.target.value,
                })
              }
              error={errors}
              setErrors={setErrors}
              required={true}
            />
          </div>
          <div className="col-12 ">
            <h4>Quantités par type de plat</h4>
            <div className="row">
              {dishTypes.length &&
                dishTypes.map((q, qk) => {
                  let ctDishType = consumerType.dishTypes.find((dt) => dt.dishType == q.id);
                  return (
                    <div className="col-12   " key={"ctdt" + qk}>
                      <div className="m-1 p-2 shadow-sm">
                        <div className="font-weight-bold mt-2">{q.name}</div>
                        <div className="row">
                          <div className="col-12 col-md-6">
                            <Control
                              label="Quantité (G)"
                              type="text"
                              name="qty"
                              value={
                                ctDishType
                                  ? ctDishType.quantities.find((qt) => qt.unit == 1).qty
                                  : ""
                              }
                              k={qk}
                              change={(e) => {
                                var ct = JSON.parse(JSON.stringify(consumerType));
                                if (ct.dishTypes.find((ctd) => ctd.dishType == q.id)) {
                                  ct.dishTypes = ct.dishTypes.map((c) => {
                                    if (c.dishType == ctDishType.dishType) {
                                      if (!c.quantities) {
                                        c.quantities = [{ qty: e.target.value, unit: 1 }];
                                      } else {
                                        c.quantities.find((qt) => qt.unit == 1).qty =
                                          e.target.value;
                                      }
                                    }
                                    return c;
                                  });
                                } else {
                                  ct.dishTypes.push({
                                    dishType: q.id,
                                    quantities: [
                                      { qty: e.target.value, unit: 1 },
                                      { qty: "", unit: 2 },
                                    ],
                                  });
                                }
                                setConsumerType(ct);
                              }}
                              suffix="GRAMMES"
                              error={errors}
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <Control
                              label="Quantité (P)"
                              type="text"
                              name="qty"
                              value={
                                ctDishType
                                  ? ctDishType.quantities.find((qt) => qt.unit == 2).qty
                                  : ""
                              }
                              k={qk}
                              change={(e) => {
                                var ct = JSON.parse(JSON.stringify(consumerType));
                                if (ct.dishTypes.find((ctd) => ctd.dishType == q.id)) {
                                  ct.dishTypes = ct.dishTypes.map((c) => {
                                    if (c.dishType == ctDishType.dishType) {
                                      if (!c.quantities) {
                                        c.quantities = [{ qty: e.target.value, unit: 2 }];
                                      } else {
                                        c.quantities.find((qt) => qt.unit == 2).qty =
                                          e.target.value;
                                      }
                                    }
                                    console.log("C", c);
                                    return c;
                                  });
                                } else {
                                  ct.dishTypes.push({
                                    dishType: q.id,
                                    quantities: [
                                      { qty: "", unit: 1 },
                                      { qty: e.target.value, unit: 2 },
                                    ],
                                  });
                                }
                                setConsumerType(ct);
                              }}
                              suffix="PIÈCES"
                              error={errors}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="col-12 mt-4" data-priv="create_modify_consumer_types">
            <div className="d-flex justify-content-center w-100">
              <SaveBtn save={saveItem} isSaving={isLoading} text="Enregistrer" type="primary" />
            </div>
          </div>
        </div>
      )}
    </Page>
  );
};

export default ConsumerTypeDetail;
