import { toast } from "react-toastify";
import dataService from "../../../../../../helpers/dataService";

export const itemsActions = (state, dispatch) => {
  const initItems = async (loadUrls) => {
    dispatch({ type: "IS_LOADING", payload: true });
    var allPromises = [];
    var actionUrls = {};
    var items = {};
    loadUrls.forEach((url) => {
      actionUrls[url.name] = { ...url.paths };
      if (url.paths?.get && url.fetchOnLoad) {
        allPromises.push(
          new Promise((resolve, reject) => {
            dataService.get(url.paths.get, resolve, reject);
          })
        );
      }
      if (!url.fetchOnLoad) {
        allPromises.push(
          new Promise((resolve, reject) => {
            resolve(url.initialState);
          })
        );
        // items[url.name] = url.initialState;
        // console.log("it", items);
      }
    });
    if (allPromises.length) {
      Promise.all(allPromises)
        .then((values) => {
          values.forEach((value, key) => {
            items[loadUrls[key].name] = value;
          });

          dispatch({
            type: "INIT_ITEMS",
            payload: { items, actionUrls },
          });
        })
        .catch((err) => {
          console.log("error", err);
          Object.values(err).forEach((er) => toast.error(er));
        });
    } else {
      var defaultItems = {};
      loadUrls.forEach((url) => {
        defaultItems[url.name] = url.initialState;
      });

      dispatch({
        type: "INIT_ITEMS",
        payload: { items: defaultItems, actionUrls },
      });
    }
  };

  const updateItemObject = (itemName, e) => {
    const { name, value, type, checked, k } = e.target;
    var newItem = { ...state.items[itemName] };

    if (type == "checkbox") {
      newItem[name] = checked ? true : false;
    } else {
      newItem[name] = value;
    }

    dispatch({
      type: "UPDATE_ITEM",
      payload: {
        item: itemName,
        value: newItem,
      },
    });
  };
  const setEntitySelected = (type, id) => {
    dispatch({ type: "SET_ENTITY_SELECTED", payload: { type, id } });
  };

  const saveSite = (site) => {
    dispatch({
      type: "SAVING_SITE",
      payload: true,
    });
    if (site.id) {
      dataService.patch(
        `sites/${site.id}`,
        site,
        (datas) => {
          toast.success("Le site a bien été enregistré");
          var sites = [...state.items.sites.map((s) => (s.id == site.id ? site : s))];

          dispatch({
            type: "UPDATE_SITES",
            payload: sites,
          });
        },
        (err) =>
          dispatch({
            type: "SET_ERRORS",
            payload: err,
          })
      );
    } else {
      dataService.post(
        `sites`,
        site,
        (datas) => {
          toast.success("Le site a bien été enregistré");
          var sites = [...state.items.sites, { ...datas }];

          dispatch({
            type: "UPDATE_SITES",
            payload: sites,
          });
        },
        (err) =>
          dispatch({
            type: "SET_ERRORS",
            payload: err,
          })
      );
    }
  };
  const saveContract = (contract) => {
    dispatch({
      type: "SAVING_CONTRACT",
      payload: true,
    });
    if (contract.id) {
      dataService.patch(
        `clientcontracts/${contract.id}`,
        contract,
        (datas) => {
          toast.success("Le contrat a bien été enregistré");
          var contracts = [...state.items.contracts.map((s) => (s.id == contract.id ? contract : s))];

          dispatch({
            type: "UPDATE_CONTRACTS",
            payload: contracts,
          });
        },
        (err) =>
          dispatch({
            type: "SET_ERRORS",
            payload: err,
          })
      );
    } else {
      dataService.post(
        `clientcontracts`,
        contract,
        (datas) => {
          toast.success("Le contrat a bien été enregistré");
          var contracts = [...state.items.contracts, { ...datas }];

          dispatch({
            type: "UPDATE_CONTRACTS",
            payload: contracts,
          });
        },
        (err) =>
          dispatch({
            type: "SET_ERRORS",
            payload: err,
          })
      );
    }
  };

  const addSlotCode = (slotCode) => {
    dispatch({
      type: "UPDATE_ITEM",
      payload: {
        item: "slotCodes",
        value: [...state.items.slotCodes, { ...slotCode }],
      },
    });
  };

  const saveItemObject = (itemName, shouldRedirect = true) => {
    dispatch({ type: "IS_LOADING", payload: true });
    var itemData = { ...state.items[itemName] };
    if (itemData.id) {
      dataService.patch(
        state.actionUrls[itemName].patch,
        itemData,
        (datas) => {
          dispatch({
            type: "UPDATE_ITEM",
            payload: { item: itemName, value: datas },
          });
        },
        (errors) => {
          dispatch({ type: "SET_ERRORS", payload: errors });
        },
        () => dispatch({ type: "IS_LOADING", payload: false })
      );
    } else {
      dataService.post(
        state.actionUrls[itemName].post,
        itemData,
        (datas) => {
          dispatch({
            type: "UPDATE_ITEM",
            payload: { item: itemName, value: datas },
          });
          if (shouldRedirect) {
            window.location.href = `/${state.actionUrls[itemName].page}/${datas.id}`;
          }
        },
        (errors) => {
          dispatch({ type: "SET_ERRORS", payload: errors });
        },
        () => dispatch({ type: "IS_LOADING", payload: false })
      );
    }
  };

  const removeContract = (id) => {
    dataService.remove(
      `clientcontracts/${id}`,
      {},
      (datas) => {
        toast.success("Contrat supprimé avec succès");
        dispatch({
          type: "UPDATE_CONTRACTS",
          payload: state.items.contracts.filter((c) => c.id != id),
        });
      },
      (err) => {
        console.log(err);
      }
    );
  };
  const removeSite = (id) => {
    dataService.remove(
      `sites/${id}`,
      {},
      (datas) => {
        toast.success("Site supprimé avec succès");
        dispatch({
          type: "UPDATE_SITES",
          payload: state.items.sites.filter((c) => c.id != id),
        });
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const activateUser = (id, isActivated) => {
    dispatch({
      type: "SAVING_USER",
      payload: true,
    });
    dataService.patch(`customers/${id}/activation`, { isActivated }, (datas) => {
      toast.success("L'utilisateur a bien été activé.");
      var users = [...state.items.users.map((s) => (s.id == id ? { ...s, isActivated: isActivated } : s))];
      dispatch({
        type: "UPDATE_USERS",
        payload: users,
      });
    });
  };

  const saveUser = (user) => {
    dispatch({
      type: "SAVING_USER",
      payload: true,
    });
    if (user.id) {
      dataService.patch(
        `customers/${user.id}`,
        user,
        (datas) => {
          toast.success("L'utilisateur a bien été enregistré");
          var users = [...state.items.users.map((s) => (s.id == user.id ? user : s))];

          dispatch({
            type: "UPDATE_USERS",
            payload: users,
          });
        },
        (err) =>
          dispatch({
            type: "SET_ERRORS",
            payload: err,
          })
      );
    } else {
      dataService.post(
        `customers`,
        user,
        (datas) => {
          toast.success("L'utilisateur a bien été enregistré");
          var users = [...state.items.users, { ...datas }];

          dispatch({
            type: "UPDATE_USERS",
            payload: users,
          });
        },
        (err) =>
          dispatch({
            type: "SET_ERRORS",
            payload: err,
          })
      );
    }
  };

  const removeUser = (id) => {
    dataService.remove(
      `customers/${id}`,
      {},
      (datas) => {
        toast.success("Utilisateur supprimé avec succès");
        dispatch({
          type: "UPDATE_USERS",
          payload: state.items.users.filter((c) => c.id != id),
        });
      },
      (err) => {
        console.log(err);
      }
    );
  };

  return {
    initItems,
    updateItemObject,
    saveItemObject,
    setEntitySelected,
    saveSite,
    saveContract,
    addSlotCode,
    removeContract,
    removeSite,
    saveUser,
    activateUser,
    removeUser,
  };
};
