import React from "react";
import tools from "../../../../helpers/tools";
import useSpecificitiesSummary from "./useSpecificitiesSummary";

const SpecSummaryTotalTable = ({ sites, specificities, textures }) => {
  const { specSummary, filters } = useSpecificitiesSummary();

  const calculateTotalForSpecificityAndTexture = (specificityId, textureId) => {
    let total = 0;
    sites.forEach((client) => {
      if (client.specificities[specificityId]) {
        if (client.specificities[specificityId][textureId]) {
          total += client.specificities[specificityId][textureId];
        }
      }
    });

    return total;
  };

  return (
    <>
      <table className="table table-bordered table-sm table-small-padding  bg-white shadow-sm hover-table ">
        <thead>
          <tr className="bg-default">
            <th style={{ minWidth: 200, width: 200 }}>TOTAL/Spécificités</th>

            {specificities.map((specificity) => {
              return (
                <th
                  className="text-center"
                  style={{ minWidth: 200 }}
                  key={`thcol${specificity.id}`}
                >
                  {specificity.name}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          <tr className="bg-default">
            <td>TOTAL</td>
            {specificities.map((specificity) => {
              return (
                <td key={`sp${specificity.id}`}>
                  <table className="table-borderless w-100">
                    <thead>
                      <tr>
                        {textures.map((texture, tk) => (
                          <th
                            className={`${
                              tk < textures.length - 1 && "border-right"
                            } text-black-50 text-center`}
                          >
                            {tools.getFirstLetters(texture.name, 3)}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {textures.map((texture, tk) => {
                          let qty = calculateTotalForSpecificityAndTexture(
                            specificity.id,
                            texture.id
                          );
                          let qtyClassName = "font-weight-lighter text-black-50";
                          if (qty) {
                            qtyClassName = "font-weight-bolder text-dark";
                          }
                          return (
                            <td
                              className={`text-center ${
                                tk < textures.length - 1 && "border-right"
                              } ${qtyClassName}`}
                            >
                              {qty}
                            </td>
                          );
                        })}
                      </tr>
                    </tbody>
                  </table>
                </td>
              );
            })}
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default SpecSummaryTotalTable;
