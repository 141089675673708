import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { modalActions } from "../../../../context/actions/modalActions";
import useStore from "../../../../context/useStore";
import dataService from "../../../../helpers/dataService";
import tools from "../../../../helpers/tools";
import Page from "../../../common/layout/Page";
import NowTime from "../NowTime";
import Filters from "./Filters";
import PackingCsv from "./PackingCsv";

const PackingSummary = () => {
  const [state, dispatch] = useStore();
  const mActions = modalActions(state, dispatch);
  const [isLoading, setIsLoading] = useState(false);
  const [datas, setDatas] = useState([]);
  const [searchDone, setSearchDone] = useState(false);
  const { DAYS, CONSUME_TIMES } = state.constants.items;

  var searchState = tools.getState("PackingSummary", null);
  const [search, setSearch] = useState(
    searchState
      ? JSON.parse(searchState.search)
      : {
          productionSite: "",
          date: "",
        }
  );
  const [comments, setComments] = useState([]);
  const [hiddenDishTypes, setHiddenDishTypes] = useState([]);
  const [hiddenTechnicalSheets, setHiddenTechnicalSheets] = useState([]);

  const getDatas = () => {
    setSearchDone(false);

    tools.saveState("PackingSummary", "search", JSON.stringify(search));
    setIsLoading(true);
    dataService.post(
      `summaries/packing`,
      search,
      (dats) => {
        setDatas(sortDatas(dats.slots));
        setComments(dats.comments);
      },
      (err) => {},
      () => {
        setIsLoading(false);
        setSearchDone(true);
      }
    );
  };

  const openCsv = () => {
    var filteredDatas = datas
      .filter((d) => !hiddenDishTypes.includes(d.id))
      .map((d) => ({
        ...d,
        technicalSheets: d.technicalSheets.filter(
          (t) => !hiddenTechnicalSheets.includes(t.technicalSheet.id)
        ),
      }));
    mActions.updateModal({
      isOpen: true,
      content: (
        <PackingCsv
          datas={filteredDatas}
          date={
            search.date
              ? DateTime.fromISO(search.date).setLocale("FR").toFormat("EEEE dd/MM/yyyy")
              : ""
          }
        />
      ),
    });
  };

  const sortDatas = (dats) => {
    return dats
      .sort((a, b) => (a.order > b.order ? 1 : -1))
      .map((d) => {
        return {
          ...d,
          technicalSheets: d.technicalSheets
            .map((t) => {
              return {
                ...t,
                sites: t.sites.sort((a, b) => (a.name > b.name ? 1 : -1)),
              };
            })
            .sort((a, b) => (a.technicalSheet.name > b.technicalSheet.name ? 1 : -1)),
        };
      });
  };

  const toggleDishType = (id) => {
    if (hiddenDishTypes.includes(id)) {
      setHiddenDishTypes(hiddenDishTypes.filter((d) => d != id));
    } else {
      setHiddenDishTypes([...hiddenDishTypes, id]);
    }
  };
  const toggleTechnicalSheet = (id) => {
    if (hiddenTechnicalSheets.includes(id)) {
      setHiddenTechnicalSheets(hiddenTechnicalSheets.filter((d) => d != id));
    } else {
      setHiddenTechnicalSheets([...hiddenTechnicalSheets, id]);
    }
  };

  return (
    <Page
      title="Récapitulatif de conditionnement"
      titleMargin="mb-3"
      isLoading={isLoading}
      printOrientation="landscape"
      container="container-fluid"
      style={{ maxWidth: "100%" }}
    >
      <NowTime />
      <Filters
        setSearch={setSearch}
        search={search}
        getDatas={getDatas}
        isLoading={isLoading}
        openCsv={openCsv}
      />
      {!datas.length ? (
        searchDone ? (
          <p>Aucune donnée trouvée</p>
        ) : null
      ) : (
        <>
          {datas.map((dishType) => (
            <table className="table table-borderless  w-100 bg-white mb-0 ">
              <thead>
                <tr className={`d-none ${hiddenDishTypes.includes(dishType.id) ? "noprint" : ""}`}>
                  <th
                    className="text-center text-secondary cursor-pointer p-1"
                    style={{
                      fontSize: 18,
                      borderTop: 0,
                      borderBottom: 0,
                      background: "#f1f1f1",
                    }}
                    onClick={() => toggleDishType(dishType.id)}
                  >
                    {/* <i
                      className={`fa noprint mr-2 fa-${
                        hiddenDishTypes.includes(dishType.id)
                          ? "low-vision"
                          : "eye"
                      }`}
                    />
                    {dishType.name} */}
                  </th>
                </tr>
              </thead>
              <tbody>
                {dishType.technicalSheets.map((technicalSheet) => (
                  <tr
                    className={
                      hiddenDishTypes.includes(technicalSheet.technicalSheet.id) ? "noprint" : ""
                    }
                  >
                    <td className="p-0 border-0">
                      <table
                        className={`w-100 table  m-0  ${
                          hiddenTechnicalSheets.includes(technicalSheet.technicalSheet.id)
                            ? "noprint"
                            : ""
                        }`}
                      >
                        <thead>
                          <tr></tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="p-0">
                              <table className="w-100 table hover-table m-0">
                                <thead>
                                  <tr>
                                    <th
                                      className="bg-default border cursor-pointer p-1"
                                      style={{ width: "30%", fontSize: 10 }}
                                      onClick={() =>
                                        toggleTechnicalSheet(technicalSheet.technicalSheet.id)
                                      }
                                    >
                                      <i
                                        className={`fa noprint mr-2 fa-${
                                          hiddenTechnicalSheets.includes(
                                            technicalSheet.technicalSheet.id
                                          )
                                            ? "low-vision"
                                            : "eye"
                                        }`}
                                      />
                                      {technicalSheet.technicalSheet.name}
                                    </th>
                                    <th
                                      className="bg-default border cursor-pointer p-1"
                                      style={{ width: 50, fontSize: 10 }}
                                    >
                                      Jour
                                    </th>
                                    <th
                                      className="bg-default border cursor-pointer p-1"
                                      style={{ width: 50, fontSize: 10 }}
                                    >
                                      Moment
                                    </th>
                                    {technicalSheet.textures.map((texture) => (
                                      <th
                                        className="bg-default border p-1"
                                        style={{
                                          fontSize: 10,
                                        }}
                                      >
                                        {texture.name}
                                      </th>
                                    ))}
                                  </tr>
                                </thead>
                                <tbody>
                                  {technicalSheet.sites.map((site) => (
                                    <tr style={{ fontSize: 10 }}>
                                      <td className="border p-1">{site.name}</td>
                                      <td className="border p-1 border-left">{site.day}</td>
                                      <td className="border p-1 border-left">{site.moment}</td>
                                      {technicalSheet.textures.map((texture) => (
                                        <td className="border p-1 border-left">
                                          {site.quantities[texture.id] ? (
                                            tools.transformQtyKg(
                                              site.quantities[texture.id].net,
                                              texture.unit
                                            )
                                          ) : (
                                            <i
                                              className="fa fa-times"
                                              style={{ color: "#bbbbbb" }}
                                            />
                                          )}
                                        </td>
                                      ))}
                                    </tr>
                                  ))}
                                  <tr style={{ fontSize: 10 }}>
                                    <td className=" text-primary font-weight-bold border p-1">
                                      TOTAL
                                    </td>
                                    <td className="border text-primary font-weight-bold p-1"></td>
                                    <td className="border text-primary font-weight-bold p-1"></td>
                                    {technicalSheet.textures.map((texture) => {
                                      var total = 0;
                                      technicalSheet.sites.forEach((site) => {
                                        var qtySite = site.quantities[texture.id];
                                        if (qtySite) {
                                          total += qtySite.net;
                                        }
                                      });
                                      return (
                                        <td className="border text-primary font-weight-bold p-1">
                                          {tools.transformQtyKg(total, texture.unit)}
                                        </td>
                                      );
                                    })}
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ))}
          <div className="row">
            {comments.map((comment, ck) => (
              <div className="col-4" key={`comment${ck}`}>
                <div className="custom-card mb-2" style={{ fontSize: 13 }}>
                  <div>
                    <i className="fa fa-comment mr-2" />
                    <strong>
                      {comment.site} - {comment.client}
                    </strong>
                  </div>
                  <p>{comment.comment}</p>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </Page>
  );
};

export default PackingSummary;
