import React from "react";

const ClientFilter = ({ clients, hiddenClients, setHiddenClients, inverted = false }) => {
  const toggleClient = (id) => {
    if (hiddenClients.includes(id)) {
      setHiddenClients(hiddenClients.filter((i) => i != id));
    } else {
      setHiddenClients([...hiddenClients, id]);
    }
  };
  const sortedClients = clients.sort((a, b) => (a.name > b.name ? 1 : -1));

  const getNewLetter = (client, clientIndex) => {
    const prevClient = sortedClients[clientIndex - 1];
    let newLetter = "";
    if (prevClient) {
      newLetter = prevClient.name[0] != client.name[0] ? client.name[0] : "";
    } else {
      newLetter = client.name[0];
    }
    return newLetter ? <div>{newLetter}</div> : null;
  };

  return !clients.length ? null : (
    <div className="row mb-3 noprint">
      <div className="col-12">
        <div>
          <button className="btn btn-link" onClick={() => setHiddenClients([])}>
            Tout afficher
          </button>
          <button
            className="btn btn-link"
            onClick={() => setHiddenClients(clients.map((c) => c.id))}
          >
            Tout masquer
          </button>
        </div>
        <div
          className=" bg-white p-2 border rounded"
          style={{
            height: 500,
            overflowY: "auto",
          }}
        >
          {clients
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((client, ck) => {
              var isActive = inverted
                ? hiddenClients.includes(client.id)
                : !hiddenClients.includes(client.id);
              const newLetter = getNewLetter(client, ck);
              return (
                <>
                  {newLetter ? <div className="w-100 font-weight-bold">{newLetter}</div> : null}
                  <button
                    className={`btn btn-xsm mb-1 mr-1 ${isActive ? "btn-primary" : "btn-default"}`}
                    onClick={() => toggleClient(client.id)}
                  >
                    {client.name}
                    {isActive ? <i className="fa fa-check ml-2" /> : null}
                  </button>
                </>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default ClientFilter;
