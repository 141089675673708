import React, { useState, useEffect } from "react";
import Page from "../../../common/layout/Page";
import useStore from "../../../../context/useStore";
import Table from "../../../common/Table/Table";
import { DateTime } from "luxon";
import dataService from "../../../../helpers/dataService";
import dataListController from "./dataListController";
import DataListFilters from "./DataListFilters";
import { Link } from "react-router-dom";
import Pagination from "../../../common/Pagination";
import useApiDatas from "../../../common/Hooks/useApiDatas";
import { sidePanelActions } from "../../../../context/actions/sidePanelActions";

import Loader from "../../../common/Loader";

var searchTimeout = null;

const TechnicalSheetList = () => {
  const {
    state,
    dispatch,
    constants,
    errors,
    setErrors,
    isLoading,
    setIsLoading,
    searchDatas,
    setSearchDatas,
    nbPages,
    setNbPages,
    getSearchDatas,
    deleteData,
    isInit,
    search,
    setSearch,
    otherDatas,
    setOtherDatas,
    nbResults,
  } = useApiDatas({
    resourcePath: "technicalsheets",
    page: "TechnicalSheetList",

    searchParams: {
      technicalSheet: { searchString: "" },
      page: 1,
    },
  });

  const sidePanel_actions = sidePanelActions(state, dispatch);

  // const downloadAll = () => {
  //   var initElements = document.querySelectorAll(".btn-pdf-init");

  //   initElements.forEach((initElem, k) => {
  //     //console.log(initElem);
  //     setTimeout(() => {
  //       initElem.click();
  //     }, k * 300);
  //   });
  // };

  const duplicateTechnicalSheet = (id) => {
    if (
      !window.confirm(
        "Confirmez vous la duplication de cette fiche technique ?"
      )
    )
      return false;
    setIsLoading(true);
    dataService.get(
      "technicalsheets/" + id + "/duplicate",
      (datas) => {
        window.location.href = "/technicalsheets/" + datas.id;
      },
      setErrors,
      () => setIsLoading(false)
    );
  };

  return (
    <Page
      title="Liste des fiches techniques"
      errors={errors}
      isLoading={isLoading}
      action={{
        to: "/technicalsheets/create",
        text: "Créer une fiche technique",
        dataPriv: "create_modify_technical_sheet",
      }}
    >
      <div className="row">
        <DataListFilters
          search={search}
          setSearch={setSearch}
          errors={errors}
        />
        <div className="d-flex flex-column justify-content-end w-100 align-items-end">
          <div style={{ maxWidth: 340 }}>
            <a
              target="_BLANK"
              className="btn btn-default btn-sm d-center mb-2"
              href="/print-all-ts-list"
            >
              <i className="fa fa-print mr-2" />
              Imprimer la liste des fiches techniques
            </a>
            <a
              target="_BLANK"
              className="btn btn-default btn-sm d-center mb-2"
              href="/print-all-ts"
            >
              <i className="fa fa-print mr-2" />
              Imprimer le détail des fiches techniques
            </a>

            {/* <a
              target="_BLANK"
              className="btn btn-default btn-sm d-center"
              href="/download-all-ts"
            >
              <i className="fa fa-download mr-2" />
              Télécharger toutes les fiches techniques
            </a> */}
          </div>
        </div>
      </div>
      <div className="row ">
        <div className="col-12">
          <Table
            nbResults={nbResults}
            isLoading={isLoading}
            exportOptions={{
              excludedFields: ["Actions"],
            }}
            fields={[
              { name: "Nom", path: "name" },
              {
                name: "Actions",
                path: "id",
                render: (el) => (
                  <>
                    <button
                      data-priv="create_modify_technical_sheet"
                      className="btn btn-secondary"
                      onClick={() => duplicateTechnicalSheet(el)}
                    >
                      <i className="fa fa-copy" />
                    </button>
                    <Link
                      to={`/technicalsheets/${el}`}
                      className="btn btn-default d-center d-inline-flex mx-1"
                    >
                      <i className="fa fa-edit mr-2" />
                      Modifier
                    </Link>
                    <button
                      data-priv="create_modify_technical_sheet"
                      className="btn btn-danger"
                      onClick={() => deleteData(el)}
                    >
                      <i className="fa fa-times" />
                    </button>
                  </>
                ),
              },
            ]}
            datas={searchDatas}
          />
          <Pagination
            nbPages={nbPages}
            page={search.page}
            changePage={(p) => setSearch({ ...search, page: p })}
          />
        </div>
      </div>
    </Page>
  );
};

export default TechnicalSheetList;
