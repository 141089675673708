import { toast } from "react-toastify";
import axios from "axios";

const calculations = {
  calcNutritionalValue: (lossPercentBrutToNet) => {},
  calcRatioBrutNetCru100g: (lossPercentBrutToNet) => {
    return 100 - parseFloat(lossPercentBrutToNet);
  },
  calcRatioNetCruCuit100g: (
    lossPercentBrutToNet,
    percentNetToCooked,
    isLoss
  ) => {
    return (
      100 -
      parseFloat(lossPercentBrutToNet) +
      (isLoss == 1
        ? -Math.abs(parseFloat(percentNetToCooked))
        : Math.abs(parseFloat(percentNetToCooked)))
    );
  },
};

export default calculations;
