import React from "react";

const MomentBlock = ({ moment, showMoments, setShowMoments, ...props }) => {
  const isHidden = !showMoments.includes(moment.id);

  return (
    <div>
      <div className="w-100 d-center pb-2 flex-column">
        <strong
          className="bg-default p-2 w-100 d-flex align-items-center justify-content-between cursor-pointer"
          style={{
            borderRadius: 5,
          }}
          onClick={() => {
            console.log(isHidden);
            if (isHidden) {
              setShowMoments([...showMoments, moment.id]);
            } else {
              setShowMoments([...showMoments.filter((s) => s != moment.id)]);
            }
          }}
        >
          {moment.name}
          <i className={`fa fa-${isHidden ? "plus" : "minus"}`} />
        </strong>
        {props.children}
      </div>
    </div>
  );
};

export default MomentBlock;
