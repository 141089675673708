import React, { useState } from "react";
import useStore from "../../../../context/useStore";
import dataService from "../../../../helpers/dataService";
import tools from "../../../../helpers/tools";
import Page from "../../../common/layout/Page";
import Filters from "./Filters";
import NowTime from "../NowTime";
import ClientComment from "./ClientComment";
import DeliveryBlockBody from "./DeliveryBlockBody";
import DeliveryBlockHeader from "./DeliveryBlockHeader";
import DeliveryBlockSpecs from "./DeliveryBlockSpecs";
import ClientFilter from "../common/ClientFilter";

const DeliverySummary = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [state, dispatch] = useStore();
  const constants = state.constants.items;
  const { UNITS, CONSUME_TIMES } = constants;
  var searchState = tools.getState("DeliverySummary", null);
  const [searchDone, setSearchDone] = useState(false);
  const [search, setSearch] = useState(
    searchState
      ? JSON.parse(searchState.search)
      : {
          productionSite: "",
          date: "",
        }
  );
  const [hiddenClients, setHiddenClients] = useState([]);

  const [datas, setDatas] = useState([]);

  const getDatas = () => {
    setSearchDone(false);
    tools.saveState("DeliverySummary", "search", JSON.stringify(search));
    setIsLoading(true);
    dataService.post(
      `summaries/delivery`,
      search,
      (dats) => {
        setDatas(dats.slots.sort((a, b) => (a.name > b.name ? 1 : -1)));
      },
      (err) => {},
      () => {
        setIsLoading(false);
        setSearchDone(true);
      }
    );
  };

  return (
    <Page
      title="Bons de livraison"
      titleMargin="mb-3 noprint"
      isLoading={isLoading}
      printOrientation="portrait"
      container="container-fluid"
      style={{ maxWidth: "100%" }}
    >
      <NowTime />
      <Filters setSearch={setSearch} search={search} getDatas={getDatas} isLoading={isLoading} />
      <ClientFilter
        clients={datas.map((d) => ({ name: d.name, id: d.id }))}
        hiddenClients={hiddenClients}
        setHiddenClients={setHiddenClients}
      />
      {!datas.length ? (
        searchDone ? (
          <p>Aucune donnée trouvée</p>
        ) : null
      ) : (
        <>
          {datas
            .filter((c) => !hiddenClients.includes(c.id))
            .map((data) => (
              <div className="break-after">
                <DeliveryBlockHeader data={data} date={search.date} />

                <DeliveryBlockBody data={data} />
                <DeliveryBlockSpecs data={data} />

                <ClientComment />
              </div>
            ))}
        </>
      )}
    </Page>
  );
};

export default DeliverySummary;
