import React, { useState } from "react";
import useStoreState from "../../Context/Store/useStoreState";
import Slot from "./Slot/Slot";
import PanelDay from "./PanelDay";
import PanelMoment from "./PanelMoment";
import "./client-panels.scss";
import PanelsActions from "./PanelsActions";
import { sidePanelActions } from "../../../../../../context/actions/sidePanelActions";

const Panels = () => {
  const { gState, items, gDispatch, items_actions } = useStoreState();
  const { DAYS, CONSUME_TIMES } = gState.constants.items;
  const spActions = sidePanelActions(gState, gDispatch);
  const [momentsVisible, setMomentsVisible] = useState([]);

  const toggleMomentVisible = (id) => {
    if (momentsVisible.includes(id)) {
      setMomentsVisible(momentsVisible.filter((v) => v != id));
    } else {
      setMomentsVisible([...momentsVisible, id]);
    }
  };

  return (
    <div className="row">
      <PanelsActions
        momentsVisible={momentsVisible}
        setMomentsVisible={setMomentsVisible}
      />
      {CONSUME_TIMES.map((ct, ctk) => (
        <div className="w-100 position-relative" key={`moment${ctk}`}>
          <button
            className="btn btn-light font-italic font-weight-bold w-100 text-left"
            onClick={() => toggleMomentVisible(ct.id)}
          >
            {ct.name}
          </button>

          <div
            className="col-panels"
            style={{
              display: momentsVisible.includes(ct.id) ? "flex" : "none",
              overflowX: "scroll",
            }}
          >
            {DAYS.map((day, dayk) => (
              <PanelDay day={day} moment={ct} key={`col-day-${dayk}`}>
                <PanelMoment consumeTime={ct} key={`col-moment-${ctk}`}>
                  {items.clientMenu.slots[ct.id] &&
                  items.clientMenu.slots[ct.id][day.id] ? (
                    <Slot
                      day={day}
                      moment={ct}
                      slots={items.clientMenu.slots[ct.id][day.id]}
                    />
                  ) : null}
                </PanelMoment>
              </PanelDay>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Panels;
