import React from "react";

const DishItem = ({ sl }) => {
  return (
    <div
      className="px-2 py-1 d-flex flex-fill justify-content-start align-items-center"
      style={{
        background: sl.color,
        fontSize: 12,
        color: "white",
        wordBreak: "break-word",
        lineHeight: "14px",
        fontWeight: "500",
      }}
    >
      {sl.technicalSheet.name}
    </div>
  );
};

export default DishItem;
