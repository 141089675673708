import React from "react";

const TableNav = ({ scrollTable }) => {
  return (
    <div
      className=" d-between noprint mx-auto noprint"
      style={{ maxWidth: 150 }}
    >
      <div className="d-center flex-row" role="group">
        <button
          style={{
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }}
          onClick={() => scrollTable(true, true)}
          className="btn btn-default   bg-white"
        >
          <i className="fa fa-angle-double-left" />
        </button>
        <button
          style={{
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          }}
          onClick={() => scrollTable(true)}
          className="btn btn-default   bg-white"
        >
          <i className="fa fa-angle-left" />
        </button>
      </div>
      <div className="d-center flex-row" role="group">
        <button
          style={{
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }}
          onClick={() => scrollTable(false)}
          className="btn btn-default bg-white"
        >
          <i className="fa fa-angle-right" />
        </button>
        <button
          style={{
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          }}
          onClick={() => scrollTable(false, true)}
          className="btn btn-default bg-white"
        >
          <i className="fa fa-angle-double-right" />
        </button>
      </div>
    </div>
  );
};

export default TableNav;
