import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";

const DateSelector = ({ beginAt, disabled, change, text = "Date du début de la semaine" }) => {
  const [displayDateSelector, setDisplayDateSelector] = useState(false);

  useEffect(() => {
    setDisplayDateSelector(false);
  }, [beginAt]);

  return (
    <div className="p-2">
      <label className="text-center d-center flex-column">
        <span className="label-text">{text}</span>
        <div className="d-flex">
          <button
            className="btn btn-primary d-center flex-column"
            disabled={disabled}
            onClick={() =>
              change({
                target: {
                  name: "beginAt",
                  value: DateTime.fromISO(beginAt).minus({ week: 1 }).toISO(),
                },
              })
            }
            style={{
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            }}
          >
            <i className="fa fa-chevron-left fa-2x" />
            {/* Semaine précédente */}
          </button>
          <button
            className="btn btn-primary"
            onClick={() => setDisplayDateSelector(!displayDateSelector)}
            disabled={disabled}
            style={{
              borderRadius: 0,
            }}
          >
            {DateTime.fromISO(beginAt).toFormat("dd/MM/yyyy")}
          </button>
          <button
            className="btn btn-primary d-center flex-column"
            disabled={disabled}
            onClick={() =>
              change({
                target: {
                  name: "beginAt",
                  value: DateTime.fromISO(beginAt).plus({ week: 1 }).toISO(),
                },
              })
            }
            style={{
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }}
          >
            <i className="fa fa-chevron-right fa-2x" />
            {/* Semaine suivante */}
          </button>
        </div>
      </label>
      {displayDateSelector && (
        <Calendar
          next2Label={null}
          prev2Label={null}
          onChange={(val) => {
            change({
              target: {
                name: "beginAt",
                value: DateTime.fromJSDate(val).startOf("week").startOf("day").toISO(),
              },
            });
          }}
          value={DateTime.fromISO(beginAt).toJSDate()}
        />
      )}
    </div>
  );
};

export default DateSelector;
