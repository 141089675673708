import { useElementScroll } from "framer-motion";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useStore from "../../../../context/useStore";
import dataService from "../../../../helpers/dataService";
import Control from "../../../common/Controls/Control";
import DynamicList from "../../../common/DynamicList";
import useApiDatas from "../../../common/Hooks/useApiDatas";
import Page from "../../../common/layout/Page";
import SaveBtn from "../../../common/SaveBtn";
import ClientContractForm from "./ClientContractForm";

const ClientContractDetail = (props) => {
  var clientId = props.match.params.clientid;
  var pathId = props.match.params.id;
  const {
    constants,
    errors,
    setErrors,
    isLoading,
    setIsLoading,
    isInit,
    itemId,
    isEdit,
    item,
    setItem,
    saveItem,
    otherDatas,
  } = useApiDatas({
    itemPaths: {
      path: "/contracts/" + clientId + "/", //url de redirection
      get: "clientcontracts/" + pathId, //get item
      post: "clientcontracts", //post item
      patch: "clientcontracts/" + pathId, //patch item
    },
    dependenciesPath: [
      `clients/${clientId}/sites`,
      `clients/${clientId}`,
      `slotcodes`,
      `productionsites`,
    ],
    itemId: pathId, //create ou id
    itemParams: {
      client: clientId,
      name: "",
      productionSite: "",
      sites: [],
      slotCodes: [],
      price: 0,
      priceHistorics: [],
      isDisplayToPrintedMenu: false,
    },
  });
  console.log(item, otherDatas, isInit, clientId);
  const clientContract = item;
  const setClientContract = setItem;
  const client = otherDatas[`clients/${clientId}/`];
  const slotCodes = otherDatas.slotcodes;
  const sites = otherDatas[`clients/${clientId}/sites`];
  const productionSites = otherDatas.productionsites;
  console.log("clientContract", clientContract);

  return (
    <Page
      container="container"
      back={`/contracts/${clientId}`}
      title={`${isEdit ? "Modification" : "Création"}
         du contrat du client 
        ${client?.name || ""}`}
      errors={errors}
      contentClassname="mx-auto"
      isInit={isInit}
    >
      {isInit && (
        <div className="row">
          <ClientContractForm
            clientContract={clientContract}
            setClientContract={setClientContract}
            sites={sites}
            productionSites={productionSites}
            slotCodes={slotCodes}
            errors={errors}
            setErrors={setErrors}
          />
          <div className="col-12 mt-4">
            <div className="d-flex justify-content-center w-100">
              <SaveBtn
                save={saveItem}
                isSaving={isLoading}
                text="Enregistrer"
                type="primary"
              />
            </div>
          </div>
        </div>
      )}
    </Page>
  );
};

export default ClientContractDetail;
