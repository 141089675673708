import { DateTime } from "luxon";
import React, { useState } from "react";
import useStore from "../../../../context/useStore";
import dataService from "../../../../helpers/dataService";
import tools from "../../../../helpers/tools";
import Page from "../../../common/layout/Page";
import Filters from "../Allotment/Filters";
import NowTime from "../NowTime";
import ClientFilter from "../common/ClientFilter";

const AllotmentSummary = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [state, dispatch] = useStore();
  const constants = state.constants.items;
  const { UNITS, CONSUME_TIMES } = constants;
  var searchState = tools.getState("AllotmentSummary", null);

  const [search, setSearch] = useState(
    searchState
      ? JSON.parse(searchState.search)
      : {
          productionSite: "",
          date: DateTime.local().toISO(),
        }
  );
  const [allotDate, setAllotDate] = useState(DateTime.local().toISO());
  const [hiddenDishTypes, setHiddenDishTypes] = useState([]);
  const [hiddenSites, setHiddenSites] = useState([]);
  const [hiddenClients, setHiddenClients] = useState([]);
  const [searchDone, setSearchDone] = useState(false);
  const [datas, setDatas] = useState([]);
  const [comments, setComments] = useState([]);

  const texturesOrder = ["Normale", "Moulinée", "Mixée", "Lisse", "Hachée"];

  const getDatas = () => {
    setSearchDone(false);
    tools.saveState("AllotmentSummary", "search", JSON.stringify(search));
    setIsLoading(true);
    dataService.post(
      `summaries/allotment`,
      search,
      (dats) => {
        setDatas(dats.slots);
        setComments(dats.comments);
      },
      (err) => {},
      () => {
        setIsLoading(false);
        setSearchDone(true);
      }
    );
  };

  const toggleSite = (id) => {
    if (hiddenSites.includes(id)) {
      setHiddenSites(hiddenSites.filter((d) => d != id));
    } else {
      setHiddenSites([...hiddenSites, id]);
    }
  };

  const toggleDishType = (id) => {
    if (hiddenDishTypes.includes(id)) {
      setHiddenDishTypes(hiddenDishTypes.filter((d) => d != id));
    } else {
      setHiddenDishTypes([...hiddenDishTypes, id]);
    }
  };
  const toggleClient = (id) => {
    if (hiddenClients.includes(id)) {
      setHiddenClients(hiddenClients.filter((d) => d != id));
    } else {
      setHiddenClients([...hiddenClients, id]);
    }
  };

  return (
    <Page
      title="Récapitulatif d'allotissement"
      titleMargin="mb-3"
      isLoading={isLoading}
      printOrientation="landscape"
      container="container-fluid"
      style={{ maxWidth: "100%" }}
    >
      <NowTime />
      <Filters
        setSearch={setSearch}
        search={search}
        getDatas={getDatas}
        isLoading={isLoading}
        allotDate={allotDate}
        setAllotDate={setAllotDate}
      />
      <ClientFilter
        clients={datas.map((site) => ({ ...site }))}
        hiddenClients={hiddenClients}
        setHiddenClients={setHiddenClients}
      />
      {!datas.length ? (
        searchDone ? (
          <p>Aucune donnée trouvée</p>
        ) : null
      ) : (
        <>
          {datas
            .filter((d) => !hiddenClients.includes(d.id))
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((site) => (
              <div className={`break-after ${hiddenSites.includes(site.id) ? "noprint" : ""}`}>
                <table className="table mb-0 table-bordered w-100 bg-white hover-table">
                  <thead>
                    <tr>
                      <th
                        className="bg-default text-center text-primary font-weight-bold cursor-pointer p-1 position-relative"
                        style={{ textTransform: "uppercase", fontSize: 12 }}
                        colSpan={site.client.textures.length + 3}
                        onClick={() => toggleSite(site.id)}
                      >
                        <i
                          className={`fa noprint mr-2 fa-${
                            hiddenSites.includes(site.id) ? "low-vision" : "eye"
                          }`}
                        />
                        {site.name}
                        <span style={{ position: "absolute", right: 0 }}>
                          liv. {tools.formatDate(search.date)} allot. {tools.formatDate(allotDate)}
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <>
                      <tr>
                        <td
                          className="w-25 text-primary text-center font-weight-bold p-1 "
                          style={{ fontSize: 10 }}
                        >
                          {site.client.name}
                        </td>
                        {site.client.textures
                          .sort(
                            (a, b) =>
                              texturesOrder.indexOf(a.name) > texturesOrder.indexOf(b.name)
                                ? 1
                                : -1,
                            { name: "" }
                          )
                          .map((texture) => (
                            <td
                              className="text-center font-weight-bold p-1"
                              style={{
                                fontSize: 10,
                              }}
                            >
                              <div className="d-center">
                                {texture.name}
                                <small
                                  className="rounded-circle bg-primary text-white d-center ml-2 font-weight-bold"
                                  style={{
                                    width: 15,
                                    height: 15,
                                  }}
                                >
                                  {texture.guests.length}
                                </small>
                              </div>
                            </td>
                          ))}
                        <td className="text-center p-1 font-weight-bold only-print">Check</td>
                        <td className="text-center p-1 font-weight-bold only-print">
                          Observations
                        </td>
                      </tr>
                      {site.client.dishTypes
                        .sort((a, b) => (a.order > b.order ? 1 : -1))
                        .map((dishType) => (
                          <>
                            <tr className={hiddenDishTypes.includes(dishType.id) ? "noprint" : ""}>
                              <td
                                colSpan={site.client.textures.length + 1}
                                className="font-weight-bold p-1 cursor-pointer "
                                style={{ fontSize: 10 }}
                                onClick={() => toggleDishType(dishType.id)}
                              >
                                <i
                                  className={`fa noprint mr-2 fa-${
                                    hiddenDishTypes.includes(dishType.id) ? "low-vision" : "eye"
                                  }`}
                                />
                                {dishType.name}
                              </td>
                              <td className="only-print p-1"></td>
                              <td className="only-print p-1"></td>
                            </tr>
                            {dishType.technicalSheets.map((technicalSheet) => (
                              <tr
                                className={hiddenDishTypes.includes(dishType.id) ? "noprint" : ""}
                                style={{ fontSize: 10 }}
                              >
                                <td className="p-1">{technicalSheet.name}</td>
                                {site.client.textures.map((texture) => {
                                  var textureQuantities = technicalSheet.quantities[texture.id];
                                  return (
                                    <td className="text-center  p-1">
                                      {textureQuantities ? (
                                        <div className="d-center">
                                          {tools.transformQtyKg(
                                            tools.round(textureQuantities.net),
                                            technicalSheet.unit
                                          )}
                                          <small
                                            className="rounded-circle bg-default d-center ml-2"
                                            style={{
                                              width: 18,
                                              height: 18,
                                            }}
                                          >
                                            {textureQuantities.qty}
                                          </small>
                                        </div>
                                      ) : (
                                        <i className="fa fa-times" style={{ color: "#bbbbbb" }} />
                                      )}
                                    </td>
                                  );
                                })}
                                <td className="only-print  p-1"></td>
                                <td className="only-print  p-1"></td>
                              </tr>
                            ))}
                          </>
                        ))}
                    </>
                  </tbody>
                </table>
                <div className="d-flex flex-row flex-wrap justify-content-start align-items-center alert alert-warning">
                  {site.specificities?.map((spec) => (
                    <small className="d-center mr-2">
                      <span className="badge badge-danger mr-1">{spec.qty}</span>
                      {spec.name}
                    </small>
                  )) || null}
                </div>
              </div>
            ))}
          <div className="row">
            {comments
              .filter((c) => !hiddenClients.includes(c.clientId))
              .map((comment, ck) => (
                <div className="col-4" key={`comment${ck}`}>
                  <div className="custom-card mb-2" style={{ fontSize: 13 }}>
                    <div>
                      <i className="fa fa-comment mr-2" />
                      <strong>
                        {comment.site} - {comment.client}
                      </strong>
                    </div>
                    <p>{comment.comment}</p>
                  </div>
                </div>
              ))}
          </div>
        </>
      )}
    </Page>
  );
};

export default AllotmentSummary;
