import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import dataService from "../../../../helpers/dataService";
import Control from "../../../common/Controls/Control";
import Loader from "../../../common/Loader";
import SaveBtn from "../../../common/SaveBtn";

const Filters = ({ search, setSearch, getDatas, isLoading, ...props }) => {
  const [productionSites, setProductionSites] = useState([]);
  console.log("PROPS", props);
  useEffect(() => {
    getProductionSites();
  }, []);
  const getProductionSites = () => {
    dataService.get("productionsites", setProductionSites);
  };

  return (
    <div className="row mb-3">
      <div className="col-6">
        <Control
          label="Cuisine"
          type="select"
          datas={productionSites}
          value={search.productionSite}
          dataIndex="id"
          dataLabel="name"
          change={(e) =>
            setSearch({ ...search, productionSite: e.target.value })
          }
        />
      </div>
      <div className="col-6">
        <Control
          label="Date"
          type="date"
          withNav
          value={search.date}
          change={(e) => setSearch({ ...search, date: e.target.value })}
        />
        <Control
          label="Date de sortie"
          type="date"
          withNav
          value={search.outletDate}
          change={(e) => setSearch({ ...search, outletDate: e.target.value })}
        />
      </div>

      <div className="col-12 offset-6 mt-2 justify-content-start align-items-center d-flex noprint">
        <button className="btn btn-secondary btn-sm mb-1" onClick={getDatas}>
          {isLoading ? <Loader color="white" /> : "Afficher"}
        </button>
        {search.productionSite && search.date && (
          <a
            target="_BLANK"
            href={`/sum-prod/${search.productionSite}/${search.date}`}
            className="btn btn-default btn-sm ml-2"
          >
            <i className="fa fa-link mr-2" />
            Ouvrir le récap. de production correspondant
          </a>
        )}
      </div>
    </div>
  );
};

export default withRouter(Filters);
