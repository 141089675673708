import React, { useEffect, useState } from "react";
import Control from "../../../../../../../../common/Controls/Control";
import actionTools from "../../../../../Context/actions/actionTools";
import useStoreState from "../../../../../Context/Store/useStoreState";
import ColorPicker from "../ColorPicker";

const AddDishSlot = ({ day, moment, isVisible }) => {
  const { items, items_actions } = useStoreState();
  const defaultColor = "#8bc34a";
  const [slotCode, setSlotCode] = useState("");
  const [color, setColor] = useState(defaultColor);
  const { slotCodes } = { ...items };

  useEffect(() => {
    if (slotCode.length) {
      setColor(slotCodes.find((s) => s.id == slotCode).color);
    }
  }, [slotCode]);

  const addNew = () => {
    let { slots } = items.menu;

    var sameSlots = slots.filter(
      (s) => s.day == day.id && s.moment == moment.id
    );
    var order = sameSlots.length
      ? Math.max.apply(
          Math,
          sameSlots.map(function (o) {
            return o.order;
          })
        ) + 1
      : 1;

    var newSlot = {
      menuRef: items.menuRef.id,
      day: day.id,
      moment: moment.id,
      slotCode,
      color,
      technicalSheet: "",
      alternatives: [],
      order,
    };
    newSlot.id = actionTools.getDishSlotUid(newSlot);

    items_actions.addDishSlot(newSlot);
    setSlotCode("");
    setColor(defaultColor);
  };

  return (
    <div
      className={`d-center mt-3 position-absolute w-100 animated faster ${
        isVisible ? "fadeIn" : "fadeOut"
      }`}
      style={{
        bottom: 10,
        left: 0,
      }}
    >
      <form
        className="d-center"
        onSubmit={(e) => {
          e.preventDefault();
          addNew();
        }}
      >
        <ColorPicker color={color} setColor={setColor} />
        <Control
          placeholder="Choisissez..."
          type="select"
          datas={slotCodes.filter((s) => s.isManual)}
          dataIndex="id"
          dataLabel="name"
          value={slotCode}
          change={(e) => setSlotCode(e.target.value)}
          labelStyle={{ minHeight: 0 }}
          className="text-dark"
        />

        <button
          type="button"
          className="btn btn-default btn-sm"
          disabled={slotCode ? false : true}
          onClick={addNew}
        >
          <i className="fa fa-plus" />
        </button>
      </form>
    </div>
  );
};

export default AddDishSlot;
