import React, { useEffect } from "react";
import Control from "../../../../common/Controls/Control";
import DynamicList from "../../../../common/DynamicList";
import Loader from "../../../../common/Loader";
import SaveBtn from "../../../../common/SaveBtn";

import useStoreState from "../useStoreState";

const ProductionSitesList = (props) => {
  const { state, items, dispatch, gState, gDispatch, items_actions } =
    useStoreState();
  const client = { ...items.client };

  useEffect(() => {
    console.log("state", state);
  }, [client]);

  return !client ? (
    <Loader />
  ) : (
    <>
      <div className="row p-3 shadow-sm bg-white rounded">
        <div className="col-12 col-md-6">
          <Control
            type="text"
            label="Nom"
            name="name"
            value={client.name}
            change={(e) => items_actions.updateItemObject("client", e)}
            error={state.errors}
          />
        </div>
        <div className="col-12 col-md-6">
          <Control
            type="text"
            label="Code"
            name="code"
            value={client.code}
            change={(e) => items_actions.updateItemObject("client", e)}
            error={state.errors}
          />
        </div>

        <div className="col-12">
          <DynamicList
            label="Frais fixes"
            name="fixFees"
            change={(e) => items_actions.updateItemObject("client", e)}
            fields={[
              {
                label: "Nom",
                type: "text",
                name: "name",
              },
              {
                label: "Prix",
                type: "float",
                name: "price",
                suffix: "€",
              },
            ]}
            value={client.fixFees}
            btnLabel="Ajouter un frais fixe"
            error={state.errors}
          />
        </div>
        <div className="col-12 col-md-6">
          <Control
            type="checkbox"
            label="Est archivé ?"
            name="isArchived"
            checked={client.isArchived}
            change={(e) => items_actions.updateItemObject("client", e)}
            error={state.errors}
          />
        </div>
      </div>
      <div className="row" data-priv="create_modify_client">
        <div className="col-12 p-0">
          <div className="d-flex justify-content-end w-100">
            <SaveBtn
              save={() => items_actions.saveItemObject("client")}
              isSaving={state.isLoading}
              text="Enregistrer le client"
              type="primary"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductionSitesList;
