import React, { useEffect, useState } from "react";
import Control from "../../../../../../../common/Controls/Control";
import SaveBtn from "../../../../../../../common/SaveBtn";

const DayComment = ({ day, itemsActions, menuRef }) => {
  console.log(day, menuRef);
  var entry = menuRef.daysComments.find((d) => d.day == day.id);
  const [comment, setComment] = useState(entry ? entry.comment : "");

  const updateComments = () => {
    var daysComments = [...menuRef.daysComments] || [];

    if (menuRef.daysComments && entry) {
      entry.comment = comment;
      daysComments = menuRef.daysComments.map((m) =>
        m.day == day.id ? { ...m, comment } : m
      );
    } else {
      entry = { day: day.id, comment };
      daysComments.push(entry);
    }
    console.log("daysComments", daysComments);

    itemsActions.updateDaysComments(daysComments);
  };

  return (
    <div>
      <label>
        <span className="label-text">Commentaire pour le {day.name}</span>

        <textarea
          className="d-block w-100"
          style={{ minHeight: 200 }}
          name="comment"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        ></textarea>
      </label>
      <div className="d-flex align-items-center justify-content-end">
        <SaveBtn save={updateComments} isSaving={false} />
      </div>
    </div>
  );
};

export default DayComment;
