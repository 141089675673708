import React, { useState } from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import PrivateRoute from "./components/common/PrivateRoute";
// Pages
import Sidenav from "./components/common/layout/Sidenav";
import Dashboard from "./components/pages/Dashboard/Dashboard";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RenewPassword from "./components/pages/RenewPassword/RenewPassword";
import ActivateAccount from "./components/pages/ActivateAccount/ActivateAccount";
import Colors from "./theme/variables.scss";
import Register from "./components/pages/Register/Register";
import Profile from "./components/pages/Profile/Profile";
import CustomModal from "./components/common/CustomModal/CustomModal";
import Test from "./components/pages/Test";
import Logout from "./components/pages/Logout/Logout";
import { AnimatePresence } from "framer-motion";

import ManagerList from "./components/pages/Managers/ManagerList/ManagerList";
import ManagerDetail from "./components/pages/Managers/ManagerDetail/ManagerDetail";
import Loader from "./components/common/Loader";
import ProductionSiteList from "./components/pages/ProductionSites/ProductionSiteList/ProductionSiteList";
import ProductionSiteDetail from "./components/pages/ProductionSites/ProductionSiteDetail/ProductionSiteDetail";
import SectorDetail from "./components/pages/Sectors/SectorDetail/SectorDetail";
import SectorList from "./components/pages/Sectors/SectorList/SectorList";
import ConsumerTypeDetail from "./components/pages/ConsumerTypes/ConsumerTypeDetail/ConsumerTypeDetail";
import ConsumerTypeList from "./components/pages/ConsumerTypes/ConsumerTypeList/ConsumerTypeList";
import DishTypeList from "./components/pages/DishTypes/DishTypeList/DishTypeList";
import DishTypeDetail from "./components/pages/DishTypes/DishTypeDetail/DishTypeDetail";
import ProviderList from "./components/pages/Providers/ProviderList/ProviderList";
import ProviderDetail from "./components/pages/Providers/ProviderDetail/ProviderDetail";
import IngredientList from "./components/pages/Ingredients/IngredientList/IngredientList";
import IngredientDetail from "./components/pages/Ingredients/IngredientDetail/IngredientDetail";
import TextureList from "./components/pages/Textures/TextureList/TextureList";
import TextureDetail from "./components/pages/Textures/TextureDetail/TextureDetail";
import ClientList from "./components/pages/Clients/ClientList/ClientList";
import ClientDetail from "./components/pages/Clients/ClientDetail/ClientDetail";

import TechnicalSheetList from "./components/pages/TechnicalSheets/TechnicalSheetList/TechnicalSheetList";
import TechnicalSheetDetail from "./components/pages/TechnicalSheets/TechnicalSheetDetail/TechnicalSheetDetail";
import SidePanel from "./components/common/SidePanel/SidePanel";
import Items from "./components/pages/Items/Items";
import GlobalMenu from "./components/pages/Menus/GlobalMenu/GlobalMenu";
import SpecificitiesList from "./components/pages/Specificities/SpecificitiesList/SpecificitiesList";
import SpecificitiesDetail from "./components/pages/Specificities/SpecificitiesDetail/SpecificitiesDetail";
import SlotCodesList from "./components/pages/SlotCodes/SlotCodesList/SlotCodesList";
import SlotCodesDetail from "./components/pages/SlotCodes/SlotCodesDetail/SlotCodesDetail";
import SiteList from "./components/pages/Sites/SiteList/SiteList";
import SiteDetail from "./components/pages/Sites/SiteDetail/SiteDetail";
import ClientContractList from "./components/pages/ClientContracts/ClientContractList/ClientContractList";
import ClientContractDetail from "./components/pages/ClientContracts/ClientContractDetail/ClientContractDetail";
import Order from "./components/pages/Order/Order";
import DuplicateMenuRef from "./components/pages/DuplicateMenuRef/DuplicateMenuRef";
import ProductionSummary from "./components/pages/Summaries/Production/ProductionSummary";
import PackingSummary from "./components/pages/Summaries/Packing/PackingSummary";
import AllotmentSummary from "./components/pages/Summaries/Allotment/AllotmentSummary";
import DeliverySummary from "./components/pages/Summaries/Delivery/DeliverySummary";
import BillingSummary from "./components/pages/Summaries/Billing/BillingSummary";
import BillingGlobalSummary from "./components/pages/Summaries/BillingGlobal/BillingGlobalSummary";
import DownloadTechnicalSheets from "./components/pages/TechnicalSheets/TechnicalSheetList/DownloadTechnicalSheets";
import PrintTechnicalSheets from "./components/pages/TechnicalSheets/TechnicalSheetList/PrintTechnicalSheets";
import PrintTechnicalSheetsList from "./components/pages/TechnicalSheets/TechnicalSheetList/PrintTechnicalSheetsList";
import PurchaseSummary from "./components/pages/Summaries/Purchase/PurchaseSummary";
import OrderReceiptList from "./components/pages/Summaries/Purchase/OrderReceiptList/OrderReceiptList";
import OutcomeSummary from "./components/pages/Summaries/Outcome/OutcomeSummary";
import PurchaseProviderSummary from "./components/pages/Summaries/PurchaseProvider/PurchaseProviderSummary";
import PurchaseIngredientSummary from "./components/pages/Summaries/PurchaseIngredient/PurchaseIngredientSummary";
import ClientMenuPrint from "./components/pages/Order/OrderPage/ClientMenuPrint";
import ExpensesSummary from "./components/pages/Summaries/Expenses/ExpensesSummary";
import RoleStyle from "./components/common/layout/RoleStyle";
import useApp from "./useApp";
import AllSitesList from "./components/pages/Sites/AllSitesList/AllSitesList";
import ClosingPeriods from "./components/pages/Clients/ClosingPeriods/ClosingPeriods";
import SpecificitiesSummary from "./components/pages/Summaries/Specificities/SpecificitiesSummary";

window.borderRadius = Colors.borderRadius;

const App = () => {
  const [sideNavOpen, setSivenavOpen] = useState(true);
  const { state } = useApp();

  return (
    <Router>
      <div className="wrapper">
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        <Sidenav isOpen={sideNavOpen} setSivenavOpen={setSivenavOpen} />
        {Object.keys(state.constants.items).length ? (
          <div id="content" className="position-relative">
            <AnimatePresence exitBeforeEnter={true}>
              <Switch>
                <Route path="/items" exact component={Items} />
                <Route path="/renew-password/:token" exact component={RenewPassword} />

                {process.env.NODE_ENV == "development" && (
                  <Route path="/test" exact component={Test} />
                )}
                <Route path="/activate-account/:token/:email" exact component={ActivateAccount} />
                <Route path="/renew-password/:token/:email" exact component={RenewPassword} />
                <Route path="/logout" exact component={Logout} />
                <Route path="/register" exact component={Register} />

                <PrivateRoute path="/" exact component={Dashboard} />
                <PrivateRoute path="/dashboard" exact component={Dashboard} />
                <PrivateRoute path="/profile" exact component={Profile} />
                <PrivateRoute path="/managers" exact component={ManagerList} />
                <PrivateRoute path="/managers/:id" exact component={ManagerDetail} />
                <PrivateRoute path="/productionsites" exact component={ProductionSiteList} />
                <PrivateRoute path="/orders" exact component={Order} />
                <PrivateRoute
                  path="/orders/print/:menurefId/:date/:clientId/:siteId"
                  exact
                  component={ClientMenuPrint}
                />

                <PrivateRoute path="/productionsites/:id" exact component={ProductionSiteDetail} />
                <PrivateRoute path="/sectors" exact component={SectorList} />
                <PrivateRoute path="/sectors/:id" exact component={SectorDetail} />
                <PrivateRoute path="/consumertypes" exact component={ConsumerTypeList} />
                <PrivateRoute path="/consumertypes/:id" exact component={ConsumerTypeDetail} />
                <PrivateRoute path="/dishtypes" exact component={DishTypeList} />
                <PrivateRoute path="/dishtypes/:id" exact component={DishTypeDetail} />
                <PrivateRoute path="/providers" exact component={ProviderList} />
                <PrivateRoute path="/providers/:id" exact component={ProviderDetail} />
                <PrivateRoute path="/ingredients" exact component={IngredientList} />
                <PrivateRoute path="/ingredients/:id" exact component={IngredientDetail} />
                <PrivateRoute path="/textures" exact component={TextureList} />
                <PrivateRoute path="/textures/:id" exact component={TextureDetail} />
                <PrivateRoute path="/clients" exact component={ClientList} />
                <PrivateRoute path="/clients/:id" exact component={ClientDetail} />
                <PrivateRoute path="/closing-periods" exact component={ClosingPeriods} />

                <PrivateRoute path="/technicalsheets" exact component={TechnicalSheetList} />
                <PrivateRoute path="/technicalsheets/:id" exact component={TechnicalSheetDetail} />
                <PrivateRoute path="/menu-reference" exact component={GlobalMenu} />
                <PrivateRoute path="/duplicate-cycle" exact component={DuplicateMenuRef} />
                <PrivateRoute path="/specificities" exact component={SpecificitiesList} />
                <PrivateRoute path="/specificities/:id" exact component={SpecificitiesDetail} />
                <PrivateRoute path="/slotcodes" exact component={SlotCodesList} />
                <PrivateRoute path="/slotcodes/:id" exact component={SlotCodesDetail} />
                <PrivateRoute path="/sites/:clientid" exact component={SiteList} />
                <PrivateRoute path="/sites" exact component={AllSitesList} />
                <PrivateRoute path="/sites/:clientid/:id" exact component={SiteDetail} />
                <PrivateRoute path="/contracts/:clientid" exact component={ClientContractList} />
                <PrivateRoute
                  path="/contracts/:clientid/:id"
                  exact
                  component={ClientContractDetail}
                />
                <PrivateRoute path="/sum-prod" exact component={ProductionSummary} />
                <PrivateRoute
                  path="/sum-prod/:productionsite/:date"
                  exact
                  component={ProductionSummary}
                />
                <PrivateRoute path="/sum-pack" exact component={PackingSummary} />
                <PrivateRoute path="/sum-allot" exact component={AllotmentSummary} />
                <PrivateRoute path="/sum-deliv" exact component={DeliverySummary} />
                <PrivateRoute path="/sum-act" exact component={BillingSummary} />
                <PrivateRoute path="/sum-ca" exact component={BillingGlobalSummary} />
                <PrivateRoute path="/sum-achat" exact component={PurchaseSummary} />
                <PrivateRoute path="/sum-spec" exact component={SpecificitiesSummary} />
                <PrivateRoute
                  path="/sum-achat-provider"
                  exact
                  component={PurchaseProviderSummary}
                />
                <PrivateRoute
                  path="/sum-achat-ingredient"
                  exact
                  component={PurchaseIngredientSummary}
                />
                <PrivateRoute path="/sum-sortie" exact component={OutcomeSummary} />
                <PrivateRoute path="/sum-depenses" exact component={ExpensesSummary} />

                <PrivateRoute
                  path="/print-all-ts-list"
                  exact
                  component={PrintTechnicalSheetsList}
                />
                <PrivateRoute path="/print-all-ts" exact component={PrintTechnicalSheets} />
                <PrivateRoute path="/download-all-ts" exact component={DownloadTechnicalSheets} />
                <PrivateRoute path="/order-receipts" exact component={OrderReceiptList} />
              </Switch>
            </AnimatePresence>
          </div>
        ) : (
          <Loader />
        )}
      </div>
      <CustomModal />
      <SidePanel />
      <RoleStyle />
    </Router>
  );
};

export default App;
