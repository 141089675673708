import React from "react";
import Control from "../../../common/Controls/Control";

const ProductionSiteListFilters = ({ search, setSearch, errors }) => {
  return (
    <>
      <div className="col-12 col-md-4">
        <Control
          label="Recherche"
          type="text"
          name="searchString"
          value={search.searchString}
          change={(e) =>
            setSearch({ ...search, page: 1, searchString: e.target.value })
          }
          error={errors}
        />
      </div>
      <div className="col-12 col-md-4"></div>
      <div className="col-12 col-md-4"></div>
    </>
  );
};

export default ProductionSiteListFilters;
